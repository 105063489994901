/**
 * ColorPicker
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { MuiColorInputValue } from 'mui-color-input';

import { ThemeTogglePart } from './parts/ThemeTogglePart';
import { BackgroundAndTextPart } from './parts/BackgroundAndTextPart';
import { ColorInputPart, ColorInputPartProps } from './parts/ColorInputPart';


export interface ColorPickerProps {
    color: MuiColorInputValue;
    onChange: (color: string) => void;
    withTheme?: boolean;
    modes?: [ 'text', 'background' ];
    onThemeChange?: (value: 'light' | 'dark') => void;
    inputProps?: Partial<ColorInputPartProps>;
}


const ColorPicker = (props: ColorPickerProps) => {

    const {
        color,
        onChange,
        onThemeChange,
        withTheme = false,
        inputProps, modes = [],
    } = props;

    switch (
        modes.length === 2
            ? withTheme ? 1 : 2
            : withTheme ? 3 : 4
        ) {
        case 1:
            return (
                <div className="flex flex-col justify-center gap-4">
                    <BackgroundAndTextPart color={color} onChange={onChange}/>

                    <ThemeTogglePart onThemeChange={onThemeChange}/>
                </div>
            );

        case 2:
            return <BackgroundAndTextPart color={color} onChange={onChange}/>;

        case 3:
            return (
                <div className="flex flex-col justify-center gap-4">
                    <ColorInputPart value={color} onChange={onChange} {...inputProps}/>

                    <ThemeTogglePart onThemeChange={onThemeChange}/>
                </div>
            );

        case 4:
            return (
                <div className="flex gap-2">
                    <ColorInputPart value={color} onChange={onChange} {...inputProps}/>
                </div>
            );
    }
};


export { ColorPicker };
