/**
 * LanguageOptionsPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from 'mobx-react';
import { ConfigStore } from '@/store/core/config';

import { languageNames } from '@/shared/types';
import { setLanguage, useLanguage } from '@/hooks/core';

import { SimpleCell } from '@exode.ru/vkui';
import { Icon20Check } from '@vkontakte/icons';

import { IconByLang } from '@/assets/images/countries';


const LanguageOptionsPart = observer(() => {

    const { languages } = useLanguage();

    return (
        <div className="min-w-[210px]">
            {languages.map((language, index) => {
                const LanguageIcon = IconByLang[language];

                return (
                    <SimpleCell key={index}
                                className="!rounded-none"
                                onClick={() => setLanguage(language)}
                                after={ConfigStore.language === language && <Icon20Check/>}
                                before={<LanguageIcon className="mr-2" width={28} height={28}/>}>
                        {languageNames[language]}
                    </SimpleCell>
                );
            })}
        </div>
    );
});


export { LanguageOptionsPart };
