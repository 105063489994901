/**
 * MaxPointSliderPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useFormikContext } from 'formik';

import { useI18n } from '@/hooks/core';
import { useAutoSaveFormContext } from '@/cutils';

import { FormItem, Slider, Text } from '@exode.ru/vkui';


interface Props {
    onInstantChange?: () => void;
}


const MaxPointSliderPart = (props: Props) => {

    const { onInstantChange } = props;

    const { t } = useI18n('components.Task.Builder.edit');

    const {
        values,
        setFieldValue,
    } = useFormikContext<{ maxPoint: number }>();

    const { handleAutoSaveChange } = useAutoSaveFormContext();

    return (
        <FormItem top={t('maxPoint')} className="px-0">
            <Slider min={1} max={10} step={1} value={values.maxPoint} onChange={(value) => {
                setFieldValue('maxPoint', value);
                onInstantChange?.();

                handleAutoSaveChange();
            }}/>

            <div className="flex justify-between">
                <Text className="text-accent" weight="regular">
                    {values.maxPoint}
                </Text>

                <Text className="text-secondary" weight="regular">
                    10
                </Text>
            </div>
        </FormItem>
    );
};


export { MaxPointSliderPart };
