/**
 * DynamicFormFieldPart
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { FormikProps } from 'formik';

import { Field } from '@/cutils';
import { getTranslated } from '@/hooks/core';
import { TranslateJson } from '@/shared/types';

import { Icon24CheckBoxOff, Icon24CheckBoxOn } from '@vkontakte/icons';
import { Checkbox, FormItem, Input, SegmentedControl, Select, Textarea } from '@exode.ru/vkui';

import { InnerHtml } from '@/components/Atoms/InnerHtml';
import { ContentCard } from '@/components/Atoms/ContentCard';
import { CopyToClipboard } from '@/components/Atoms/CopyToClipboard';
import { FieldBottomLimiter } from '@/components/Atoms/FieldBottomLimiter';

import { DynamicFormProps } from '../DynamicForm';


interface Props {
    index: number;
    config: FormikProps<Record<string, string>> | null;
    field: DynamicFormProps['fields'][number];
    payload?: DynamicFormProps['payload'];
    forceShowHidden?: boolean;
}


const DynamicFormFieldPart = (props: Props) => {

    const field = _.cloneDeep(props.field);

    const {
        index,
        config,
        payload = {},
        forceShowHidden = false,
    } = props;

    const {
        values = {},
        touched = {},
        errors = {},
        handleBlur = () => {},
        handleChange = () => {},
        setFieldValue = () => {},
    } = config || {};

    const {
        type,
        name,
        title,
        hidden,
        hideIf,
        options,
        placeholder,
        description,
        setValueType,
        templates = [],
    } = field;

    const top = field.top
        ? <InnerHtml content={getTranslated(field.top as TranslateJson)}/>
        : undefined;

    const hiddenByValue = _.some(hideIf || {}, (e: any[], path) => (
        e?.includes(_.get(props, path))
    ));

    if (!forceShowHidden && (hidden || hiddenByValue)) {
        return <></>;
    }

    for (const templatePath of templates) {
        try {
            _.templateSettings.interpolate = /{{([\s\S]+?)}}/g;

            const compiled = _.template(_.get(field, templatePath));

            _.set(field, templatePath, compiled(payload));
        } catch (e) {
            console.log('DynamicFormFieldPart template error', e);
        }
    }

    const transformSetValue = (value: any) => {
        switch (setValueType) {
            case 'number':
                return +value;

            case 'string':
                return `${value}`;

            default:
                return value;
        }
    };

    switch (type) {
        case 'input':
            return (
                <FormItem top={top}
                          key={index}
                          className="p-0"
                          data-test="dynamic-form.input"
                          status={Field.status(errors, touched, name)}
                          bottom={Field.message(errors, touched, name)} {...field.props}>
                    <Input name={name}
                           onBlur={handleBlur}
                           value={values[name]}
                           placeholder={getTranslated(placeholder as TranslateJson)}
                           onChange={Field.transform.ignoreSpaceOnChange(handleChange)}
                           {...field.props}/>
                </FormItem>
            );

        case 'textarea':
            return (
                <FormItem top={top}
                          key={index}
                          className="p-0"
                          data-test="dynamic-form.textarea"
                          status={Field.status(errors, touched, name)}
                          bottom={(
                              <FieldBottomLimiter value={values[name]}
                                                  maxLength={field.maxLength}
                                                  message={Field.message(errors, touched, name)}/>
                          )}>
                    <Textarea name={name}
                              rows={field.rows}
                              value={values[name]}
                              maxLength={field.maxLength}
                              placeholder={getTranslated(placeholder as TranslateJson)}
                              onChange={Field.transform.ignoreSpaceOnChange(handleChange)}
                              {...field.props}/>
                </FormItem>
            );

        case 'select':
            return (
                <FormItem top={top}
                          key={index}
                          className="p-0"
                          data-test="dynamic-form.select"
                          status={Field.status(errors, touched, name)}
                          bottom={Field.message(errors, touched, name)}>
                    <Select name={name}
                            onBlur={handleBlur}
                            value={values[name]}
                            placeholder={getTranslated(placeholder as TranslateJson)}
                            onChange={(e) => setFieldValue(name, transformSetValue(e.target.value))}
                            options={(
                                _.map(options, ({ value, label }) => ({
                                    value,
                                    label: getTranslated(label as TranslateJson) || '',
                                }))
                            )}
                            {...field.props}/>
                </FormItem>
            );

        case 'checkbox':
            return (
                <ContentCard compact
                             key={index}
                             mode="outline"
                             hasHover={false}
                             hasActive={false}
                             activeAfter={false}
                             innerCardClassName="bg-hover"
                             data-test="dynamic-form.checkbox"
                             className="!rounded-lg select-none mt-1 mb-4"
                             header={(
                                 <Checkbox name={name}
                                           hasHover={false}
                                           hasActive={false}
                                           onChange={handleChange}
                                           checked={!!values[name]}
                                           data-test="dynamic-form.checkbox"
                                           onIcon={<Icon24CheckBoxOn fill="var(--dynamic_green)"/>}
                                           offIcon={<Icon24CheckBoxOff fill="var(--text_secondary)"/>}
                                           description={(
                                               <InnerHtml className="text-primary" content={(
                                                   getTranslated(description as TranslateJson)
                                               )}/>
                                           )} {...field.props}>
                                     <InnerHtml content={getTranslated(title as TranslateJson)}/>
                                 </Checkbox>
                             )}/>
            );

        case 'banner':
            return (
                <ContentCard transparent
                             key={index}
                             mode="outline"
                             activeAfter={false}
                             data-test="dynamic-form.banner"
                             className="vk-rounded overflow-hidden"
                             header={(
                                 field.props?.header && (
                                     <InnerHtml content={getTranslated(field.props.header)}/>
                                 )
                             )}
                             subtitle={(
                                 field.props?.subtitle && (
                                     <InnerHtml content={getTranslated(field.props.subtitle)}/>
                                 )
                             )}
                             caption={(
                                 field.props?.caption && (
                                     <InnerHtml content={getTranslated(field.props.caption)}/>
                                 )
                             )} {...field.props}/>
            );

        case 'copy-input':
            return (
                <FormItem key={index} top={top} className="p-0" data-test="dynamic-form.copy-input">
                    <div>
                        <CopyToClipboard link={field.props?.link} {...field.props}/>
                    </div>
                </FormItem>
            );

        case 'segmented-control':
            return (
                <FormItem top={top}
                          key={index}
                          className="p-0"
                          data-test="dynamic-form.segmented-control"
                          status={Field.status(errors, touched, name)}
                          bottom={Field.message(errors, touched, name)}>
                    <SegmentedControl size="l"
                                      className="w-full"
                                      value={values[name]}
                                      onChange={(value) => setFieldValue(name, transformSetValue(value))}
                                      options={(
                                          _.map(options, ({ value, label }) => ({
                                              value,
                                              label: getTranslated(label as TranslateJson) || '',
                                          }))
                                      )} {...field.props}/>
                </FormItem>
            );

        case 'custom-html':
            return (
                <InnerHtml key={index} data-test="dynamic-form.custom-html" {...field.props}/>
            );

        default:
            return <></>;
    }
};


export { DynamicFormFieldPart };
