/**
 * EnrollBannerItem
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ComponentType, MutableRefObject, useEffect } from 'react';

import { useLocation } from '@/router/index';

import { observer, UserAuthStore } from '@/store/user/auth';

import { useI18n, useNative } from '@/hooks/core';
import { ScrollHelper } from '@/helpers/ui';
import { CourseFindOne } from '@/types/course';
import { DocumentEvent } from '@/types/window';
import { useCourseStart } from '@/hooks/apollo';
import { Router } from '@/services/Utils/Router';

import { UserStateKey } from '@/codegen/graphql';

import { Icon16FireVerified, Icon16ShoppingCartOutline, Icon28AddSquareOutline } from '@vkontakte/icons';

import { GradientButtonCard } from '@/components/Atoms/GradientButtonCard';


interface Props {
    course: CourseFindOne;
    component?: ComponentType;
    componentProps?: Record<any, any>;
    demoLesson?: CourseFindOne['lessons'][number];
    pricesRef?: MutableRefObject<HTMLDivElement | null>;
    onePrice?: CourseFindOne['product']['prices'][number] | null;
}


const EnrollBannerItem = observer((props: Props) => {

    const {
        course,
        onePrice,
        pricesRef,
        demoLesson,
        componentProps,
        component: Component = GradientButtonCard,
        course: {
            product,
            product: { currentOnSaleLaunch },
        },
    } = props;

    const { t } = useI18n('pages.Course.Information');

    const {
        route: {
            params: { enrollAfterAuth },
        },
    } = useLocation();

    const { triggerHaptic } = useNative();

    const {
        onAccessForFree,
        onAccessForFreeCalled,
        onAccessForFreeLoading,
    } = useCourseStart();

    const proceedToPay = async () => {
        if (onePrice) {
            triggerHaptic();

            Router.pushPage('/pay/:productId([0-9]+)/:priceId([0-9]+)', {
                productId: `${product.id}`,
                priceId: `${onePrice.id}`,
            });
        }
    };

    const scrollToPrices = () => {
        if (pricesRef?.current) {
            ScrollHelper.to(
                ScrollHelper.calcTopToElement(pricesRef.current),
                true,
            );
        }
    };

    const handleClick = () => {
        if (demoLesson) {
            return Router.pushPage(
                '/courses/:page([0-9]+)/:courseId([0-9]+)/study/:lessonId([0-9]+)',
                { courseId: `${course.id}`, lessonId: `${demoLesson.id}` },
            );
        }

        return !product.isFree
            ? (onePrice ? proceedToPay() : scrollToPrices())
            : onAccessForFree(currentOnSaleLaunch?.id);
    };

    const handleFreeEnrollment = () => {
        Router.updateParams({ enrollAfterAuth: 'false' });

        onAccessForFree(currentOnSaleLaunch?.id);
    };

    useEffect(() => {
        if (enrollAfterAuth !== 'true'
            || onAccessForFreeCalled
            || course?.isParticipant
        ) {
            return;
        }

        if (UserAuthStore.states[UserStateKey.PersonalInfoFilled]) {
            handleFreeEnrollment();
        } else {
            document.addEventListener(
                DocumentEvent.ProfilePersonalInfoFilledSuccess,
                handleFreeEnrollment,
                { once: true },
            );

            document.addEventListener(
                DocumentEvent.ProfilePersonalInfoAlreadyFilled,
                handleFreeEnrollment,
                { once: true },
            );
        }

        return () => {
            document.removeEventListener(
                DocumentEvent.ProfilePersonalInfoFilledSuccess,
                handleFreeEnrollment,
            );

            document.removeEventListener(
                DocumentEvent.ProfilePersonalInfoAlreadyFilled,
                handleFreeEnrollment,
            );
        };
    }, [ enrollAfterAuth ]);

    return (
        <Component transparent
                   wholeIsClickable
                   onClick={handleClick}
                   data-test="course.payButton"
                   className="card-clear-after"
                   isLoading={onAccessForFreeLoading}
                   style={{ background: 'var(--accent)' }}
                   gradient={[ 'd:!transparent' ].join(' ')}
                   afterIcon={<Icon28AddSquareOutline fill="var(--white)"/>}
                   subheader={(
                       <span className="text-white">
                           {t('willStartTrainingAfterEnrollment')}
                       </span>
                   )}
                   header={(
                       <div className="flex gap-1 text-white">
                           {(
                               product.isFree
                                   ? t('takeCourseForFree')
                                   : t('enrollInCourse')
                           )}
                       </div>
                   )}
                   buttonProps={{
                       appearance: 'overlay',
                       children: product.isFree
                           ? t('startEducation')
                           : (onePrice ? t('pay') : t('tariffs')),
                       before: (
                           product.isFree
                               ? <Icon16FireVerified/>
                               : <Icon16ShoppingCartOutline/>
                       ),
                   }}
                   {...{
                       loading: onAccessForFreeLoading,
                       disabled: onAccessForFreeLoading,
                   }}
                   {...componentProps}/>
    );
});


export { EnrollBannerItem };
