/**
 * ProfileSetStatusView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import moment from 'moment';

import { createValidator } from 'class-validator-formik';

import React from 'react';

import { Form, Formik } from 'formik';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';
import { UserAuthStore } from '@/store/user/auth';

import { ProfileGetTitleQuery } from '@/codegen/graphql';

import { Field, Graphql, If } from '@/cutils';
import { RouterStore } from '@/store/core/router';
import { ConfigStore, observer } from '@/store/core/config';

import { Router } from '@/services/Utils/Router';
import { useProfileUpdateTitleManually } from '@/hooks/apollo';

import { DateInput } from '@/components/Atoms/DateInput';
import { Button, ButtonGroup, FormItem, Input, Select } from '@exode.ru/vkui';
import { Icon20ClearDataOutline, Icon20HistoryBackwardOutline, Icon20UserStarBadgeOutline } from '@vkontakte/icons';

import { UpdateTitleProfileInput } from '@/libs/class-validator';

import { ProfileStatusEmojiPickerItem } from '../items/ProfileStatusEmojiPickerItem';


const ProfileSetStatusView = observer(() => {

    const { t } = useI18n('pages.Profile.Profile');

    const { user } = UserAuthStore;

    const {
        loadingItem,
        profileClearTitleManually,
        profileUpdateTitleManually,
        profileUpdateTitleManuallyLoading,
    } = useProfileUpdateTitleManually();

    return (
        <ProfileGetTitleQuery skip={!user?.id} variables={{ userId: user?.id || 0 }} children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <Page.Spinner/>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({ profileFindOne: profile }) => {
                        const initialValues = {
                            manualTitle: profile.titleState.manualTitle || '',
                            manualEmojiTitle: profile.titleState.manualEmojiTitle || '',
                            autoCleanMode: profile.titleState.manualExpiredAt
                                ? 'dateAndTime'
                                : 'permanent',
                            manualExpiredAt: moment(profile.titleState.manualExpiredAt).isAfter()
                                ? moment(profile.titleState.manualExpiredAt).toDate()
                                : undefined,
                        };

                        return (
                            <Formik validateOnBlur
                                    validateOnMount
                                    validateOnChange
                                    enableReinitialize
                                    isInitialValid={false}
                                    initialValues={initialValues}
                                    initialTouched={{ manualExpiredAt: true }}
                                    validate={createValidator(UpdateTitleProfileInput)}
                                    onSubmit={async (values) => {
                                        const manualExpiredAt = moment(values.manualExpiredAt).isAfter()
                                            ? values.manualExpiredAt
                                            : null;

                                        const prevIsTemporary = !!profile.titleState.manualExpiredAt;

                                        const [ titleKey, emojiKey ] = [
                                            prevIsTemporary ? 'manualNextTitle' : 'manualTitle',
                                            prevIsTemporary ? 'manualNextEmojiTitle' : 'manualEmojiTitle',
                                        ] as const;

                                        await profileUpdateTitleManually({
                                            manualExpiredAt,
                                            ..._.pick(values, [
                                                'manualTitle',
                                                'manualEmojiTitle',
                                            ]),
                                            manualNextTitle: manualExpiredAt
                                                ? profile.titleState[titleKey]
                                                : null,
                                            manualNextEmojiTitle: manualExpiredAt
                                                ? profile.titleState[emojiKey]
                                                : null,
                                        });

                                        if (RouterStore.hasOverlay) {
                                            Router.replaceModal();
                                        }
                                    }}>
                                {({ values, setFieldValue, handleChange, handleSubmit, isValid, touched, errors }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <FormItem className="px-0"
                                                  top={t('indicateYourStatus')}
                                                  status={Field.status(errors, touched, 'manualTitle')}
                                                  bottom={Field.message(errors, touched, 'manualTitle', '')}>
                                            <Input maxLength={25}
                                                   name="manualTitle"
                                                   value={values.manualTitle || ''}
                                                   placeholder={t('whatIsYourStatus')}
                                                   onChange={Field.transform.ignoreSpaceOnChange(handleChange)}
                                                   after={(
                                                       <div className="fs-13 pr-3 text-right w-[50px]">
                                                           {values.manualTitle?.length || 0}/25

                                                       </div>
                                                   )}
                                                   before={(
                                                       <ProfileStatusEmojiPickerItem emoji={values.manualEmojiTitle}
                                                                                     onEmojiClick={(emoji) => setFieldValue('manualEmojiTitle', emoji)}/>
                                                   )}/>
                                        </FormItem>

                                        <FormItem className="px-0" top={t('deleteYourStatusAfter')}>
                                            <Select name="autoCleanMode"
                                                    onChange={handleChange}
                                                    value={values.autoCleanMode}
                                                    options={[
                                                        { label: t('makeItPermanent'), value: 'permanent' },
                                                        { label: t('specifyDateAndTime'), value: 'dateAndTime' },
                                                    ]}/>

                                            <If is={values.autoCleanMode === 'permanent'}>
                                                <div className="mb-4"/>
                                            </If>
                                        </FormItem>

                                        <If is={values.autoCleanMode !== 'permanent'}>
                                            <FormItem className="px-0 mb-4"
                                                      top={t('specifyExpirationDateAndTimeStatus')}
                                                      status={Field.status(errors, touched, 'manualExpiredAt')}
                                                      bottom={Field.message(errors, touched, 'manualExpiredAt', '')}>
                                                <DateInput enableTime
                                                           disablePast
                                                           closeOnChange
                                                           alwaysShowTime
                                                           name="manualExpiredAt"
                                                           minDate={moment().toDate()}
                                                           value={values.manualExpiredAt}
                                                           onChange={(value) => setFieldValue('manualExpiredAt', value)}
                                                           defaultTime={values.manualExpiredAt || moment().add(30, 'minutes').toDate()}/>
                                            </FormItem>
                                        </If>

                                        <ButtonGroup stretched
                                                     className="mt-2"
                                                     mode={ConfigStore.isDesktop ? 'horizontal' : 'vertical'}>
                                            <Button stretched
                                                    size="l"
                                                    type="submit"
                                                    mode="commerce"
                                                    before={<Icon20UserStarBadgeOutline/>}
                                                    disabled={!isValid || profileUpdateTitleManuallyLoading}
                                                    loading={loadingItem === 'update' && profileUpdateTitleManuallyLoading}>
                                                {t('install')}
                                            </Button>
                                        </ButtonGroup>

                                        <If is={(
                                            !!profile.titleState.manualTitle
                                            || !!profile.titleState.manualEmojiTitle
                                            || !!profile.titleState.manualNextTitle
                                            || !!profile.titleState.manualNextEmojiTitle
                                        )}>
                                            <ButtonGroup stretched mode="vertical" className="mt-4">
                                                <If is={!profile.titleState.manualExpiredAt}>
                                                    <Button stretched
                                                            size="m"
                                                            mode="tertiary"
                                                            before={<Icon20ClearDataOutline/>}
                                                            disabled={profileUpdateTitleManuallyLoading}
                                                            loading={loadingItem === 'clear' && profileUpdateTitleManuallyLoading}
                                                            onClick={async () => {
                                                                await profileClearTitleManually();

                                                                if (RouterStore.hasOverlay) {
                                                                    Router.replaceModal();
                                                                }
                                                            }}>
                                                        {t('clearStatus')}
                                                    </Button>
                                                </If>

                                                <If is={!!profile.titleState.manualExpiredAt}>
                                                    <Button stretched
                                                            size="m"
                                                            mode="tertiary"
                                                            before={<Icon20HistoryBackwardOutline/>}
                                                            onClick={async () => {
                                                                await profileClearTitleManually({
                                                                    manualTitle: profile.titleState.manualNextTitle,
                                                                    manualEmojiTitle: profile.titleState.manualNextEmojiTitle,
                                                                });
                                                            }}>
                                                        {t('clearTemporaryStatus')}
                                                    </Button>
                                                </If>
                                            </ButtonGroup>
                                        </If>
                                    </Form>
                                )}
                            </Formik>
                        );
                    }}
                </Graphql.Success>

                <Graphql.Error result={result}/>
            </>
        )}/>
    );
});


export { ProfileSetStatusView };
