/**
 * IconWrapper
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactNode } from 'react';


interface Props {
    children: ReactNode;
    bgColor?: string;
}


const IconWrapper = (props: Props) => {

    const { children, bgColor } = props;

    return (
        <div className={[
            bgColor,
            'flex items-center justify-center bg-gradient-to-br p-1 aspect-[1/1] rounded-full',
        ].join(' ')}>
            {children}
        </div>
    );
};


export { IconWrapper };
