/**
 * CourseLessonInput
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { IsString, MaxLength, ValidateIf } from 'class-validator';


export class CourseLessonInput {

    @IsString()
    @MaxLength(120)
    readonly name: string;

    @IsString()
    @MaxLength(500)
    @ValidateIf((__, v) => !_.isNil(v))
    readonly description: string;

}

