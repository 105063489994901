/**
 * UpdateProfileInput
 *
 * @author: exode <hello@exode.ru>
 */

import * as Yup from 'yup';

import { IS_BIZ_WELCOME } from '@/root/src/env';

import { Parse, regex } from '@/utils';

import { UserSexType } from '@/codegen/graphql';


const firstAndLastNameYupFragment = {
    firstName: Yup.string()
        .required('Имя не может быть пустым')
        .matches(regex.alphabetAndSpace, 'Используйте только буквы')
        .min(2, 'Минимум 2 символа')
        .max(15, 'Максимум 15 символов'),
    lastName: Yup.string()
        .required('Фамилия обязательна')
        .matches(regex.alphabetAndSpace, 'Используйте только буквы')
        .min(2, 'Минимум 2 символа')
        .max(15, 'Максимум 15 символов'),
};

const ContactProfileInputSchema = Yup.object({
    phone: Yup.string()
        .when([], {
            is: () => IS_BIZ_WELCOME,
            then: (schema) => schema.required('Телефон обязателен')
                .test(
                    'is-valid-phone',
                    'Некорректный телефон',
                    (value) => !value || Parse.phone(value).isValid,
                ),
            otherwise: (schema) => schema.notRequired(),
        }),
});

const UpdateProfileInputSchema = Yup.object({
    ...firstAndLastNameYupFragment,
    birthDay: Yup.string().notRequired(),
    birthMonth: Yup.string().notRequired(),
    birthYear: Yup.string().notRequired(),
    bdate: Yup.string().notRequired(),
    domain: Yup.string().required('Домен не может быть пустым'),
    sex: Yup.mixed<UserSexType>().notRequired(),
    contact: Yup.object().when([], {
        is: () => IS_BIZ_WELCOME,
        then: () => ContactProfileInputSchema.required(),
        otherwise: () => ContactProfileInputSchema.notRequired(),
    }),
});

const SchoolUserUpdateInputSchema = Yup.object().shape({
    phone: Yup.string()
        .test(
            'is-valid-phone',
            'Некорректный телефон',
            (value) => !value || Parse.phone(value).isValid,
        ).notRequired(),
    email: Yup.string().email().notRequired(),
    profile: Yup.object(firstAndLastNameYupFragment),
});


export {
    UpdateProfileInputSchema,
    SchoolUserUpdateInputSchema,
};
