/**
 * Basic request headers
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { APP_VERSION } from '@/root/src/env';

import { Storage } from '@/api/storage';
import { PageStore } from '@/store/core/page';
import { ConfigStore } from '@/store/core/config';


export const basicRequestHeaders = () => {
    const token = Storage.get('user:auth-token');

    return {
        'Authorization': _.isString(token) ? `Bearer ${token}` : null,
        'App-version': APP_VERSION,
        'Recaptcha-token': PageStore.recaptchaToken,
        'Bypass-Tunnel-Reminder': true,
        'School-Id': window.exode.school?.id,
        'Seller-Id': Storage.get('seller:id', undefined),
        'Client-Tab': ConfigStore.tabUuid,
        'Ux-Language': ConfigStore.language,
    };
};
