/**
 * BlockWrapperPart
 *
 * @author: exode <hello@exode.ru>
 */

import React, { CSSProperties, ReactNode } from 'react';

import { Page } from '@/pages/Core';


interface Props {
    children: ReactNode;
    index?: number;
    style?: CSSProperties;
}


const BlockWrapperPart = (props: Props) => {

    const { children, index, style } = props;

    return (
        <Page.Row className="relative" style={style}>
            <Page.Section className="pb-3 z-[1] relative" data-test={`ce.block.${(index || 0) + 1}`}>
                {children}
            </Page.Section>
        </Page.Row>
    );
};


export { BlockWrapperPart };
