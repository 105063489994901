/**
 * PlatformLogo
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageStore } from '@/store/core/page';
import { SchoolStore } from '@/store/platform';
import { UserAuthStore } from '@/store/user/auth';
import { observer, PreferenceStore } from '@/store/preference/preference';

import { If, Link, Platform } from '@/cutils';

import { IconButton } from '@mui/material';
import { Icon24PicturePlusOutline } from '@vkontakte/icons';

import { IconBiz, IconBizWhite, IconExodeIcon } from '@/images/icons';


interface Props {
    size?: number;
}


const PlatformLogo = observer((props: Props) => {

    const isSlimMenu = PageStore.mode === 'slim-menu';

    const { size = isSlimMenu ? 30 : 28 } = props;

    return (
        <>
            <Platform.Market>
                <IconExodeIcon width={size}
                               height={size}
                               className="mr-3 min-w-[28px]"
                               style={{ borderRadius: '32%', marginLeft: isSlimMenu ? 1 : 16 }}/>
            </Platform.Market>

            <Platform.School>
                <If is={(!SchoolStore?.school?.iconUrl) && UserAuthStore.isManager}>
                    <Link stopPropagation pushPage={{ id: '/manage/school/settings/preferences' }}>
                        <div className="mr-1" style={{ marginLeft: isSlimMenu ? -5 : 5 }}>
                            <IconButton>
                                <Icon24PicturePlusOutline fill="var(--text_primary)"/>
                            </IconButton>
                        </div>
                    </Link>
                </If>

                <If is={!!SchoolStore?.school?.iconUrl}>
                    <img width={size}
                         height={size}
                         alt="IconSquare"
                         src={SchoolStore?.school?.iconUrl || ''}
                         className={[
                             'mr-3',
                             isSlimMenu ? 'min-w-[30px]' : ' min-w-[28px]',
                         ].join(' ')}
                         style={{
                             borderRadius: '32%',
                             marginLeft: isSlimMenu ? 0 : 17,
                         }}/>
                </If>
            </Platform.School>

            <Platform.BizWelcome>
                <img width={size}
                     height={size}
                     alt="IconSquare"
                     className="mr-3 min-w-[28px]"
                     style={{ borderRadius: '32%', marginLeft: 17 }}
                     src={PreferenceStore.isDark ? IconBizWhite : IconBiz}/>
            </Platform.BizWelcome>
        </>
    );
});


export { PlatformLogo };
