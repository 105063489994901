/**
 * MessageMediaItem
 *
 * @author: exode <hello@exode.ru>
 */

import moment from 'moment';

import React from 'react';

import { StorageFileType } from '@/codegen/graphql';

import { If } from '@/cutils';
import { ChatMessageItem } from '@/types/chat';
import { useParseToPlyrSources, usePlyrApi } from '@/hooks/core';

import { Plyr } from '@/components/Atoms/Plyr';
import { FileRow } from '@/components/Atoms/FileRow';
import { PhotoViewer } from '@/components/Atoms/PhotoViewer';
import { PhotoProvider, PhotoView } from '@/components/Atoms/PhotoProvider';

import { MessageMediaImageItem } from './MessageMediaImageItem';
import { MessageMediaWidgetItem } from './MessageMediaWidgetItem';


interface Props {
    chatId: number;
    message: ChatMessageItem;
}


const MessageMediaItem = (props: Props) => {

    const {
        chatId,
        message,
        message: { medias },
    } = props;

    const { plyrVideoRef } = usePlyrApi({
        onReady: () => {},
    });

    const widgets = medias.filter((item) => item.hasOwnProperty('widget'));
    const attachments = medias.filter((item) => item.hasOwnProperty('storage'));

    const filterByFileType = (fileType: StorageFileType) => {
        return medias.filter(({ storage }) => storage?.fileType === fileType);
    };

    return (
        <>
            <If is={!!widgets?.length}>
                <div onClick={(e) => e.stopPropagation()} className="flex items-start flex-wrap gap-2 cursor-default">
                    {widgets?.map(({ id, widget }) => (
                        <MessageMediaWidgetItem key={id}
                                                widgetId={id}
                                                chatId={chatId}
                                                widget={widget}
                                                message={message}/>
                    ))}
                </div>
            </If>

            <If is={!!attachments?.length}>
                <div className="flex flex-col items-start flex-1 gap-3">
                    <PhotoProvider overlayRender={(props) => (
                        <PhotoViewer.OverlayRender {...props} userCellProps={{
                            disabled: true,
                            mode: 'simple',
                            userId: message.user.id,
                            profile: message.user.profile,
                            lastOnlineAt: message.createdAt,
                            subtitle: moment(message.createdAt).format('DD.MM.YY, HH:mm'),
                            avatarProps: {
                                badge: undefined,
                                showUserOnlineBadge: false,
                            },
                        }}/>
                    )}>
                        {filterByFileType(StorageFileType.Image).map(({ id, storage }) => (
                            <PhotoView src={storage?.imageSizes?.maximum || ''}>
                                <MessageMediaImageItem messageId={message.id}
                                                       media={{ id, storage }}
                                                       onClick={(e) => e.stopPropagation()}/>
                            </PhotoView>
                        ))}
                    </PhotoProvider>

                    {filterByFileType(StorageFileType.File).map(({ storage }) => (
                        <FileRow key={storage?.id}
                                 size={storage?.meta.size}
                                 createdAt={storage?.createdAt}
                                 location={`${storage?.location}`}
                                 onClick={(e) => e.stopPropagation()}
                                 mimeType={`${storage?.meta.mimeType}`}
                                 name={`${storage?.meta.originalName}`}
                                 className="thin-border bg-hover vk-rounded"/>
                    ))}

                    {filterByFileType(StorageFileType.Video).map(({ storage }) => (
                        <Plyr.Wrapper key={storage?.id}
                                      styles={{ roundedOnMobile: true }}
                                      onClick={(e) => e.stopPropagation()}
                                      className="vk-rounded shadow-lg d:max-w-[425px] md:!max-w-[300px] w-full">
                            <Plyr.Video ref={plyrVideoRef}
                                        deps={[ storage?.location ]}
                                        source={useParseToPlyrSources(storage?.location)}/>
                        </Plyr.Wrapper>
                    ))}
                </div>
            </If>
        </>
    );
};


export { MessageMediaItem };
