/**
 * ProductLaunchInput
 *
 * @author: exode <hello@exode.ru>
 */

import { IsOptional, IsString, MinLength } from 'class-validator';

import { DateCompare, IsOnlyDate } from '@/shared/validators';

import { isNotEmpty } from '../constants';


export class ProductLaunchInput {

    @IsString({ message: isNotEmpty('Название') })
    @MinLength(5, {
        message: 'Название должно быть не менее 5 символов',
    })
    readonly name: string;

    @IsOptional()
    @DateCompare(
        'isSameOrBefore',
        'saleFinishAt',
        { isOptional: true },
        { message: 'Дата не может быть больше окончания' },
    )
    @IsOnlyDate({
        optional: true,
        message: isNotEmpty('Дата начала продаж', 'пустой'),
    })
    readonly saleStartAt: Date;

    @IsOptional()
    @DateCompare(
        'isSameOrAfter',
        'saleStartAt',
        { isOptional: true },
        { message: 'Дата не может быть меньше начала' },
    )
    @DateCompare(
        'isSameOrBefore',
        'educationFinishAt',
        { isOptional: true },
        { message: 'Дата не может быть больше окончания обучения' },
    )
    @IsOnlyDate({
        optional: true,
        message: isNotEmpty('Дата окончания продаж', 'пустой'),
    })
    readonly saleFinishAt: Date;

}
