/**
 * UseProfileUpdateTitleManually
 *
 * @author: exode <hello@exode.ru>
 */

import { useState } from 'react';

import { ProfileStore } from '@/store/user/profile';

import { UpdateTitleProfileInput, useProfileUpdateTitleManuallyMutation } from '@/codegen/graphql';

import { Notify } from '@/cutils';
import { useI18n } from '@/hooks/core';


export const useProfileUpdateTitleManually = () => {

    const { t } = useI18n('hooks.apollo.profile');

    const [ loadingItem, setLoadingItem ] = useState<'update' | 'clear' | null>();

    const [ _update, {
        loading: profileUpdateTitleManuallyLoading,
        error: profileUpdateTitleManuallyError,
    } ] = useProfileUpdateTitleManuallyMutation();

    const profileUpdateTitleManually = async (
        title: UpdateTitleProfileInput,
        isSetLoadingItem = true,
    ) => {
        if (isSetLoadingItem) {
            setLoadingItem('update');
        }

        return _update({
            variables: { title },
            update(__, { data }) {
                const { profileUpdateTitleManually } = data || {};

                if (profileUpdateTitleManually) {
                    ProfileStore.merge({
                        ...profileUpdateTitleManually,
                    });
                }
            },
            onError(error) {
                console.error(error);
            },
            onCompleted() {
                Notify.toast.success(t('changesWereSavedSuccessfully'));
            },
        });
    };

    const profileClearTitleManually = (
        title?: UpdateTitleProfileInput,
    ) => {
        setLoadingItem('clear');

        return profileUpdateTitleManually({
            manualTitle: null,
            manualEmojiTitle: null,
            manualExpiredAt: null,
            manualNextTitle: null,
            manualNextEmojiTitle: null,
            ...title,
        }, false);
    };

    return {
        loadingItem,
        profileClearTitleManually,
        profileUpdateTitleManually,
        profileUpdateTitleManuallyError,
        profileUpdateTitleManuallyLoading,
    };
};
