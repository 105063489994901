/**
 * IconKarakalpakstan component
 *
 * @author: exode <hello@exode.ru>
 * @docs: https://nucleoapp.com/svg-flag-icons
 */

import React, { SVGProps } from 'react';


const IconKarakalpakstan = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M1 8C1 5.79086 2.79086 4 5 4H27C29.2091 4 31 5.79086 31 8V24C31 26.2091 29.2091 28 27 28H5C2.79086 28 1 26.2091 1 24V8Z"
                  fill="#209C3A"/>
            <path d="M1 8C1 5.79086 2.79086 4 5 4H27C29.2091 4 31 5.79086 31 8V10C31 12.2091 29.2091 14 27 14H5C2.79086 14 1 12.2091 1 10V8Z"
                  fill="#008EB7"/>
            <path d="M1 9H31V19H1V9Z" fill="#008EB7"/>
            <path d="M1 12H31V20H1V12Z" fill="white"/>
            <path d="M1 12H31V20H1V12Z" fill="#DA251D"/>
            <path d="M1 13H31V19H1V13Z" fill="#F7BD29"/>
            <path d="M7.07787 10.1439C8.2686 10.1439 9.23387 9.19835 9.23387 8.03192C9.23387 6.8655 8.2686 5.91992 7.07787 5.91992C5.88715 5.91992 4.92188 6.8655 4.92188 8.03192C4.92188 9.19835 5.88715 10.1439 7.07787 10.1439Z"
                  fill="white"/>
            <path d="M7.85809 9.85503C8.88645 9.85503 9.72009 9.0384 9.72009 8.03103C9.72009 7.02366 8.88645 6.20703 7.85809 6.20703C6.82974 6.20703 5.99609 7.02366 5.99609 8.03103C5.99609 9.0384 6.82974 9.85503 7.85809 9.85503Z"
                  fill="#008EB7"/>
            <path d="M9.23047 9.07155H11.0945L9.58647 10.1449L10.1625 8.4082L10.7385 10.1449L9.23047 9.07155Z"
                  fill="white"/>
            <path d="M10.2344 7.92702H12.0984L10.5904 9.00033L11.1664 7.26367L11.7424 9.00033L10.2344 7.92702Z"
                  fill="white"/>
            <path d="M11.2383 9.06959H13.1024L11.5943 10.1429L12.1703 8.40625L12.7463 10.1429L11.2383 9.06959Z"
                  fill="white"/>
            <path d="M12.2422 7.92702H14.1063L12.5982 9.00033L13.1742 7.26367L13.7503 9.00033L12.2422 7.92702Z"
                  fill="white"/>
            <path d="M13.2461 9.06959H15.1102L13.6021 10.1429L14.1781 8.40625L14.7542 10.1429L13.2461 9.06959Z"
                  fill="white"/>
            <path opacity="0.15"
                  d="M27 4H5C2.791 4 1 5.791 1 8V24C1 26.209 2.791 28 5 28H27C29.209 28 31 26.209 31 24V8C31 5.791 29.209 4 27 4ZM30 24C30 25.654 28.654 27 27 27H5C3.346 27 2 25.654 2 24V8C2 6.346 3.346 5 5 5H27C28.654 5 30 6.346 30 8V24Z"
                  fill="black"/>
        </svg>
    );
};


export { IconKarakalpakstan };
