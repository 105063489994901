/**
 * TypingItem component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';
import { GqlResult } from '@/types/graphql';
import { ChatFindOneInfo, ChatListItem } from '@/types/chat';
import { ChatListenNewTypingStateSubscriptionResult, ChatType } from '@/codegen/graphql';

import { Caption } from '@exode.ru/vkui';

import { Container } from './TypingItem.styled';


interface Props {
    chat: ChatListItem | ChatFindOneInfo;
    typingState: GqlResult<ChatListenNewTypingStateSubscriptionResult>['chatListenNewTypingState'];
    className?: string;
    captionClassName?: string;
    dotContainerClassName?: string;
}


const TypingItem = (props: Props) => {

    const { t } = useI18n('pages.Chat.Dialog');

    const {
        chat,
        typingState,
        className = '',
        dotContainerClassName = '',
        captionClassName = '',
    } = props;

    const text = () => {
        const { profiles, anotherCount } = typingState || {};

        const typingNames = profiles?.map(({ firstName }) => firstName).join(' ,');

        return [
            chat.type !== ChatType.Personal && typingNames,
            anotherCount && t('andFurtherMembersTyping', { count: anotherCount }),
            !anotherCount && t('severalTyping', { count: profiles?.length }),
        ].filter(e => e).join(' ');
    };

    return (
        <Container className={[ className ].join(' ')}>
            <div className={`flex mt-[2px] ${dotContainerClassName}`}>
                <div className="dot"/>
                <div className="dot"/>
                <div className="dot"/>
            </div>

            <Caption className={[ 'overflow-hidden text-ellipsis', captionClassName ].join(' ')} level="2">
                {text()}
            </Caption>
        </Container>
    );
};


export { TypingItem };
