/**
 * ListPanelView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { DebounceInput } from 'react-debounce-input';

import React, { useEffect, useRef, useState } from 'react';

import { useHotkeys } from 'react-hotkeys-hook';

import { IS_SCHOOL } from '@/root/src/env';

import { DebouncedInput } from '@/types/html';

import { ConfigStore } from '@/store/core/config';

import { useI18n } from '@/hooks/core';
import { observer } from '@/pages/Core';
import { useLocation } from '@/router/index';
import { ScrollHelper } from '@/helpers/ui';
import { ChatListViewMode } from '@/types/chat';
import { If, Link, Portal, ScrollTo } from '@/cutils';

import { Router } from '@/services/Utils/Router';

import { ChatType } from '@/codegen/graphql';

import { Button, HorizontalScroll, IconButton, Search, Tabs } from '@exode.ru/vkui';
import { Icon20AddSquareOutline, Icon24Dismiss, Icon24WriteOutline } from '@vkontakte/icons';

import { StickyButton } from '@/components/Atoms/StickyButton';
import { WindowsCustomScrollbar } from '@/components/Atoms/Styled';

import { ChatCreationView } from './ChatCreationView';
import { ListModePanelView } from './ListModePanelView';
import { SearchModePanelView } from './SearchModePanelView';

import { ChatFolderItem } from '../items/ChatFolderItem';
import { FolderWrapper } from '../parts/FolderWrapper';

import { useChatListFilter } from '../filter';
import { supportChatParams } from '../constants';
import { ChatDialogsPageProps } from '../ChatDialogsPage';


interface Props {
    activeChatId?: number;
    position?: 'left' | 'right';
    listPanel?: ChatDialogsPageProps['listPanel'];
}


const ListPanelView = observer((props: Props) => {

    const { t } = useI18n('pages.Chat.Dialog');

    const { position, activeChatId } = props;

    const { route: { params } } = useLocation();
    const { urlFilter, setUrlFilter } = useChatListFilter();

    const [ mode, setMode ] = useState<ChatListViewMode>('list');

    const parentRef = useRef<HTMLDivElement | null>(null);
    const rounded = position === 'right' ? 'rounded-tr-xl' : 'rounded-tl-xl';

    const withTabButton = mode === 'list' && [
        ChatType.Support,
    ].includes(urlFilter.folder as ChatType);

    const chatFolders = [
        'All' as const,
        ChatType.Personal,
        ChatType.Support,
        ChatType.CuratorSupport,
    ] as const;

    const {
        listPanel: {
            action: panelAction = (
                <IconButton hasHover={false}
                            hasActive={false}
                            className="cursor-pointer"
                            data-test="chat.list-panel-action"
                            onClick={() => setMode(mode === 'list' ? 'creation' : 'list')}>
                    <If is={mode === 'list'}>
                        <Icon24WriteOutline width={28} height={28} fill="var(--accent)"/>
                    </If>

                    <If is={[ 'creation', 'search' ].includes(mode)}>
                        <Icon24Dismiss width={28} height={28} fill="var(--accent)"/>
                    </If>
                </IconButton>
            ),
            search: panelSearch = (
                <DebounceInput minLength={1}
                               maxLength={15}
                               debounceTimeout={500}
                               className="p-0 pl-[2px]"
                               data-test="chat.search.by-chat"
                               onFocus={() => setMode('search')}
                               element={Search as DebouncedInput}
                               value={urlFilter.chatSearch || ''}
                               onChange={({ target: { value } }) => setUrlFilter({ chatSearch: value })}
                               onKeyDown={(e) => {
                                   if (e.key === 'Escape') {
                                       backToListState();
                                       (e.target as HTMLInputElement)?.blur();
                                   }
                               }}/>
            ),
            filters: panelFilters = (
                <Tabs mode="accent" className="thin-border-bottom">
                    <HorizontalScroll showArrows={false}>
                        <FolderWrapper>
                            {chatFolders
                                /** Remove support for school */
                                .filter((item) => IS_SCHOOL ? item !== ChatType.Support : item)
                                .map((folder) => (
                                    <ChatFolderItem key={folder} folder={folder} onSelectedClick={() => {
                                        ScrollHelper.to(
                                            0,
                                            true,
                                            parentRef.current,
                                        );
                                    }}/>
                                ))
                            }
                        </FolderWrapper>
                    </HorizontalScroll>
                </Tabs>
            ),
        } = {},
    } = props;

    const backToListState = () => {
        setMode('list');
        setUrlFilter({ chatSearch: '' });
    };

    const onChatCellClick = (
        clickMode: 'chat' | 'user',
        ids: { chatId: number } | { userId: number },
    ) => {
        const { chatId, userId } = ids as { chatId: number } & { userId: number };

        if (mode === 'search') {
            setUrlFilter({ chatSearch: '' });
        }

        Router.pushPage('/chat', {
            ..._.pick(params, [ 'folder' ]),
            /** Reset folder after click on search chat cell */
            ...(mode === 'search' ? { folder: '' } : {}),
            ...(clickMode === 'chat'
                    ? { chatId: `${chatId}` }
                    : { personalUserId: `${userId}` }
            ),
        });

        if (mode === 'search') {
            setMode('list');
        }
    };

    useHotkeys('esc', () => {
        mode === 'search' && backToListState();
    }, [ mode ]);

    useEffect(() => {
        ScrollHelper.to(0, false, parentRef.current);
    }, [ mode ]);

    return (
        <div className={[
            ConfigStore.isMobileOnly && 'overflow-hidden',
            'w-[300px] m:w-full d:h-[calc(var(--app-height)_-_88px)] d-thin-border-right',
        ].join(' ')}>
            <div className={[
                `bg-content p-2 pr-0 h-[36px]`,
                `flex justify-between items-center`,
                `d:${rounded} m:rounded-none d-thin-border-bottom d:rounded-tl-[14px]`,
            ].join(' ')}>
                <Portal renderer id="page:chat:list-panel:search" placeholder={panelSearch}/>

                <>{panelAction}</>
            </div>

            <If is={mode === 'list'}>
                <>{panelFilters}</>
            </If>

            <WindowsCustomScrollbar ref={parentRef} className={[
                'overflow-y-auto overflow-x-hidden m:h-full relative',
                mode === 'list' ? 'd:h-[calc(100%_-_97px)]' : 'd:h-[calc(100%_-_52px)]',
                mode === 'creation' ? 'flex flex-col flex-1' : '',
            ].join(' ')}>
                {/** List mode (default) */}
                <If is={mode === 'list'}>
                    <ListModePanelView parentRef={parentRef}
                                       chatFolders={chatFolders}
                                       activeChatId={activeChatId}
                                       withTabButton={withTabButton}
                                       onChatCellClick={onChatCellClick}/>
                </If>

                {/** Search mode */}
                <If is={mode === 'search'}>
                    <SearchModePanelView parentRef={parentRef} onChatCellClick={onChatCellClick} options={{
                        activeChatId,
                        chatSearch: urlFilter.chatSearch || '',
                        profileSkip: !urlFilter.chatSearch,
                        personalUserId: +params.personalUserId,
                    }}/>
                </If>

                {/** Creation mode */}
                <If is={mode === 'creation'}>
                    <ScrollTo element={parentRef.current}/>
                    <ChatCreationView setMode={setMode} backToListState={backToListState}/>
                </If>

                {/** Tab button*/}
                <If is={withTabButton}>
                    <StickyButton className={[
                        '!mt-0 m:w-[calc(100vw_-_2rem)] m:fixed m:bottom-tabbar',
                        '!z-0 m:!z-10 thin-border-top d:px-2.5 py-2.5 d:pl-[12px]',
                    ].join(' ')}>
                        <Link pushPage={{
                            id: '/chat',
                            params: { ...supportChatParams() },
                        }}>
                            <Button stretched
                                    size="m"
                                    mode="commerce"
                                    before={<Icon20AddSquareOutline/>}
                                    data-test="chat.create.create.button">
                                {t('newQuestion')}
                            </Button>
                        </Link>
                    </StickyButton>
                </If>
            </WindowsCustomScrollbar>
        </div>
    );
});


export { ListPanelView };
