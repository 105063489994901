/**
 * UseInvitation
 *
 * @author: exode <hello@exode.ru>
 */

import { Storage } from '@/api/storage';
import { ApolloError } from '@apollo/client';

import { GqlResult } from '@/types/graphql';
import { ApplyInvitationBonusMutationResult, useApplyInvitationBonusMutation } from '@/codegen/graphql';


export const useInvitation = () => {

    const [
        _applyInvitationBonus,
        {
            data: applyInvitationResult,
            loading: applyInvitationBonusLoading,
            called: applyInvitationBonusCalled,
        },
    ] = useApplyInvitationBonusMutation();

    const applyInvitation = async (
        inviterUuid: string,
        onCompleted?: (lesson: GqlResult<ApplyInvitationBonusMutationResult>['promotionApplyInvitationBonus']) => void,
        onError?: (error: ApolloError) => void,
    ) => {
        Storage.destroy('user:referral-uuid');

        await _applyInvitationBonus({
            variables: { inviterUuid },
            onCompleted(data) {
                onCompleted?.(data.promotionApplyInvitationBonus);
            },
            onError(error) {
                onError?.(error);
            },
        });
    };

    return {
        applyInvitation,
        applyInvitationBonusCalled,
        applyInvitationBonusLoading,
        applyInvitationResult: applyInvitationResult?.promotionApplyInvitationBonus,
    };
};
