/**
 * UserMainInfoModal
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect } from 'react';

import { useI18n } from '@/hooks/core';

import { Modal } from '@/cutils';
import { DocumentEvent } from '@/types/window';
import { Router } from '@/services/Utils/Router';

import { Title } from '@exode.ru/vkui';

import { OnboardingView } from '@/pages/User/Settings/views/OnboardingView';


interface Props {
    id: string;
}


const UserMainInfoModal = (props: Props) => {

    const { t } = useI18n('modals.User.MainInfo');

    const handleProfilePersonalInfoFilled = () => {
        Router.replaceModal();
    };

    useEffect(() => {
        document.addEventListener(
            DocumentEvent.ProfilePersonalInfoFilledSuccess,
            handleProfilePersonalInfoFilled,
        );

        return () => {
            document.removeEventListener(
                DocumentEvent.ProfilePersonalInfoFilledSuccess,
                handleProfilePersonalInfoFilled,
            );
        };
    }, []);

    return (
        <Modal.Card disableManualClose id={props.id} backdropFilter={15} header={(
            <Title className="modal-title" level="2" weight="3">
                {t('letsKnowEachOther')}
            </Title>
        )} subheader={t('fillTheBelowFieldsAndGetStarted')}>
            <Modal.Body isScrollable>
                <OnboardingView placement="modal"/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { UserMainInfoModal };
