/**
 * PracticeAttemptsUpdateManage
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { useFormikContext } from 'formik';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { ObjectUtil } from '@/utils';
import { getTaskCompletionStat } from '@/shared/helpers';

import { practiceAttemptStatus, PracticeAttemptWidgetItem } from '@/types/practice';
import { CourseLessonPracticeAttemptStatus, UpdateAnswersTaskItemInput } from '@/codegen/graphql';

import { Button } from '@exode.ru/vkui';

import { InnerHtml } from '@/components/Atoms/InnerHtml';
import { SubNavigationIconPart, SubNavigationSelect } from '@/components/Atoms/SubNavigationSelect';


interface Props {
    attempt: PracticeAttemptWidgetItem;
    className?: string;
    simpleSaveLoading?: boolean;
    onSimpleSaveClick?: () => void;
}


const PracticeAttemptsUpdateManage = (props: Props) => {

    const {
        className,
        simpleSaveLoading,
        onSimpleSaveClick,
        attempt: {
            answers,
            practice,
        },
    } = props;

    const {
        values,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        initialValues,
        values: { status, taskAnswers },
    } = useFormikContext<{
        status: CourseLessonPracticeAttemptStatus;
        taskAnswers: UpdateAnswersTaskItemInput[];
    }>();

    const { completionRate } = getTaskCompletionStat(
        _.map(practice.tasks, 'task'),
        answers.map((a) => ({
            ...a,
            point: _.find(taskAnswers, { taskAnswerId: a.id })?.point ?? a.point,
        })),
    );

    const practiceAttemptStatuses = practiceAttemptStatus();

    const { t } = useI18n('components.Practice.PracticeAttemptsUpdateManage');

    return (
        <div className={[
            className,
            'flex items-center text-primary w-full m:flex-col m:items-start gap-4',
        ].join(' ')}>
            <div className="flex items-center justify-center gap-3">
                <SubNavigationSelect hideSelected
                                     name="statuses"
                                     value={status}
                                     initialTitle={practiceAttemptStatuses[status].name}
                                     onChange={(__, value) => setFieldValue('status', value)}
                                     filterIcon={(
                                         <SubNavigationIconPart icon={practiceAttemptStatuses[status]?.icon}
                                                                bgColor={practiceAttemptStatuses[status]?.bgColor}/>
                                     )}
                                     options={(
                                         [
                                             CourseLessonPracticeAttemptStatus.Failed,
                                             CourseLessonPracticeAttemptStatus.Verified,
                                             CourseLessonPracticeAttemptStatus.OnCorrection,
                                         ].map((status) => {
                                             const {
                                                 name,
                                                 icon,
                                                 bgColor,
                                             } = practiceAttemptStatuses[status];

                                             return {
                                                 value: status,
                                                 label: name || '',
                                                 className: 'px-3',
                                                 before: <SubNavigationIconPart icon={icon} bgColor={bgColor}/>,
                                             };
                                         })
                                     )}/>

                <If is={!ObjectUtil.isEqual(initialValues, values)}>
                    <Button size="m"
                            mode="commerce"
                            loading={isSubmitting}
                            data-test="attempt:send"
                            onClick={() => handleSubmit()}
                            disabled={isSubmitting || simpleSaveLoading}>
                        {t('send')}
                    </Button>
                </If>
            </div>

            <If is={!!practice.passThreshold}>
                <InnerHtml className="text-primary" content={(
                    t('passCalculation', {
                        completionRate,
                        passThreshold: practice.passThreshold,
                        className: completionRate >= (practice.passThreshold || 0)
                            ? 'text-positive'
                            : 'text-destructive',
                    })
                )}/>
            </If>

            <If is={!ObjectUtil.isEqual(initialValues.taskAnswers, taskAnswers)}>
                <Button mode="tertiary"
                        className="d:ml-auto"
                        data-test="attempt:save"
                        onClick={onSimpleSaveClick}
                        loading={simpleSaveLoading}
                        disabled={simpleSaveLoading}>
                    {t('simpleSave')}
                </Button>
            </If>
        </div>
    );
};


export { PracticeAttemptsUpdateManage };
