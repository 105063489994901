/**
 * UseBlockLayout
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { useEffect, useMemo } from 'react';

import { ContentLayoutMode } from '@/types/content';
import { ContentElementType } from '@/codegen/graphql';

import { ContentElementItem } from '@/components/ContentElement';


interface Props {
    blocks: ContentElementItem[];
    onModeChange?: (mode: ContentLayoutMode) => void;
}


export const useBlockLayout = (props: Props) => {

    const { blocks, onModeChange } = props;

    const { mode, availableBlocks } = useMemo(() => {
        const blockerIndex = _.findIndex(blocks, (block) => {
            switch (block.type) {
                case ContentElementType.Checkpoint:
                    return !_.get(block, 'myInteraction.data.reached');

                default:
                    return false;
            }
        });

        return {
            mode: blockerIndex !== -1
                ? ContentLayoutMode.Sequence
                : ContentLayoutMode.Free,
            availableBlocks: blockerIndex === -1
                ? blocks
                : _.slice(blocks, 0, blockerIndex + 1),
        };
    }, [ blocks ]);

    useEffect(() => {
        onModeChange?.(mode);
    }, [ mode ]);

    return {
        mode,
        availableBlocks,
    };
};
