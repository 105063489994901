/**
 * GradientBanner styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';
import { GradientBannerProps } from '@/components/Atoms/GradientBanner';


export const Container = styled.div<GradientBannerProps>`
    overflow: hidden;
    position: relative;
    border-radius: var(--vkui--size_border_radius_paper--regular);

    .card-clear-after {
        .Banner__in:before {
            display: none !important;
        }
    }

    ${(props) => props.withShadow && 'box-shadow: var(--vkui--elevation3, 0 2px 24px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.08));'};

    .Banner {
        margin: 0;
        padding: 0;

        &__header {
            color: ${(props) => props.textColor || 'var(--text_primary)'} !important;
        }

        &__subheader {
            color: ${(props) => props.textColor || 'var(--text_primary)'} !important;
        }

        &__in {
            display: flex;
            align-items: center;
            background: ${(props) => props.transparent && 'transparent'} !important;

            &:before {
                border: none;
                box-shadow: inset 0 0 0 var(--thin-border) var(--input_border) !important;
            }
        }

        & > .banner-bg {
            background-image: ${props => props.bgImage ? `url(${props.bgImage})` : 'none'};
            background-position: right bottom;
            background-size: cover;
            background-repeat: no-repeat;
        }

        &__image {
            position: absolute;
            z-index: 1;
        }
    }
`;
