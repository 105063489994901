/**
 * Use protect copy
 *
 * @author: exode <hello@exode.ru>
 */

import { useEffect } from 'react';


interface Props {
    disabled?: boolean;
    wrapperId?: string;
    ignoreElements?: string[];
}


const useProtectCopy = (props: Props) => {

    const {
        disabled,
        wrapperId,
        ignoreElements = [ 'code' ],
    } = props;

    const isIgnoreElement = (
        e: ClipboardEvent | MouseEvent | KeyboardEvent,
        withClosest = true,
    ) => {
        const target = e.target as HTMLElement;

        if (wrapperId) {
            const wrapper = document.getElementById(wrapperId);

            if (!wrapper?.contains(target)) {
                return true;
            }
        }

        return ignoreElements.includes(target.tagName.toLowerCase())
            || (withClosest && ignoreElements.some((e) => target.closest(e)));
    };

    useEffect(() => {
        if (disabled) {
            return;
        }

        const preventCopy = (e: ClipboardEvent) => {
            if (isIgnoreElement(e, false)) {
                return true;
            }

            e.preventDefault();

            return false;
        };

        const preventContextMenu = (e: MouseEvent) => {
            if (isIgnoreElement(e)) {
                return true;
            }

            e.preventDefault();

            return false;
        };

        const preventKeyboardShortcut = (e: KeyboardEvent) => {
            if (isIgnoreElement(e)) {
                return true;
            }

            if (
                (e.ctrlKey || e.metaKey) &&
                (e.key === 'c' || e.key === 'C' || e.key === 'v' || e.key === 'V')
            ) {
                e.preventDefault();

                return false;
            }
        };

        document.addEventListener('copy', preventCopy);
        document.addEventListener('contextmenu', preventContextMenu);
        document.addEventListener('keydown', preventKeyboardShortcut);

        return () => {
            document.removeEventListener('copy', preventCopy);
            document.removeEventListener('contextmenu', preventContextMenu);
            document.removeEventListener('keydown', preventKeyboardShortcut);
        };
    }, [ disabled ]);
};


export { useProtectCopy };
