/**
 * CheckpointContentElementShow
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useRef } from 'react';

import { motion } from 'framer-motion';

import { ConfigStore } from '@/store/core/config';
import { UserAuthStore } from '@/store/user/auth';
import { observer, ProfileStore } from '@/store/user/profile';

import { CheckpointContent, CheckpointInteraction } from '@/shared/types';

import { If } from '@/cutils';
import { Page } from '@/pages/Core/Page';
import { useI18n } from '@/hooks/core';
import { ScrollHelper } from '@/helpers/ui';

import { Button, ButtonGroup } from '@exode.ru/vkui';

import { ChatMessage } from '@/components/Chat/ChatMessage';
import { ContentElementShowProps } from '@/components/ContentElement';


interface Props extends ContentElementShowProps<CheckpointContent, CheckpointInteraction> {

}


const CheckpointContentElementShow = observer((props: Props) => {

    const {
        interaction,
        contentElement: {
            myInteraction,
            content: { uuid, text, textColor, bgColor, message },
        },
    } = props;

    const { t } = useI18n('components.ContentElement.show');

    const wrapperRef = useRef<HTMLDivElement>(null);

    return (
        <div ref={wrapperRef} className="w-full">
            <If is={!myInteraction?.data?.reached}>
                <Page.Card className="w-full m:!px-0">
                    <ButtonGroup className="gap-3 justify-center w-full">
                        <Button size="l"
                                data-ce={`checkpoint.${uuid}`}
                                loading={interaction?.loading}
                                disabled={interaction?.loading}
                                style={{
                                    color: textColor || 'var(--button_primary_foreground)',
                                    background: bgColor || 'var(--accent)',
                                }}
                                onClick={() => {
                                    interaction?.interact({
                                        ...myInteraction?.data,
                                        reached: true,
                                        time: new Date(),
                                    }, () => {
                                        if (wrapperRef.current) {
                                            ScrollHelper.to(
                                                ScrollHelper.calcTopToElement(
                                                    wrapperRef.current,
                                                    ConfigStore.isDesktop ? 0 : -60,
                                                ),
                                                true,
                                            );
                                        }
                                    });
                                }}>
                            {text || t('goNext')}
                        </Button>
                    </ButtonGroup>
                </Page.Card>
            </If>

            <If is={!!myInteraction?.data?.reached && !!message?.show}>
                <motion.div className="w-full py-4"
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, amount: 0.2 }}
                            transition={{ duration: 0.5, type: 'spring' }}>
                    <ChatMessage text={text}
                                 textClassName="fs-content"
                                 dataTest="ce.checkpoint.message"
                                 header={ProfileStore.firstName}
                                 avatar={{
                                     name: `${ProfileStore.firstName}`,
                                     userId: UserAuthStore.user?.id || 0,
                                     src: `${ProfileStore.avatar?.medium}`,
                                 }}/>
                </motion.div>
            </If>
        </div>
    );
});


export { CheckpointContentElementShow };
