/**
 * UseProductFeedback
 *
 * @author: exode <hello@exode.ru>
 */

import { GqlResult } from '@/types/graphql';

import {
    CreateFeedbackProductInput,
    ProductFeedbackFindOneByUserAndProductDocument,
    ProductFeedbackUpsertMutationResult,
    useProductFeedbackUpsertMutation,
} from '@/codegen/graphql';


export const useProductFeedback = (productId: number) => {

    const [
        _upsertProductFeedback,
        { loading: upsertProductFeedbackLoading },
    ] = useProductFeedbackUpsertMutation({
        onError: (error) => console.error(error),
        refetchQueries: [
            {
                query: ProductFeedbackFindOneByUserAndProductDocument,
                variables: { productId },
            },
        ],
    });

    const upsertProductFeedback = (
        feedback: CreateFeedbackProductInput,
        onCompleted?: (meta: GqlResult<ProductFeedbackUpsertMutationResult>['productFeedbackUpsert']) => void,
    ) => {
        return _upsertProductFeedback({
            variables: {
                productId,
                feedback,
            },
            onCompleted: (data) => {
                onCompleted?.(data.productFeedbackUpsert);
            },
        });
    };

    return {
        upsertProductFeedback,
        upsertProductFeedbackLoading,
    };
};
