/**
 * TimerText
 *
 * @author: exode <hello@exode.ru>
 */

import moment from 'moment';

import React, { useCallback, useEffect, useRef, useState } from 'react';


interface Props {
    mode: 'fromNow';
    date: Date;
}


const TimerText = (props: Props) => {

    const { mode, date } = props;

    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const getTimerText = useCallback(() => {
        switch (mode) {
            case 'fromNow':
                return moment(date).fromNow();
        }
    }, [ mode, date ]);

    const [ timeText, setTimeText ] = useState(getTimerText());

    useEffect(() => {
        /** Reset time text when date changes */
        setTimeText(getTimerText());
    }, [ date, getTimerText ]);

    useEffect(() => {
        /** Get the seconds from date */
        const targetSecond = moment(date).seconds();

        /** Calculate time until the next occurrence of that specific second */
        const now = moment();
        const currentSecond = now.seconds();
        const currentMillisecond = now.milliseconds();

        /** Wait until it occurs in the next minute */
        const secondsToWait = currentSecond <= targetSecond
            ? targetSecond - currentSecond
            : 60 - currentSecond + targetSecond;

        /** Add a 1-second delay to ensure time calculations have changed */
        const timeToStartInterval = (secondsToWait * 1000 - currentMillisecond) + 1000;

        const updateTimerText = () => {
            const newTimeText = getTimerText();

            if (timeText !== newTimeText) {
                setTimeText(newTimeText);
            }
        };

        const initialTimeout = setTimeout(() => {
            updateTimerText();

            /** After initial sync, set interval to run exactly every minute */
            intervalRef.current = setInterval(updateTimerText, 60 * 1000);
        }, timeToStartInterval);

        return () => {
            clearTimeout(initialTimeout);

            if (intervalRef.current) {
                clearInterval(intervalRef.current);

                intervalRef.current = null;
            }
        };
    }, [ date, getTimerText, timeText ]);

    return (
        <>
            {timeText}
        </>
    );
};


export { TimerText };
