/**
 * Constants
 *
 * @author: exode <hello@exode.ru>
 */

import { TFunction } from 'i18next';

import { AppColorScheme } from '@/shared/types';


export const getColorVariables = (tStatic: TFunction) => {
    return [
        {
            name: '--accent',
            title: tStatic('accent'),
            description: tStatic('accentDescription'),
            defaultValue: {
                [AppColorScheme.BrightLight]: '#3f67e0',
                [AppColorScheme.SpaceGray]: '#3a9cfc',
            },
        },
        {
            name: '--text_primary',
            title: tStatic('textPrimary'),
            description: tStatic('textPrimaryDescription'),
            defaultValue: {
                [AppColorScheme.BrightLight]: '#000000',
                [AppColorScheme.SpaceGray]: '#e1e3e6',
            },
        },
        {
            name: '--button_commerce_background',
            title: tStatic('buttonCommerceBackground'),
            description: tStatic('buttonCommerceDescription'),
            defaultValue: {
                [AppColorScheme.BrightLight]: '#4bb34b',
                [AppColorScheme.SpaceGray]: '#4bb34b',
            },
        },
    ] as const;
};
