/**
 * ChatService
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import moment from 'moment';

import { Parse } from '@/utils';

import { DocumentEvent } from '@/types/window';
import { ComponentHelper } from '@/helpers/react';
import { ChatListItem, ChatMessageItem } from '@/types/chat';

import { ChatFragmentFragment, ChatMessageType, ChatPermissionMode } from '@/codegen/graphql';

import { BaseService } from '@/services/Core/Base';


class ChatService extends BaseService {

    /**
     * Safety parse message text
     * @param {string | null | undefined} text
     * @param {Parameters<typeof Parse['parseAndWrapEmoji']>[1]} options
     * @returns {{className: string, text: string}}
     */
    static safetyParseText(
        text: string | null | undefined,
        options?: Parameters<typeof Parse['parseAndWrapEmoji']>[1],
    ) {
        return Parse.parseAndWrapEmoji(
            ComponentHelper.linkifyHtml(
                Parse.nlToBr(
                    Parse.escapeHtml(text || ''),
                ),
            ),
            options,
        );
    }

    /**
     * Check can send a new message
     * @param {} chat
     */
    static canSendMessage(
        chat: Pick<ChatFragmentFragment,
            'sendMessagePermission' |
            'isAdmin' |
            'myMember'>,
    ) {
        if (chat.myMember === null) {
            return false;
        }

        return chat.sendMessagePermission === ChatPermissionMode.Admin
            ? !!chat.isAdmin
            : !chat.myMember?.blocked;
    }

    /**
     * Check gap by time interval
     * @param {number} messageId
     * @param {ChatMessageItem[]} chatMessages
     * @returns {boolean}
     */
    static hasGapByInterval(
        messageId: number,
        chatMessages: ChatMessageItem[],
    ) {
        const messages = chatMessages.filter((m) => m?.type === ChatMessageType.User);

        const index = _.findIndex(messages, { id: messageId });

        const interval = 5;
        const prevIndex = index - 1;

        if (!messages || !messages.length || index === 0) {
            return false;
        }

        /** Длинное сообщение, не будет видно отправителя */
        if ((messages[index]?.text?.length || 0) > 1000) {
            return false;
        }

        const prevTime = messages[prevIndex]?.createdAt;
        const currentTime = messages[index]?.createdAt;

        const diff = moment(currentTime).diff(prevTime, 'minutes');

        if (messages[index].user.id === messages[prevIndex].user.id) {
            return Math.abs(diff) <= interval;
        }

        return false;
    }

    /**
     * Parse chat date
     * @param {Date | string} date
     * @returns {string}
     */
    static parseChatDate(date: Date | string) {
        const dates = {
            sameDay: 'HH:mm',
            sameElse: 'DD.MM.YY',
            lastDay: 'ddd. в HH:mm',
            lastWeek: 'ddd. в HH:mm',
        };

        return moment(date, moment.ISO_8601).calendar(null, dates);
    }

    /**
     * Parse last message text or emoji (attachment/poll)
     * @param {ChatListItem['lastMessage']} message
     * @returns {string}
     */
    static lastMessageText(
        message: ChatListItem['lastMessage'],
    ) {
        const byContent = [
            message?.medias?.length && '📎',
            message?.text,
        ].filter(e => e).join(' ');

        return byContent || '📎';
    }

    /**
     * Check not channel admin or not member
     * @param {Pick<, 'type' | 'isAdmin' | 'myMember' | 'sendMessagePermission'>} chat
     */
    static notChannelAdminOrNotMember(
        chat?: Pick<ChatFragmentFragment, 'type'
            | 'isAdmin'
            | 'myMember'
            | 'sendMessagePermission'>,
    ) {
        if (chat?.myMember === null) {
            return true;
        }

        return !!chat
            && !chat?.isAdmin
            && chat.sendMessagePermission === ChatPermissionMode.Admin;
    }

    /**
     * Scroll to message
     * @param {number | string} messageId
     */
    static scrollToMessage(
        messageId: number | string | undefined,
    ) {
        if (!messageId) {
            return;
        }

        document.dispatchEvent(
            new CustomEvent(
                DocumentEvent.ChatScrollToMessage,
                { detail: { messageId: +messageId } },
            ),
        );
    }

}


export { ChatService };
