/**
 * ManageSellerSelectPlaceholder
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { IS_MARKETPLACE } from '@/root/src/env';

import { Avatar, Spinner } from '@exode.ru/vkui';

import { IconBizSquare, IconRuSquare } from '@/images/icons';


interface Props {
    isAdmin: boolean;
    isSeller: boolean;
    hasSelectedSeller: boolean;
    loading?: boolean;
}


const ManageSellerLogoPlaceholder = (props: Props) => {

    const {
        isAdmin,
        loading,
        isSeller,
        hasSelectedSeller,
    } = props;

    return (
        <Avatar shadow
                size={32}
                mode="app"
                alt="IconSquare"
                children={loading ? <Spinner/> : undefined}
                src={!loading ? (IS_MARKETPLACE ? IconRuSquare : IconBizSquare) : undefined}
                className={[
                    'flex mt-2 mb-2 mx-auto rounded-full',
                    loading
                        ? 'bg-[#ffffff25]'
                        : (isAdmin || isSeller || hasSelectedSeller)
                            ? 'cursor-pointer'
                            : 'cursor-default',
                ].join(' ')}/>
    );
};


export { ManageSellerLogoPlaceholder };
