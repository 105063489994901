/**
 * UseCoursePractice
 *
 * @author: exode <hello@exode.ru>
 */

import { Maybe } from 'graphql/jsutils/Maybe';

import { apolloClient, getGqlCause } from '@/api/graphql';

import { useI18n } from '@/hooks/core';
import { SaveStoreDeepKeys, SaveStoreKeys, SavingStore } from '@/store/core/saving';

import { Notify } from '@/cutils';

import { Router } from '@/services/Utils/Router';

import { PracticeAttemptItem } from '@/types/practice';

import {
    AuthException,
    ChatFindManyDocument,
    CourseException,
    CourseLessonPracticeFindOneDocument,
    CourseLessonPracticeFindOneQuery,
    SaveAnswerTaskInput,
    useCourseLessonPracticeAttemptFinishAttemptMutation,
    useCourseLessonPracticeAttemptSaveTaskAnswerMutation,
    useCourseLessonPracticeGetOneActiveOrCreateAttemptMutation,
    useCourseLessonUpsertChatMutation,
} from '@/codegen/graphql';


export const useCoursePractice = (
    courseId: number,
    lessonId: number,
    practiceId?: number,
) => {
    const { t } = useI18n('hooks.apollo.course');

    const taskAnswerIsEditing = SavingStore.sectionIsEditing(SaveStoreKeys.TaskShow);

    const getExistCachedAttempts = () => {
        const cachedPractice = apolloClient.cache.readQuery<CourseLessonPracticeFindOneQuery>({
            query: CourseLessonPracticeFindOneDocument,
            variables: { courseId, lessonId },
        });

        return {
            cachedPractice,
            cachedAttempts: cachedPractice?.courseLessonPracticeFindOne?.attempts || [],
        };
    };

    const [ upsertChat, { loading: upsertChatLoading } ] = useCourseLessonUpsertChatMutation({
        variables: { lessonId },
        onError: (error) => {
            const notParticipantErrors = [
                AuthException.Forbidden,
                CourseException.NotCourseParticipant,
            ];

            if (notParticipantErrors.includes(getGqlCause(error))) {
                Notify.toast.error(t('needToEnrollToTheCourse'), {
                    id: 'useCoursePractice.needToEnrollToTheCourse',
                });
            }
        },
        onCompleted: ({ courseProgressUpsertChat }) => {
            Router.pushPage('/chat', { chatId: `${courseProgressUpsertChat?.id}` });
        },
        update: () => {
            apolloClient.refetchQueries({
                include: [ ChatFindManyDocument ],
            });
        },
    });

    const [ _getOneActiveOrCreateAttempt, {
        loading: getOneActiveOrCreateAttemptLoading,
    } ] = useCourseLessonPracticeGetOneActiveOrCreateAttemptMutation();

    const getOneActiveOrCreateAttempt = (
        practiceId: number,
        forceCreate = false,
    ) => {
        return _getOneActiveOrCreateAttempt({
            variables: { practiceId, forceCreate },
            onError: (error) => {
                if (getGqlCause(error) === AuthException.Forbidden) {
                    Notify.toast.error(t('needToEnrollToTheCourse'), {
                        id: 'useCoursePractice.needToEnrollToTheCourse',
                    });
                }
            },
            update: (cache, { data }) => {
                if (data) {
                    const attempt = data.courseLessonPracticeAttemptGetOneActiveOrCreate;

                    const { cachedAttempts } = getExistCachedAttempts();

                    cache.modify({
                        id: `CourseLessonPracticeEntity:${practiceId}`,
                        fields: {
                            attempts: () => [
                                ...cachedAttempts.filter((a) => a.id !== attempt.id),
                                attempt,
                            ],
                            activeAttempt: () => ({
                                __ref: `CourseLessonPracticeAttemptEntity:${attempt.id}`,
                            }),
                        },
                    });
                }
            },
        });
    };

    const [ _saveTaskAnswer, {
        data: answerData,
        error: saveTaskAnswerError,
        loading: saveTaskAnswerLoading,
    } ] = useCourseLessonPracticeAttemptSaveTaskAnswerMutation({
        onError: (error) => console.error(error),
    });

    const saveTaskAnswer = async (
        attemptId: number,
        answer: SaveAnswerTaskInput,
        onCompleted?: () => void,
    ) => {
        await _saveTaskAnswer({
            variables: { attemptId, answer },
            onCompleted: () => onCompleted?.(),
        });
    };

    const [ _finishAttempt, {
        data: finishData,
        loading: finishAttemptLoading,
    } ] = useCourseLessonPracticeAttemptFinishAttemptMutation({
        onError: (error) => console.error(error),
        update: (cache, { data }) => {
            const { courseLessonPracticeAttemptFinishAttempt: attempt } = data || {};

            const { cachedAttempts } = getExistCachedAttempts();

            if (attempt) {
                const { lesson, lesson: { next } } = attempt.practice;

                cache.modify({
                    id: `CourseLessonPracticeEntity:${practiceId}`,
                    fields: {
                        activeAttempt: () => null,
                        lastFinishedAttempt: () => attempt,
                        attempts: () => [
                            ...cachedAttempts.filter((a) => a.id !== attempt.id),
                            attempt,
                        ],
                    },
                });

                for (const item of [ lesson, next, next?.next ]) {
                    if (!item) {
                        continue;
                    }

                    for (const entity of [ 'CourseLessonPreview', 'CourseLessonEntity' ]) {
                        cache.modify({
                            id: `${entity}:${item.id}`,
                            fields: {
                                myProgressStatus: () => item.myProgressStatus,
                                canStartNextLesson: () => item.canStartNextLesson,
                            },
                        });
                    }
                }
            }

            [
                'courseLessonGetProgressStatus',
                'courseProgressFindManyCurrent',
            ].map((fieldName) => {
                cache.evict({ id: 'ROOT_QUERY', fieldName });
            });
        },
    });

    const finishAttempt = async (
        attemptId: number,
        options: {
            currentLesson?: Maybe<{
                withPractice: boolean;
            }>,
            onCompleted?: (attempt: PracticeAttemptItem) => void,
        } = {},
    ) => {
        return _finishAttempt({
            variables: { attemptId },
            onCompleted: (data) => {
                if (data) {
                    options.onCompleted?.(data.courseLessonPracticeAttemptFinishAttempt);
                }
            },
        });
    };

    const setTaskAnswerEditing = (editing = true) => {
        SavingStore.setEditing(
            SaveStoreKeys.TaskShow,
            SaveStoreDeepKeys.TaskShowEdit,
            editing,
        );
    };

    return {
        upsertChat,
        upsertChatLoading,
        saveTaskAnswer,
        saveTaskAnswerLoading,
        saveTaskAnswerError,
        finishAttempt,
        finishAttemptLoading,
        answerData,
        finishData,
        setTaskAnswerEditing,
        taskAnswerIsEditing,
        getOneActiveOrCreateAttempt,
        getOneActiveOrCreateAttemptLoading,
    };
};
