/**
 * LessonAvatarBadgePart component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Icon16Play } from '@vkontakte/icons';

import { If } from '@/cutils';

import { gradient } from '@/styles/modules/gradient';


interface Props {
    hasVideo: boolean;
    badgeSize: number;
}


const LessonAvatarBadgePart = (props: Props) => {

    const { hasVideo, badgeSize } = props;

    const iconProps = {
        width: badgeSize,
        height: badgeSize,
        fill: 'var(--white)',
    };

    return (
        <If is={hasVideo}>
            <div className="z-[1]" style={{ transform: 'translate3d(0, 0, 0)' }}>
                <div className={[
                    'bg-gradient-to-br p-[3px] rounded-full',
                    gradient.blue400ToBlue700Gradient,
                ].join(' ')}>
                    <Icon16Play {...iconProps}/>
                </div>
            </div>
        </If>
    );
};


export { LessonAvatarBadgePart };
