/**
 * ChatMessageContentElementEdit
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { nanoid } from 'nanoid';

import React, { useCallback } from 'react';

import { FastField, FastFieldProps, FieldArray, Formik } from 'formik';

import { Page } from '@/pages/Core/Page';
import { AutoSaveForm, Field, If } from '@/cutils';

import { ContentElementType, StorageSpace } from '@/codegen/graphql';
import { ChatMessageContent, FileExtension } from '@/shared/types';

import { useI18n, useOnceUpdate } from '@/hooks/core';
import { Button, FormItem, Input, List, Textarea } from '@exode.ru/vkui';
import { Icon16DeleteOutline, Icon20MessageAddOutline } from '@vkontakte/icons';

import { FileUpload } from '@/components/Atoms/FileUpload';
import { TextAccordion } from '@/components/Atoms/TextAccordion';
import { ConfirmButton } from '@/components/Atoms/ConfirmButton';
import { InputEmojiPicker } from '@/components/Atoms/InputEmojiPicker';
import { ContentElementEditProps, getContentElementContextValue } from '@/components/ContentElement';

import { UploadAvatarPart } from './parts/UploadAvatarPart';
import { BlockHeaderPart, BlockWrapperPart } from '../../parts/edit';
import { ChatMessageContentElementShow } from '../../show/ChatMessageContentElementShow';


interface Props extends ContentElementEditProps<ChatMessageContent> {

}


const ChatMessageContentElementEdit = (props: Props) => {

    const {
        list,
        loading,
        onEdit,
        update,
        onDelete,
        contentElement,
    } = props;

    const { t } = useI18n('components.ContentElement.edit');

    const {
        edit: {
            blockHeaderProps = {},
            blockWrapperProps = {},
            BlockWrapper = BlockWrapperPart,
        } = {},
    } = getContentElementContextValue(ContentElementType.ChatMessage);

    const {
        initialValues,
        handleUpdateSubmit,
    } = useOnceUpdate(
        _.pick(contentElement, [ 'content' ]),
        async (values) => update?.(values),
    );

    const debouncedCallback = useCallback(
        _.debounce((callback) => callback(), 500),
        [ initialValues ],
    );

    return (
        <Formik initialValues={initialValues} onSubmit={handleUpdateSubmit}>
            {({
                  values,
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  values: { content },
              }) => (
                <AutoSaveForm onChange={() => handleSubmit()} onInstantChange={() => onEdit?.()}>
                    <BlockWrapper index={list?.index} {...blockWrapperProps}>
                        <BlockHeaderPart list={list}
                                         loading={loading}
                                         onDelete={onDelete}
                                         type={ContentElementType.ChatMessage}
                                         {...blockHeaderProps}/>

                        <Page.Card>
                            <ChatMessageContentElementShow contentElement={{
                                ...contentElement,
                                myInteraction: null,
                                content: values.content,
                            }}/>
                        </Page.Card>

                        <TextAccordion className="mt-2" text={t('settings')}>
                            <div className="flex flex-col items-center gap-3 my-4 w-full">
                                <FieldArray name="content.messages">
                                    {({ push, remove }) => (
                                        <List className="flex flex-col gap-4 w-full">
                                            {content.messages?.map((message, index, messages) => (
                                                <FormItem key={message.uuid}
                                                          className="p-0 w-full relative group"
                                                          top={(
                                                              <div className="flex items-center gap-2">
                                                                  {t('messageText')}

                                                                  <div className="hidden group-hover:flex">
                                                                      <If is={messages.length > 1}>
                                                                          <ConfirmButton placement="right"
                                                                                         className="cursor-pointer"
                                                                                         confirmProps={{
                                                                                             className: 'text-destructive',
                                                                                         }}
                                                                                         callback={() => {
                                                                                             remove(index);

                                                                                             debouncedCallback(() => {
                                                                                                 handleSubmit();
                                                                                             });
                                                                                         }}>
                                                                              <Icon16DeleteOutline/>
                                                                          </ConfirmButton>
                                                                      </If>
                                                                  </div>
                                                              </div>
                                                          )}>
                                                    <FastField name={`content.messages[${index}].text`}>
                                                        {({ field }: FastFieldProps) => (
                                                            <div className="relative">
                                                                <Textarea rows={2}
                                                                          {...field}
                                                                          placeholder={t('enterMessageText')}
                                                                          onChange={(
                                                                              Field.transform
                                                                                  .ignoreSpaceOnChange(handleChange)
                                                                          )}/>

                                                                <InputEmojiPicker pickerKey={field.value}
                                                                                  onEmojiClick={({ emoji }) => {
                                                                                      onEdit?.();

                                                                                      setFieldValue(
                                                                                          `content.messages[${index}].text`,
                                                                                          field.value + emoji,
                                                                                      );

                                                                                      debouncedCallback(() => handleSubmit());
                                                                                  }}/>
                                                            </div>
                                                        )}
                                                    </FastField>
                                                </FormItem>
                                            ))}

                                            <div className="flex justify-center w-full">
                                                <Button before={<Icon20MessageAddOutline/>}
                                                        onClick={() => push({ uuid: nanoid(), text: '' })}>
                                                    {t('addMessage')}
                                                </Button>
                                            </div>
                                        </List>
                                    )}
                                </FieldArray>
                            </div>

                            <div className="flex w-full gap-4 mb-3 items-center justify-between">
                                <div className="w-[66px]">
                                    <FileUpload editImageBeforeUpload
                                                width="100%"
                                                height="100%"
                                                multiple={false}
                                                space={StorageSpace.Course}
                                                accept={FileExtension.Image}
                                                id={`ce-chat-message-${contentElement.uuid}`}
                                                childrenOptions={{ disableClonedClick: true }}
                                                cropperProps={{ stencilProps: { aspectRatio: 1 } }}
                                                onFileUpload={([ file ]) => {
                                                    setFieldValue('content.avatarUrl', file.location);

                                                    handleSubmit();
                                                }}>
                                        <UploadAvatarPart avatarUrl={content.avatarUrl}/>
                                    </FileUpload>
                                </div>

                                <FormItem top={t('messageSender')} className="p-0 w-full">
                                    <Input className="w-full"
                                           name="content.senderName"
                                           value={content.senderName || ''}
                                           placeholder={t('enterSenderName')}
                                           onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                                </FormItem>
                            </div>
                        </TextAccordion>
                    </BlockWrapper>
                </AutoSaveForm>
            )}
        </Formik>
    );
};


export { ChatMessageContentElementEdit };
