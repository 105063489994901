/**
 * UseEmojiPicker
 *
 * @author: exode <hello@exode.ru>
 */

import { RefObject, useEffect, useRef, useState } from 'react';

import { EmojiClickData } from 'emoji-picker-react';


interface Props {
    writeBarRef?: RefObject<HTMLTextAreaElement>;
}


export const useEmojiPicker = (props: Props) => {

    const { writeBarRef } = props;

    const timeoutRef = useRef<NodeJS.Timeout>();

    const [ showEmojiPicker, setShowEmojiPicker ] = useState(false);

    const onEmojiClick = (
        emojiDate: EmojiClickData,
        currentText: string,
        setInput?: (message: string) => void,
    ) => {
        const cursor = writeBarRef?.current?.selectionStart;
        const newCursor = (cursor ?? 0) + emojiDate.emoji.length;

        const message = [
            currentText.slice(0, cursor),
            emojiDate.emoji,
            currentText.slice(cursor),
        ].join('');

        setInput?.(message);

        timeoutRef.current = setTimeout(() => {
            writeBarRef?.current?.setSelectionRange(newCursor, newCursor);
        }, 10);
    };

    useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, []);

    return { showEmojiPicker, setShowEmojiPicker, onEmojiClick };
};
