/**
 * Practice types
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { GqlResult } from '@/types/graphql';

import {
    Icon28ArrowUturnLeftOutline,
    Icon28CancelOutline,
    Icon28FavoriteOutline,
    Icon28HourglassOutline,
    Icon28ThumbsUpOutline,
    Icon28ViewOutline,
} from '@vkontakte/icons';

import {
    CourseLessonPracticeAttemptFindManyQueryResult,
    CourseLessonPracticeAttemptStatus,
    CourseLessonPracticeAttemptWidgetQueryResult,
    CourseLessonPracticeFindOneQueryResult,
} from '@/codegen/graphql';


export type LessonPractice = NonNullable<GqlResult<CourseLessonPracticeFindOneQueryResult>['courseLessonPracticeFindOne']>
export type PracticeAttemptItem = LessonPractice['attempts'][number];
export type PracticeAttemptsItem = GqlResult<CourseLessonPracticeAttemptFindManyQueryResult>['courseLessonPracticeAttemptFindMany']['items'][number];
export type PracticeAttemptWidgetItem = GqlResult<CourseLessonPracticeAttemptWidgetQueryResult>['courseLessonPracticeAttemptFindOneByUuid']

export const practiceAttemptStatus = () => {

    const { t } = useI18n('types.course');

    return {
        [CourseLessonPracticeAttemptStatus.OnReview]: {
            name: t('onReview'),
            bgColor: 'bg-amber-500',
            icon: <Icon28ViewOutline fill="var(--white)"/>,
        },
        [CourseLessonPracticeAttemptStatus.Verified]: {
            name: t('verified'),
            bgColor: 'bg-positive',
            icon: <Icon28ThumbsUpOutline fill="var(--white)"/>,
        },
        [CourseLessonPracticeAttemptStatus.Created]: {
            name: t('created'),
            icon: <Icon28HourglassOutline fill="var(--white)"/>,
            bgColor: 'bg-blueGray-500',
        },
        [CourseLessonPracticeAttemptStatus.AutoVerified]: {
            name: t('autoVerified'),
            bgColor: 'bg-accent',
            icon: <Icon28FavoriteOutline fill="var(--white)"/>,
        },
        [CourseLessonPracticeAttemptStatus.OnCorrection]: {
            name: t('onCorrection'),
            bgColor: 'bg-orange',
            icon: <Icon28ArrowUturnLeftOutline fill="var(--white)"/>,
        },
        [CourseLessonPracticeAttemptStatus.Failed]: {
            name: t('failed'),
            bgColor: 'bg-destructive',
            icon: <Icon28CancelOutline fill="var(--white)"/>,

        },
    };
};
