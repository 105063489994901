/**
 * ContentElement
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect } from 'react';

import { SubscriptionStore } from '@/store/subscription/subscription';

import { ContentElementType } from '@/codegen/graphql';
import { useContentElementInteract } from '@/hooks/apollo';

import { ContentElementEditProps, ContentElementShowProps } from './interfaces';

import { AudioContentElementEdit } from './edit/AudioContentElementEdit';
import { ButtonContentElementEdit } from './edit/ButtonContentElementEdit';
import { ChatMessageContentElementShow } from './show/ChatMessageContentElementShow';
import { ChecklistContentElementEdit } from './edit/ChecklistContentElementEdit';
import { CheckpointContentElementEdit } from './edit/CheckpointContentElementEdit';
import { EditorJsBlockContentElementEdit } from './edit/EditorJsBlockContentElementEdit';
import { NotionPageContentElementEdit } from './edit/NotionPageContentElementEdit';
import { VideoContentElementEdit } from './edit/VideoContentElementEdit';

import { AudioContentElementShow } from './show/AudioContentElementShow';
import { ButtonContentElementShow } from './show/ButtonContentElementShow';
import { ChatMessageContentElementEdit } from './edit/ChatMessageContentElementEdit';
import { ChecklistContentElementShow } from './show/ChecklistContentElementShow';
import { CheckpointContentElementShow } from './show/CheckpointContentElementShow';
import { EditorJsBlockContentElementShow } from './show/EditorJsBlockContentElementShow';
import { NotionPageContentElementShow } from './show/NotionPageContentElementShow';
import { VideoContentElementShow } from './show/VideoContentElementShow';


interface EditProps extends ContentElementEditProps {

}

interface ShowProps extends ContentElementShowProps {

}

interface Props {
    mode: 'edit' | 'show';
}


const ContentElement = (props: (EditProps | ShowProps) & Props) => {

    const { contentElement: { uuid } } = props;

    const components = {
        edit: {
            [ContentElementType.ActionContextBanner]: EditorJsBlockContentElementEdit,
            [ContentElementType.AdContextBanner]: EditorJsBlockContentElementEdit,
            [ContentElementType.Audio]: AudioContentElementEdit,
            [ContentElementType.Button]: ButtonContentElementEdit,
            [ContentElementType.ChatBotContent]: EditorJsBlockContentElementEdit,
            [ContentElementType.ChatMessage]: ChatMessageContentElementEdit,
            [ContentElementType.Checklist]: ChecklistContentElementEdit,
            [ContentElementType.Checkpoint]: CheckpointContentElementEdit,
            [ContentElementType.Survey]: ChecklistContentElementEdit,
            [ContentElementType.Text]: EditorJsBlockContentElementEdit,
            [ContentElementType.EditorJsBlock]: EditorJsBlockContentElementEdit,
            [ContentElementType.NotionPage]: NotionPageContentElementEdit,
            [ContentElementType.Video]: VideoContentElementEdit,
        },
        show: {
            [ContentElementType.ActionContextBanner]: EditorJsBlockContentElementShow,
            [ContentElementType.AdContextBanner]: EditorJsBlockContentElementShow,
            [ContentElementType.Audio]: AudioContentElementShow,
            [ContentElementType.Button]: ButtonContentElementShow,
            [ContentElementType.ChatBotContent]: EditorJsBlockContentElementShow,
            [ContentElementType.ChatMessage]: ChatMessageContentElementShow,
            [ContentElementType.Checklist]: ChecklistContentElementShow,
            [ContentElementType.Checkpoint]: CheckpointContentElementShow,
            [ContentElementType.Survey]: ChecklistContentElementShow,
            [ContentElementType.Text]: EditorJsBlockContentElementShow,
            [ContentElementType.EditorJsBlock]: EditorJsBlockContentElementShow,
            [ContentElementType.NotionPage]: NotionPageContentElementShow,
            [ContentElementType.Video]: VideoContentElementShow,
        },
    };

    const ContentElementComponent = components[props.mode][props.contentElement.type];

    const {
        interact,
        interactError,
        interactLoading,
    } = useContentElementInteract();

    useEffect(() => {
        SubscriptionStore.subscribeToContentElementUuid(uuid);
    }, [ uuid ]);

    return (
        <ContentElementComponent {...(props as EditProps & ShowProps)} interaction={{
            error: interactError,
            loading: interactLoading,
            interact: (data, onCompleted) => interact(
                uuid,
                data,
                onCompleted,
            ),
        }}/>
    );
};


export { ContentElement };
