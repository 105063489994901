/**
 * QuestionTooltip
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { If } from '@/cutils';

import { Subhead } from '@exode.ru/vkui';
import { PopperCommonProps } from '@exode.ru/vkui/dist/components/Popper/Popper';
import { RichTooltip, RichTooltipProps } from '@exode.ru/vkui/unstable';


interface Props {
    shown?: boolean;
    icon?: ReactElement;
    offsetDistance?: number;
    offsetSkidding?: number;
    subheadClassName?: string;
    customContent?: ReactElement;
    richToolTipClassName?: string;
    subheadElement?: ReactElement | string;
    placement?: PopperCommonProps['placement'];
    appearance?: RichTooltipProps['appearance'];
}


const QuestionTooltip = (props: Props) => {

    const {
        icon,
        shown,
        placement,
        appearance,
        customContent,
        subheadElement,
        offsetSkidding,
        subheadClassName,
        offsetDistance,
        richToolTipClassName,
    } = props;

    return (
        <RichTooltip shown={shown}
                     placement={placement}
                     appearance={appearance}
                     offsetDistance={offsetDistance}
                     offsetSkidding={offsetSkidding}
                     className={richToolTipClassName}
                     content={(
                         <>
                             <If is={!customContent}>
                                 <Subhead className={[ subheadClassName ].join(' ')}>
                                     {subheadElement}
                                 </Subhead>
                             </If>

                             {customContent ?? null}
                         </>
                     )}>
            {icon}
        </RichTooltip>
    );
};


export { QuestionTooltip };
