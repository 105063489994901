/**
 * CourseLessonPracticeAttemptWidgetItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from '@/pages/Chat/Dialog/store';

import { useI18n } from '@/hooks/core';
import { Graphql, If, Rbac } from '@/cutils';
import { useChatInfoContext } from '@/hooks/apollo';

import { CourseLessonPracticeAttemptWidgetQuery, Permission } from '@/codegen/graphql';

import { Skeleton } from '@mui/material';
import { Caption, Tappable } from '@exode.ru/vkui';

import { MessageMediaWidgetItemProps } from '../../MessageMediaItem';

import { CourseLessonPracticeAttemptWidgetRender } from './CourseLessonPracticeAttemptWidgetRender';


interface Props extends MessageMediaWidgetItemProps {

}


const CourseLessonPracticeAttemptWidgetItem = observer((props: Props) => {

    const { widget } = props;

    const { chat } = useChatInfoContext();

    const { t } = useI18n('pages.Chat.Dialog.WidgetItem');

    const { courseLessonPracticeAttemptUuid: attemptUuid } = widget?.data || {};

    return (
        <CourseLessonPracticeAttemptWidgetQuery notifyOnNetworkStatusChange children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <Skeleton height={64} width="100%" variant="rectangular" className="vk-rounded thin-border"/>

                    <If is={!!chat?.isAdmin}>
                        <Rbac.Permission permissions={[ Permission.CourseCurator ]}>
                            <Skeleton height={33.5}
                                      width="160px"
                                      variant="rectangular"
                                      className="mt-1 mb-2 vk-rounded thin-border"/>
                        </Rbac.Permission>
                    </If>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({ courseLessonPracticeAttemptFindOneByUuid: attempt }) => (
                        <CourseLessonPracticeAttemptWidgetRender {...props} attempt={attempt}/>
                    )}
                </Graphql.Success>

                <Graphql.Error disableRefetch result={result}>
                    <div className={[
                        'flex items-center justify-center',
                        'vk-rounded bg-hover thin-border w-full h-[64px]',
                    ].join(' ')}>
                        <Caption className="text-secondary">
                            {t('failedToLoadWidget')}
                        </Caption>
                    </div>

                    <If is={!!chat?.isAdmin}>
                        <Rbac.Permission permissions={[ Permission.CourseCurator ]}>
                            <Tappable onClick={() => result.refetch()} className={[
                                'h-[33.5px] w-[160px] mt-1 mb-2 vk-rounded bg-hover-50',
                                'thin-border flex items-center justify-center select-none',
                            ].join(' ')}>
                                {t('tryAgain')}
                            </Tappable>
                        </Rbac.Permission>
                    </If>
                </Graphql.Error>
            </>
        )} skip={!attemptUuid} variables={{ attemptUuid: `${attemptUuid}` }}/>
    );
});


export { CourseLessonPracticeAttemptWidgetItem };
