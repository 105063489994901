/**
 * ChatMessage
 *
 * @author: exode <hello@exode.ru>
 */

import React, { Fragment, ReactNode } from 'react';

import { ChatService } from '@/services/Chat/Chat';

import { If, useRedirect } from '@/cutils';
import { ComponentHelper } from '@/helpers/react';

import { Caption } from '@exode.ru/vkui';

import { IconBubbleTail } from '@/assets/images/icons';

import { UserAvatar, UserAvatarProps } from '@/components/Atoms/UserAvatar';

import { MessageContainer } from '@/pages/Chat/Dialog/items/MessageItem';


interface Props {
    text?: string;
    texts?: string[];
    side?: 'left' | 'right';
    header?: ReactNode;
    avatar?: UserAvatarProps;
    dataTest?: string;
    placeholder?: string;
    textClassName?: string;
    withBubbleTail?: boolean;
}


const ChatMessage = (props: Props) => {

    const {
        header,
        avatar,
        dataTest,
        placeholder,
        textClassName,
        side = 'right',
        withBubbleTail = true,
    } = props;

    const { redirect, handleInnerHtmlLinks } = useRedirect();

    return (
        <div data-test={dataTest} className={[
            'flex flex-1 gap-2 w-full',
            side === 'left' ? 'flex-row-reverse' : '',
        ].join(' ')}>
            <div className={[
                'flex flex-col max-w-[70%] w-fit',
                side === 'right' ? 'ml-auto justify-end items-end' : 'mr-auto',
            ].join(' ')}>
                {(props.texts || [ props.text ]).map((text, index, texts) => {
                    const {
                        text: messageText,
                        className: messageTextClassName,
                    } = ChatService.safetyParseText(
                        text || placeholder || '',
                        { defaultEmojiClassName: textClassName },
                    );

                    return (
                        <Fragment key={[ index, text ].join('.')}>
                            <If is={!!header && index === 0}>
                                <Caption className="text-subhead mb-1">
                                    {header}
                                </Caption>
                            </If>

                            <MessageContainer style={{
                                backgroundImage: [
                                    `linear-gradient(${side == 'left' ? 65 : 230}deg`,
                                    'transparent 0%, rgba(0, 0, 0, 0.04) 100%)',
                                ].join(', '),
                            }} className={[
                                'flex rounded-[16px] px-3 py-2 w-fit',
                                index !== texts.length - 1 ? 'mb-2' : '',
                                side === 'right' ? 'bg-accent' : 'bg-button-secondary',
                            ].join(' ')}>
                                <span style={{ wordWrap: 'break-word', lineHeight: '150%' }}
                                      ref={(ref) => ref && handleInnerHtmlLinks(ref, (h) => redirect(h), true)}
                                      dangerouslySetInnerHTML={{
                                          __html: ComponentHelper.sanitizeHtml(messageText),
                                      }}
                                      className={[
                                          textClassName,
                                          messageTextClassName,
                                          'message-text whitespace-pre-wrap',
                                          'flex items-center flex-wrap word-break',
                                          side === 'right' ? 'text-white' : 'text-primary',
                                      ].join(' ')}/>

                                <If is={withBubbleTail && index === texts.length - 1}>
                                    <div style={{ transform: side === 'left' ? 'scaleX(-1)' : undefined }} className={[
                                        'absolute bottom-[-7px]',
                                        side === 'right' ? 'right-[-6px]' : 'left-[-6px]',
                                    ].join(' ')}>
                                        <IconBubbleTail className={[
                                            side == 'right'
                                                ? 'text-accent'
                                                : 'text-bg-button-secondary--bg',
                                        ].join(' ')}/>
                                    </div>
                                </If>
                            </MessageContainer>
                        </Fragment>
                    );
                })}
            </div>

            <If is={!!avatar}>
                <div className="flex items-end">
                    {avatar && <UserAvatar size={42} {...avatar}/>}
                </div>
            </If>
        </div>
    );
};


export { ChatMessage };
