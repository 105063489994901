/**
 * ChatCellBadgePart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ChatListItem } from '@/types/chat';

import { ChatType } from '@/codegen/graphql';

import { Icon16Advertising, Icon16Users } from '@vkontakte/icons';


interface Props {
    chat: ChatListItem;
}


const ChatCellBadgePart = (props: Props) => {

    switch (props.chat.type) {
        case ChatType.GroupChannel:
        case ChatType.PublicChannel:
        case ChatType.PrivateChannel:
            return (
                <div className="bg-accent rounded-full p-[3px]">
                    <Icon16Advertising width={12} height={12} fill="white"/>
                </div>
            );

        case ChatType.GroupChat:
            return (
                <div className="bg-accent rounded-full p-[3px]">
                    <Icon16Users width={12} height={12} fill="white"/>
                </div>
            );

        default:
            return <></>;
    }
};


export { ChatCellBadgePart };
