/**
 * TwoFactorSetModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, useStore } from '@/pages/Core';
import { UserSettingsPageStore } from '@/pages/User/Settings/store';

import { Modal } from '@/cutils';

import { Title } from '@exode.ru/vkui';

import { QrFormView } from '@/pages/User/Settings/views/QrFormView';
import { twoFactorHeader } from '@/pages/User/SetTwoFactor/constants';


interface Props {
    id: string;
}


const TwoFactorSetModal = observer((props: Props) => {

    const { input } = useStore(UserSettingsPageStore);

    const { title, subheader } = twoFactorHeader(input.hasOtp);

    return (
        <Modal.Card id={props.id}
                    subheader={subheader}
                    header={<Title className="modal-title" level="2" weight="3">{title}</Title>}>
            <Modal.Body isScrollable>
                <QrFormView/>
            </Modal.Body>
        </Modal.Card>
    );
});


export { TwoFactorSetModal };
