/**
 * LastOnlineText
 *
 * @author: exode <hello@exode.ru>
 */

import moment from 'moment';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { observer } from '@/pages/Core';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { useUserOnline } from '@/hooks/user';
import { ObjectUtil, Time } from '@/utils';

import { UserSexType } from '@/codegen/graphql';


interface Props {
    userId: number;
    lastOnlineAt: Date | null;
    sex?: UserSexType;
    hidePrefix?: boolean;
}


const LastOnlineText = observer((props: Props) => {

    const { userId, lastOnlineAt, hidePrefix } = props;

    const { t } = useI18n('components.Profile.Page');

    const { isOnline } = useUserOnline(userId);

    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const [ parsedTime, setParsedTime ] = useState(() => (
        lastOnlineAt
            ? Time.getHoursAndMinutes(lastOnlineAt)
            : { hours: 0, minutes: 0 }
    ));

    /** Memoize year precision calculation to avoid recalculating on every render */
    const yearPrecision = useMemo(() => {
        if (!lastOnlineAt) {
            return '';
        }

        const year = moment(lastOnlineAt).toDate().getFullYear();
        const currentYear = new Date().getFullYear();

        return year < currentYear ? t('year', { year }) : '';
    }, [ lastOnlineAt ]);

    /** Memoize date format to avoid string concatenation on every render */
    const dateFormat = useMemo(() => {
        return [
            'D MMMM',
            yearPrecision,
            'в HH:mm',
        ].filter(e => e).join(' ');
    }, [ yearPrecision ]);

    /** Memoize hours display text */
    const hoursToDisplay = useMemo(() => (
        parsedTime.hours
            ? t('hours', { count: parsedTime.hours })
            : ''
    ), [ parsedTime.hours, t ]);

    /** Memoize the wasOnlineAt text to avoid recalculating on every render */
    const wasOnlineAt = useMemo(() => {
        if (!lastOnlineAt) {
            return '';
        }

        const { hours, minutes } = parsedTime;

        if (hours > 6) {
            return [
                !hidePrefix && t('wasActive'),
                Time.parseRelative(lastOnlineAt, dateFormat).toLowerCase(),
            ].filter(e => e).join(' ');
        } else {
            return [
                !hidePrefix && t('wasActive'),
                minutes === 0
                    ? t('justNow')
                    : `${hoursToDisplay} ${t('minutes', { count: minutes })} ${t('back')}`,
            ].filter(e => e).join(' ');
        }
    }, [
        parsedTime,
        hidePrefix,
        dateFormat,
        lastOnlineAt,
        hoursToDisplay,
    ]);

    /** Use callback to avoid recreating the function on every render */
    const onTick = useCallback(() => {
        if (!lastOnlineAt) {
            return;
        }

        const newParsedTime = Time.getHoursAndMinutes(lastOnlineAt);

        if (!ObjectUtil.isEqual(parsedTime, newParsedTime)) {
            setParsedTime(newParsedTime);
        }
    }, [ lastOnlineAt, parsedTime ]);

    useEffect(() => {
        /** Reset parsed time when lastOnlineAt changes */
        if (lastOnlineAt) {
            setParsedTime(Time.getHoursAndMinutes(lastOnlineAt));
        }
    }, [ lastOnlineAt ]);

    useEffect(() => {
        if (!lastOnlineAt) return;

        onTick();

        /** Get the seconds from lastOnlineAt */
        const targetSecond = moment(lastOnlineAt).seconds();

        /** Time until the next occurrence of that specific second */
        const now = moment();
        const currentSecond = now.seconds();
        const currentMillisecond = now.milliseconds();

        /** Wait until it occurs in the next minute */
        const secondsToWait = currentSecond <= targetSecond
            ? targetSecond - currentSecond
            : 60 - currentSecond + targetSecond;

        const timeToStartInterval = (secondsToWait * 1000 - currentMillisecond) + 1000;

        /** Set initial timeout to sync with the specific second */
        const initialTimeout = setTimeout(() => {
            onTick();

            /** After initial sync, set interval to run exactly every minute */
            intervalRef.current = setInterval(onTick, 60 * 1000);
        }, timeToStartInterval);

        return () => {
            clearTimeout(initialTimeout);

            if (intervalRef.current) {
                clearInterval(intervalRef.current);

                intervalRef.current = null;
            }
        };
    }, [ lastOnlineAt, onTick ]);

    return (
        <span className="first-letter:capitalize">
            <If is={!lastOnlineAt}>
                <>{lastOnlineAt === null ? t('wasNotOnline') : t('loading')}</>
            </If>

            <If is={!!lastOnlineAt}>
                <>{isOnline ? t('activeNow') : wasOnlineAt}</>
            </If>
        </span>
    );
});


export { LastOnlineText };
