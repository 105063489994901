/**
 * UseUserState
 *
 * @author: exode <hello@exode.ru>
 */

import { Storage } from '@/api/storage';
import { apolloClient } from '@/api/graphql';
import { UserAuthStore } from '@/store/user/auth';

import { GqlResult } from '@/types/graphql';

import {
    GetUserStateDocument,
    GetUserStateQuery,
    GetUserStateQueryResult,
    useGetUserStateQuery,
    UserStateKey,
    useUpdateUserStateMutation,
} from '@/codegen/graphql';


/** For call from service (non-component) */
export const getUserState = async (
    key: UserStateKey,
    options: {
        mergeToStore?: boolean;
    } = {},
) => {
    const { data, error, loading } = await apolloClient.query<GqlResult<GetUserStateQueryResult>>({
        query: GetUserStateDocument,
        variables: { key },
    });

    if (options.mergeToStore && data) {
        UserAuthStore.merge({
            states: {
                [key]: data.userStateGetByKey,
                ...UserAuthStore.states,
            },
        });
    }

    return {
        data,
        error,
        loading,
        value: data?.userStateGetByKey,
    };
};

/**
 * Get user state
 * @param {} getKey
 */
export const useGetUserState = (getKey: UserStateKey) => {
    const {
        data: userState,
        error: userStateError,
        loading: userStateLoading,
    } = useGetUserStateQuery({
        variables: { key: getKey },
        onError: (error) => console.error(error),
        skip: !Storage.get('user:auth-token') || !UserAuthStore.isLoggedIn,
    });

    return [
        userState?.userStateGetByKey,
        userStateLoading,
        userStateError,
    ];
};

/**
 * User set user state
 */
export const useSetUserState = () => {

    const [
        _setUserState,
        { loading: setUserStateLoading },
    ] = useUpdateUserStateMutation({
        onError: (error) => console.error(error),
    });

    const setUserState = async (
        key: UserStateKey,
        value: any,
        options: {
            cacheOnly?: boolean;
            optimistic?: boolean;
        } = {},
    ) => {
        const { optimistic = true, cacheOnly = false } = options;

        if (!Storage.get('user:auth-token')) {
            return;
        }

        const writeToCache = () => {
            apolloClient.cache.writeQuery<GetUserStateQuery>({
                query: GetUserStateDocument,
                variables: { key },
                data: {
                    __typename: 'Query',
                    userStateGetByKey: value,
                },
            });
        };

        if (cacheOnly) {
            return writeToCache();
        }

        if (optimistic) {
            writeToCache();
        }

        const promise = _setUserState({
            update: () => writeToCache(),
            onError: (error) => console.error(error),
            variables: {
                key,
                input: { value },
            },
        });

        return !optimistic ? await promise : null;
    };

    return {
        setUserState,
        setUserStateLoading,
    };
};
