/**
 * SubNavigationSelect styled container
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { useLocation } from '@/router/index';
import { useUrlFilters } from '@/hooks/core';

import { Dropdown } from '@exode.ru/vkui/dist/unstable';
import { SimpleCell, SimpleCellProps, SubnavigationButton, SubnavigationButtonProps } from '@exode.ru/vkui';

import {
    Icon16CheckCircleFillGreen,
    Icon16Dropdown,
    Icon16DropdownFlipped,
    Icon24Cancel,
    Icon24Filter,
} from '@vkontakte/icons';

import { Container, OptionsWrapper } from './SubNavigationSelect.styled';


interface Props {
    value: any;
    name: string;
    initialTitle: string;
    onChange: (name: string, value: any) => void;
    options: Array<{
        value: string | number;
        label: string;
    } & Partial<SimpleCellProps>>;
    filterIcon?: ReactNode;
    placeholder?: string;
    hideSelected?: boolean;
    navigationButtonProps?: Partial<SubnavigationButtonProps>;
}


const SubNavigationSelect = (props: Props) => {

    const {
        name,
        value,
        options,
        onChange,
        initialTitle,
        navigationButtonProps,
        hideSelected = false,
        filterIcon = <Icon24Filter fill="var(--icon_secondary)"/>,
    } = props;

    const [ showDropdown, setShowDropdown ] = useState(false);
    const [ selectedValue, setSelectedValue ] = useState(value);

    const { route: { params } } = useLocation();
    const { removeUrlFilter } = useUrlFilters({});

    const selectOptions = useMemo(() => {
        return options.filter((option) => hideSelected
            ? option.value !== value
            : true,
        );
    }, [ options, value, hideSelected ]);

    const setSelectedOption = (value: string | number) => {
        setShowDropdown(false);

        setSelectedValue(value);
        onChange(name, value);
    };

    useEffect(() => {
        setSelectedValue(value);
    }, [ value ]);

    return (
        <Container hasValue={!!value}>
            <Dropdown sameWidth
                      action="click"
                      shown={showDropdown}
                      key={JSON.stringify(params)}
                      onShownChange={setShowDropdown}
                      className="overflow-hidden z-[1] thin-border vk-shadow"
                      content={(
                          <OptionsWrapper>
                              {selectOptions.map(({ label, value, className, ...rest }, index) => (
                                  <SimpleCell key={index}
                                              onClick={() => setSelectedOption(value)}
                                              className={[ '!rounded-none pr-2', className ].join(' ')}
                                              after={(
                                                  value === props.value
                                                      ? <Icon16CheckCircleFillGreen/>
                                                      : undefined
                                              )} {...rest}>
                                      {label}
                                  </SimpleCell>
                              ))}
                          </OptionsWrapper>
                      )}>
                <SubnavigationButton open={showDropdown} after={(
                    !showDropdown
                        ? <Icon16Dropdown fill="var(--icon_secondary)"/>
                        : <Icon16DropdownFlipped fill="var(--accent)"/>
                )} className={[
                    'z-[2]',
                    showDropdown
                        ? 'thin-border-border'
                        : 'thin-border-border--transparent',
                ].join(' ')} before={(
                    params[name]
                        ? <Icon24Cancel fill="var(--accent)" onClick={(e) => {
                            e.stopPropagation();
                            setShowDropdown(false);

                            removeUrlFilter([ name as never ]);
                        }}/>
                        : filterIcon
                )} {...navigationButtonProps}>
                    {_.find(options, { value: selectedValue })?.label || initialTitle}
                </SubnavigationButton>
            </Dropdown>
        </Container>
    );
};


export { SubNavigationSelect };
