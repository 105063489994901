/**
 * CatalogTab
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { IS_SCHOOL } from '@/root/src/env';

import { SchoolStore } from '@/store/platform';
import { observer, Page, pageRow } from '@/pages/Core';

import { Platform } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { SchoolBlockMode } from '@/codegen/graphql';

import { SpaceBlock } from '@/components/Landing/SpaceBlock';
import { DesktopHeaderMenu } from '@/components/Desktop/Navigation';
import { InlineInfoCaptionText } from '@/components/Atoms/InlineInfoCaptionText';

import { ElementBackground } from '@/images/landing';

import { SchoolHeaderView } from './views/SchoolHeaderView';
import { MarketplaceHeaderView } from './views/MarketplaceHeaderView';
import { CatalogCoursesView } from './views/CatalogCoursesView';
import { CreateCourseBannerView } from './views/CreateCourseBannerView';


const CatalogTab = observer(() => {

    const { t } = useI18n('tabs.Catalog');

    return (
        <Page.Wrapper withShadow={false}>
            <Page.Head>
                <Page.Header backTo={null} title={<DesktopHeaderMenu mobileTitle={t('catalogOfCourses')}/>}/>
            </Page.Head>

            <Page.Content>
                <Page.Row isSquare
                          fullHeight
                          separator="hide"
                          innerClassName="!px-5 !bg-transparent"
                          className={[ 'm:bg-center m:min-h-[168px]', pageRow.classNames.gradientBanner ].join(' ')}
                          style={{
                              ...(IS_SCHOOL
                                      ? {
                                          backgroundColor: SchoolStore.blocks?.catalog?.color || undefined,
                                          backgroundImage: SchoolStore.blocks?.catalog?.mode === SchoolBlockMode.Image
                                              ? `url(${SchoolStore.blocks?.catalog?.url})`
                                              : undefined,
                                      }
                                      : {
                                          backgroundImage: `url(${ElementBackground})`,
                                      }
                              ),
                          }}>
                    <Platform.Market>
                        <MarketplaceHeaderView/>
                    </Platform.Market>

                    <Platform.School>
                        <SchoolHeaderView/>
                    </Platform.School>
                </Page.Row>

                <Page.Row isSquare fullHeight separator="hide" innerClassName="d:!px-5 min-h-[calc(100vh_-_400px)]">
                    <CatalogCoursesView/>

                    <Platform.Market>
                        <SpaceBlock count={2}/>
                        <InlineInfoCaptionText className="m:px-3" text={t('didNotFoundTheCourse?')}/>
                        <SpaceBlock count={2}/>

                        <CreateCourseBannerView/>
                    </Platform.Market>

                    <SpaceBlock/>
                    <SpaceBlock className="m:hidden"/>
                </Page.Row>
            </Page.Content>
        </Page.Wrapper>
    );
});


export { CatalogTab };
