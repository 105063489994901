/**
 * ChatMessagesView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, Page, useStore } from '@/pages/Core';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';

import { ChatException, ChatMessageFindManyQuery } from '@/codegen/graphql';

import { useI18n } from '@/hooks/core';
import { getGqlCause } from '@/api/graphql';
import { RoutePathType } from '@/router/paths';
import { Graphql, If, Redirect } from '@/cutils';
import { useChatInfo, UseChatInputProps } from '@/hooks/apollo';

import { ErrorFallback } from '@/components/App/ErrorFallback';

import { ChatProvider } from '../contexts/ChatContext';

import { InputView } from './InputView';
import { PinnedView } from './PinnedView';
import { MessagesListView } from './MessagesListView';
import { ChatNoMessagesView } from './ChatNoMessagesView';

import { TopBarPart } from '../parts/TopBarPart';
import { TopBarSkeleton } from '../parts/TopBarSkeleton';
import { TopBarBlankPart } from '../parts/TopBarBlankPart';


interface Props {
    personalUserId?: number;
    backOrTo?: RoutePathType;
    input?: {
        placeholder?: string;
    };
    blank?: {
        title: string;
        subtitle?: string;
        noMessageSubtitle?: string;

    };
}


const ChatMessagesView = observer((props: Props) => {

    const { backOrTo, personalUserId, blank } = props;

    const { t } = useI18n('pages.Chat.Dialog');

    const {
        list,
        filter,
        sort,
        params: {
            chatId,
            chatMessage,
            create = 'chat',
        },
    } = useStore(ChatDialogsPageStore);

    const chatInfo = useChatInfo({
        chatId,
        backOrTo,
        personalUserId,
    });

    const {
        chat,
        myMemberIsBlocked,
        sendMessageIsBlocked,
        companionMemberIsBlocked,
    } = chatInfo;

    return (
        <ChatProvider value={chatInfo}>
            {/** Exist chat view */}
            <If is={!!chatId}>
                <div className="flex justify-between items-center py-2 px-2 thin-border-bottom h-[36px]">
                    {chat ? <TopBarPart/> : <TopBarSkeleton/>}
                </div>

                <ChatMessageFindManyQuery key={chatId} children={(result) => (
                    <>
                        <Graphql.Loading result={result}>
                            <Page.Spinner/>
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ chatMessageFindMany: { items: messages, cursor } }) => (
                                <>
                                    <PinnedView messages={[]}/>

                                    <MessagesListView cursor={cursor} messages={messages} fetchMore={result.fetchMore}/>
                                </>
                            )}
                        </Graphql.Success>

                        <Graphql.Error result={result}>
                            {(error) => (
                                <>
                                    <If is={getGqlCause(error) === ChatException.NotChatMember}>
                                        <Redirect to="/chat" replace/>
                                    </If>

                                    <If is={getGqlCause(error) !== ChatException.NotChatMember}>
                                        <ErrorFallback message={error?.message} onClick={() => result.refetch()}/>
                                    </If>
                                </>
                            )}
                        </Graphql.Error>
                    </>
                )} variables={{
                    chatId: +chatId,
                    list: { ...list.messages },
                    filter: { ...filter.messages },
                    sort: { ...sort.messages },
                }}/>

                <InputView value={chatMessage} isBlocked={sendMessageIsBlocked} placeholder={(
                    myMemberIsBlocked
                        ? t('messagesAreBlocked')
                        : companionMemberIsBlocked
                            ? t('youAreLimitedMessages')
                            : chat?.archived
                                ? t('messageSendingUnavailable')
                                : undefined
                )}/>
            </If>

            {/** Blank chat view */}
            <If is={!!personalUserId && !chatId || !!blank}>
                <div className="flex flex-col w-full relative">
                    <If is={!blank}>
                        <div className="flex justify-between items-center py-2 px-2 thin-border-bottom h-[36px]">
                            <TopBarPart/>
                        </div>
                    </If>

                    <If is={!!blank}>
                        <TopBarBlankPart title={`${blank?.title}`} subtitle={blank?.subtitle}/>
                    </If>

                    <ChatNoMessagesView subtitle={blank?.noMessageSubtitle}/>

                    <InputView value={chatMessage}
                               placeholder={props.input?.placeholder}
                               useChatInputProps={{ create: create as UseChatInputProps['create'] }}/>
                </div>
            </If>
        </ChatProvider>
    );
});


export { ChatMessagesView };
