/**
 * InviteFriendsView
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import { ListFriendsByStatusQueryResult, useTournamentInvitationsQuery } from '@/codegen/graphql';

import { Counter, Header, List, Spinner } from '@exode.ru/vkui';
import { Icon24AddOutline, Icon24DoneOutline } from '@vkontakte/icons';

import { If } from '@/cutils';
import { GqlResult } from '@/types/graphql';
import { useTournamentLobby } from '@/hooks/apollo';

import { User } from '@/components/Atoms/User';
import { CustomScrollbar } from '@/components/Atoms/Styled';


interface Props {
    tournamentId: number;
    friends: GqlResult<ListFriendsByStatusQueryResult>['friendshipFindMany'];
    isContext?: boolean;
}


const InviteFriendsView = (props: Props) => {

    const {
        tournamentId,
        isContext = false,
        friends: { items, count },
    } = props;

    const [ inviteId, setInviteId ] = useState(0);
    const friends = isContext ? items?.slice(0, 5) : items;

    const { sendInvitation, sendInvitationLoading } = useTournamentLobby();

    const { data, loading: queryLoading } = useTournamentInvitationsQuery({
        variables: {
            tournamentId,
            list: { skip: 0, take: 20 },
        },
        onError: error => {
            console.error(error);
        },
    });

    const {
        items: invitedUsers,
    } = data?.tournamentLobbyFindManyInvitations || { items: [] };

    const invitedUserIds = invitedUsers.map(({ to }) => to.id);

    const handleInvite = async (toUserId: number) => {
        setInviteId(toUserId);

        if (!invitedUserIds?.includes(toUserId)) {
            await sendInvitation(tournamentId, toUserId);
        }
    };

    return (
        <If is={!!items?.length}>
            <Header mode="secondary" aside={<Counter size="m" mode="primary">{count}</Counter>}>
                Мои друзья
            </Header>

            <CustomScrollbar className="d:max-h-[200px] d:overflow-auto mt-2">
                <List>
                    {friends?.map(({ id: userId, profile, lastOnlineAt }) => (
                        <User.Cell key={userId}
                                   mode="simple"
                                   userId={userId}
                                   profile={profile}
                                   lastOnlineAt={lastOnlineAt}
                                   onClick={() => handleInvite(userId)}
                                   disabled={queryLoading || sendInvitationLoading}
                                   avatarProps={{ badgeBg: 'var(--modal_card_background)' }}
                                   after={(
                                       (queryLoading || sendInvitationLoading && userId === inviteId)
                                           ? <Spinner size="regular" className="text-accent"/>
                                           : invitedUserIds?.includes(userId)
                                               ? <Icon24DoneOutline/>
                                               : <Icon24AddOutline/>
                                   )}/>
                    ))}
                </List>
            </CustomScrollbar>
        </If>
    );
};


export { InviteFriendsView };
