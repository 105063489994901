/**
 * ContentElementSubscription component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ConfigStore } from '@/store/core/config';
import { observer, UserAuthStore } from '@/store/user/auth';

import { DocumentEvent } from '@/types/window';
import { useContentElementInteract } from '@/hooks/apollo';

import {
    useContentElementListenMyInteractionUpdateSubscription,
    useContentElementListenUpdateSubscription,
} from '@/codegen/graphql';


interface Props {
    uuid: string;
}


const ContentElementSubscription = observer((props: Props) => {

    const { uuid } = props;

    const { updateMyInteractionInCache } = useContentElementInteract();

    useContentElementListenUpdateSubscription({
        variables: { contentElementUuid: uuid },
    });

    useContentElementListenMyInteractionUpdateSubscription({
        skip: !UserAuthStore.isLoggedIn,
        variables: { contentElementUuid: uuid },
        onData: ({ data: { data } }) => {
            if (data?.contentElementListenMyInteractionUpdate) {
                const {
                    tracker,
                    entity: myInteraction,
                    entity: { contentElement },
                } = data?.contentElementListenMyInteractionUpdate;

                updateMyInteractionInCache(contentElement.id, myInteraction);

                if (tracker !== ConfigStore.tabUuid) {
                    document.dispatchEvent(
                        new CustomEvent(
                            DocumentEvent.ContentElementMyInteractionUpdated,
                            { detail: { uuid: contentElement.uuid, myInteraction } },
                        ),
                    );
                }
            }
        },
    });

    return <></>;
});


export { ContentElementSubscription };
