/**
 * UseGroupMembersManage
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ManageGroupsStudentsPageStore } from '@/pages/Manage/Groups/Students/store';

import { apolloClient } from '@/api/graphql';

import { Notify } from '@/cutils';
import { useStore } from '@/pages/Core';

import {
    GroupMemberFindManyDocument,
    GroupMemberFindManyQuery,
    useGroupMemberCreateManyMutation,
    useGroupMemberDeleteManyMutation,
} from '@/codegen/graphql';

import { Icon16Cancel } from '@vkontakte/icons';


export const useGroupMembersManage = () => {

    const { list, filter, sort, params } = useStore(ManageGroupsStudentsPageStore);

    const getVariables = () => ({
        list: { ...list },
        filter: { ...filter },
        sort: { ...sort },
    });

    const getCachedMembers = () => {
        const variables = getVariables();

        const cachedMembers = apolloClient.cache.readQuery<GroupMemberFindManyQuery>({
            query: GroupMemberFindManyDocument,
            variables,
        });

        return {
            variables,
            cachedMembers: cachedMembers?.groupMemberFindMany,
        };
    };

    const [ _createMembers, {
        error: createMembersError,
        loading: createMembersLoading,
    } ] = useGroupMemberCreateManyMutation({
        onError(error) {
            console.error(error);

            Notify.vkui({
                appearance: 'error',
                message: error.message,
                icon: <Icon16Cancel/>,
            });
        },
        update: (cache, { data }) => {
            const { variables, cachedMembers } = getCachedMembers();

            if (!cachedMembers) {
                return console.warn('[Cache]: cachedMembers отсутствуют в кэше');
            }

            const { count, items, ...rest } = cachedMembers;

            if (data?.groupMemberCreateMany) {
                cache.writeQuery<GroupMemberFindManyQuery>({
                    query: GroupMemberFindManyDocument,
                    variables,
                    data: {
                        groupMemberFindMany: {
                            ...rest,
                            count: count + data?.groupMemberCreateMany?.created.length,
                            items: [
                                ...data?.groupMemberCreateMany.created,
                                ...(items ?? []),
                            ],
                        },
                    },
                });
            }

            if (params.courseId) {
                /** Refetch groups count in group card */
                cache.evict({
                    id: `GroupEntity:${variables.filter.groupIds[0]}`,
                    fieldName: 'countMembers',
                });
            }
        },
    });

    const createMembers = (
        groupId: number,
        userIds: number[],
        onCompleted?: () => void,
    ) => {
        return _createMembers({
            variables: { groupId, userIds },
            onCompleted: () => onCompleted?.(),
        });
    };

    const [ _deleteGroupMember, {
        loading: deleteGroupMemberLoading,
        error: deleteGroupMemberError,
    } ] = useGroupMemberDeleteManyMutation();

    const deleteGroupMember = async (groupId: number, userIds: number[]) => {
        return _deleteGroupMember({
            variables: { groupId, userIds },
            update: (cache, { data }) => {
                const { variables, cachedMembers } = getCachedMembers();

                if (!cachedMembers) {
                    return console.warn('[Cache]: cachedMembers отсутствуют в кэше');
                }

                const { count, items, ...rest } = cachedMembers;

                if (data?.groupMemberDeleteMany) {
                    cache.writeQuery<GroupMemberFindManyQuery>({
                        query: GroupMemberFindManyDocument,
                        variables,
                        data: {
                            groupMemberFindMany: {
                                ...rest,
                                count: count - 1,
                                items: [
                                    ...items.filter(item => !userIds.includes(item.user.id)),
                                ],
                            },
                        },
                    });
                }

                /** Refetch groups count in group card */
                cache.evict({
                    id: `GroupEntity:${groupId}`,
                    fieldName: 'countMembers',
                });
            },
        });
    };

    return {
        createMembers,
        createMembersError,
        createMembersLoading,
        deleteGroupMember,
        deleteGroupMemberLoading,
        deleteGroupMemberError,
    };
};
