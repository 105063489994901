/**
 * Icon images
 *
 * @author: exode <hello@exode.ru>
 */

import { PageSpace } from '@/shared/types';

import { IconAtom } from './IconAtom';
import { IconBubbleTail } from './IconBubbleTail';
import { IconDoubleSwords } from './IconDoubleSwords';
import { IconExodeLogo } from './IconExodeLogo';
import { IconExodeIcon } from './IconExodeIcon';
import { IconGooglePlay } from './IconGooglePlay';
import { IconMedal } from './IconMedal';
import { IconNotion } from './IconNotion';
import { IconTelegram } from './IconTelegram';
import { IconTikTok } from './IconTikTok';
import { IconVk } from './IconVk';
import { IconWallet } from './IconWallet';


const Logo = (process.env.PUBLIC_URL || '') + '/static/images/brand/common/logo.svg';
const IconPart = (process.env.PUBLIC_URL || '') + '/static/images/brand/common/icon-part.svg';
const IconWhite = (process.env.PUBLIC_URL || '') + '/static/images/brand/common/icon-white.svg';

const IconRu = (process.env.PUBLIC_URL || '') + '/static/images/brand/ru/icon.svg';
const IconRuSquare = (process.env.PUBLIC_URL || '') + '/static/images/brand/ru/icon-square.svg';

const IconBiz = (process.env.PUBLIC_URL || '') + '/static/images/brand/biz/icon.svg';
const IconBizWhite = (process.env.PUBLIC_URL || '') + '/static/images/brand/biz/icon-white.svg';
const IconBizSquare = (process.env.PUBLIC_URL || '') + '/static/images/brand/biz/icon-square.svg';

const PlatformLogo = {
    [PageSpace.MarketplacePlatform]: IconRu,
    [PageSpace.BizWelcomePlatform]: IconBiz,
    [PageSpace.SchoolPlatform]: typeof window !== 'undefined' && window.exode.school?.iconUrl,
};


export {
    Logo,
    IconPart,
    IconWhite,
    IconRu,
    IconRuSquare,
    IconBiz,
    IconBizWhite,
    IconBizSquare,
    PlatformLogo,
    IconAtom,
    IconBubbleTail,
    IconDoubleSwords,
    IconExodeLogo,
    IconExodeIcon,
    IconGooglePlay,
    IconMedal,
    IconNotion,
    IconTelegram,
    IconVk,
    IconTikTok,
    IconWallet,
};
