/**
 * ChatWidget component index file
 *
 * @author: exode <hello@exode.ru>
 */

import { CourseLessonPracticeAttemptWidgetItem } from './CourseLessonPracticeAttemptWidgetItem';


export const ChatWidget = {
    PracticeAttempt: CourseLessonPracticeAttemptWidgetItem,
};
