/**
 * ContentView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { ManageHomeworkPageStore } from '../store';

import { Graphql, If } from '@/cutils';
import { usePagination } from '@/hooks/core';
import { observer, Page } from '@/pages/Core';

import { CourseLessonPracticeAttemptFindManyQuery } from '@/codegen/graphql';

import { Pagination } from '@/components/Atoms/Pagination';
import { UserListSkeleton } from '@/components/Atoms/Skeleton/UserList';

import { TableView } from './TableView';
import { EmptyContentView } from './EmptyContentView';

import { useManageHomeworkFilter } from '../filter';


const ContentView = observer(() => {

    const { list, sort, page, onPageChange } = usePagination({
        store: ManageHomeworkPageStore,
        path: '/manage/homeworks/:page([0-9]+)',
    });

    const { dtoFilter } = useManageHomeworkFilter();

    return (
        <CourseLessonPracticeAttemptFindManyQuery children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <Page.Row>
                        <UserListSkeleton withHeader
                                          baseSkeletonWidth="100%"
                                          itemClassName="children-full-w"
                                          listClassName="mt-1.5 gap-1.5"
                                          headerClassName="min-w-[240px]"/>
                    </Page.Row>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({ courseLessonPracticeAttemptFindMany: { pages, items } }) => (
                        <>
                            <Page.Row>
                                <If is={!_.isEmpty(items)}>
                                    <TableView items={items}/>
                                </If>

                                <If is={_.isEmpty(items)}>
                                    <EmptyContentView/>
                                </If>
                            </Page.Row>

                            <Pagination sticky
                                        boundaryCount={1}
                                        currentPage={page}
                                        totalPages={pages}
                                        onChange={(value) => onPageChange(value, result.refetch)}/>
                        </>
                    )}
                </Graphql.Success>

                <Graphql.Error result={result}/>
            </>
        )} variables={{
            list: { ...list },
            filter: dtoFilter,
            sort: { ...sort },
        }}/>
    );
});


export { ContentView };
