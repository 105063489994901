/**
 * UseSession
 *
 * @author: exode <hello@exode.ru>
 */

import {
    MyUserSettingsDocument,
    MyUserSettingsQuery,
    useKillSessionByIdMutation,
    useKillSessionsMutation,
} from '@/codegen/graphql';


export const useSession = () => {

    const [ killOtherSessions, { loading: killOtherSessionsLoading } ] = useKillSessionsMutation({
        refetchQueries: [ { query: MyUserSettingsDocument } ],
        onError: (e) => {
            console.error(e);
        },
    });

    const [
        _killSessionById,
        { loading: killSessionByIdLoading },
    ] = useKillSessionByIdMutation({
        update: (cache, { data }) => {
            const session = data?.sessionKillSessionById;

            const cachedSettings = cache.readQuery<MyUserSettingsQuery>({
                query: MyUserSettingsDocument,
            });

            if (session && cachedSettings) {
                cache.writeQuery<MyUserSettingsQuery>({
                    query: MyUserSettingsDocument,
                    data: {
                        ...cachedSettings,
                        sessionGetActiveSessions: cachedSettings.sessionGetActiveSessions
                            .filter(({ id }) => id !== session.id),
                    },
                });
            }
        },
        onError(error) {
            console.error(error);
        },
    });

    const killSessionById = (sessionId: number) => {
        return _killSessionById({ variables: { sessionId } });
    };

    return {
        killSessionById,
        killSessionByIdLoading,
        killOtherSessions,
        killOtherSessionsLoading,
    };
};
