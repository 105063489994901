/**
 * DownloadAppBanner
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { observer, RouterStore } from '@/store/core/router';

import { IS_VK, IS_WEB } from '@/root/src/env';

import { useLocation } from '@/router/index';
import { useBanner, useI18n } from '@/hooks/core';
import { If, Link, Responsive } from '@/cutils';

import { RoutePathType } from '@/router/paths';
import { LandingRoutes } from '@/router/routes/Landing';
import { PromoBannerLsKey } from '@/types/promotion';

import { Button, Text } from '@exode.ru/vkui';


const DownloadAppBanner = observer(() => {

    const { t } = useI18n('components.Promotion.MobileBanners.DownloadAppBanner');

    const { route: { pageId, params } } = useLocation();

    const { show, closeBanner } = useBanner(
        'promo:banners',
        PromoBannerLsKey.DownloadAppReminderInWeb,
    );

    const blackListPages: RoutePathType[] = [
        '/',
        '/login',
        '/catalog',
        '/login/app',
        '/login/signin',

        /** All landing keys */
        ..._.keys(LandingRoutes) as RoutePathType[],
    ];

    const blackListParams: string[] = [
        'no-download-app-banner',
    ];

    const isAllowedPage = !blackListPages.includes(pageId as RoutePathType)
        && blackListParams.every((param) => _.isNil(params[param]));

    return (
        <If is={IS_WEB && !IS_VK && show && isAllowedPage}>
            <Responsive.Mobile>
                <div className={[
                    'flex sticky justify-between items-center',
                    'left-0 right-0 z-[100] p-4 blue-light-blue-gradient',
                    RouterStore.showTabbar ? 'bottom-tabbar' : 'bottom-0 pb-safe',
                ].join(' ')}>
                    <div>
                        <Text weight="2" className="text-white">
                            {t('itsMoreConvenientInTheApp')}
                        </Text>

                        <Text weight="3" onClick={closeBanner} className="text-white fs-12 underline">
                            {t('continueInTheBrowser')}
                        </Text>
                    </div>

                    <Link pushPage={{ id: '/app' }}>
                        <Button onClick={closeBanner} size="s" mode="overlay_primary">
                            <span className="text-accent">
                                {t('open')}
                            </span>
                        </Button>
                    </Link>
                </div>
            </Responsive.Mobile>
        </If>
    );
});


export { DownloadAppBanner };
