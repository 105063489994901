/**
 * SystemMessageItem component
 *
 * @author: exode <hello@exode.ru>
 */

import moment from 'moment';

import React from 'react';

import { ChatMessageItem } from '@/types/chat';

import { Text } from '@exode.ru/vkui';
import { TextTooltip } from '@exode.ru/vkui/unstable';

import { MessageReaderWrapperItem } from '../MessageReaderWrapperItem';

import { Container } from './SystemMessageItem.styled';


interface Props {
    message: ChatMessageItem;
}


const SystemMessageItem = (props: Props) => {

    const { message: { id, text, createdAt } } = props;

    return (
        <MessageReaderWrapperItem message={props.message}>
            <Container key={id} data-id={text} className="flex items-center justify-center m-2">
                <TextTooltip placement="left" text={moment(createdAt).calendar()}>
                    <Text className="thin-border text-secondary fs-14 px-3 py-0.5 rounded-2xl">
                        {text}
                    </Text>
                </TextTooltip>
            </Container>
        </MessageReaderWrapperItem>
    );
};


export { SystemMessageItem };
