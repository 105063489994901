/**
 * CourseLessonPracticeAttemptWidgetRender
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { Formik } from 'formik';

import { UserAuthStore } from '@/store/user/auth';

import { useStore } from '@/pages/Core';
import { If, Link, Rbac, Responsive } from '@/cutils';
import { ChatDialogsPageStore, observer } from '@/pages/Chat/Dialog/store';

import { useI18n } from '@/hooks/core';
import { Router } from '@/services/Utils/Router';
import { getTaskCompletionStat } from '@/shared/helpers';
import { useChatInfoContext, usePracticeAttemptManage } from '@/hooks/apollo';

import { practiceAttemptStatus, PracticeAttemptWidgetItem } from '@/types/practice';
import { Permission, TaskAnswerStatus, UpdateAnswersTaskItemInput } from '@/codegen/graphql';

import { Icon16LinkOutline } from '@vkontakte/icons';
import { Button, CustomSelect } from '@exode.ru/vkui';

import { PracticeAttemptStatusItem } from '@/pages/Manage/Homework';

import { TaskResultWidget } from '@/components/Widgets/Task/TaskResultWidget';
import { MessageMediaWidgetItemProps } from '@/pages/Chat/Dialog/items/MessageMediaItem';
import { PracticeAttemptsUpdateManage } from '@/components/Practice/PracticeAttemptsUpdateManage';


interface Props extends MessageMediaWidgetItemProps {
    attempt: PracticeAttemptWidgetItem;
}


const CourseLessonPracticeAttemptWidgetRender = observer((props: Props) => {

    const {
        chatId,
        message,
        attempt,
        attempt: {
            status,
            answers,
            practice,
            pointsAmount,
            attemptIndex,
            maxPointsAmount,
            id: attemptId,
            practice: { passThreshold },
        },
    } = props;

    const practiceAttemptStatuses = practiceAttemptStatus();

    const { t } = useI18n('pages.Chat.Dialog.WidgetItem');

    const { chat } = useChatInfoContext();
    const { calculateRate } = getTaskCompletionStat([], []);
    const { store, input, stater } = useStore(ChatDialogsPageStore);

    const {
        updatePracticeAttemptStatus,
        updatePracticeAttemptStatusLoading,
    } = usePracticeAttemptManage();

    const isExpanded = !!stater.expandedMessages[chatId]?.[message.id];

    return (
        <Formik enableReinitialize initialValues={{
            status: attempt.status,
            taskAnswers: [] as UpdateAnswersTaskItemInput[],
        }} onSubmit={(values, { resetForm }) => {
            updatePracticeAttemptStatus(
                attemptId,
                values,
                () => resetForm(),
            );
        }}>
            {({ resetForm, setFieldValue, values: { taskAnswers } }) => (
                <>
                    <TaskResultWidget answers={answers}
                                      points={pointsAmount}
                                      isExpanded={isExpanded}
                                      maxPoints={maxPointsAmount}
                                      tasks={_.map(practice.tasks, 'task')}
                                      completionRate={calculateRate(pointsAmount, maxPointsAmount)}
                                      mode={(
                                          !!chat?.isAdmin && UserAuthStore.canAny([ Permission.CourseCurator ])
                                              ? 'manage'
                                              : 'preview'
                                      )}
                                      onExpand={(expanded) => {
                                          Router.updateParams({ attemptId: `${attemptId}` });

                                          store.handleExpandMessage({
                                              chatId,
                                              expanded,
                                              messageId: message.id,
                                              withResetAnother: true,
                                          });
                                      }}
                                      subtitle={[
                                          practiceAttemptStatuses[attempt.status].name,
                                          t('attemptIndex', {
                                              attemptIndex: (attemptIndex ?? 0) + 1,
                                          }),
                                          passThreshold ? t('threshold', { passThreshold }) : '',
                                      ].filter((e) => e).join(' • ')}
                                      icon={(
                                          <PracticeAttemptStatusItem status={status} className="p-1.5 mr-3"/>
                                      )}
                                      simpleCellProps={{
                                          after: (
                                              <Responsive.Desktop>
                                                  <Button size="m"
                                                          className={!isExpanded ? 'vk-shadow' : ''}
                                                          mode={isExpanded ? 'tertiary' : 'overlay_primary'}>
                                                      {!isExpanded ? t('expand') : t('collapse')}
                                                  </Button>
                                              </Responsive.Desktop>
                                          ),
                                      }}
                                      handleReply={(
                                          chat?.myMember
                                              ? () => {
                                                  store.setInput('replyMessage', {
                                                      ...input.replyMessage,
                                                      [chatId]: message,
                                                  });
                                              }
                                              : undefined
                                      )}
                                      title={(
                                          <Link blank pushPage={{
                                              id: '/courses/:page([0-9]+)/:courseId([0-9]+)/study/:lessonId([0-9]+)',
                                              params: {
                                                  lessonId: `${practice.lesson.id}`,
                                                  courseId: `${practice.lesson.course.id}`,
                                              },
                                          }}>
                                              <div className="flex items-center gap-1">
                                                  {practice?.lesson.name}

                                                  <Icon16LinkOutline width={12} height={12} fill="var(--accent)"/>
                                              </div>
                                          </Link>
                                      )}
                                      managePointRenderer={({ id: taskAnswerId, point, status, maxPoint }) => (
                                          <CustomSelect placeholder="—"
                                                        className="w-fit min-w-[80px]"
                                                        data-test="manage-point-select"
                                                        value={(
                                                            _.find(taskAnswers, { taskAnswerId })?.point
                                                            ?? (status !== TaskAnswerStatus.Verified ? undefined : point)
                                                        )}
                                                        options={(
                                                            Array(maxPoint + 1).fill(null)
                                                                .map((__, value) => ({
                                                                    value,
                                                                    label: `${value} / ${maxPoint}`,
                                                                }))
                                                        )}
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                'taskAnswers',
                                                                _.uniqBy([
                                                                    {
                                                                        taskAnswerId,
                                                                        point: +e.target.value,
                                                                        status: TaskAnswerStatus.Verified,
                                                                    },
                                                                    ...taskAnswers,
                                                                ], 'taskAnswerId'),
                                                            );
                                                        }}/>
                                      )}/>

                    <If is={!!chat?.isAdmin}>
                        <Rbac.Permission permissions={[ Permission.CourseCurator ]}>
                            <PracticeAttemptsUpdateManage attempt={attempt}
                                                          className="mt-1 mb-2 !justify-start w-full"
                                                          simpleSaveLoading={updatePracticeAttemptStatusLoading}
                                                          onSimpleSaveClick={() => {
                                                              updatePracticeAttemptStatus(
                                                                  attemptId,
                                                                  { taskAnswers },
                                                                  () => resetForm(),
                                                              );
                                                          }}/>
                        </Rbac.Permission>
                    </If>
                </>
            )}
        </Formik>
    );
});


export { CourseLessonPracticeAttemptWidgetRender };
