/**
 * ChatNoMessagesView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { useChatInfoContext } from '@/hooks/apollo';

import { Spinner } from '@exode.ru/vkui';
import { Text } from '@exode.ru/vkui/tokenized';
import { Icon56MessagesOutline } from '@vkontakte/icons';


interface Props {
    subtitle?: string;
}


const ChatNoMessagesView = (props: Props) => {

    const { t } = useI18n('pages.Chat.Dialog');

    const { subtitle = t('sendFirstMessage') } = props;

    const { createChatIsLoading } = useChatInfoContext();

    return (
        <>
            <div className="flex flex-1 relative">
                <div className={[
                    'flex flex-col gap-1.5 items-center justify-center',
                    'absolute top-0 bottom-0 left-2 right-2 overflow-auto',
                ].join(' ')}>
                    <If is={!createChatIsLoading}>
                        <Icon56MessagesOutline height={96} width={96} fill="var(--accent)"/>

                        <Text className="text-secondary mt-4">
                            {subtitle}
                        </Text>
                    </If>

                    <If is={createChatIsLoading}>
                        <div>
                            <Spinner/>

                            <Text className="fs-14 text-secondary">
                                {t('chatCreationLoading')}
                            </Text>
                        </div>
                    </If>
                </div>
            </div>
        </>
    );
};


export { ChatNoMessagesView };
