/**
 * Use copy
 *
 * @author: exode <hello@exode.ru>
 */

import { useRef } from 'react';

import { useI18n } from '@/hooks/core';

import { Notify } from '@/cutils';


const useCopy = (
    withMessage: string | boolean = '',
    callback?: () => void,
) => {

    const { t } = useI18n('hooks.core');

    const ref = useRef<HTMLDivElement>(null);

    const copy = async (value?: string) => {
        if (withMessage) {
            onCopy(
                typeof withMessage === 'string'
                    ? withMessage
                    : undefined,
                value,
            );
        }

        callback?.();

        if (value) {
            return navigator.clipboard.writeText(value)
                .catch(e => console.error(e));
        }

        if (ref.current) {
            return navigator.clipboard.writeText(ref.current.innerText)
                .catch(e => console.error(e));
        }
    };

    const onCopy = (
        message: string = t('copied'),
        value?: string,
    ) => {
        Notify.toast.success(message, { id: value });
    };

    return { copy, onCopy, ref };
};


export { useCopy };
