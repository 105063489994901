/**
 * Use plyr api
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useEffect, useRef } from 'react';

import toast from 'react-hot-toast';

import { Provider } from 'plyr';
import Plyr, { APITypes } from 'plyr-react';

import { useI18n } from '@/hooks/core';
import { FileUtil, Time, Url } from '@/utils';
import { DocumentEvent } from '@/types/window';

import { Icon24DeleteClockOutline } from '@vkontakte/icons';


interface Props {
    videoLink?: string | null;
    onPlay?: (plyr?: Plyr) => void;
    onPause?: (plyr?: Plyr) => void;
    onReady?: (plyr?: Plyr) => void;
    onVolumeChange?: (plyr?: Plyr) => void;
    currentTime?: number;
}


/**
 * Create plyr api instance
 * @param {Props} props
 */
export const usePlyrApi = (props: Props = {}) => {

    const { currentTime } = props;

    const { t } = useI18n('hooks.core');

    const plyrVideoRef = useRef<APITypes>(null);

    const getPlyrApi = () => plyrVideoRef.current?.plyr;

    /** Fixer to start video on timecode click */
    const forceStartPlay = async () => {
        const plyr = getPlyrApi();

        if (!plyr) {
            return;
        }

        await Time.timer(100);

        plyr.muted = false;

        plyr.togglePlay(true);
        plyr.toggleControls(true);
    };

    /** Handle timecode click */
    const onTimeCodeClick = async (e: any) => {
        const plyr = getPlyrApi();

        if (plyr && _.isFinite(e?.detail?.time)) {
            const time = e?.detail?.time;

            if (plyr.duration < time) {
                return toast(t('timeCodeExceedsVideoTime'), {
                    icon: <Icon24DeleteClockOutline fill="var(--accent)"/>,
                });
            }

            await forceStartPlay();

            plyr.currentTime = e?.detail?.time;
        }
    };

    /** Event handlers */
    const onPlay = () => {
        props.onPlay?.(getPlyrApi());
    };

    const onPause = () => {
        props.onPause?.(getPlyrApi());
    };

    const onReady = () => {
        const plyr = getPlyrApi();
        props.onReady?.(plyr);

        if (plyr && currentTime) {
            plyr.currentTime = currentTime;

            plyr.once?.('canplay', () => {
                plyr.currentTime = currentTime;
            });
        }
    };

    const onVolumeChange = () => {
        props.onVolumeChange?.(getPlyrApi());
    };

    const onTimeUpdate = () => {};

    /** Handle timecodes */
    useEffect(() => {
        document.addEventListener(DocumentEvent.VideoTimecodeClick, onTimeCodeClick);

        return () => {
            document.removeEventListener(DocumentEvent.VideoTimecodeClick, onTimeCodeClick);
        };
    }, [ plyrVideoRef.current?.plyr ]);

    const removePlyrListeners = (plyr: Plyr | undefined) => {
        plyr?.off?.('ready', onReady);
        plyr?.off?.('canplay', onReady);
        plyr?.off?.('play', onPlay);
        plyr?.off?.('pause', onPause);
        plyr?.off?.('volumechange', onVolumeChange);
        plyr?.off?.('timeupdate', onTimeUpdate);
    };

    useEffect(() => {
        setImmediate(() => {
            const plyr = getPlyrApi();

            removePlyrListeners(plyr);

            plyr?.on?.('ready', onReady);
            plyr?.once?.('canplay', onReady);
            plyr?.on?.('play', onPlay);
            plyr?.on?.('pause', onPause);
            plyr?.on?.('volumechange', onVolumeChange);
            plyr?.on?.('timeupdate', onTimeUpdate);
        });

        return () => {
            removePlyrListeners(getPlyrApi());
        };
    }, [ plyrVideoRef.current ]);

    return { plyrVideoRef, getPlyrApi, forceStartPlay };
};

/**
 * Parse link to plyr source
 * @param {string} link
 * @returns {{provider: any, src: any}[] | {src: string}[]}
 */
export const useParseToPlyrSources = (link?: string | null): Plyr.SourceInfo => {
    const { id: providerLinkId, provider } = Url.parseVideoUrl(link || '');

    const extension = FileUtil.getFileUrlExtension(link || '');

    const sources = providerLinkId && provider
        ? [ {
            type: 'video',
            provider: provider as Provider,
            src: providerLinkId,
        } ]
        : [ { type: `video/${extension}`, src: link || '' } ];

    return {
        sources,
        type: 'video',
    };
};
