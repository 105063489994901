/**
 * UseContentElementInteract
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { apolloClient } from '@/api/graphql';
import { UserAuthStore } from '@/store/user/auth';

import { DocumentEvent } from '@/types/window';

import {
    ContentElementFragmentFragment,
    ContentElementMyInteractionFragmentFragment,
    UpsertInteractionElementContentInput,
    useContentElementInteractMutation,
} from '@/codegen/graphql';


export const useContentElementInteract = () => {

    const [
        _interact,
        {
            error: interactError,
            loading: interactLoading,
        },
    ] = useContentElementInteractMutation({
        onError: (error) => console.error(error),
    });

    const interact = (
        contentElementUuid: string,
        data: UpsertInteractionElementContentInput['data'],
        onCompleted?: (myInteraction: ContentElementFragmentFragment['myInteraction']) => void,
    ) => {
        if (!UserAuthStore.isLoggedIn) {
            return null;
        }

        return _interact({
            variables: {
                contentElementUuid,
                interaction: { data },
            },
            onCompleted: ({ contentElementInteractionUpsert: myInteraction }) => {
                onCompleted?.(myInteraction);

                document.dispatchEvent(
                    new CustomEvent(
                        DocumentEvent.ContentElementMyInteractionSaved,
                        { detail: myInteraction },
                    ),
                );
            },
            update: (__, { data }) => {
                const myInteraction = data?.contentElementInteractionUpsert;

                if (myInteraction?.contentElement) {
                    const { contentElement } = myInteraction;

                    updateMyInteractionInCache(contentElement.id, myInteraction);
                }
            },
        });
    };

    const updateMyInteractionInCache = (
        contentElementId: number,
        myInteraction: ContentElementMyInteractionFragmentFragment,
    ) => {
        apolloClient.cache.modify({
            id: `ContentElementEntity:${contentElementId}`,
            fields: {
                myInteraction: () => _.omit(myInteraction, [ 'contentElement' ]),
            },
        });
    };

    return {
        interact,
        interactError,
        interactLoading,
        updateMyInteractionInCache,
    };
};
