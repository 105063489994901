/**
 * VkPublishButtonPart
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import toast from 'react-hot-toast';
import { useParams } from 'router.tsx';

import { useGetUserState } from '@/hooks/apollo';

import {
    PromotionPublicationType,
    TournamentPromotionContentType,
    UserStateKey,
    useVkPublishTournamentResultMutation,
} from '@/codegen/graphql';

import { useI18n } from '@/hooks/core';
import { If, Notify } from '@/cutils';
import { Button, ButtonGroup } from '@exode.ru/vkui';
import { Icon24NarrativeOutline, Icon24StoryOutline } from '@vkontakte/icons';

import { VKUIButtonProps } from '@exode.ru/vkui/dist/components/Button/Button';


interface Props {
    tournamentPromotionType?: TournamentPromotionContentType;
    isWinner?: boolean;
    className?: string;
    buttonClassName?: string;
    layout?: 'vertical' | 'horizontal';
    buttonSize?: VKUIButtonProps['size'];
    buttonMode?: VKUIButtonProps['mode'];
    buttonSecondaryMode?: VKUIButtonProps['mode'];
    disappearOnSuccess?: boolean;
}


const VkPublishButtons = (props: Props) => {

    const {
        className,
        buttonClassName,
        tournamentPromotionType,
        isWinner = false,
        buttonSize = 'l',
        layout = 'vertical',
        buttonMode = 'primary',
        buttonSecondaryMode = 'secondary',
        disappearOnSuccess = false,
    } = props;

    const { t } = useI18n('components.Atoms.VkPublishButtons');

    const { tournamentId } = useParams();

    const [ userVkToken ] = useGetUserState(UserStateKey.VkToken);

    const [ render, setRender ] = useState(true);
    const [ lastClicked, setLastClicked ] = useState<PromotionPublicationType | null>(null);

    const [ publish, { loading } ] = useVkPublishTournamentResultMutation({
        onError: error => {
            console.error(error);
            errorSnackbar();
        },
    });

    const errorSnackbar = () => toast.error(t('somethingWentWrong'));

    const publishToVK = async (type: PromotionPublicationType) => {
        setLastClicked(type);

        await publish({
            variables: {
                tournamentId: +tournamentId,
                promotionType: type,
                contentType: tournamentPromotionType
                    || TournamentPromotionContentType[isWinner ? 'WinnerShare' : 'ResultShare'],
            },
            onCompleted: ({ tournamentPromotionPublish }) => {
                disappearOnSuccess && setRender(false);

                tournamentPromotionPublish
                    ? Notify.toast.success('Успешно опубликовано')
                    : errorSnackbar();
            },
        });
    };

    return (
        <If is={render && !!userVkToken}>
            <ButtonGroup mode={layout} className={[ className, 'flex' ].join(' ')}>
                <Button stretched
                        size={buttonSize}
                        disabled={loading}
                        mode={buttonSecondaryMode}
                        className={buttonClassName}
                        before={<Icon24NarrativeOutline/>}
                        onClick={() => publishToVK(PromotionPublicationType.VkWallPost)}
                        loading={lastClicked === PromotionPublicationType.VkWallPost && loading}>
                    VK запись
                </Button>

                <Button stretched
                        mode={buttonMode}
                        size={buttonSize}
                        disabled={loading}
                        className={buttonClassName}
                        before={<Icon24StoryOutline/>}
                        onClick={() => publishToVK(PromotionPublicationType.VkStories)}
                        loading={lastClicked === PromotionPublicationType.VkStories && loading}>
                    VK сторис
                </Button>
            </ButtonGroup>
        </If>
    );
};


export { VkPublishButtons };
