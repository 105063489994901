/**
 * MessageInfoItem
 *
 * @author: exode <hello@exode.ru>
 */

import moment from 'moment';

import React, { MutableRefObject } from 'react';

import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';

import { ChatService } from '@/services/Chat/Chat';
import { If, SvgComponent } from '@/cutils';

import { useI18n } from '@/hooks/core';
import { useStore } from '@/pages/Core';
import { useChatInfoContext } from '@/hooks/apollo';

import { ChatMessageItem, chatMessageStatusIcon } from '@/types/chat';

import { Caption } from '@exode.ru/vkui';
import { TextTooltip } from '@exode.ru/vkui/unstable';

import {
    Icon16CheckDoubleOutline,
    Icon16PenOutline,
    Icon16Reply,
    Icon20MessageArrowRightOutline,
} from '@vkontakte/icons';


interface Props {
    message: ChatMessageItem;
    isCompact: boolean;
    isHovered: boolean;
    inputRef: MutableRefObject<HTMLTextAreaElement | null>;
}


const MessageInfoItem = (props: Props) => {

    const {
        message,
        inputRef,
        isCompact,
        isHovered,
    } = props;

    const { t } = useI18n('pages.Chat.Dialog');

    const { createdAt } = message;

    const { chat } = useChatInfoContext();

    const { store, input } = useStore(ChatDialogsPageStore);

    return (
        <div className={[
            'flex gap-2',
            isCompact ? 'absolute top-[3px] right-0' : '',
        ].join(' ')}>
            <If is={!!message.editedAt}>
                <TextTooltip placement="left" text={(
                    <div className="relative flex gap-1">
                        <Icon16PenOutline/>
                        {moment(message.editedAt).format('DD.MM.YYYY HH:mm')}
                    </div>
                )}>
                    <div>
                        <Caption level="2" weight="regular" className="text-subhead">
                            {t('edit')}
                        </Caption>
                    </div>
                </TextTooltip>
            </If>

            <If is={!isCompact}>
                <div>
                    <TextTooltip placement="left" text={(
                        <div className="relative flex gap-1">
                            <Icon20MessageArrowRightOutline width={16} height={16}/>
                            {moment(message.createdAt).format('DD.MM.YYYY HH:mm')}
                        </div>
                    )}>
                        <div>
                            <Caption level="2" weight="regular" className="first-letter:capitalize text-subhead">
                                {ChatService.parseChatDate(createdAt)}
                            </Caption>
                        </div>
                    </TextTooltip>
                </div>
            </If>

            {/**
             * Show reply for not mine message in invisible mode
             * For mines message - just replace message status icon
             */}
            <If is={(isHovered || !isCompact && !message.isMine) && !!chat?.myMember}>
                <TextTooltip placement="top" text={t('reply')}>
                    <div className={[
                        !isHovered ? 'invisible' : '',
                        isCompact ? 'top-[5px]' : 'top-[10px]',
                        'opacity-70 hover:opacity-100 right-[10px] w-4 h-4',
                    ].join(' ')} onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        if (!chat?.myMember) {
                            return;
                        }

                        inputRef.current?.focus();

                        store.setInput('isEditing', 0);
                        store.setInput('replyMessage', {
                            ...input.replyMessage,
                            [chat.id]: message,
                        });
                    }}>
                        <Icon16Reply fill="var(--accent)"/>
                    </div>
                </TextTooltip>
            </If>

            <If is={!isHovered && message.isMine}>
                <div className="text-accent">
                    <SvgComponent svgProps={{
                        width: 18,
                        height: 18,
                    }} element={(
                        <>
                            <If is={message.isRead}>
                                <Icon16CheckDoubleOutline/>
                            </If>

                            <If is={!message.isRead}>
                                {chatMessageStatusIcon[message.status]}
                            </If>
                        </>
                    )}/>
                </div>
            </If>
        </div>
    );
};


export { MessageInfoItem };
