/**
 * LessonCard component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactNode } from 'react';

import { observer, PreferenceStore } from '@/store/preference/preference';

import { CourseLessonAccessType, CourseLessonFindManyQueryResult } from '@/codegen/graphql';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { GqlResult } from '@/types/graphql';

import {
    CourseFindManyItem,
    CourseFindOne,
    CourseFindOneLessons,
    CourseLessonFindOneCourse,
    CourseLessonItems,
    CourseProgressCurrentItem,
    CurrentLesson,
} from '@/types/course';

import { RichCell } from '@exode.ru/vkui';
import { Avatar } from '@exode.ru/vkui/tokenized';
import { Icon24ChevronRightCircle } from '@vkontakte/icons';

import { getCourseCardColors } from '@/components/Course/CourseCard/helper';

import { DemoTagPart } from './parts/DemoTagPart';
import { VideoTagPart } from './parts/VideoTagPart';
import { HomeWorkTagPart } from './parts/HomeWorkTagPart';
import { LessonAfterIconPart } from './parts/LessonAfterIconPart';
import { LessonAvatarBadgePart } from './parts/LessonAvatarBadgePart';

import { Container } from './LessonCard.styled';


type LessonType = CurrentLesson
    | CourseLessonItems[number]
    | CourseProgressCurrentItem['lesson']
    | NonNullable<GqlResult<CourseLessonFindManyQueryResult>['courseLessonFindMany']['items']>[number]

export interface LessonCardProps {
    type: 'module' | 'lesson';
    course: CourseFindOne
        | CourseFindManyItem
        | CourseFindOneLessons
        | CourseLessonFindOneCourse
        | CourseProgressCurrentItem['course'];
    lesson: LessonType & { items?: LessonType[]; };
    order?: number;
    size?: 'default' | 'compact';
    active?: boolean;
    colorful?: boolean;
    onClick?: () => void;
    dataTest?: string;
    className?: string;
    before?: ReactNode;
    after?: ReactNode;
    caption?: ReactNode;
    captionTags?: ReactNode;
    hideBaseCaption?: boolean;
    richCellClassName?: string;
    openLessonLoading?: boolean;
    expandState?: Record<string | number, boolean>;
    onExpandClick?: (lessonId: number, expanded?: boolean) => void;
    publishedOrder?: { order: number; parentOrder?: number | null; } | null | undefined;
}


const LessonCard = observer((props: LessonCardProps) => {

    const {
        type,
        course,
        active,
        lesson,
        onClick,
        dataTest,
        colorful,
        className,
        captionTags,
        expandState,
        onExpandClick,
        publishedOrder,
        hideBaseCaption,
        openLessonLoading,
        richCellClassName,
        size = 'default',
        lesson: {
            name,
            wrapper,
            blocksInfo,
            withPractice,
        },
        before = (
            <If is={!!wrapper}>
                <Avatar mode="app"
                        shadow={false}
                        src={wrapper?.small || ''}
                        className="lesson-image-wrapper bg-transparent"
                        badge={(
                            <If is={lesson.withContent}>
                                <LessonAvatarBadgePart hasVideo={blocksInfo.hasVideo}
                                                       badgeSize={size === 'default' ? 12 : 10}/>
                            </If>
                        )}/>
            </If>
        ),
        after = (
            <div className="flex items-center gap-2">
                <If is={size !== 'compact' && !!lesson?.items?.length}>
                    <Avatar size={21} shadow={false} className="bg-accent cursor-pointer" onClick={(e) => {
                        e.stopPropagation();
                        onExpandClick?.(lesson.id);
                    }}>
                        <Icon24ChevronRightCircle fill="var(--white)" className="transition-all" style={{
                            transform: expandState?.[lesson.id]
                                ? 'rotate(-90deg)'
                                : 'rotate(-270deg)',
                        }}/>
                    </Avatar>
                </If>

                <If is={lesson.withContent}>
                    <LessonAfterIconPart course={course}
                                         lesson={lesson}
                                         colorful={colorful}
                                         isLoading={openLessonLoading}/>
                </If>
            </div>
        ),
    } = props;

    const { t } = useI18n('components.Course.LessonCard');

    const order = props.order || (
        publishedOrder
            ? [
                publishedOrder?.parentOrder,
                publishedOrder?.order,
            ].filter((e) => e).join('.')
            : lesson.order
    );

    const caption = props.caption || (
        <div className={[
            colorful ? 'text-primary' : '',
            'flex flex-wrap items-center gap-x-2 gap-y-1 pb-0.5',
        ].join(' ')}>
            <If is={!course.preferenceSettings.hideModuleOrders}>
                <span>
                    {type === 'module'
                        ? t('moduleOrder', { order })
                        : t('lessonOrder', { order })
                    }
                </span>
            </If>

            <If is={!hideBaseCaption}>
                <If is={lesson.accessType === CourseLessonAccessType.Demo}>
                    <DemoTagPart className="mt-[1px]"/>
                </If>

                <If is={!!blocksInfo.totalVideoDuration}>
                    <VideoTagPart duration={blocksInfo.totalVideoDuration || 0}/>
                </If>

                <If is={withPractice}>
                    <HomeWorkTagPart/>
                </If>
            </If>

            <>{captionTags}</>

            {/** Space saver */}
            <>{' '}</>
        </div>
    );

    return (
        <Container {...props} onClick={onClick}
                   className={[ className, 'select-none' ].join(' ')}
                   style={{ background: colorful ? getCourseCardColors(course, PreferenceStore.isDark) : undefined }}>
            <RichCell multiline
                      after={after}
                      before={before}
                      hasHover={false}
                      hasActive={false}
                      caption={caption}
                      data-test={dataTest}
                      data-value={lesson.id}
                      className={[
                          'cursor-pointer',
                          richCellClassName,
                          size === 'compact' ? 'min-h-0' : '',
                      ].join(' ')}>
                <span className={[
                    'break-words text-ellipsis',
                    active ? 'text-accent' : 'text-primary',
                    size === 'compact' ? 'line-clamp-1' : 'line-clamp-2 py-0.5',
                ].join(' ')}>
                    {name}
                </span>
            </RichCell>
        </Container>
    );
});


export { LessonCard };
