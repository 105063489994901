/**
 * UsePracticeAttemptManage
 *
 * @author: exode <hello@exode.ru>
 */

import { Notify } from '@/cutils';

import {
    AttemptCommonFragmentFragment,
    UpdateLessonPracticeAttemptCourseInput,
    useCourseLessonPracticeAttemptManageUpdateMutation,
} from '@/codegen/graphql';


export const usePracticeAttemptManage = () => {

    const [ updateStatus, {
        error: updatePracticeAttemptStatusError,
        loading: updatePracticeAttemptStatusLoading,
    } ] = useCourseLessonPracticeAttemptManageUpdateMutation();

    const updatePracticeAttemptStatus = async (
        attemptId: number,
        attempt: UpdateLessonPracticeAttemptCourseInput,
        onCompleted?: (attempt: AttemptCommonFragmentFragment) => void,
    ) => {
        return updateStatus({
            variables: {
                attempt,
                attemptId,
            },
            onCompleted: (data) => {
                onCompleted?.(data.courseLessonPracticeAttemptManageUpdate);
            },
            onError(error) {
                console.error(error);

                Notify.toast.error(error.message);
            },
        });
    };

    return {
        updatePracticeAttemptStatus,
        updatePracticeAttemptStatusError,
        updatePracticeAttemptStatusLoading,
    };
};


