/**
 * Translate resources
 *
 * @author: exode <hello@exode.ru>
 */

export const translateResources = {
    ru: {
        components: require('./generated/ru/components.json'),
        types: require('./generated/ru/types.json'),
        pages: require('./generated/ru/pages.json'),
        modals: require('./generated/ru/modals.json'),
        hooks: require('./generated/ru/hooks.json'),
        tabs: require('./generated/ru/tabs.json'),
        static: require('./generated/ru/static.json'),
    },
    uz: {
        components: require('./generated/uz/components.json'),
        types: require('./generated/uz/types.json'),
        pages: require('./generated/uz/pages.json'),
        modals: require('./generated/uz/modals.json'),
        hooks: require('./generated/uz/hooks.json'),
        tabs: require('./generated/uz/tabs.json'),
        static: require('./generated/uz/static.json'),
    },
    qa: {
        components: require('./generated/qa/components.json'),
        types: require('./generated/qa/types.json'),
        pages: require('./generated/qa/pages.json'),
        modals: require('./generated/qa/modals.json'),
        hooks: require('./generated/qa/hooks.json'),
        tabs: require('./generated/qa/tabs.json'),
        static: require('./generated/qa/static.json'),
    },
};
