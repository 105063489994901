/**
 * AutoSaveFormContext
 *
 * @author: exode <hello@exode.ru>
 */

import { createContext, ReactElement, useContext } from 'react';


interface AutoSaveFormContext {
    handleAutoSaveChange: () => void;
}

interface AutoSaveFormContextProps {
    value: AutoSaveFormContext;
    children: ReactElement | ReactElement[];
}


/**
 * AutoSaveForm context
 */
const AutoSaveFormContext = createContext<AutoSaveFormContext>({
    handleAutoSaveChange: () => {},
});

/**
 * AutoSaveForm provider
 * @param {AutoSaveFormContextProps} props
 * @constructor
 */
const AutoSaveFormProvider = (props: AutoSaveFormContextProps) => {
    return (
        <AutoSaveFormContext.Provider value={props.value}>
            {props.children}
        </AutoSaveFormContext.Provider>
    );
};

/**
 * UseAutoSaveFormContext hook
 */
const useAutoSaveFormContext = (): AutoSaveFormContext => useContext(AutoSaveFormContext);


export {
    AutoSaveFormProvider,
    useAutoSaveFormContext,
};
