/**
 * AvailableNewAppVersionContent
 *
 * @author: exode <hello@exode.ru>
 */

import semver from 'semver';

import React, { useState } from 'react';

import { observer } from 'mobx-react';

import { RouterStore } from '@/store/core/router';
import { PlatformStore } from '@/store/platform';

import { useI18n } from '@/hooks/core';
import { OS_PLATFORM } from '@/root/src/env';
import { If, Link, Responsive } from '@/cutils';

import { Button, Text } from '@exode.ru/vkui';
import { Icon28SmartphoneStarsOutline, Icon32DonutCircleFillYellow } from '@vkontakte/icons';

import { RoutePathType } from '@/router/paths';
import { useCurrentRoute, useLocation } from '@/router/index';


interface Props {
    newVersion: string;
    difference: semver.ReleaseType;
}


const AvailableNewAppVersionContent = observer((props: Props) => {

    const { COMMON } = PlatformStore;

    const { t } = useI18n('components.Promotion.MobileBanners.AvailableNewVersion');

    const [ hidden, setHidden ] = useState(false);

    const { isTab } = useCurrentRoute();
    const { route: { pageId } } = useLocation();

    const blackListPages: RoutePathType[] = [
        '/menu',
        '/education',
    ];

    const isAllowedPage = !blackListPages.includes(pageId as RoutePathType);

    return (
        <If is={isTab && !hidden && isAllowedPage}>
            <Responsive.Mobile>
                <div className={[
                    'flex sticky left-0 right-0 z-[100] overflow-hidden',
                    'justify-between items-center p-4 blue-light-blue-gradient',
                    RouterStore.showTabbar ? 'bottom-tabbar' : 'bottom-0 pb-safe',
                ].join(' ')}>
                    <div className="z-10">
                        <Text weight="3" className="text-white fs-11">
                            {t('version', { version: props.newVersion })}
                        </Text>

                        <Text weight="2" className="text-white flex items-center">
                            <span className="flex mr-2">{t('updateIsAvailable')}</span>

                            <Icon32DonutCircleFillYellow width={18} height={18}/>
                        </Text>

                        <Text weight="3" className="text-white fs-12 mt-4" onClick={() => setHidden(true)}>
                            {props.difference !== 'major' ? t('downloadLater') : t('thisUpdateShouldNotBeMissed')}
                        </Text>
                    </div>

                    <Link toOuter={COMMON?.organization.native[OS_PLATFORM === 'ios' ? 'appStore' : 'googlePlay']}>
                        <Button size="s" mode="overlay_primary" className="z-10">
                            <span className="text-accent">
                                {t('download')}
                            </span>
                        </Button>
                    </Link>

                    <Icon28SmartphoneStarsOutline width={240} height={240} fill="var(--white)" className={[
                        'absolute w-[240px] h-[240px] opacity-25 right-[-20px] top-[-35px]',
                    ].join(' ')}/>
                </div>
            </Responsive.Mobile>
        </If>
    );
});


export { AvailableNewAppVersionContent };
