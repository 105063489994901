/**
 * EditUserFormSchoolHeader
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';
import { Graphql, If, Link } from '@/cutils';
import { useI18n, useRefreshDocs } from '@/hooks/core';

import {
    ProductAccessFindManyDocument,
    ProductAccessFindOneDocument,
    SchoolUserFindOneDocument,
    SchoolUserFindOneQuery,
    SchoolUserSessionFindManyDocument,
} from '@/codegen/graphql';

import { Icon12Switch, Icon20MessageOutline } from '@vkontakte/icons';
import { Button, ButtonGroup, Caption, Separator, Title } from '@exode.ru/vkui';

import { User } from '@/components/Atoms/User';
import { UserAvatar } from '@/components/Atoms/UserAvatar';
import { LastOnlineText } from '@/components/Profile/Page';
import { TagInlineText } from '@/components/Atoms/TagInlineText';

import { UserActionsDropdown } from './dropdowns/UserActionsDropdown';


interface Props {
    userId: number;
}


const EditUserFormSchoolHeader = (props: Props) => {

    const { userId } = props;

    const { refetchGqlDocs } = useRefreshDocs();

    const { t } = useI18n('pages.Manage.School.Users.views.EditUserFormView');

    return (
        <>
            <div className="p-7 pb-6 items-start">
                <SchoolUserFindOneQuery variables={{ userId: +userId }} children={(result) => (
                    <>
                        <Graphql.Loading result={result}>
                            <Page.Spinner/>
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ schoolUserFindOne: user }) => (
                                <div className="flex flex-1 gap-6">
                                    <UserAvatar size={120}
                                                userId={user.id}
                                                name={user.profile.firstName || ''}
                                                src={user.profile.avatar.medium || ''}/>

                                    <div className="flex flex-1 flex-col items-start gap-1 max-w-full overflow-hidden">
                                        <Caption className="flex gap-2 text-gray-500">
                                            <span>ID: {user.id}</span>

                                            <If is={!user.banned && !!(user.phone || user.email)}>
                                                <span>•</span>
                                                <span>{user.phone || user.email}</span>
                                            </If>

                                            <If is={user.banned}>
                                                <span>•</span>

                                                <span className="text-destructive">
                                                    {t('userIsBanned')}
                                                </span>
                                            </If>

                                            <span>•</span>

                                            <TagInlineText size="m"
                                                           gradient="blue"
                                                           content={t('refresh')}
                                                           className="cursor-pointer"
                                                           icon={<Icon12Switch width={10} height={10} fill="white"/>}
                                                           onClick={() => refetchGqlDocs('EditUserFormSchoolHeader', [
                                                               SchoolUserFindOneDocument,
                                                               ProductAccessFindOneDocument,
                                                               ProductAccessFindManyDocument,
                                                               SchoolUserSessionFindManyDocument,
                                                           ])}/>
                                        </Caption>

                                        <Title level="1" weight="3" className="line-clamp-1">
                                            <User.Name profile={user.profile}/>
                                        </Title>

                                        <Caption className="text-secondary mt-1">
                                            <LastOnlineText userId={user.id}
                                                            sex={user.profile?.sex}
                                                            lastOnlineAt={user.lastOnlineAt}/>
                                        </Caption>

                                        <ButtonGroup stretched mode="horizontal" className="mt-3">
                                            <Link pushPage={{
                                                id: '/chat',
                                                params: { personalUserId: `${userId}` },
                                            }}>
                                                <Button size="m"
                                                        stretched
                                                        data-test="user.chat-button"
                                                        before={<Icon20MessageOutline/>}>
                                                    {t('writeMessage')}
                                                </Button>
                                            </Link>

                                            <UserActionsDropdown user={user}/>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            )}
                        </Graphql.Success>

                        <Graphql.Error result={result}/>
                    </>
                )}/>
            </div>

            <Separator wide/>
        </>
    );
};


export { EditUserFormSchoolHeader };
