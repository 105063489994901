/**
 * BackgroundAndTextPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { FormItem } from '@exode.ru/vkui';

import { ColorPickerProps } from '@/components/Atoms/ColorPicker';

import { ColorInputPart } from './ColorInputPart';


interface Props extends Pick<ColorPickerProps, 'color' | 'onChange' | 'inputProps'> {

}


const BackgroundAndTextPart = (props: Props) => {

    const { color, onChange, inputProps } = props;

    const { t } = useI18n('components.Atoms.ColorPicker.parts');

    return (
        <div className="flex justify-between gap-2">
            <FormItem top={t('background')} className="p-0">
                <ColorInputPart value={color} onChange={onChange} {...inputProps}/>
            </FormItem>

            <FormItem top={t('text')} className="p-0">
                <ColorInputPart value={color} onChange={onChange} {...inputProps}/>
            </FormItem>
        </div>
    );
};


export { BackgroundAndTextPart };
