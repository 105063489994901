/**
 * TextAccordion
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactNode, useState } from 'react';

import { If } from '@/cutils';

import { Text } from '@exode.ru/vkui';
import { Icon24ChevronDown, Icon24ChevronUp } from '@vkontakte/icons';


interface Props {
    text: string;
    children?: ReactNode;
    className?: string;
    initialExpanded?: boolean;
}


const TextAccordion = (props: Props) => {

    const {
        text,
        children,
        className,
        initialExpanded = false,
    } = props;

    const [ isExpanded, setIsExpanded ] = useState(initialExpanded);

    return (
        <>
            <Text onClick={() => setIsExpanded(!isExpanded)} className={[
                className,
                'flex items-center gap-2 text-accent cursor-pointer fs-14 mb-2 select-none',
            ].join(' ')}>
                {!isExpanded ? <Icon24ChevronDown/> : <Icon24ChevronUp/>}

                <span>{text}</span>
            </Text>

            <If is={isExpanded}>
                <>{children}</>
            </If>
        </>
    );
};


export { TextAccordion };
