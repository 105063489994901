/**
 * BlockHeaderPart
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactNode } from 'react';

import { IconButton } from '@mui/material';

import { useI18n } from '@/hooks/core';
import { If, SvgComponent } from '@/cutils';

import { ContentElementType } from '@/codegen/graphql';
import { contentElementDescription, contentElementType, contentElementTypeIcon } from '@/types/content';

import { Header } from '@exode.ru/vkui';
import { TextTooltip } from '@exode.ru/vkui/unstable';
import { Icon24ChevronDown, Icon24ChevronUp, Icon24DeleteOutline, Icon24Dismiss } from '@vkontakte/icons';

import { ConfirmButton } from '@/components/Atoms/ConfirmButton';

import { ContentElementEditProps } from '../../interfaces';


interface Props {
    type: ContentElementType;
    list: ContentElementEditProps['list'];
    loading: ContentElementEditProps['loading'];
    onDelete: ContentElementEditProps['onDelete'];
    className?: string;
    asideBefore?: ReactNode;
}


const BlockHeaderPart = (props: Props) => {

    const {
        type,
        list,
        loading,
        onDelete,
        className,
        asideBefore,
    } = props;

    const { t } = useI18n('components.ContentElement.part.edit');

    const contentElementTypes = contentElementType();
    const contentElementDescriptions = contentElementDescription();

    return (
        <Header mode="primary" className={[
            className,
            'px-0 mb-0 flex-align-center',
            asideBefore ? 'items-center' : '',
        ].join(' ')} aside={onDelete && (
            <div className={[
                asideBefore ? 'flex items-center gap-3' : '',
                !className?.includes('no-margin') ? 'mt-[6px]' : '',
            ].join(' ')}>
                <>{asideBefore}</>

                <ConfirmButton callback={onDelete}
                               text={t('deleteBlock?')}
                               className="rounded-full"
                               loading={loading?.delete}
                               initialIcon={<Icon24Dismiss fill="var(--accent)"/>}
                               confirmIcon={<Icon24DeleteOutline fill="var(--destructive)"/>}/>
            </div>
        )}>
            {!!list && (
                <div className="flex items-center justify-center gap-2">
                    {contentElementTypeIcon[type] && (
                        <TextTooltip placement="left" text={(
                            <div className="flex flex-col max-w-[180px]">
                                <span className="font-medium">
                                    {contentElementTypes[type]}
                                </span>

                                <span className="pt-1 fs-12">
                                    {contentElementDescriptions[type]}
                                </span>
                            </div>
                        )}>
                            <div className="flex items-center justify-center mr-1">
                                <SvgComponent element={contentElementTypeIcon[type]}
                                              svgProps={{ width: 28, height: 28, className: 'opacity-75' }}/>
                            </div>
                        </TextTooltip>
                    )}

                    <div className="flex flex-col gap-0.5 mr-4">
                        <span>
                            {t('blockNo', { no: list.index + 1 })}
                        </span>

                        <span className="fs-11 text-subhead" style={{ lineHeight: 1 }}>
                            {contentElementTypes[type]}
                        </span>
                    </div>

                    <If is={list.index > 0}>
                        <IconButton size="small" className="ml-1" onClick={() => list?.onOrderChange?.('up')}>
                            <Icon24ChevronUp fill="var(--accent)"/>
                        </IconButton>
                    </If>

                    <If is={(list.index > 0 || !!list.totalCount) && list?.index < (list.totalCount || 0) - 1}>
                        <IconButton size="small" onClick={() => list?.onOrderChange?.('down')}>
                            <Icon24ChevronDown fill="var(--accent)"/>
                        </IconButton>
                    </If>
                </div>
            )}
        </Header>
    );
};


export { BlockHeaderPart };
