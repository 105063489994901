/**
 * InputEmojiPicker
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { EmojiClickData } from 'emoji-picker-react';

import { useEmojiPicker } from '@/hooks/core';

import { Dropdown } from '@exode.ru/vkui/dist/unstable';
import { WriteBarIcon } from '@exode.ru/vkui';
import { Icon24SmileOutline } from '@vkontakte/icons';

import { EmojiPicker } from '@/components/Atoms/EmojiPicker';


interface Props {
    pickerKey?: string;
    onEmojiClick: (emoji: EmojiClickData, event: MouseEvent) => void;
}


const InputEmojiPicker = (props: Props) => {

    const { pickerKey, onEmojiClick } = props;

    const {
        showEmojiPicker,
        setShowEmojiPicker,
    } = useEmojiPicker({});

    return (
        <Dropdown action="hover"
                  placement="right"
                  shown={showEmojiPicker}
                  deta-test="input-emoji-picker"
                  onShownChange={setShowEmojiPicker}
                  content={(
                      <div className="absolute right-0 bottom-0 pb-4 z-20">
                          <EmojiPicker className="d:mr-4 d:mb-2" key={pickerKey} onEmojiClick={onEmojiClick}/>
                      </div>
                  )}>
            <WriteBarIcon className="absolute right-0 bottom-0 z-[1]">
                <Icon24SmileOutline className="bg-input rounded-full"/>
            </WriteBarIcon>
        </Dropdown>
    );
};


export { InputEmojiPicker };
