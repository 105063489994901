/**
 * ListModePanelView
 *
 * @author: exode <hello@exode.ru>
 */

import React, { MutableRefObject, useCallback } from 'react';

import { ChatDialogsPageStore } from '../store';
import { observer, useStore } from '@/pages/Core';

import { useI18n } from '@/hooks/core';
import { chatFolderName, ChatListItem } from '@/types/chat';
import { Graphql, If, ScrollSaver, ScrollTo } from '@/cutils';
import { ChatFindManyQuery, ChatType } from '@/codegen/graphql';

import { Icon20MessageAddOutline } from '@vkontakte/icons';

import { WindowsCustomScrollbar } from '@/components/Atoms/Styled';
import { UserListSkeleton } from '@/components/Atoms/Skeleton/UserList';
import { PlaceholderWrapper } from '@/components/Atoms/PlaceholderWrapper';

import { DialogsListView, DialogsListViewProps } from './DialogsListView';
import { ChatFriendsHasNotPersonalChatView } from './ChatFriendsHasNotPersonalChatView';

import { useChatListFilter } from '../filter';


export interface ListModePanelViewProps {
    parentRef: MutableRefObject<HTMLDivElement | null>;
    onChatCellClick: DialogsListViewProps['onChatCellClick'];
    chatFolders: Readonly<(ChatType | 'All')[]>;
    activeChatId?: number;
    withTabButton?: boolean;
    filterFn?: (chats?: ChatListItem[] | null) => ChatListItem[];
}


const ListModePanelView = observer((props: ListModePanelViewProps) => {

    const {
        parentRef,
        chatFolders,
        activeChatId,
        withTabButton,
        onChatCellClick,
        filterFn = (chats) => chats?.filter((chat) => !chat.archived),
    } = props;

    const { t } = useI18n('pages.Chat.Dialog');

    const chatFolderNames = chatFolderName();

    const { list, sort } = useStore(ChatDialogsPageStore);

    const filterChats = useCallback(filterFn, []);
    const { urlFilter, dtoFilter } = useChatListFilter();

    return (
        <>
            {chatFolders.map((folder) => (
                <ChatFindManyQuery key={folder} skip={folder !== urlFilter.folder} children={(result) => (
                    <>
                        <Graphql.Loading result={result}>
                            <If is={!!result.previousData}>
                                <ScrollTo element={parentRef.current}/>
                            </If>

                            <UserListSkeleton baseAvatarSize={50}
                                              listClassName="gap-[1px]"
                                              itemClassName="px-2.5 my-[6px]"/>
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ chatFindMany: { items, cursor } }) => {
                                const chats = filterChats(items);

                                return (
                                    <>
                                        <ScrollSaver element={parentRef.current}
                                                     scrollDeps={[ `${urlFilter.folder}` ]}
                                                     scrollKey={`ListModePanelView:${urlFilter.folder}`}/>

                                        <PlaceholderWrapper count={chats?.length} subCounters={(setCounts) => ([
                                            <If is={cursor.hasNext && [ 'All', ChatType.Personal ].includes(urlFilter.folder)}>
                                                <ChatFriendsHasNotPersonalChatView onChatCellClick={onChatCellClick}
                                                                                   onSuccess={({ count }) => {
                                                                                       setCounts('friends', count);
                                                                                   }}/>
                                            </If>,
                                        ])} placeholder={{
                                            iconSize: 72,
                                            innerPadding: '0px',
                                            header: folder !== 'All'
                                                ? chatFolderNames[folder]
                                                : t('chatsWereNotCreated'),
                                            className: 'm:pt-0 my-auto h-[calc(100%_-_52px)]',
                                            icon: (
                                                <Icon20MessageAddOutline className="pt-0 text-accent opacity-[0.85]"/>
                                            ),
                                            content: (
                                                <span className="m:w-full w-9/10 flex mx-auto">
                                                    {t('yourChatsWillAppearHere')}
                                                </span>
                                            ),
                                        }}>
                                            <WindowsCustomScrollbar className={[
                                                withTabButton ? 'overflow-y-auto relative' : '',
                                                withTabButton ? 'h-[calc(100%_-_56px)] m:pb-[56px]' : '',
                                                withTabButton && (
                                                    chats?.length
                                                        ? 'd:!h-[calc(100%_-_52px)]'
                                                        : 'd:!h-[calc(100%_-_54px)]'
                                                ),
                                            ].join(' ')}>
                                                <DialogsListView cursor={cursor}
                                                                 chats={chats || []}
                                                                 parentRef={parentRef}
                                                                 activeChatId={activeChatId}
                                                                 fetchMore={result.fetchMore}
                                                                 onChatCellClick={onChatCellClick}
                                                                 previousVariables={result.variables}/>
                                            </WindowsCustomScrollbar>
                                        </PlaceholderWrapper>
                                    </>
                                );
                            }}
                        </Graphql.Success>

                        <Graphql.Error result={result}/>
                    </>
                )} variables={{
                    filter: dtoFilter,
                    list: { ...list.chats },
                    sort: { ...sort.chats },
                }}/>
            ))}
        </>
    );
});


export { ListModePanelView };
