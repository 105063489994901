/**
 * CourseFiltersModal
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { useI18n } from '@/hooks/core';

import { EducationSubject } from '@/codegen/graphql';

import { Link, Modal } from '@/cutils';
import { educationSubjects } from '@/types/subject';

import { Button, CustomSelect, FormItem, Slider, Text, Title } from '@exode.ru/vkui';


interface Props {
    id: string;
}


const CourseFiltersModal = (props: Props) => {

    const { t } = useI18n('modals.Course.Filters');

    return (
        <Modal.Card id={props.id}
                    header={<Title className="modal-title" level="2" weight="3">{t('createFilter')}</Title>}>
            <Modal.Body isScrollable>
                <form>
                    <CustomSelect className="mt-5"
                                  placeholder={t('createFilterSubjectPlaceholder')}
                                  options={_.map(_.entries(educationSubjects), ([ subject ]) => ({
                                      value: subject,
                                      label: educationSubjects[subject as EducationSubject],
                                  }))}/>

                    <FormItem className="p-0 mt-6" top={t('createFilterCost')}>
                        <Slider step={100} min={0} max={500}/>
                        <div className="flex justify-between">
                            <Text className="text-accent" weight="regular">
                                100
                            </Text>
                            <Text className="text-secondary" weight="regular">
                                3000
                            </Text>
                        </div>
                    </FormItem>

                    <Link replaceModal>
                        <Button stretched size="l" mode="primary" className="mt-7">
                            {t('applyFilter')}
                        </Button>
                    </Link>
                </form>
            </Modal.Body>
        </Modal.Card>
    );
};


export { CourseFiltersModal };
