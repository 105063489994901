/**
 * CommonHeaderPart
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';
import { If, StickyObserver } from '@/cutils';
import { taskAnswerType, TaskViewMode } from '@/types/task';

import { Header } from '@exode.ru/vkui';
import { IconButton } from '@mui/material';
import { Icon24ChevronDown, Icon24ChevronUp, Icon24PenOutline, Icon24ViewOutline } from '@vkontakte/icons';

import { TaskBuilderProps, useTaskBuilderContext } from '@/components/Task/Builder';

import { ActionsPart } from '../edit/parts/ActionsPart';


interface Props {
    title?: string;
    task: TaskBuilderProps['task'];
    stickyDisabled?: boolean;
}


const CommonHeaderPart = (props: Props) => {

    const { task, title, stickyDisabled } = props;

    const { t } = useI18n('components.Task.Builder.edit');

    const taskAnswerTypes = taskAnswerType();

    const {
        mode,
        edit: {
            setMode,
            list: {
                index,
                expanded,
                onExpandToggle,
            } = {},
        },
    } = useTaskBuilderContext();

    const stickyHeaderClassName = 'sticky top-[-1px] pt-header -mt-header z-10 -mx-2';

    return (
        <StickyObserver sides={[ 'top' ]}
                        key={[ index, expanded ].join('.')}
                        disabled={stickyDisabled || !expanded}
                        stackedProps={{
                            'data-stacked-top': 'false',
                            'data-glass-morphism': 'default',
                            className: stickyHeaderClassName,
                        }}>
            <Page.Row separator="hide" className={[
                stickyHeaderClassName,
                'inner-transparent card-clear-after',
            ].join(' ')}>
                <Page.Section className={[ 'cursor-pointer' ].join(' ')}>
                    <div className="flex justify-between py-2" onClick={() => {
                        onExpandToggle?.(task.id);

                        setMode?.(TaskViewMode.Edit);
                    }}>
                        <div className="flex items-center gap-2 max-w-[calc(100%_-_140px)] select-none">
                            <div className="flex items-center gap-4">
                                <If is={_.isFinite(index)}>
                                    <div className={[
                                        'bg-button-muted vk-rounded p-2.5',
                                        'fs-15 font-medium whitespace-nowrap',
                                    ].join(' ')}>
                                        {`№ ${(index || 0) + 1}`}
                                    </div>
                                </If>

                                <Header mode="primary" className="p-0 no-margin-top mt-1.5" subtitle={(
                                    <div className="flex gap-1 items-center">
                                        <span>{taskAnswerTypes[task.answerType]}</span>
                                    </div>
                                )} children={(
                                    <div className="line-clamp-1">
                                        {title || task.title || t('noName')}
                                    </div>
                                )}/>
                            </div>
                        </div>

                        <div className="flex gap-2 items-center">
                            <If is={!!expanded}>
                                <IconButton onClick={(e) => {
                                    e.stopPropagation();

                                    setMode?.(
                                        mode === TaskViewMode.Edit
                                            ? TaskViewMode.Show
                                            : TaskViewMode.Edit,
                                    );
                                }} data-test={(
                                    `practice.${mode === TaskViewMode.Edit
                                        ? 'preview'
                                        : 'edit'}-task`
                                )}>
                                    {mode === TaskViewMode.Edit
                                        ? <Icon24ViewOutline fill="var(--accent)"/>
                                        : <Icon24PenOutline fill="var(--accent)"/>
                                    }
                                </IconButton>
                            </If>

                            <ActionsPart className={[ !expanded ? 'hidden group-hover:flex' : '' ].join(' ')}/>

                            <IconButton data-test="task.expand-button"
                                        className="bg-button-muted rounded-full"
                                        onClick={(e) => {
                                            e.stopPropagation();

                                            onExpandToggle?.(task.id);

                                            setMode?.(TaskViewMode.Edit);
                                        }}>
                                {(!!expanded
                                        ? <Icon24ChevronUp fill="var(--accent)"/>
                                        : <Icon24ChevronDown fill="var(--accent)"/>
                                )}
                            </IconButton>
                        </div>
                    </div>
                </Page.Section>
            </Page.Row>
        </StickyObserver>
    );
};


export { CommonHeaderPart };
