/**
 * SubNavigationIconPart
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { SvgComponent } from '@/cutils';


interface Props {
    icon: ReactElement;
    bgColor?: string;
}


const SubNavigationIconPart = (props: Props) => {

    const { icon, bgColor } = props;

    return (
        <SvgComponent element={icon} svgProps={{
            with: 20,
            height: 20,
            fill: 'var(--white)',
            className: [
                bgColor,
                '!p-0.5 !w-[20px] !h-[20px] rounded-full mr-2',
            ].join(' '),
        }}/>
    );
};


export { SubNavigationIconPart };
