/**
 * UserSettingsPersonalInfoView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useRef, useState } from 'react';

import { Form, Formik, FormikProps } from 'formik';

import { observer } from '../store';

import { CURRENT_PLATFORM } from '@/root/src/env';

import { PageSpace } from '@/shared/types';
import { UserGetMyInfoResult } from '@/types/user';
import { ProfileRole, ProfileUpdateMutationResult, UserSexType } from '@/codegen/graphql';

import { Page } from '@/pages/Core';

import { GqlResult } from '@/types/graphql';
import { UpdateProfileInputSchema } from '@/libs/class-validator';

import { useI18n } from '@/hooks/core';
import { useProfileUpdate } from '@/hooks/apollo';

import { ObjectUtil, Time } from '@/utils';
import { Field, If, Notify, Platform } from '@/cutils';

import {
    Icon20CancelCircleFillRed,
    Icon20CheckCircleFillGreen,
    Icon20Flash,
    Icon24EducationOutline,
    Icon24VideocamAddOutline,
} from '@vkontakte/icons';

import {
    Button,
    CustomSelectOption,
    FormItem,
    FormLayoutGroup,
    Header,
    Input,
    SegmentedControl,
    Select,
    Spinner,
} from '@exode.ru/vkui';

import { PhoneInput } from '@/components/Atoms/PhoneInput';

import { CheckUserDomainItem } from '../items/CheckUserDomainItem';


interface Props {
    placement: 'page' | 'modal';
    userInfo: UserGetMyInfoResult;
    onUpdate?: (profile: GqlResult<ProfileUpdateMutationResult>['profileUpdate']) => void;
    isSkeleton?: boolean;
}


const UserSettingsPersonalInfoView = observer((props: Props) => {

    const {
        userInfo,
        onUpdate,
        placement,
        isSkeleton,
    } = props;

    const { t } = useI18n('pages.User.Settings');

    const formikRef = useRef<FormikProps<any>>(null);

    const {
        dayOptions,
        yearOptions,
        monthOptions,
    } = Time.getCalendarOptions();

    const commonInputProps = {
        disabled: isSkeleton,
        after: isSkeleton && <Spinner/>,
    };

    const {
        updateProfile,
        updateProfileLoading,
        setUserDomain,
        setUserDomainLoading,
        getInitialValues,
    } = useProfileUpdate();

    const [ domainState, setDomainState ] = useState({
        success: true,
        loading: false,
        cause: '',
    });

    const handleSubmit = async (
        values: ReturnType<typeof getInitialValues>,
    ) => {
        const fullBdate = Time.toFullDate(
            values.birthYear,
            values.birthMonth,
            values.birthDay,
        );

        if (formikRef.current?.initialValues.domain !== values.domain) {
            await setUserDomain({
                variables: { domain: values.domain },
                onError(error) {
                    formikRef?.current?.setErrors({ domain: error.message });
                },
            });
        }

        await updateProfile({
                ..._.pick(values, [
                    'sex',
                    'role',
                    'firstName',
                    'lastName',
                    'contact',
                ]),
                bdate: (values.birthYear && values.birthMonth && values.birthDay)
                    ? fullBdate
                    : undefined,
            },
            async (profile) => {
                Notify.toast.success(
                    t('informationSavedSuccessfully'),
                    { id: 'personal-info:saved' },
                );

                onUpdate?.(profile);
            }, (error) => {
                console.error(error);

                formikRef?.current?.setErrors({
                    birthDay: t('indicateDateOfBirth'),
                    birthMonth: ' ',
                    birthYear: ' ',
                });
            });
    };

    return (
        <Page.Row isFirst>
            <If is={placement === 'page'}>
                <Header mode="secondary">
                    {t('personalInformation')}
                </Header>
            </If>

            <Formik validateOnBlur
                    validateOnChange
                    enableReinitialize
                    innerRef={formikRef}
                    onSubmit={handleSubmit}
                    validateOnMount={!isSkeleton}
                    initialValues={getInitialValues(userInfo)}
                    validationSchema={UpdateProfileInputSchema}>
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      errors,
                      handleBlur,
                      touched,
                      isValid,
                      setFieldValue,
                      setFieldError,
                      initialValues,
                  }) => (
                    <Form onSubmit={handleSubmit}
                          className={[ placement === 'modal' ? 'm:px-2 px-3' : 'px-4' ].join(' ')}>
                        <FormLayoutGroup mode="horizontal" className="pb-0 px-0">
                            <FormItem top={t('firstName*')}
                                      status={Field.status(errors, touched, 'firstName')}
                                      bottom={Field.message(errors, touched, 'firstName')}>
                                <Input type="text"
                                       name="firstName"
                                       placeholder={t('firstName')}
                                       onBlur={handleBlur}
                                       value={values.firstName}
                                       onInput={Field.transform.ignoreSpaceOnChange(handleChange)}
                                       {...commonInputProps}/>
                            </FormItem>

                            <FormItem top={t('lastName*')}
                                      status={Field.status(errors, touched, 'lastName')}
                                      bottom={Field.message(errors, touched, 'lastName')}>
                                <Input type="text"
                                       name="lastName"
                                       onBlur={handleBlur}
                                       placeholder={t('lastName')}
                                       value={values.lastName || ''}
                                       onInput={Field.transform.ignoreSpaceOnChange(handleChange)}
                                       {...commonInputProps}/>
                            </FormItem>
                        </FormLayoutGroup>

                        <Platform.BizWelcome>
                            <If is={!userInfo.phone}>
                                <FormItem className="pt-0 px-0"
                                          top={t('contactPhoneNumber*')}
                                          status={Field.status(errors, touched, 'contact', 'phone')}
                                          bottom={Field.message(errors, touched, 'contact', '', 'phone')}>
                                    <PhoneInput name="contact.phone"
                                                style={{ height: '44px' }}
                                                className="w-full min-h-[44px]"
                                                value={values.contact?.phone || ''}
                                                onBlur={(e) => setTimeout(() => handleBlur(e), 300)}
                                                onChange={(phone) => setFieldValue('contact.phone', phone)}/>
                                </FormItem>
                            </If>
                        </Platform.BizWelcome>

                        <If is={CURRENT_PLATFORM === PageSpace.MarketplacePlatform || placement !== 'modal'}>
                            <FormItem top={t('userDomain')}
                                      status={errors.domain ? 'error' : 'default'}
                                      bottom={errors.domain ? errors.domain : ' '}
                                      className={[
                                          'pt-0.5 pb-0 px-0',
                                          errors.domain ? 'shake-animation' : '',
                                      ].join(' ')}>
                                <CheckUserDomainItem domain={values.domain}
                                                     isSkeleton={isSkeleton}
                                                     handleChange={handleChange}
                                                     setFieldError={setFieldError}
                                                     setDomainState={setDomainState}
                                                     domainAfter={(
                                                         (isSkeleton || domainState.loading)
                                                             ? <Spinner size="small"/>
                                                             : domainState.success
                                                                 ? <Icon20CheckCircleFillGreen/>
                                                                 : <Icon20CancelCircleFillRed/>
                                                     )}/>
                                <Input hidden className="hidden"/>
                            </FormItem>
                        </If>

                        <Platform.Market>
                            <FormItem top={t('myRole')}
                                      className="py-0 px-0"
                                      status={Field.status(errors, touched, 'role')}
                                      bottom={Field.message(errors, touched, 'role')}>
                                <SegmentedControl size="l"
                                                  value={values.role}
                                                  className="thin-border"
                                                  data-test="settings-role"
                                                  onChange={(value) => {
                                                      setFieldValue('role', value);
                                                  }}
                                                  options={[
                                                      {
                                                          value: ProfileRole.Student,
                                                          label: (
                                                              <>
                                                                  <Icon24EducationOutline fill={(
                                                                      values.role === ProfileRole.Student
                                                                          ? 'var(--button_primary_foreground)'
                                                                          : 'var(--icon_primary)'
                                                                  )}/>

                                                                  <span className="pl-1">
                                                                      {t('study')}
                                                                  </span>
                                                              </>
                                                          ),
                                                      },
                                                      {
                                                          value: ProfileRole.Tutor,
                                                          label: (
                                                              <>
                                                                  <Icon24VideocamAddOutline fill={(
                                                                      values.role === ProfileRole.Tutor
                                                                          ? 'var(--button_primary_foreground)'
                                                                          : 'var(--icon_primary)'
                                                                  )}/>

                                                                  <span className="pl-1">
                                                                      {t('educate')}
                                                                  </span>
                                                              </>
                                                          ),
                                                      },
                                                  ]}/>
                            </FormItem>
                        </Platform.Market>

                        <FormItem top={t('sex')}
                                  className="pt-0.5 pb-0 px-0"
                                  status={Field.status(errors, touched, 'sex')}
                                  bottom={Field.message(errors, touched, 'sex')}>
                            <Select name="sex"
                                    value={values.sex}
                                    onChange={handleChange}
                                    data-test="settings.sex"
                                    onBlurCapture={handleBlur}
                                    placeholder={t('sexNotSelected')}
                                    options={[
                                        {
                                            label: t('female'),
                                            value: UserSexType.Women,
                                        },
                                        {
                                            label: t('male'),
                                            value: UserSexType.Men,
                                        },
                                    ]}
                                    renderOption={({ option, ...restProps }) => (
                                        <CustomSelectOption {...restProps} />
                                    )} {...commonInputProps}/>
                        </FormItem>

                        <FormItem top={t('dateOfBirth')} className="pt-0.5 pb-0 px-0">
                            <div className={[
                                'grid grid-cols-3 gap-6 m:gap-4',
                                'grid-cols-[minmax(0,_0.7fr)_minmax(0,_1fr)_minmax(0,_0.8fr)]',
                            ].join(' ')}>
                                <FormItem className="p-0"
                                          status={Field.status(errors, touched, 'birthDay')}
                                          bottom={Field.message(errors, touched, 'birthDay')}>
                                    <Select name="birthDay"
                                            autoFocus={false}
                                            placeholder={t('day')}
                                            value={values.birthDay}
                                            onChange={handleChange}
                                            data-test="settings.birthDate"
                                            onBlurCapture={handleBlur('birthDay')}
                                            options={dayOptions(+values.birthMonth!, +values.birthYear!)}/>
                                </FormItem>

                                <FormItem className="p-0"
                                          status={Field.status(errors, touched, 'birthMonth')}
                                          bottom={Field.message(errors, touched, 'birthMonth')}>
                                    <Select name="birthMonth"
                                            autoFocus={false}
                                            options={monthOptions}
                                            onChange={handleChange}
                                            placeholder={t('month')}
                                            value={values.birthMonth}
                                            data-test="settings.birthMonth"
                                            onBlurCapture={handleBlur('birthMonth')}/>
                                </FormItem>

                                <FormItem className="p-0"
                                          status={Field.status(errors, touched, 'birthYear')}
                                          bottom={Field.message(errors, touched, 'birthYear')}>
                                    <Select name="birthYear"
                                            autoFocus={false}
                                            options={yearOptions}
                                            placeholder={t('year')}
                                            onChange={handleChange}
                                            value={values.birthYear}
                                            data-test="settings.birthYear"
                                            onBlurCapture={handleBlur('birthYear')}/>
                                </FormItem>
                            </div>
                        </FormItem>

                        <FormItem className={[
                            'flex m:justify-center px-0 m:pb-0',
                            placement === 'modal' ? 'd:!sticky d:pb-2' : 'd:mb-1',
                        ].join(' ')}>
                            <Button size="l"
                                    stretched
                                    type="submit"
                                    data-test="settings.button.save"
                                    before={(placement === 'modal'
                                            ? <Icon20Flash/>
                                            : undefined
                                    )}
                                    loading={(
                                        isSkeleton
                                        || updateProfileLoading
                                        || domainState.loading
                                        || setUserDomainLoading
                                    )}
                                    mode={(
                                        placement === 'modal' || !ObjectUtil.isEqual(initialValues, values)
                                            ? 'primary'
                                            : 'secondary'
                                    )}
                                    disabled={(
                                        updateProfileLoading
                                        || !isValid
                                        || isSkeleton
                                        || !!domainState.cause
                                        || domainState.loading
                                        || setUserDomainLoading
                                        || placement !== 'modal' && ObjectUtil.isEqual(initialValues, values)
                                    )}>
                                {placement === 'modal' ? t('done') : t('save')}
                            </Button>
                        </FormItem>
                    </Form>
                )}
            </Formik>
        </Page.Row>
    );
});


export { UserSettingsPersonalInfoView };
