/**
 * RequisitePayoutInput
 *
 * @author: exode <hello@exode.ru>
 */

import { IsNotEmpty, IsString, Matches } from 'class-validator';

import { InputType } from '@nestjs/graphql';

import { regex } from '@/utils/Regex';

import { isNotEmpty } from '../constants';


@InputType()
export class RequisitePayoutInput {

    @IsString()
    @IsNotEmpty({ message: isNotEmpty('БИК банка') })
    readonly bic: string;

    @IsString()
    @IsNotEmpty({ message: isNotEmpty('Корреспондентский счет') })
    readonly correspondentAccount: string;

    @IsString()
    @IsNotEmpty({ message: isNotEmpty('Расчетный счет') })
    @Matches(regex.bankAccountNumber, { message: () => 'Расчетный счет невалидный' })
    readonly bankAccountNumber: string;

}
