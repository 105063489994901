/**
 * IconBubbleTail component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { SVGProps } from 'react';


const IconBubbleTail = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={19} fill="none" {...props}>
            <path fill="currentColor"
                  d="M15.5 18.458c-8.8 0-14-4.3333-15.5-6.5C3.16667 5.62471 10-5.74196 10 3.45804 10 12.658 13.5 16.9581 15.5 18.458z"/>
        </svg>
    );
};


export { IconBubbleTail };
