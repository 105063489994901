/**
 * Use open or focus tab
 *
 * @author: exode <hello@exode.ru>
 */

import { useCallback } from 'react';

import { useConfigContext, useConfigState } from '@/hooks/core';


export interface UseOpenOrFocusTabProps {
    id: string;
    url: string;
    type: 'course' | 'lesson' | 'practice' | 'storage' | 'school';
}


const useOpenOrFocusTab = () => {

    const { setStateByPath } = useConfigContext();

    const { user: { openedTabs } } = useConfigState();

    const openOrFocusTab = useCallback(({ url, type, id }: UseOpenOrFocusTabProps) => {
        const isSameUrl = new URL(url).href === openedTabs[type][id]?.location.href;

        const newTab = isSameUrl && openedTabs[type][id] && !openedTabs[type][id]?.closed
            ? openedTabs[type][id]
            : window.open(url);

        setStateByPath(`user.openedTabs.${type}.${id}`, newTab);

        openedTabs[type][id]?.focus();
    }, [ openedTabs ]);

    return [ openOrFocusTab ];
};


export { useOpenOrFocusTab };
