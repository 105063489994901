/**
 * Language types
 *
 * @author: exode <hello@exode.ru>
 */

/**
 * ISO 639-1 language codes
 */
export enum Language {
    Ru = 'Ru',
    Uz = 'Uz',
    Qa = 'Qa',
}

export const languageNames: Record<Language, string> = {
    Ru: 'Русский',
    Uz: 'O\'zbekcha',
    Qa: 'Qaraqalpaq',
};


export type TranslateJson = { [key in Language & 'Default']?: string }
