/**
 * UsersTableView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { DebounceInput } from 'react-debounce-input';

import { ManageSchoolUsersPageStore, observer } from '../store';

import { Page } from '@/pages/Core';
import { useI18n, usePagination } from '@/hooks/core';
import { Graphql, If, Link, Portal } from '@/cutils';

import { DebouncedInput } from '@/types/html';
import { SchoolUserFindManyQuery } from '@/codegen/graphql';

import { Icon20CheckCircleOutline } from '@vkontakte/icons';
import { Caption, Counter, Search, Text } from '@exode.ru/vkui';

import { User } from '@/components/Atoms/User';
import { Table } from '@/components/Atoms/Table';
import { Pagination } from '@/components/Atoms/Pagination';
import { TagInlineText } from '@/components/Atoms/TagInlineText';
import { UserListSkeleton } from '@/components/Atoms/Skeleton/UserList';

import { useSchoolManageUsersFilter } from '../filter';


const UsersTableView = observer(() => {

    const { t } = useI18n('pages.Manage.School.Users.items');

    const { page, list, sort, onPageChange } = usePagination({
        store: ManageSchoolUsersPageStore,
        path: '/manage/school/users/:page([0-9]+)',
    });

    const { dtoFilter, urlFilter, setUrlFilter } = useSchoolManageUsersFilter();

    return (
        <>
            <Page.Row>
                <DebounceInput minLength={1}
                               maxLength={15}
                               debounceTimeout={500}
                               value={urlFilter.search}
                               className="mt-2 search-w-full"
                               element={Search as DebouncedInput}
                               data-test="users-table.search-input"
                               onChange={({ target: { value } }) => {
                                   setUrlFilter({ search: value, profile: { search: value } });
                               }}/>

                <SchoolUserFindManyQuery children={(result) => (
                    <>
                        <Graphql.Loading result={result}>
                            <UserListSkeleton withHeader
                                              baseSkeletonWidth="100%"
                                              itemClassName="children-full-w"
                                              listClassName="mt-1.5 gap-1.5"
                                              headerClassName="min-w-[240px]"/>
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ schoolUserFindMany: { items, pages, count } }) => (
                                <>
                                    <Table.Wrapper grid="1.25fr 1fr 0.5fr 1fr 1fr" gap="0.5rem">
                                        <Table.Header>
                                            {[
                                                <div className="flex gap-2 items-center">
                                                    {t('user')}

                                                    <Counter size="m" mode="primary">
                                                        {count}
                                                    </Counter>
                                                </div>,
                                                t('login'),
                                                t('products'),
                                                t('curators'),
                                                t('income'),
                                            ].map((column, index) => (
                                                <Text key={index} className="font-medium">
                                                    {column}
                                                </Text>
                                            ))}
                                        </Table.Header>

                                        {items.map((user) => (
                                            <Link key={user.id} pushModal={{
                                                id: 'manage-user-edit',
                                                params: { userId: `${user.id}` },
                                            }}>
                                                <Table.Cell>
                                                    <User.Cell mode="div"
                                                               userId={user.id}
                                                               avatarSize={42}
                                                               profile={user.profile}
                                                               lastOnlineAt={user.lastOnlineAt}
                                                               afterElements={{
                                                                   userName: (
                                                                       <>
                                                                           <If is={user.banned}>
                                                                               <TagInlineText gradient="red"
                                                                                              content={t('banned')}/>
                                                                           </If>

                                                                           <If is={!user.activated}>
                                                                               <TagInlineText gradient="gray"
                                                                                              content={t('notActivated')}/>
                                                                           </If>
                                                                       </>
                                                                   ),
                                                               }}/>

                                                    <Text className="line-clamp-1 text-primary">
                                                        {user.phone}

                                                        <Caption className="text-secondary line-clamp-1">
                                                            {user.email}
                                                        </Caption>
                                                    </Text>

                                                    <div className="flex gap-2">
                                                        <Counter size="m" mode={(
                                                            user.productAccessesCount
                                                                ? 'primary'
                                                                : 'secondary'
                                                        )}>
                                                            {user.productAccessesCount}
                                                        </Counter>
                                                    </div>

                                                    <Text className="line-clamp-1 text-primary">
                                                        {'—'}
                                                    </Text>

                                                    <div className="flex items-center gap-2">
                                                        <div className="rounded-full bg-positive p-1 mr-0.5">
                                                            <Icon20CheckCircleOutline fill="var(--white)"/>
                                                        </div>

                                                        <div className="flex flex-col">
                                                            <Text weight="regular"
                                                                  className="text-positive line-clamp-1">
                                                                {'—'}
                                                            </Text>

                                                            <Caption className="text-secondary line-clamp-1">
                                                                {'—'}
                                                            </Caption>
                                                        </div>
                                                    </div>
                                                </Table.Cell>
                                            </Link>
                                        ))}
                                    </Table.Wrapper>

                                    <Portal id="page:pagination">
                                        <Pagination sticky
                                                    boundaryCount={1}
                                                    currentPage={page}
                                                    totalPages={pages}
                                                    onChange={(value) => onPageChange(value, result.refetch)}/>
                                    </Portal>
                                </>
                            )}
                        </Graphql.Success>

                        <Graphql.Error result={result}/>
                    </>
                )} variables={{
                    list: { ...list },
                    filter: { ...dtoFilter },
                    sort: { ...sort },
                }}/>
            </Page.Row>

            <Portal renderer id="page:pagination"/>
        </>
    );
});


export { UsersTableView };
