/**
 * ChatForwardMessageModal
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useLayoutEffect, useRef, useState } from 'react';

import { DebounceInput } from 'react-debounce-input';

import { observer, useStore } from '@/pages/Core';

import { useI18n } from '@/hooks/core';
import { useChatSendMessage } from '@/hooks/apollo';

import { Modal, Notify } from '@/cutils';
import { DebouncedInput } from '@/types/html';
import { Router } from '@/services/Utils/Router';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';

import { ChatMessageEntity } from '@/codegen/graphql';

import { Search } from '@exode.ru/vkui';

import { WindowsCustomScrollbar } from '@/components/Atoms/Styled';
import { SearchModePanelView } from '@/pages/Chat/Dialog/views/SearchModePanelView';


interface Props {
    id: string;
}


const ChatForwardMessageModal = observer((props: Props) => {

    const { t } = useI18n('modals.Chat');

    const [ search, setSearch ] = useState('');
    const parentRef = useRef<HTMLDivElement | null>(null);

    const {
        store,
        params: { forwardChatId },
        stater: { selectedMessages },
    } = useStore(ChatDialogsPageStore);

    const forwardingMessages = selectedMessages[+forwardChatId] || [];

    const { forwardChatMessage } = useChatSendMessage();

    const forwardMessage = async (chatId: number) => {
        const toastId = `forward-to:${chatId}`;

        Router.replacePage('/chat', { chatId: `${chatId}` });
        store.setSelectedMessages({ ...selectedMessages, [+forwardChatId]: [] });

        Notify.toast.loading(t('forwarding'), { id: toastId });

        await forwardChatMessage(
            chatId,
            forwardingMessages as ChatMessageEntity[],
            () => {
                Notify.toast.success(t('sent'), {
                    id: toastId,
                    duration: 1100,
                });
            },
            () => {
                Notify.toast.error(t('errorHappened'), {
                    id: toastId,
                    duration: 1100,
                });
            },
        );
    };

    useLayoutEffect(() => {
        if (!forwardingMessages.length) {
            (async () => {
                await Router.afterUpdate();

                Router.replaceModal();
            })();
        }
    }, [ forwardChatId ]);

    return (
        <Modal.Card id={props.id}>
            <Modal.Body fullHeight isScrollable>
                <div className={[
                    `flex justify-between m:rounded-none items-center`,
                    `p-0 h-[36px] pb-4 sticky top-0 d:rounded-tl-[14px]`,
                ].join(' ')}>
                    <DebounceInput minLength={1}
                                   maxLength={15}
                                   value={search}
                                   className="p-0"
                                   debounceTimeout={500}
                                   data-test="chat.forward.search"
                                   element={Search as DebouncedInput}
                                   onChange={({ target: { value } }) => setSearch(value)}/>
                </div>

                <WindowsCustomScrollbar className="overflow-y-auto h-[calc(100%_-_52px)] relative">
                    <div ref={parentRef} className="h-full overflow-x-hidden">
                        <SearchModePanelView parentRef={parentRef} options={{
                            chatSearch: search,
                            profileSkip: true,
                            chetCellIsRounded: true,
                        }} onChatCellClick={(__, ids) => {
                            if (ids.hasOwnProperty('chatId')) {
                                forwardMessage((ids as { chatId: number }).chatId);
                            }
                        }}/>
                    </div>
                </WindowsCustomScrollbar>
            </Modal.Body>
        </Modal.Card>
    );
});


export { ChatForwardMessageModal };
