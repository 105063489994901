/**
 * Condition util
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactNode } from 'react';


interface Props {
    is: boolean;
    children: ReactNode;
}


const If = (props: Props) => {
    return props.is ? <>{props.children}</> : <></>;
};


export { If };
