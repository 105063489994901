/**
 * Icon images
 *
 * @author: exode <hello@exode.ru>
 */

import { Language } from '@/shared/types';

import { IconRussia } from './IconRussia';
import { IconUzbekistan } from './IconUzbekistan';
import { IconKarakalpakstan } from './IconKarakalpakstan';


const IconByLang = {
    [Language.Ru]: IconRussia,
    [Language.Uz]: IconUzbekistan,
    [Language.Qa]: IconKarakalpakstan,
};


export {
    IconRussia,
    IconUzbekistan,
    IconKarakalpakstan,
    IconByLang,
};
