/**
 * EmojiPicker component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { observer, PreferenceStore } from '@/store/preference/preference';

import Picker, { Categories, EmojiStyle, Theme } from 'emoji-picker-react';

import { Container } from './EmojiPicker.styled';
import { PickerConfig } from 'emoji-picker-react/src/config/config';


interface Props extends PickerConfig {
    className?: string;
}


const categories = () => {

    const { t } = useI18n('components.Atoms.EmojiPicker');

    return [
        {
            category: Categories.SUGGESTED,
            name: t('constantlyUsed'),
        },
        {
            category: Categories.SMILEYS_PEOPLE,
            name: t('smiles'),
        },
        {
            category: Categories.FLAGS,
            name: t('flags'),
        },
        {
            category: Categories.ACTIVITIES,
            name: t('activities'),
        },
        {
            category: Categories.OBJECTS,
            name: t('objects'),
        },
        {
            category: Categories.ANIMALS_NATURE,
            name: t('animals&Nature'),
        },
        {
            category: Categories.FOOD_DRINK,
            name: t('meal&Drinks'),
        },
        {
            category: Categories.SYMBOLS,
            name: t('symbols'),
        },
        {
            category: Categories.TRAVEL_PLACES,
            name: t('travel'),
        },
    ];
};


const EmojiPicker = observer((props: Props) => {

    const { t } = useI18n('components.Atoms.EmojiPicker');

    return (
        <Container className={[
            props.className,
            'vk-shadow vk-rounded overflow-hidden',
        ].join(' ')}>
            <Picker searchDisabled
                    skinTonesDisabled
                    categories={categories()}
                    emojiStyle={EmojiStyle.NATIVE}
                    searchPlaceHolder={t('search')}
                    previewConfig={{ showPreview: false }}
                    theme={PreferenceStore.isDark ? Theme.DARK : Theme.LIGHT}
                    {...props}/>
        </Container>
    );
});


export { EmojiPicker };
