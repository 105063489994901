/**
 * Internationalization
 *
 * @author: exode <hello@exode.ru>
 */

import i18n from 'i18next';

import moment from 'moment';

import { initReactI18next } from 'react-i18next';
import { PluralRules } from '@formatjs/intl-pluralrules';

import { ConfigStore } from '@/store/core/config';

import { translateResources } from '@/libs/i18n/resources';

/** Should be full path (circular deps) */
import { getFallbackLang, getLanguage } from '@/hooks/core/useI18n';

/** Import all moment js localizations*/
import './momentjs';


const currentLanguage = getLanguage();

i18n.use(initReactI18next);

moment.locale(getLanguage().toLowerCase());

/**
 * Plural rules
 */
const pluralRules = {
    ru: (count: number) => new PluralRules('ru').select(count),
    uz: (count: number) => new PluralRules('uz').select(count),
} as const;

/**
 * Plural formatting
 * @param {string} value
 * @param {number} count
 */
const formatPlural = (value: string, count: number) => {
    const key = pluralRules[i18n.language as keyof typeof pluralRules](count);

    return i18n.t(`${value}_${key}`, { count });
};

/**
 * Set HTML language
 * @param {string} language
 */
const setHtmlLang = (language: string) => {
    document.querySelector('html')?.setAttribute('lang', language.toLowerCase());
};

setHtmlLang(currentLanguage);

/**
 * Initialize i18n
 */

ConfigStore.merge({ language: currentLanguage });

i18n.init({
    fallbackLng: getFallbackLang(),
    lng: currentLanguage.toLowerCase(),
    resources: translateResources,
    interpolation: {
        escapeValue: false,
        format: (value, format, __, options) => {
            return format === 'plural'
                ? formatPlural(value, options?.count)
                : value;
        },
    },
}).catch(e => console.log(e));

i18n.on('languageChanged', (language) => {
    /** Trigger on change only */
    moment.locale(language);
    setHtmlLang(language);
});


export { i18n };
