/**
 * UseChatMember
 *
 * @author: exode <hello@exode.ru>
 */

import { useApolloClient } from '@apollo/client';

import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';

import {
    ChatFindManyDocument,
    ChatFindManyQuery,
    UpdateMemberChatInput,
    useChatMemberDeleteMutation,
    useChatMemberSelfJoinMutation,
    useChatMemberToggleBlockPersonalMutation,
    useChatMemberUpdateMutation,
} from '@/codegen/graphql';

import { useStore } from '@/pages/Core';
import { Router } from '@/services/Utils/Router';


export const useChatMember = () => {

    const { cache } = useApolloClient();

    const { list, filter, sort } = useStore(ChatDialogsPageStore);

    const [
        _selfJoinChatMember,
        { loading: selfJoinChatMemberLoading },
    ] = useChatMemberSelfJoinMutation({
        onError: (error) => console.error(error),
    });

    const selfJoinChatMember = async (
        chatId: number,
    ) => {
        return _selfJoinChatMember({
            variables: { chatId },
        });
    };

    const [
        _updateChatMember,
        { loading: updateChatMemberLoading },
    ] = useChatMemberUpdateMutation({
        onError: (error) => console.error(error),
    });

    const updateChatMember = async (
        chatId: number,
        member: UpdateMemberChatInput,
    ) => {
        await _updateChatMember({
            variables: { chatId, member },
            onCompleted(data) {
                if (data?.chatMemberUpdate.deletedAt) {
                    filterDeletedChat(chatId);

                    Router.replacePage('/chat');
                }
            },
        });
    };

    const [ _deleteChatMember, {
        loading: deleteChatMemberLoading,
    } ] = useChatMemberDeleteMutation({
        onError: (error) => console.error(error),
    });

    const deleteChatMember = async (chatId: number) => {
        return _deleteChatMember({
            variables: { chatId },
            update(__, { data }) {
                if (data?.chatMemberDelete) {
                    filterDeletedChat(chatId);

                    Router.replacePage('/chat');
                }
            },
        });
    };

    const filterDeletedChat = (chatId: number) => {
        const variables = {
            list: { ...list.chats },
            filter: { ...filter.chats },
            sort: { ...sort.chats },
        };

        const { chatFindMany } = cache.readQuery<ChatFindManyQuery>({
            query: ChatFindManyDocument,
            variables,
        }) || {};

        if (!chatFindMany) {
            return console.warn('cached Chat not found');
        }

        cache.writeQuery<ChatFindManyQuery>({
            query: ChatFindManyDocument,
            variables,
            data: {
                chatFindMany: {
                    __typename: 'ListChatOutput',
                    count: chatFindMany?.count,
                    cursor: chatFindMany?.cursor,
                    items: chatFindMany?.items?.filter(({ id }) => id !== chatId),
                },
            },
        });
    };

    const [
        _blockChatMemberPersonal,
        { loading: blockChatMemberPersonalLoading },
    ] = useChatMemberToggleBlockPersonalMutation({
        onError: (error) => console.error(error),
    });

    const blockChatMemberPersonal = async (
        chatId: number,
        companionUserId: number,
        blocked: boolean,
    ) => {
        await _blockChatMemberPersonal({
            variables: {
                chatId,
                companionUserId,
                blocked,
            },
        });
    };

    return {
        selfJoinChatMember,
        selfJoinChatMemberLoading,
        updateChatMember,
        updateChatMemberLoading,
        deleteChatMember,
        deleteChatMemberLoading,
        blockChatMemberPersonal,
        blockChatMemberPersonalLoading,
    };
};
