/**
 * ConfigProvider component
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import moment from 'moment';

import React, { createContext, Dispatch, ReactElement, SetStateAction, useState } from 'react';

import { useConfigGetServerTimeQuery } from '@/codegen/graphql';


export interface ConfigState {
    platform: {
        digitalIsOnSale: {
            ios: boolean;
            android: boolean;
            web: boolean;
            vk: boolean;
        };
    };

    user: {
        clientTimeOffset: number;

        openedTabs: {
            course: {
                [key: string]: null | Window
            };

            lesson: {
                [key: string]: null | Window
            };

            practice: {
                [key: string]: null | Window
            };

            storage: {
                [key: string]: null | Window
            };

            school: {
                [key: string]: null | Window
            };
        };
    };
}

interface ConfigStateProps {
    children: ReactElement | ReactElement[];
}

export interface UseConfigContext {
    state: ConfigState;
    setState: (patch: Partial<ConfigState> | ((prevState: ConfigState) => Partial<ConfigState>)) => void;
    setStateByPath: (path: string, value: any) => void;
}


/**
 * Get initial state
 */
const getInitialConfigState = () => {

    const { digitalIsOnSale } = window.exode?.common?.organization?.settings || {};

    return {
        platform: {
            digitalIsOnSale: {
                vk: true,
                web: true,
                ios: !_.isNil(digitalIsOnSale?.IosIsOnSale)
                    ? digitalIsOnSale.IosIsOnSale
                    : true,
                android: !_.isNil(digitalIsOnSale?.AndroidIsOnSale)
                    ? digitalIsOnSale.AndroidIsOnSale
                    : true,
            },
        },
        user: {
            clientTimeOffset: 0,
            openedTabs: {
                course: {},
                lesson: {},
                practice: {},
                storage: {},
                school: {},
            },
        },
    };
};

/**
 * Config context
 * @returns {ISocketContext}
 */
export const ConfigContext = createContext({
    state: getInitialConfigState(),
    setState: Function as Dispatch<SetStateAction<any>>,
    setStateByPath: (() => {}) as UseConfigContext['setStateByPath'],
});

/**
 * Config provider
 * @param {ConfigStateProps} props
 * @constructor
 */
const ConfigProvider = (props: ConfigStateProps) => {

    const [ state, setState ] = useState(getInitialConfigState());

    const setStateByPath: UseConfigContext['setStateByPath'] = (path, value) => {
        setState((prev) => {
            const newState = _.cloneDeep(prev);

            _.set(newState, path, value);

            return newState;
        });
    };

    useConfigGetServerTimeQuery({
        onError: (error) => console.error(error),
        onCompleted: ({ configGetServerTime }) => {
            const clientTimeOffset = moment().diff(moment(configGetServerTime));

            /** Set client time offset state */
            setState((prev) => ({
                ...prev,
                user: {
                    ...prev.user,
                    clientTimeOffset,
                },
            }));
        },
    });

    return (
        <ConfigContext.Provider value={{ state, setState, setStateByPath }}>
            {props.children}
        </ConfigContext.Provider>
    );
};


export { ConfigProvider };
