/**
 * ToggleCheckbox
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from 'mobx-react';

import { Checkbox, CheckboxProps, Spinner, Switch } from '@exode.ru/vkui';


interface Props extends Omit<CheckboxProps, 'onChange' | 'onClick'> {
    dataTest?: string;
    isLoading?: boolean;
    isOrigin?: boolean;
    onChange?: (e: { target: { checked: boolean } } & MouseEvent) => void;
}


const ToggleCheckbox = observer((props: Props) => {

    const {
        name,
        checked,
        onChange,
        dataTest,
        children,
        className,
        isLoading,
        isOrigin = true,
        ...rest
    } = props;

    return (
        <Checkbox name={name}
                  checked={checked}
                  hasHover={false}
                  hasActive={false}
                  data-test={dataTest}
                  onClick={onChange as React.MouseEventHandler<HTMLInputElement> | undefined}
                  className={[
                      className,
                      isOrigin ? 'checkbox-origin' : '',
                  ].join(' ')}
                  onIcon={(
                      <div className="flex items-center justify-center min-w-[38px]">
                          {!isLoading
                              ? <Switch name={name}
                                        checked={checked}
                                        onClick={onChange as React.MouseEventHandler<HTMLInputElement> | undefined}/>
                              : <Spinner className="text-accent"/>
                          }
                      </div>
                  )}
                  offIcon={(
                      <div className="flex items-center justify-center min-w-[38px]">
                          {!isLoading
                              ? <Switch name={name}
                                        checked={checked}
                                        onClick={onChange as React.MouseEventHandler<HTMLInputElement> | undefined}/>
                              : <Spinner className="text-accent"/>
                          }
                      </div>
                  )}
                  {...rest}>
            {children}
        </Checkbox>
    );
});


export { ToggleCheckbox };
