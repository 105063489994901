/**
 * UseSchoolState
 *
 * @author: exode <hello@exode.ru>
 */

import { SchoolStore } from '@/store/platform';

import { apolloClient } from '@/api/graphql';
import { Router } from '@/services/Utils/Router';
import { ApolloError } from '@apollo/client/errors';

import {
    JsonObjectInput,
    SchoolManageGetStateByKeyDocument,
    SchoolManageGetStateByKeyQuery,
    SchoolStateKey,
    useSchoolManageGetStateByKeyQuery,
    useSchoolManageSetStateByKeyMutation,
} from '@/codegen/graphql';


/**
 * Get school state
 * @param {number} schoolId
 * @param {} key
 */
export const useGetSchoolState = (
    schoolId: number,
    key: SchoolStateKey,
) => {
    const {
        data: schoolState,
        loading: schoolStateLoading,
        error: schoolStateError,
    } = useSchoolManageGetStateByKeyQuery({
        variables: { schoolId, key },
    });

    return [
        schoolState?.schoolManageGetStateByKey,
        schoolStateLoading,
        schoolStateError,
    ];
};

/**
 * Set school state
 */
export const useSetSchoolState = () => {

    const [
        _setSchoolState,
        { loading: setSchoolStateLoading },
    ] = useSchoolManageSetStateByKeyMutation({
        onCompleted: () => Router.replaceModal(),
        onError: (error: ApolloError) => console.error(error),
    });

    const setSchoolState = async (
        key: SchoolStateKey,
        input: JsonObjectInput,
        options: {
            cacheOnly?: boolean;
            optimistic?: boolean;
            onCompleted?: () => void;
        } = {},
    ) => {
        const { optimistic = true, cacheOnly = false } = options;

        const writeToCache = () => {
            apolloClient.cache.writeQuery<SchoolManageGetStateByKeyQuery>({
                query: SchoolManageGetStateByKeyDocument,
                variables: {
                    key,
                    schoolId: SchoolStore.school?.id,
                },
                data: {
                    __typename: 'Query',
                    schoolManageGetStateByKey: input.value,
                },
            });
        };

        if (cacheOnly) {
            return writeToCache();
        }

        if (optimistic) {
            writeToCache();
        }

        const promise = _setSchoolState({
            update: () => writeToCache(),
            onError: (error) => console.error(error),
            variables: {
                key,
                input,
                schoolId: SchoolStore.schoolId,
            },
            onCompleted: () => {
                options.onCompleted?.();
            },
        });

        return !optimistic ? await promise : null;
    };

    return {
        setSchoolState,
        setSchoolStateLoading,
    };
};
