/**
 * UseProfileUpdate
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';
import moment from 'moment';

import { ApolloError } from '@apollo/client';
import { ProfileStore } from '@/store/user/profile';

import { GqlResult } from '@/types/graphql';
import { UserGetMyInfoResult } from '@/types/user';

import {
    ProfileRole,
    ProfileUpdateMutationResult,
    UpdateProfileInput,
    useProfileUpdateMutation,
    UserSexType,
    useSetUserDomainMutation,
} from '@/codegen/graphql';


export const useProfileUpdate = () => {

    const [ _updateProfile, { loading: updateProfileLoading } ] = useProfileUpdateMutation({
        onError: (error) => console.error(error),
        update(__, { data }) {
            const { profileUpdate } = data || {};

            if (profileUpdate) {
                ProfileStore.merge({
                    ..._.omit(profileUpdate, 'user'),
                    user: {
                        ...ProfileStore.user,
                        domain: profileUpdate.user.domain,
                    },
                });
            }
        },
    });

    const updateProfile = (
        profile: UpdateProfileInput,
        onCompleted: (data: GqlResult<ProfileUpdateMutationResult>['profileUpdate']) => void,
        onError: (error: ApolloError) => void,
    ) => {
        return _updateProfile({
            onError,
            variables: { profile },
            onCompleted: (data) => onCompleted?.(data.profileUpdate),
        });
    };

    const [ setUserDomain, { loading: setUserDomainLoading } ] = useSetUserDomainMutation({
        onError: (error) => console.error(error),
    });

    const getInitialValues = (
        user: UserGetMyInfoResult,
    ) => {
        const {
            sex,
            role,
            bdate,
            contact,
            originalNames,
        } = user.profile || {};

        const birthDate = bdate
            ? moment(bdate).toObject()
            : undefined;

        return {
            firstName: originalNames?.firstName || '',
            lastName: originalNames?.lastName || '',
            sex: sex || UserSexType.Ufo,
            birthDay: birthDate && String(birthDate.date) || '',
            birthMonth: birthDate && String(birthDate.months + 1) || '',
            birthYear: birthDate && String(birthDate.years) || '',
            domain: user.domain || '',
            role: role || ProfileRole.Student,
            contact: {
                phone: contact?.phone || user?.phone || '',
            },
        } as const;
    };

    return {
        updateProfile,
        updateProfileLoading,
        setUserDomain,
        setUserDomainLoading,
        getInitialValues,
    };
};
