/**
 * FcmMessagingService
 *
 * @author: exode <hello@exode.ru>
 */

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

import { DocumentEvent } from '@/types/window';
import { CURRENT_PLATFORM, IS_DEV, IS_WEB } from '@/root/src/env';

import { BaseService } from '@/services/Core/Base';

import { PlatformLogo } from '@/images/icons';


class FcmMessagingService extends BaseService {

    static register() {
        if (!IS_WEB) {
            return;
        }
    }

    static init() {
        if (!IS_WEB || IS_DEV) {
            return;
        }

        const firebaseConfig = {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
        };

        initializeApp(firebaseConfig);

        document.addEventListener(DocumentEvent.FcmWebGetToken, () => {
            const messaging = getMessaging();

            getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
                .then(this.onSuccessGenerateToken)
                .catch(this.onFailedGenerateToken);
        });

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/sw.js')
                .then((registration) => {
                    console.log('SW OK', registration.scope);

                    registration.active?.postMessage({
                        type: 'INIT_FIREBASE',
                        config: firebaseConfig,
                        icon: PlatformLogo[CURRENT_PLATFORM],
                    });
                })
                .catch((error) => console.error('SW ERROR', error));
        }
    }

    static onSuccessGenerateToken(token: string) {
        if (!token) {
            return;
        }

        document.dispatchEvent(
            new CustomEvent(
                DocumentEvent.FcmWebTokenReceived,
                { detail: { token } },
            ),
        );
    }

    static onFailedGenerateToken(error: Error) {
        console.log('An error occurred while retrieving FCM token. ', error);

        const event = new CustomEvent(DocumentEvent.FcmWebTokenDeclined);

        document.dispatchEvent(event);
    }

}


export { FcmMessagingService };
