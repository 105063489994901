/**
 * AvailableNewAppVersion
 *
 * @author: exode <hello@exode.ru>
 */

import semver, { ReleaseType } from 'semver';

import React, { useState } from 'react';

import { Graphql, If } from '@/cutils';
import { APP_VERSION, IS_NATIVE, OS_PLATFORM } from '@/root/src/env';

import { DbConfigKey, GetDbConfigByKeyQuery } from '@/codegen/graphql';

import { AvailableNewAppVersionContent } from './AvailableNewAppVersionContent';


const AvailableNewAppVersion = () => {

    const [ remoteIsGreater, setRemoteIsGreater ] = useState(false);
    const [ difference, setDifference ] = useState<ReleaseType | null>(null);

    const dbKey = OS_PLATFORM === 'ios'
        ? DbConfigKey.IosAppVersion
        : DbConfigKey.AndroidAppVersion;

    if (!IS_NATIVE) {
        return <></>;
    }

    return (
        <GetDbConfigByKeyQuery variables={{ key: dbKey, version: APP_VERSION }} children={(result) => (
            <>
                <Graphql.Success result={result}>
                    {({ configGetOneByKey }) => {
                        const localVersion = !semver.valid(APP_VERSION)
                            ? APP_VERSION.split('.')?.slice(0, -1)?.join('.')
                            : APP_VERSION;

                        const remoteVersion = configGetOneByKey || localVersion;

                        try {
                            const difference = semver.diff(localVersion, remoteVersion);

                            setDifference(difference);
                            setRemoteIsGreater(semver.compare(remoteVersion, localVersion) === 1);
                        } catch (e) {}

                        return (
                            <If is={remoteIsGreater && !!difference}>
                                <AvailableNewAppVersionContent newVersion={remoteVersion}
                                                               difference={difference as ReleaseType}/>
                            </If>
                        );
                    }}
                </Graphql.Success>
            </>
        )}/>
    );
};


export { AvailableNewAppVersion };
