/**
 * EditorJsTextArea component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { CSSProperties, ReactElement } from 'react';

import { Container } from './EditorJsTextArea.styled';


interface Props {
    children: ReactElement;
    isReadOnly?: boolean;
    bgColor?: string;
    dataTest?: string;
    onInput?: () => void;
    redactorStyle?: CSSProperties;
    paragraphStyle?: CSSProperties;
}


const EditorJsTextArea = (props: Props) => {

    const {
        onInput,
        bgColor,
        children,
        dataTest,
        redactorStyle,
        paragraphStyle,
        isReadOnly = false,
    } = props;

    return (
        <Container bgColor={bgColor}
                   data-test={dataTest}
                   isReadOnly={isReadOnly}
                   className="editor-js-wrapper"
                   redactorStyle={redactorStyle}
                   paragraphStyle={paragraphStyle}
                   onKeyDown={(e) => e.key === 'Tab' && e.preventDefault()}
                   onInput={(e) => {
                       if (!(e.target as HTMLDivElement)?.classList?.contains('cdx-search-field__input')) {
                           onInput?.();
                       }
                   }}>
            {children}
        </Container>
    );
};


export { EditorJsTextArea };
