/**
 * UserAuthService
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { Cookie } from '@/api/cookie';
import { Storage } from '@/api/storage';
import { RestApi } from '@/api/restapi';
import { LocalStorageKeyType } from '@/types/config';

import { ConfigStore } from '@/store/core/config';
import { RouterStore } from '@/store/core/router';
import { UserAuthStore } from '@/store/user/auth';
import { PreferenceStore } from '@/store/preference/preference';

import { IS_MARKETPLACE, IS_NATIVE, OS_PLATFORM } from '@/root/src/env';

import {
    ChangedPasswordOutput,
    ConnectCallbackTelegramInput,
    LoginAuthOutput,
    LogoutAuthOutput,
    RecoverPasswordOutput,
    ServiceLoginAuthOutput,
    SetOtpAuthInput,
    SignupAuthOutput,
    UpdateAuthedPasswordInput,
    UpdateRecoverPasswordInput,
    VkAuthMode,
    VkIdLoginAuthInput,
} from '@/codegen/graphql';

import { Url } from '@/utils';
import { Router } from '@/services/Utils/Router';
import { BaseService } from '@/services/Core/Base';
import { RecoverPasswordInput } from '@/libs/class-validator';


class UserAuthService extends BaseService {

    /**
     * Инициализация сервиса
     */
    static init() {
        const isRestored = UserAuthService.restoreAuthStateToStorage();

        if (isRestored) {
            return window.location.reload();
        }

        const token = Storage.get('user:auth-token');

        if (token) {
            const { pageId, params } = RouterStore;

            UserAuthStore.authByToken(token)
                .then(async () => {
                    /** Return to the current page after onboarding (if applicable) */
                    await Router.openAfterLogin({
                        skipOnFilled: true,
                        params: {
                            to: pageId,
                            toParams: Url.objectToQuery(params),
                        },
                    });
                });
        } else {
            UserAuthStore.cleanOnLogout(false, 'onInit');
        }
    }

    /**
     * Регистрация пользователя.
     * Если не существует и передан верный код, то регистрируем и входим.
     * @param data
     * @returns {}
     */
    static async signup(
        data: {
            login: string;
            code?: string;
        },
    ) {
        const result = await RestApi.post('auth/signup', data);

        return this.parse<SignupAuthOutput & { session: { token: string } }>(result);
    }

    /**
     * Вход через логин и пароль
     * @param {} data
     * @returns {}
     */
    static async login(
        data: {
            login: string;
            password: string;
            otp?: string;
        },
    ) {
        const result = await RestApi.post('auth/login', data);

        return this.parse<LoginAuthOutput & { session: { token: string } }>(result);
    }

    /**
     * Вход через VK ID
     * @param {} mode
     * @param {} data
     * @returns {Promise<>}
     */
    static async vkTokenLogin(
        mode: VkAuthMode,
        data: VkIdLoginAuthInput,
    ) {
        return RestApi.post(`auth/${mode}/login`, data) as Promise<ServiceLoginAuthOutput>;
    }

    /**
     * Вход через TG
     * @param {} callbackAuthInput
     * @returns {Promise<>}
     */
    static async tgLoginCallback(
        callbackAuthInput: ConnectCallbackTelegramInput,
    ) {
        return RestApi.post(`auth/telegram/callback`, { callbackAuthInput }) as Promise<ServiceLoginAuthOutput>;
    }

    /**
     * Выход из аккаунта
     * @returns {}
     */
    static async logout() {
        const result = await RestApi.post('auth/logout');

        return this.parse<LogoutAuthOutput>(result);
    }

    /**
     * Получение кода для восстановления пароля
     * @param data
     * @returns {}
     */
    static async recoverSendOtp(data: RecoverPasswordInput) {
        const result = await RestApi.post('password/recover/send-otp', data);

        return this.parse<RecoverPasswordOutput>(result);
    }

    /**
     * Изменение пароля (через восстановление)
     * @param data
     * @returns {}
     */
    static async recoverUpdate(
        data: UpdateRecoverPasswordInput,
    ) {
        const result = await RestApi.post('password/recover/update', data);

        return this.parse<ChangedPasswordOutput & { session: { token: string } }>(result);
    }

    /**
     * Изменение пароля авторизованным пользователем
     * @param data
     * @returns {}
     */
    static async updateAuthed(
        data: UpdateAuthedPasswordInput,
    ) {
        const result = await RestApi.post('password/update/authed', data);

        return this.parse<ChangedPasswordOutput>(result);
    }

    /**
     * Установка 2FA
     * @param data
     * @returns {}
     */
    static async setOtp(data: SetOtpAuthInput) {
        const result = await RestApi.post('auth/otp/set', data);

        return this.parse<any>(result);
    }

    /**
     * Получение auth state для native
     */
    static getNativeAuthStateQuery() {
        return Url.objectToQuery({
            ...Router.routerParams,
            state: 'redirect:true,inapp:true',
        });
    }

    /**
     * Сохранение состояния авторизации в "куки"
     */
    static saveAuthStateToCookie() {
        const lsKeys: LocalStorageKeyType[] = [
            'seller:id',
            'user:language',
            'user:auth-token',
            'preference:scheme',
            'fcm-notifications:asked',
        ];

        const emptyLsReserve: { [key in LocalStorageKeyType]?: any } = {
            'user:language': ConfigStore.language,
            'preference:scheme': PreferenceStore.scheme,
        };

        const authRestore = _.mapValues(
            _.keyBy(lsKeys), (__: any, key: typeof lsKeys[number]) => (
                Storage.get(key) ?? emptyLsReserve[key]
            ),
        );

        Cookie.set('auth:restore', authRestore);
    }

    /**
     * Восстановление состояния авторизации из "куки"
     */
    static restoreAuthStateToStorage() {
        const saved = Cookie.get('auth:restore');

        Cookie.destroy('auth:restore');

        if (saved) {
            PreferenceStore.setSpinner();
        }

        for (const [ key, value ] of _.entries(saved || {})) {
            Storage.set(key as LocalStorageKeyType, value);
        }

        return !!saved;
    }

    /**
     * Доступные методы входа
     * @param {'email'[]} exclude
     * @param {boolean} filterEnabled
     * @returns {}
     */
    static availableLoginMethods(
        exclude: ('email')[] = [],
        filterEnabled = true,
    ) {
        const methods = {
            phone: true,
            email: true,
            vk: IS_MARKETPLACE,
            tg: !!window.exode.common?.user.auth.telegramLoginBotName,
            apple: IS_MARKETPLACE && IS_NATIVE && OS_PLATFORM === 'ios',
        } as const;

        return _.omitBy(_.omit(methods, exclude), (v) => filterEnabled ? !v : false) as Record<keyof typeof methods, boolean>;
    }

}


export { UserAuthService };
