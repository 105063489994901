/**
 * UseBecomePartner
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import {
    SellerBecomeRequestFindOneByCurrentUserDocument,
    SellerBecomeRequestInput,
    SellerOrganizationForm,
    SellerType,
    useRequestBecomeSellerMutation,
    useSellerBecomeRequestUpsertShortFormMutation,
} from '@/codegen/graphql';

import { Notify } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { Storage } from '@/api/storage';
import { apolloClient } from '@/api/graphql';
import { Router } from '@/services/Utils/Router';
import { SellerBecomeRequestItem } from '@/types/seller';

import { useSeller } from '@/hooks/apollo';

import { SellerStore } from '@/store/user/seller';
import { UserAuthStore } from '@/store/user/auth';
import { PreferenceStore } from '@/store/preference/preference';

import { useSellerContext } from '@/components/App/SellerProvider';


export const useBecomePartner = () => {

    const { t } = useI18n('hooks.apollo.user');

    const { handleSelectSeller } = useSeller();
    const { setStateOnCurrentSellerChange } = useSellerContext();

    const [
        upsertBecomePartner,
        { loading: upsertBecomePartnerLoading },
    ] = useRequestBecomeSellerMutation({
        onError() {
            Notify.vkui({
                appearance: 'error',
                message: t('errorOccurredTryLater'),
            });
        },
        update(__, { data }) {
            const requestStatus = data?.sellerBecomeRequestUpsert.status;

            if (requestStatus) {
                Storage.set('seller:become-partner-status', requestStatus);

                SellerStore.merge({ requestStatus });
            }
        },
    });

    const [
        upsertShortFormBecomePartner,
        { loading: upsertShortFormBecomePartnerLoading },
    ] = useSellerBecomeRequestUpsertShortFormMutation({
        onError() {
            Notify.vkui({
                appearance: 'error',
                message: t('errorOccurredTryLater'),
            });
        },
        update(__, { data }) {
            const requestStatus = data?.sellerBecomeRequestUpsertShortForm.status;

            if (requestStatus) {
                Storage.set('seller:become-partner-status', requestStatus);

                SellerStore.merge({ requestStatus });
            }
        },
        onCompleted: async ({ sellerBecomeRequestUpsertShortForm: request }) => {
            Router.replaceModal();

            PreferenceStore.setSpinner();

            /** Пере-получение прав пользователя */
            await UserAuthStore.authByToken();

            await Router.afterUpdate();

            await apolloClient.refetchQueries({
                include: [
                    SellerBecomeRequestFindOneByCurrentUserDocument,
                ],
            });

            if (request?.sellerOrganization) {
                const { seller } = request.sellerOrganization;

                setStateOnCurrentSellerChange(seller);

                await handleSelectSeller(
                    seller.id,
                    seller.currentManager.permissions,
                );

                PreferenceStore.setSpinner(false);

                Router.pushPage('/manage/courses/:page([0-9]+)', { partnerOnboarding: 'true' });
            } else {
                PreferenceStore.setSpinner(false);
            }

            request?.sellerOrganization
                ? Notify.toast.success(t('greatYouHaveBecomePartner'))
                : Notify.toast.error(t('errorOccurredWhilePartnerCreation'));
        },
    });

    const getFullRequestInitialValues = (request?: SellerBecomeRequestItem) => {
        const baseRequestForm: SellerBecomeRequestInput = {
            type: SellerType.Tutor,
            organizationForm: SellerOrganizationForm.Ip,
            inn: '',
            bic: '',
            bankAccountNumber: '',

            /** Individual entity */
            firstName: '',
            lastName: '',
            surname: '',

            passport: {
                series: '',
                number: '',
                issuedBy: '',
                issuedByCode: '',
                dateOfIssue: undefined as unknown as string,
                registration: '',
                pages: [],
            },
        };

        return request
            ? _.pick({
                ...request,
                passport: (!request.passport && _.isEmpty(request.passport))
                    ? baseRequestForm.passport
                    : {
                        ...request.passport,
                        dateOfIssue: new Date(request.passport?.dateOfIssue || ''),
                    },
            }, [
                'inn',
                'bic',
                'type',
                'surname',
                'passport',
                'lastName',
                'firstName',
                'organizationForm',
                'bankAccountNumber',
            ]) as SellerBecomeRequestInput
            : baseRequestForm;
    };

    return {
        upsertBecomePartner,
        upsertBecomePartnerLoading,
        upsertShortFormBecomePartner,
        upsertShortFormBecomePartnerLoading,
        getFullRequestInitialValues,
    };
};
