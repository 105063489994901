/**
 * UseChatMessage
 *
 * @author: exode <hello@exode.ru>
 */

import { useState } from 'react';

import { DocumentEvent } from '@/types/window';
import { useConfirm, useI18n } from '@/hooks/core';

import { useStore } from '@/pages/Core';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';

import {
    ChatMessageDeleteManyMutationOptions,
    ChatMessageFindManyDocument,
    ChatMessageFindManyQuery,
    ChatMessageFindManyQueryVariables,
    useChatMessageDeleteManyMutation,
} from '@/codegen/graphql';


export const useChatMessage = () => {

    const [
        chatMessageDeleteMany,
        { loading: deleteChatMessageManyLoading },
    ] = useChatMessageDeleteManyMutation();

    const deleteChatMessage = (
        props: {
            onCompleted?: () => void,
            queryVariables: ChatMessageFindManyQueryVariables
            mutationVariables: ChatMessageDeleteManyMutationOptions['variables'],
        },
    ) => {
        const { onCompleted, mutationVariables, queryVariables } = props;

        return chatMessageDeleteMany({
            variables: mutationVariables,
            onError: (error) => console.error(error),
            update: (cache, { data }) => {
                const deleted = data?.chatMessageDeleteMany;

                const { chatMessageFindMany: cachedMessages } = cache.readQuery<ChatMessageFindManyQuery>({
                    query: ChatMessageFindManyDocument,
                    variables: queryVariables,
                }) || {};

                cachedMessages && cache.writeQuery<ChatMessageFindManyQuery>({
                    query: ChatMessageFindManyDocument,
                    variables: queryVariables,
                    data: {
                        chatMessageFindMany: {
                            __typename: 'ListMessageChatOutput',
                            cursor: cachedMessages.cursor,
                            items: cachedMessages.items?.filter(({ id }) => !deleted?.includes(id)),
                        },
                    },
                });

                setImmediate(() => {
                    document.dispatchEvent(
                        new CustomEvent(DocumentEvent.ChatAfterDeleteMessageInCache),
                    );
                });
            },
            onCompleted: () => {
                onCompleted?.();
            },
        });
    };

    return {
        deleteChatMessage,
        deleteChatMessageManyLoading,
    };
};

export const useConfirmDeleteMessages = (chatId: number) => {

    const { t } = useI18n('hooks.apollo.chat');

    const { deleteChatMessage } = useChatMessage();

    const [ loading, setLoading ] = useState(false);
    const { list, filter, sort, stater: { selectedMessages } } = useStore(ChatDialogsPageStore);

    const messages = ChatDialogsPageStore.state.selectedMessages?.[+chatId];

    const { openConfirm: openConfirmDeleteMessages } = useConfirm({
        isLoading: loading,
        title: t('thisCannotBeRolledBack'),
        subtitle: t('confirmDeleteMessages', { count: messages?.length }),
        buttons: {
            confirm: { text: t('delete') },
        },
        onConfirm: async () => {
            setLoading(true);

            await deleteChatMessage({
                queryVariables: {
                    chatId: +chatId,
                    list: { ...list.messages },
                    filter: { ...filter.messages },
                    sort: { ...sort.messages },
                },
                mutationVariables: {
                    chatId: +chatId,
                    messageIds: selectedMessages[+chatId]?.map(({ id }) => id),
                },
            });

            ChatDialogsPageStore.setSelectedMessages({});

            setLoading(false);
        },
    });

    return { openConfirmDeleteMessages };
};
