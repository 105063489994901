/**
 * SelectOptionPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If, SvgComponent } from '@/cutils';

import { Icon20PictureOutline } from '@vkontakte/icons';
import { Avatar, CustomSelectOption, CustomSelectOptionProps } from '@exode.ru/vkui';

import { UserAvatar } from '@/components/Atoms/UserAvatar';
import { DynamicVkIcon } from '@/components/Atoms/DynamicVkIcon';
import { SearchSelectProps } from '@/components/Atoms/SearchSelect';


interface Props extends Pick<SearchSelectProps,
    'avatarType' |
    'avatarMode' |
    'selectedIds'> {
    option: CustomSelectOptionProps;
}


const SelectOptionPart = (props: Props) => {

    const {
        avatarMode,
        avatarType,
        selectedIds,
        option: {
            option,
            ...restProps
        },
    } = props;

    const avatarShowMode = option?.avatar?.includes('Icon') ? 'icon' : 'link';

    return (
        <CustomSelectOption {...restProps} selected={selectedIds?.includes(option.value as never)}
                            before={avatarType && (
                                <>
                                    <If is={avatarType === 'user'}>
                                        <UserAvatar size={24}
                                                    lazy={false}
                                                    mode={avatarMode}
                                                    name={option.label}
                                                    src={option.avatar}
                                                    userId={option.value}
                                                    showUserOnlineBadge={false}/>
                                    </If>

                                    <If is={avatarType === 'icon'}>
                                        <Avatar shadow size={24} mode={avatarMode} src={(
                                            avatarShowMode === 'link'
                                                ? option.avatar
                                                : undefined
                                        )}>
                                            <If is={!option.avatar}>
                                                <Icon20PictureOutline width={16} height={16}/>
                                            </If>

                                            <If is={avatarShowMode === 'icon'}>
                                                <SvgComponent element={(
                                                    <DynamicVkIcon vkIconName={option.avatar}/>
                                                )} svgProps={{
                                                    width: 20,
                                                    height: 20,
                                                    fill: 'var(--accent)',
                                                }}/>
                                            </If>
                                        </Avatar>
                                    </If>
                                </>
                            )}>
            {option.label}
        </CustomSelectOption>
    );
};


export { SelectOptionPart };
