/**
 * LessonAfterIconPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { SvgComponent } from '@/cutils';
import { lessonProgressStatusIcon } from '@/types/course';
import { CourseProgressLessonStatus } from '@/codegen/graphql';

import { Spinner } from '@exode.ru/vkui';
import { Icon24SkipToAction } from '@vkontakte/icons';

import { CourseService } from '@/services/Course/Course';

import { LessonCardProps } from '@/components/Course/LessonCard';


interface Props {
    course: LessonCardProps['course'];
    lesson: Pick<LessonCardProps['lesson'], 'myProgressStatus' | 'previous'>;
    isLoading?: boolean;
    colorful?: boolean;
}


const LessonAfterIconPart = (props: Props) => {

    const {
        lesson,
        colorful,
        isLoading,
        lesson: { myProgressStatus },
    } = props;

    const featureDisabledIsAvailable = CourseService.canStartNextFeatureDisabled(
        lesson.previous,
        lesson.myProgressStatus,
    );

    const svgProps = {
        fill: colorful
            ? 'var(--text_primary)'
            : null,
    };

    return (
        <div className="min-w-[24px] min-h-[24px] relative">
            {myProgressStatus && lessonProgressStatusIcon[myProgressStatus]
                ? (
                    featureDisabledIsAvailable
                        ? (
                            isLoading
                                ? <Spinner size="regular"/>
                                : <Icon24SkipToAction fill={colorful ? 'var(--text_primary)' : 'var(--accent)'}/>

                        )
                        : <SvgComponent svgProps={svgProps} element={lessonProgressStatusIcon[myProgressStatus]}/>
                )
                : (
                    <SvgComponent svgProps={svgProps}
                                  element={lessonProgressStatusIcon[CourseProgressLessonStatus.NotParticipant]}/>
                )}
        </div>
    );
};


export { LessonAfterIconPart };
