/**
 * Use once update
 *
 * @Note: help trigger update callback only if it's necessary
 *
 * @author: exode <hello@exode.ru>
 */

import { useRef } from 'react';

import { ObjectUtil } from '@/utils';


export const useOnceUpdate = <T = any, >(
    initialValues: T,
    callback?: (values: T) => void,
    isEqualOnTrigger?: () => void,
) => {
    const prevValues = useRef(initialValues);

    const needToUpdate = (values: typeof initialValues) => {
        return !ObjectUtil.isEqual(prevValues.current, values);
    };

    return {
        initialValues,
        needToUpdate,
        handleUpdateSubmit: (values: typeof initialValues) => {
            if (needToUpdate(values)) {
                prevValues.current = values;

                callback?.(values);
            } else {
                isEqualOnTrigger?.();
            }
        },
    };
};
