/**
 * UploadAvatarPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { TextTooltip } from '@exode.ru/vkui/unstable';
import { Avatar, Spinner } from '@exode.ru/vkui';
import { Icon28PicturePenOutline } from '@vkontakte/icons';


interface Props {
    loading?: boolean;
    progress?: number;
    avatarUrl?: string;
}


const UploadAvatarPart = (props: Props) => {

    const { loading, progress, avatarUrl, ...rest } = props;

    const { t } = useI18n('components.ContentElement.edit');

    return (
        <TextTooltip placement="left"
                     shown={progress && !!loading || undefined}
                     text={loading ? `${progress} %` : t('setUpAvatar')}>
            <Avatar size={66} className="cursor-pointer" src={avatarUrl} {...rest}>
                <If is={!loading && !avatarUrl}>
                    <Icon28PicturePenOutline/>
                </If>

                <If is={!!loading}>
                    <Spinner className="text-accent bg-content"/>
                </If>
            </Avatar>
        </TextTooltip>
    );
};


export { UploadAvatarPart };
