/**
 * UseTournamentLobby
 *
 * @author: exode <hello@exode.ru>
 */

import { useBatchUpdateCacheFields } from '@/hooks/core';

import {
    TournamentBlitzLobbyStatus,
    TournamentEntity,
    TournamentException,
    TournamentInvitationsDocument,
    TournamentInvitationsQuery,
    TournamentLobbyAppointMutationResult,
    useSendInvitationMutation,
    useStartTournamentMutation,
    useTournamentLobbyAppointMutation,
    useTournamentSetStatusUserBlitzQueueMutation,
} from '@/codegen/graphql';

import { Notify } from '@/cutils';
import { getGqlCause } from '@/api/graphql';

import { GqlResult } from '@/types/graphql';
import { tournamentExceptions } from '@/types/tournament';


export const useTournamentLobby = () => {

    const [ _appointTournament, {
        loading: appointTournamentLoading,
        error: appointTournamentError,
    } ] = useTournamentLobbyAppointMutation();

    const [ _startTournament, {
        loading: startTournamentLoading,
    } ] = useStartTournamentMutation();

    const [ _cancelCurrentBlitz, {
        loading: cancelCurrentBlitzLoading,
    } ] = useTournamentSetStatusUserBlitzQueueMutation({
        onError: (error) => console.error(error),
    });

    const cancelCurrentBlitz = (blitzLobbyId: number) => {
        return _cancelCurrentBlitz({
            variables: {
                blitzLobbyId,
                status: TournamentBlitzLobbyStatus.OnHold,
            },
        });
    };

    const appointTournament = (
        tournamentId: number,
        inviteKey: string,
        onCompleted?: (appoint: GqlResult<TournamentLobbyAppointMutationResult>['tournamentLobbyAppoint']) => void,
    ) => {
        return _appointTournament({
            variables: { tournamentId, inviteKey },
            onError(error) {
                console.error(error);

                const message = tournamentExceptions[getGqlCause(error) as TournamentException];

                if (message) {
                    Notify.toast.error(
                        message,
                        { id: 'tournament-appoint-error' },
                    );
                }
            },
            onCompleted: (data: GqlResult<TournamentLobbyAppointMutationResult>) => {
                onCompleted?.(data.tournamentLobbyAppoint);
            },
            update: (cache, { data }) => {
                const { tournamentLobbyAppoint } = data || {};

                tournamentLobbyAppoint && cache.modify({
                    id: `TournamentEntity:${tournamentId}`,
                    fields: useBatchUpdateCacheFields<TournamentEntity>(tournamentLobbyAppoint),
                });
            },
        });
    };

    const startTournament = (tournamentId: number) => {
        return _startTournament({
            variables: {
                tournamentId,
            },
            onError(error) {
                console.error(error);
            },
        });
    };

    const [
        _sendInvitation,
        { loading: sendInvitationLoading },
    ] = useSendInvitationMutation({
        onError: (error) => console.error(error),
    });

    const sendInvitation = (
        tournamentId: number,
        toUserId: number,
    ) => {
        return _sendInvitation({
            variables: { invitation: { tournamentId, toUserId } },
            update: (cache, { data }) => {
                const invitedUser = data?.tournamentLobbySendInvitation;

                const cachedInvitations = cache.readQuery<TournamentInvitationsQuery>({
                    query: TournamentInvitationsDocument,
                    variables: {
                        tournamentId,
                        list: { skip: 0, take: 20 },
                    },
                });

                if (!cachedInvitations?.tournamentLobbyFindManyInvitations) {
                    return console.warn('[Cache]: cachedInvitations отсутствуют в кэше');
                }

                const { items, ...rest } = cachedInvitations?.tournamentLobbyFindManyInvitations;

                if (invitedUser) {
                    cache.writeQuery<TournamentInvitationsQuery>({
                        query: TournamentInvitationsDocument,
                        variables: {
                            tournamentId,
                            list: { skip: 0, take: 20 },
                        },
                        data: {
                            tournamentLobbyFindManyInvitations: {
                                ...rest,
                                items: [ invitedUser, ...(items || []) ],
                            },
                        },
                    });
                }
            },
            onError(error) {
                console.error(error);
            },
        });
    };


    return {
        appointTournament,
        appointTournamentLoading,
        appointTournamentError,
        startTournament,
        startTournamentLoading,
        cancelCurrentBlitz,
        cancelCurrentBlitzLoading,
        sendInvitation,
        sendInvitationLoading,
    };
};
