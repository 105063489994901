/**
 * UserPasswordPage store
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { TFunction } from 'i18next';

import { makeObservable } from 'mobx';
import { FormikHelpers } from 'formik';

import { apolloClient } from '@/api/graphql';
import { observer, PageStore } from '@/pages/Core';

import { Notify } from '@/cutils';
import { Router } from '@/services/Utils/Router';
import { UserAuthService } from '@/services/User/Auth';


class UserPasswordPage extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    state = {
        input: {
            password: '',
            confirmPassword: '',
        },
        loading: {
            updatePassword: false,
        },
    };

    async updateAuthed(
        formik: FormikHelpers<UserPasswordPage['state']['input']>,
        tStatic: TFunction<string, undefined>,
    ) {
        const { input } = this.state;

        const { success, exception, payload } = await this.wrapService('updatePassword', {
            service: UserAuthService.updateAuthed({
                logout: false,
                password: input.password,
            }),
        });

        if (!success) {
            if (exception) {
                formik.setErrors({
                    password: exception.message,
                    confirmPassword: ' ',
                });
            }

            return false;
        }

        if (success && !_.isEmpty(payload)) {
            Router.replacePage('/settings');

            this.state.input = {
                password: '',
                confirmPassword: '',
            };

            formik.resetForm();
            Notify.toast.success(tStatic('passwordSuccessfullySet'));

            apolloClient.cache.modify({
                id: `PasswordEntity:${payload?.previous?.id}`,
                fields: {
                    updatedAt: () => new Date(),
                },
            });
        }
    }

}

const UserPasswordPageStore = new UserPasswordPage();


export { observer, UserPasswordPageStore };
