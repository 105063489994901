/**
 * UseChatMessageRender
 *
 * @author: exode <hello@exode.ru>
 */

import { useState } from 'react';

import { useStore } from '@/pages/Core';
import { ConfigStore } from '@/store/core/config';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';

import { ChatService } from '@/services/Chat/Chat';

import { ChatMessageStatus } from '@/codegen/graphql';
import { ChatMessageItem, ChatMessageItemNullable } from '@/types/chat';


interface Props {
    chatId: number;
    message: ChatMessageItem;
    messages: ChatMessageItemNullable;
}


export const useChatMessageRender = (props: Props) => {

    const { chatId, message, messages } = props;

    const { store } = useStore(ChatDialogsPageStore);

    const [ isHovered, setIsHovered ] = useState<number | null>(null);

    const isDesktop = ConfigStore.isDesktop;
    const isCompact = ChatService.hasGapByInterval(message.id, messages || []);
    const isSelected = store.state.selectedMessages?.[chatId]?.find((item) => item.id === message.id);
    const isExpanded = !!store.state.expandedMessages?.[chatId]?.[message.id];

    const isSending = message.status === ChatMessageStatus.Sending;
    const isSent = [ ChatMessageStatus.Sent, ChatMessageStatus.Scheduled ].includes(message.status);
    const unsentMessage = store.state.unsentMessages[chatId]?.find((item) => item.messageId === message.id);

    const selectIconClassName = [
        isCompact ? '!my-auto' : 'mt-[10px]',
        isHovered || isSelected ? 'visible' : 'invisible',
        'mb-1 mr-2 self-start cursor-pointer m:hidden sticky top-4 bottom-4 pt-[2px]',
    ].join(' ');

    return {
        isSent,
        isSending,
        isCompact,
        isSelected,
        isExpanded,
        isDesktop,
        isHovered,
        setIsHovered,
        unsentMessage,
        selectIconClassName,
    };
};
