/**
 * ManageHomeworkPage
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { CourseLessonPracticeAttemptFindManyDocument } from '@/codegen/graphql';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';

import { Icon28ArchiveCheckOutline } from '@vkontakte/icons';

import { DesktopHeaderMenu } from '@/components/Desktop/Navigation';

import { HeaderView } from './views/HeaderView';
import { ContentView } from './views/ContentView';


const ManageHomeworkPage = () => {

    const { t } = useI18n('pages.Manage.Homework');

    return (
        <Page.Wrapper mode="slim-menu">
            <Page.Head>
                <Page.Header refetchDocs={[
                    CourseLessonPracticeAttemptFindManyDocument,
                ]} title={(
                    <DesktopHeaderMenu responsiveMode="isDesktop" mobileTitle={t('practice')} after={null} items={[
                        {
                            title: t('practice'),
                            before: <Icon28ArchiveCheckOutline/>,
                            path: { id: '/manage/homeworks/:page([0-9]+)' },
                        },
                    ]}/>
                )}/>
            </Page.Head>

            <Page.Content>
                <HeaderView/>

                <ContentView/>
            </Page.Content>
        </Page.Wrapper>
    );
};


export { ManageHomeworkPage };
