/**
 * NotionPageContentElementEdit
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { Formik } from 'formik';

import { NotionPage } from '@/shared/types';
import { ContentElementType } from '@/codegen/graphql';

import { useI18n } from '@/hooks/core';
import { AutoSaveForm, Field, If } from '@/cutils';

import { FormItem, Input } from '@exode.ru/vkui';

import { ContentElementEditProps, getContentElementContextValue } from '@/components/ContentElement';

import { NotionPageContentElementShow } from '../show/NotionPageContentElementShow';

import { BlockHeaderPart, BlockWrapperPart } from '../parts/edit';


interface Props extends ContentElementEditProps<NotionPage> {

}


const NotionPageContentElementEdit = (props: Props) => {

    const {
        list,
        loading,
        onEdit,
        update,
        onDelete,
        contentElement,
    } = props;

    const { t } = useI18n('components.ContentElement.edit');

    const {
        edit: {
            blockWrapperProps = {},
            blockHeaderProps = {},
            BlockWrapper = BlockWrapperPart,
        } = {},
    } = getContentElementContextValue(ContentElementType.NotionPage);

    return (
        <Formik initialValues={_.pick(contentElement, [ 'content' ])} onSubmit={(values) => update?.(values)}>
            {({ values, handleSubmit, handleChange }) => (
                <AutoSaveForm onChange={() => handleSubmit()} onInstantChange={() => onEdit?.()}>
                    <BlockWrapper index={list?.index} style={{}} {...blockWrapperProps}>
                        <BlockHeaderPart list={list}
                                         loading={loading}
                                         onDelete={onDelete}
                                         type={ContentElementType.NotionPage}
                                         {...blockHeaderProps}/>

                        <FormItem className="px-0" top={t('enterNotionPageUrl')}>
                            <Input name="content.notionUrl"
                                   value={values.content.notionUrl}
                                   placeholder="https://my-space.notion.site/mypagepath..."
                                   onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                        </FormItem>

                        <If is={!!values.content.notionUrl}>
                            <NotionPageContentElementShow contentElement={{
                                ...contentElement,
                                myInteraction: null,
                                content: values.content,
                            }}/>
                        </If>
                    </BlockWrapper>
                </AutoSaveForm>
            )}
        </Formik>
    );
};


export { NotionPageContentElementEdit };
