/**
 * UserCheckboxCell
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Cell, CellProps } from '@exode.ru/vkui';

import { SchoolUserFindManyItem } from '@/types/user';
import { SearchProfileListItem } from '@/types/profile';

import { User } from '@/components/Atoms/User';
import { UserAvatar } from '@/components/Atoms/UserAvatar';
import { LastOnlineText } from '@/components/Profile/Page';

import { Container } from './UserCheckboxCell.styled';


export interface UserCheckboxCellProps extends CellProps {
    user: SearchProfileListItem['user'] | SchoolUserFindManyItem;
    profile: SearchProfileListItem | SchoolUserFindManyItem['profile'];
    rounded?: boolean;
    className?: string;
}


const UserCheckboxCell = (props: UserCheckboxCellProps) => {

    const {
        user,
        profile,
        className,
        rounded = true,
        ...rest
    } = props;

    return (
        <Container rounded={rounded} className="flex-1" data-test="chat.create.cell-item">
            <Cell mode="selectable" className={className} subtitle={(
                <LastOnlineText userId={user.id} sex={profile.sex} lastOnlineAt={user.lastOnlineAt}/>
            )} before={(
                <UserAvatar size={48}
                            className="mr-3"
                            userId={user.id}
                            name={profile.fullName || ''}
                            src={profile.avatar.medium || ''}/>
            )} {...rest}>
                <User.Name profile={profile}/>
            </Cell>
        </Container>
    );
};


export { UserCheckboxCell };
