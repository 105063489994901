/**
 * AddBlockButtonPart
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { useI18n } from '@/hooks/core';
import { If, SvgComponent } from '@/cutils';
import { ContentElementType } from '@/codegen/graphql';
import { contentElementTypeIcon } from '@/types/content';

import { TextTooltip } from '@exode.ru/vkui/unstable';
import { Button, Spinner } from '@exode.ru/vkui';
import { Icon12Picture, Icon16InfoOutline } from '@vkontakte/icons';

import { TagInlineText } from '@/components/Atoms/TagInlineText';


export interface AddBlockButtonPartProps {
    text: string;
    onClick: () => void;
    size?: 'l' | 's';
    tooltip?: string;
    dataTest?: string;
    loading?: boolean;
    type?: ContentElementType;
    icon?: ReactElement;
    loadingType?: ContentElementType | null;
    disabled?: boolean;
}


const AddBlockButtonPart = (props: AddBlockButtonPartProps) => {

    const {
        type,
        text,
        tooltip,
        loading,
        onClick,
        disabled,
        loadingType,
        size = 'l',
        dataTest = `content-layout-block-manage.${type}`,
        icon = type && contentElementTypeIcon[type] || <></>,
    } = props;

    const { t } = useI18n('components.Content.ContentLayoutBlockManage');

    return (
        <>
            <If is={size === 's'}>
                <Button stretched
                        size="l"
                        mode="tertiary"
                        onClick={onClick}
                        data-test={dataTest}
                        className="!outline-none bg-hover"
                        loading={loading && type === loadingType}
                        disabled={loading && type === loadingType || disabled}
                        before={<SvgComponent element={icon} svgProps={{ width: 24, height: 24 }}/>}>
                    {text}
                </Button>
            </If>

            <If is={size === 'l'}>
                <div key={type} data-test={dataTest} onClick={onClick} className={[
                    'flex flex-col items-center justify-center gap-2 thin-border group !outline-none',
                    'relative p-2 vk-rounded bg-content cursor-pointer h-[80px] m:h-[60px] overflow-hidden',
                    disabled ? 'pointer-events-none opacity-50' : '',
                ].join(' ')}>
                    <If is={!!disabled}>
                        <TagInlineText size="m"
                                       gradient="gray"
                                       content={`🚧 • ` + t('soon')}
                                       className="absolute left-[10px] top-[10px]"/>
                    </If>

                    <If is={!loading || loadingType !== type}>
                        <div className="relative text-secondary group-hover:!text-[var(--accent)] w-[32px] h-[32px]">
                            <SvgComponent element={icon} svgProps={{ width: 32, height: 32 }}/>

                            <If is={!!type && type === ContentElementType.EditorJsBlock}>
                                <div className={[
                                    'absolute bg-content rounded-full',
                                    'p-[2px] right-[1px] bottom-[1px]',
                                ].join(' ')}>
                                    <Icon12Picture height={11} width={11}/>
                                </div>
                            </If>
                        </div>
                    </If>

                    <If is={!!loading && loadingType === type}>
                        <div className="flex items-center justify-center w-[32px] h-[32px]">
                            <Spinner size="regular" className="text-accent"/>
                        </div>
                    </If>

                    <span className="text-center line-clamp-1 fs-13">
                        {text}
                    </span>

                    <If is={!!tooltip}>
                        <div onClick={(e) => e.stopPropagation()} className="absolute top-0 right-0 cursor-help">
                            <TextTooltip text={tooltip} placement="right" offsetDistance={0} className="max-w-[180px]">
                                <div className="p-3">
                                    <Icon16InfoOutline fill="var(--icon_secondary)"/>
                                </div>
                            </TextTooltip>
                        </div>
                    </If>
                </div>
            </If>
        </>
    );
};


export { AddBlockButtonPart };
