/**
 * Content element shared types
 *
 * @author: exode <hello@exode.ru>
 */


/**
 * Common edit provider props
 * @Note: Используется для обертывания провайдером одного/набора CE
 * и переопределения дефолтного состояния в режимах show и edit
 */
export interface CommonEditProps {
    withTitle?: boolean;
    blockHeaderProps?: Record<any, any>;
    BlockWrapper?: any;
    blockWrapperProps?: Record<any, any>;
}

/**
 * Common show provider props
 */
export interface CommonShowProps {

}

/**
 * AUDIO
 * @Note: CE with upload/record audio and playing
 */

/** Audio content */
export interface AudioContent {
    duration?: number;
    location?: string;

    timecodes?: {
        time: string;
        description: string;
    }[];

    meta?: {
        size?: number | null;
        encoding?: string | null;
        mimeType?: string | null;
        originalName?: string | null;
    };
}

export interface AudioEditProps extends CommonEditProps {

}

export interface AudioShowProps extends CommonShowProps {

}

/**
 * CHAT MESSAGE
 * @Note: CE for display text in message format
 */

/** ChatMessage content */
export interface ChatMessageContent {
    senderName: string;
    animate?: boolean;
    avatarUrl?: string;

    messages: {
        uuid: string;
        text: string;
    }[];
}

export interface ChatMessageEditProps extends CommonEditProps {

}

export interface ChatMessageShowProps extends CommonShowProps {

}

/**
 * CHECKLIST
 * @Note: To-do checklist with interacting
 */

/** Checklist content */
export interface ChecklistContent {
    items: {
        uuid: string;
        text: string;
        defaultChecked?: boolean;
        disabled?: boolean;
    }[];
}

/** Checklist interaction */
export interface ChecklistInteraction {
    items: {
        [uuid: string]: {
            checked: boolean;
            time: Date;
        };
    };
}

export interface ChecklistEditProps extends CommonEditProps {

}

export interface ChecklistShowProps extends CommonShowProps {

}

/**
 * CHECKPOINT
 * @Note: Split content by checkpoints with button
 */

/** Checkpoint content */
export interface CheckpointContent {
    uuid: string;
    text: string;
    bgColor?: string;
    textColor?: string;
    message?: {
        show?: true;
    };
}

/** Checkpoint interaction */
export interface CheckpointInteraction {
    reached: boolean;
    time: Date;
}

export interface CheckpointEditProps extends CommonEditProps {

}

export interface CheckpointShowProps extends CommonShowProps {

}

/**
 * BUTTON
 * @Note: Open link or another action by button click
 */

/** Button content */
export interface ButtonContent {
    buttons: {
        uuid: string;
        link: string;
        text: string;
        target?: '_blank' | '_self';
        bgColor?: string;
        textColor?: string;
    }[];
}

/** Button interaction */
export interface ButtonInteraction {
    clicks: {
        uuid: string;
        time: Date;
    }[];
}

export interface ButtonEditProps extends CommonEditProps {

}

export interface ButtonShowProps extends CommonShowProps {

}


/**
 * EDITOR JS
 * @Note: Text block with rich toolbox
 */

/** EditorJs block content */
export interface EditorJsBlockContent {
    time: number;
    version: string;
    blocks: Record<any, any>[];
}

export interface EditorJsBlockEditProps extends CommonEditProps {

}

export interface EditorJsBlockShowProps extends CommonShowProps {

}


/**
 * NOTION
 * @Note: CE for Notion
 */

/** Notion interaction */
export interface NotionPage {
    notionUrl: string;
}

export interface NotionPageEditProps extends CommonEditProps {

}

export interface NotionPageShowProps extends CommonShowProps {

}


/**
 * SURVEY
 * @Note: CE for get feedback with interacting
 */

/** Survey content */
export interface SurveyContent {
    type: SurveyContentType;

    items?: {
        uuid: string;
        text: string;
        defaultChecked: boolean;
        disabled?: boolean;
    }[];

    ratingSettings?: {
        max: number;
        precision?: number;
        value?: number;
    };
}

export enum SurveyContentType {
    Variant = 'Variant',
    Rating = 'Rating',
}

/** Survey interaction */
export interface SurveyInteraction {
    item?: {
        uuid: string;
        checked: boolean;
    };

    rating?: {
        value: number;
        max?: number;
        precision?: number;
    };
}


/**
 * VIDEO
 * @Note: CE for upload/link and show video
 */

/** Video content */
export interface VideoContent {
    type?: VideoContentType;
    duration?: number;
    location?: string;

    timecodes?: {
        time: string;
        description: string;
    }[];

    meta?: {
        size?: number | null;
        encoding?: string | null;
        mimeType?: string | null;
        originalName?: string | null;
    };
    wrapperUrl?: string;
}

export interface VideoEditProps extends CommonEditProps {

}

export interface VideoShowProps extends CommonShowProps {

}

export enum VideoContentType {
    Local = 'Local',
    ThirdParty = 'ThirdParty',
}
