/**
 * CreateCourseBannerView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { Button } from '@exode.ru/vkui';
import { Icon16Flash, Icon20AddCircle } from '@vkontakte/icons';

import { Router } from '@/services/Utils/Router';

import { GraffitiBackground } from '@/images/landing';

import { TagInlineText } from '@/components/Atoms/TagInlineText';
import { FeatureBanner } from '@/components/Landing/FeatureBanner';


const CreateCourseBannerView = () => {

    const { t } = useI18n('tabs.Catalog.views');

    return (
        <FeatureBanner title={t('postYourCourse')}
                       backgroundImage={GraffitiBackground}
                       startHeaderClassName="dark:accent-shadow"
                       subtitle={t('convenientCombinationOfCmsLm&sCrm')}
                       caption={(
                           <TagInlineText gradient="white"
                                          isPointer={false}
                                          content={t('chargedPlatform')}
                                          className="fs-14 !p-2 !pr-4 !text-black"
                                          icon={<Icon16Flash fill="var(--dynamic_green)"/>}/>
                       )}
                       actions={(
                           <Button size="l"
                                   className="mt-8"
                                   appearance="overlay"
                                   before={<Icon20AddCircle/>}
                                   onClick={() => {
                                       Router.replaceToModalAfterLogin(
                                           '/education',
                                           'short-form-become-partner',
                                       );
                                   }}>
                               {t('createYourCourse')}
                           </Button>
                       )}/>
    );
};


export { CreateCourseBannerView };
