/**
 * Router store
 *
 * @author: exode <hello@exode.ru>
 */

import { ReactComponentElement } from 'react';

import { observer } from 'mobx-react';
import { makeAutoObservable } from 'mobx';

import { PageParams } from 'router.tsx';

import { ConfigStore } from '@/store/core/config';

import { ScrollHelper } from '@/helpers/ui';

import { ModalTypes } from '@/modals/index';
import { RoutePathType } from '@/router/paths';
import { PageAction } from '@/services/Utils/Router';

import { EpicViewType, TabBarItemsType } from '@/types/router';


type TabsInfoType = { [key in TabBarItemsType]: { activePage?: PageAction } };


class Router {

    constructor() {
        makeAutoObservable(this);
    }

    /** Тип активной страницы */
    type: EpicViewType = 'tab';

    /** Подтип-тип активной страницы
     * Например, тип type iframe - но выступает как вкладка на mobile.
     */
    subType: EpicViewType = 'tab';

    /** Tabbar item - активная иконка меню */
    tab?: TabBarItemsType;

    /** Id активной view внутри epic (н-р, /) */
    viewId: RoutePathType = '/';

    /** Id активной страницы (н-р, /) */
    pageId: RoutePathType = '/';

    /** Текущая ссылка */
    location: string = '';

    /** Route + Get параметры страницы (пересечение с Route перетирает Get) */
    params: PageParams = {};

    /** Открытая modal */
    activeModal: ModalTypes | null = null;

    /** Открытый popup */
    activePopup: string | null = null;

    /** Статус открытия modal */
    isModal: boolean = false;

    /** Принудительный показ tabbar  */
    forceTabbar: 'show' | 'hide' | null = null;

    /** Статус открытия popup */
    isPopup: boolean = false;

    /** Статус открытия overlay */
    hasOverlay: boolean = false;

    /** Элемент popout */
    popoutElement: ReactComponentElement<any> | string | null = null;

    /** Информация по вкладкам */
    tabsInfo: TabsInfoType = {
        education: {},
        catalog: {},
        chat: {},
        menu: {},
    };

    get showTabbar() {
        return !ConfigStore.isDesktop
            && [ 'tab', 'iframe' ].includes(this.type)
            && this.forceTabbar !== 'hide';
    }

    /**
     * Установка нового состояния роутера
     * @param router
     */
    setRouter(router: Partial<Router>) {
        const { pageId, params, activeModal = null, activePopup = null } = router;

        Object.assign(this, { ...router, activeModal, activePopup });

        /** Сохраняем предыдущую страницу в эту вкладку */
        if ([ router.type, router.subType ].includes('tab')) {
            this.tabsInfo[router.tab as TabBarItemsType || 'menu'] = {
                activePage: { id: pageId as RoutePathType, params },
            };
        }

        ScrollHelper.freezeScroll(this.hasOverlay);
    }

    /**
     * Установка popup элемента
     * @param {React.ReactComponentElement<any> | string | null} popout
     */
    setPopout(popout: ReactComponentElement<any> | string | null = null) {
        this.popoutElement = this.activePopup ? popout : null;
    }

    /**
     * Установка значений в поля store
     * @param partial
     */
    merge(partial: Partial<Router>) {
        Object.assign(this, partial);
    }

}

const RouterStore = new Router();


export { observer, RouterStore };
