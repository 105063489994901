/**
 * UseSetFriendshipStatus
 *
 * @author: exode <hello@exode.ru>
 */

import { useConfirm, useI18n } from '@/hooks/core';

import { FriendshipStatus, useSetFriendshipStatusMutation } from '@/codegen/graphql';


export const useSetFriendshipStatus = () => {

    const [ _setFriendshipStatus,
        {
            data: mutationData,
            loading: setFriendshipStatusLoading,
        },
    ] = useSetFriendshipStatusMutation();

    const setFriendshipStatus = (
        id: number,
        status: FriendshipStatus,
    ) => {
        return _setFriendshipStatus({
            variables: { relUserId: id, status },
            update: (cache, { data }) => {
                if (data?.friendshipSetStatus) {
                    const updated = data.friendshipSetStatus;

                    cache.modify({
                        id: cache.identify(updated),
                        fields: {
                            friendshipGetStatus: (value, { toReference }) => ({
                                ...value,
                                status: updated.status,
                                to: toReference(cache.identify(updated.to)!),
                                from: toReference(cache.identify(updated.from)!),
                            }),
                        },
                    });
                }
            },
            onError(error) {
                console.error(error);
            },
        });
    };

    return {
        setFriendshipStatus,
        setFriendshipStatusLoading,
        mutationData,
    };
};

export const useConfirmDeleteFriend = (userId: number) => {

    const { t } = useI18n('hooks.apollo.user');

    const { setFriendshipStatus } = useSetFriendshipStatus();

    const { openConfirm: openConfirmDeleteFriend } = useConfirm({
        title: t('deleteFromFriends'),
        subtitle: t('areYouSureThatYouWantToDeleteUserFromFriends'),
        onConfirm: () => setFriendshipStatus(userId, FriendshipStatus.Canceled),
        buttons: {
            confirm: { text: t('delete') },
        },
    });

    return { openConfirmDeleteFriend };
};
