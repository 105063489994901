/**
 * UseGroupManage
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { ManageGroupsListPageStore } from '@/pages/Manage/Groups/List/store';
import { SaveStoreDeepKeys, SaveStoreKeys, Saving, SavingStore } from '@/store/core/saving';

import { Notify } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { getGqlCause } from '@/api/graphql';

import { useStore } from '@/pages/Core';
import { GroupItem } from '@/types/group';
import { GqlResult } from '@/types/graphql';

import {
    CreateGroupInput,
    GroupAccessStartPointType,
    GroupException,
    GroupFindManyDocument,
    GroupFindManyQuery,
    GroupManageCreateMutationResult,
    GroupScheduleOpenUnit,
    GroupScheduleType,
    UpdateGroupInput,
    useGroupManageCreateMutation,
    useGroupManageDeleteMutation,
    useGroupManageUpdateMutation,
} from '@/codegen/graphql';

import { Icon16Cancel } from '@vkontakte/icons';


interface Props {
    withSavingStore?: boolean;
}


export const useGroupManage = (props: Props = {}) => {

    const { t } = useI18n('hooks.apollo.group');

    const { list, filter, sort, params } = useStore(ManageGroupsListPageStore);

    const groupExceptionMap: Record<'name', Record<string, string>> = {
        name: {
            [GroupException.GroupNameIsNotUniq]: t('groupNameIsNotUniq'),
        },
    };

    const setGroupEditing = (editing = true) => {
        SavingStore.setEditing(
            SaveStoreKeys.Group,
            SaveStoreDeepKeys.GroupSettings,
            editing,
        );
    };

    const getInitialValues = (group: GroupItem | null): UpdateGroupInput => {

        const {
            communication,
            accessLimitation,
            contentLimitation,
            scheduleLimitation,
        } = group || {};

        return {
            name: group?.name || '',
            maxMembers: group?.maxMembers || undefined,
            communication: {
                channelIsActive: !_.isNil(communication?.channelIsActive)
                    ? communication?.channelIsActive :
                    false,
                groupChatIsActive: !_.isNil(communication?.groupChatIsActive)
                    ? communication?.groupChatIsActive
                    : false,
                dialogueWithEmployeesBlocked: !_.isNil(communication?.dialogueWithEmployeesBlocked)
                    ? communication?.dialogueWithEmployeesBlocked
                    : false,
            },
            accessLimitation: {
                active: !_.isNil(accessLimitation?.active)
                    ? accessLimitation?.active
                    : false,
                durationInMinutes: accessLimitation?.durationInMinutes || undefined,
                startPointType: accessLimitation?.startPointType || GroupAccessStartPointType.CommonDate,
                commonDateStartAt: accessLimitation?.commonDateStartAt || null,
            },
            scheduleLimitation: {
                active: !_.isNil(scheduleLimitation?.active)
                    ? scheduleLimitation?.active
                    : false,
                type: scheduleLimitation?.type || GroupScheduleType.ByWeekdays,
                openUnit: scheduleLimitation?.openUnit || GroupScheduleOpenUnit.Lesson,
                byWeekdays: {
                    selected: scheduleLimitation?.byWeekdays?.selected || [],
                    startFrom: scheduleLimitation?.byWeekdays?.startFrom || undefined,
                },
                onceForInterval: {
                    durationInMinutes: scheduleLimitation?.onceForInterval?.durationInMinutes || 0,
                    startFrom: scheduleLimitation?.onceForInterval?.startFrom || undefined,
                },
            },
            contentLimitation: {
                disableSendHomework: !_.isNil(contentLimitation?.disableSendHomework)
                    ? contentLimitation?.disableSendHomework
                    : undefined,
                hideEarlyAccessLessons: !_.isNil(contentLimitation?.hideEarlyAccessLessons)
                    ? contentLimitation?.hideEarlyAccessLessons
                    : undefined,
                lessons: contentLimitation?.lessons || {},
            },
        } as const;
    };

    /** Create a group */
    const [ _createGroup, {
        error: createGroupError,
        loading: createGroupLoading,
    } ] = useGroupManageCreateMutation({
        onError: (error) => {
            if (getGqlCause(error) === GroupException.GroupNameIsNotUniq) {
                Notify.toast.error(t('groupNameIsNotUniq'));
            }
        },
        update: (cache, { data }) => {
            const variables = {
                list: { ...list },
                filter: { ...filter },
                sort: { ...sort },
            };

            const cachedGroups = cache.readQuery<GroupFindManyQuery>({
                variables,
                query: GroupFindManyDocument,
            });

            if (!cachedGroups) {
                return console.warn('[Cache]: cachedGroups отсутствуют в кэше');
            }

            const { count, items, ...rest } = cachedGroups.groupFindMany;

            if (data?.groupManageCreate) {
                cache.writeQuery<GroupFindManyQuery>({
                    query: GroupFindManyDocument,
                    variables,
                    data: {
                        groupFindMany: {
                            ...rest,
                            count: count + 1,
                            items: [
                                data?.groupManageCreate,
                                ...(items ?? []),
                            ],
                        },
                    },
                });
            }

            if (params.courseId) {
                /** Refetch groups count in course card */
                cache.evict({
                    id: `CourseEntity:${params.courseId}`,
                    fieldName: 'product',
                });
            }
        },
    });

    const createGroup = (
        group: CreateGroupInput,
        onCompleted?: (data: GqlResult<GroupManageCreateMutationResult>['groupManageCreate']) => void,
    ) => {
        return _createGroup({
            variables: { group },
            onCompleted: (data) => {
                onCompleted?.(data.groupManageCreate);
            },
        });
    };

    /** Update group */
    const [ _updateGroup, {
        error: updateGroupError,
        loading: updateGroupLoading,
    } ] = useGroupManageUpdateMutation();

    const updateGroup = (
        groupId: number,
        group: UpdateGroupInput,
        onCompleted?: () => void,
    ) => {
        return _updateGroup({
            variables: { groupId, group },
            onCompleted: () => onCompleted?.(),
            onError: (error) => {
                if (getGqlCause(error) === GroupException.GroupNameIsNotUniq) {
                    Notify.toast.error(t('groupNameIsNotUniq'));
                }
            },
        });
    };

    const [ _deleteGroup, {
        error: deleteGroupError,
        loading: deleteGroupLoading,
    } ] = useGroupManageDeleteMutation();

    const deleteGroup = (groupId: number) => {
        return _deleteGroup({
            variables: { groupId },
            onError(error) {
                console.error(error);

                Notify.vkui({
                    appearance: 'error',
                    message: error.message,
                    icon: <Icon16Cancel/>,
                });
            },
            update: (cache) => {
                const variables = {
                    list: { ...list },
                    filter: { ...filter },
                    sort: { ...sort },
                };

                const { groupFindMany: cachedGroups } = cache.readQuery<GroupFindManyQuery>({
                    query: GroupFindManyDocument,
                    variables,
                }) || {};

                if (cachedGroups) {
                    cache.writeQuery<GroupFindManyQuery>({
                        query: GroupFindManyDocument,
                        variables: variables,
                        data: {
                            groupFindMany: {
                                ...cachedGroups,
                                count: cachedGroups.count - 1,
                                items: cachedGroups.items?.filter(({ id }) => id !== groupId),
                            },
                        },
                    });
                }

                if (params.courseId) {
                    /** Refetch groups count in course card */
                    cache.evict({
                        id: `CourseEntity:${params.courseId}`,
                        fieldName: 'product',
                    });
                }
            },
        });
    };

    Saving.listenSetSaving(
        SaveStoreKeys.Group,
        SaveStoreDeepKeys.GroupSettings,
        [ updateGroupLoading ],
        [ updateGroupError ],
        { disabled: !props?.withSavingStore },
    );

    return {
        createGroup,
        createGroupError,
        createGroupLoading,
        updateGroup,
        updateGroupError,
        updateGroupLoading,
        deleteGroup,
        deleteGroupError,
        deleteGroupLoading,
        setGroupEditing,
        getInitialValues,
        groupExceptionMap,
        createGroupCause: getGqlCause(createGroupError),
        updateGroupCause: getGqlCause(updateGroupError),
    };
};
