/**
 * Preload util
 *
 * Предзагрузка картинок, шрифтов и т.д.
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { IS_NATIVE } from '@/root/src/env';

/** Icons */
import { IconPart, IconRu, IconRuSquare, IconWhite, Logo } from '@/images/icons';

/** Subjects */
import {
    IconBiology,
    IconChemistry,
    IconGeography,
    IconHistory,
    IconInformatics,
    IconLiterature,
    IconMath,
    IconMathBasic,
    IconPhysics,
    IconRussian,
    IconSocial,
} from '@/images/subjects';

import { useI18n } from '@/hooks/core';
import { If, Platform } from '@/cutils';

import { Satellite } from '@/images/kits';
import { SimpleObject } from '@/types/common';


const images: SimpleObject = {
    IconRu,
    IconPart,
    IconRuSquare,
    IconWhite,
    Logo,
    IconBiology,
    IconChemistry,
    IconGeography,
    IconHistory,
    IconInformatics,
    IconLiterature,
    IconMath,
    IconMathBasic,
    IconPhysics,
    IconRussian,
    IconSocial,
    Satellite,
};


const Preload = () => {

    const { t } = useI18n('components.Utils');

    return (
        <div className="fixed left-0 top-0 overflow-hidden" style={{ width: 1, height: 1, top: -1000, zIndex: -1 }}>
            <span className="font-vk-sans-display">{t('fontUpload')}</span>

            <Platform.Market>
                <If is={!IS_NATIVE}>
                    <img src={images.Logo} alt="IconRuSquare"/>
                    <img src={images.Icon} alt="IconRuSquare"/>
                    <img src={images.IconSquare} alt="IconRuSquare"/>
                    <img src={images.Satellite} alt="Satellite"/>
                </If>

                <If is={IS_NATIVE}>
                    {_.keys(images).map((image, i) => (
                        <If key={i} is={!!images[image]}>
                            <img src={images[image]} alt={i + '-preload'}/>
                        </If>
                    ))}
                </If>
            </Platform.Market>
        </div>
    );
};


export { Preload };
