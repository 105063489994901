/**
 * NotionPageContentElementShow styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
    --notion-max-width: 100%;
    --fg-color: var(--text_primary);
    --notion-font: var(--font-common);
    --notion-gray_background_co: var(--background_hover);

    .notion {
        font-size: var(--fs-content);
    }

    .notion-page {
        margin: 0 !important;
        padding: 0 !important;

        .notion-title {
            display: none;
        }

        .notion-h1,
        .notion-h2,
        .notion-h3 {
            font-size: var(--fs-header);
        }

        .notion-h-title {
            line-height: 130%;
            font-weight: var(--vkui--font_weight_accent3, 400);
            font-family: var(--font-display), serif !important;
        }

        .notion-quote {
            font-size: var(--fs-content);
        }

        .notion-hash-link {
            display: none;
        }

        blockquote {
            border-top-right-radius: var(--vkui--size_border_radius_paper--regular);
            border-bottom-right-radius: var(--vkui--size_border_radius_paper--regular);
        }

        .notion-callout {
            border: none;
            border-radius: var(--vkui--size_border_radius_paper--regular);
        }
    }
`;
