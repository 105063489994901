/**
 * SellerOrganizationDocsView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { useI18n } from '@/hooks/core';

import { SellerOrganizationFindManyDocumentsQueryResult } from '@/codegen/graphql';

import { SimpleCell } from '@exode.ru/vkui';
import { Avatar } from '@exode.ru/vkui/tokenized';
import { Icon28ChevronRightCircle, Icon28DocumentOutline } from '@vkontakte/icons';

import { Time } from '@/utils';
import { If, Link } from '@/cutils';
import { GqlResult } from '@/types/graphql';
import { sellerOrganizationDocumentType } from '@/types/seller';


interface Props {
    items: GqlResult<SellerOrganizationFindManyDocumentsQueryResult>['sellerOrganizationDocumentFindMany']['items'];
}


const SellerOrganizationDocsView = (props: Props) => {

    const { items } = props;

    const sellerOrganizationDocumentTypes = sellerOrganizationDocumentType();

    const { t } = useI18n('pages.Seller.Seller.views');

    return (
        <If is={!!items && !_.isEmpty(items)}>
            {items?.map(({ id, location, updatedAt, type }) => (
                <Link blank key={id} toOuter={location}>
                    <SimpleCell key={id}
                                multiline
                                className="!m-0"
                                after={<Icon28ChevronRightCircle/>}
                                subtitle={t('documentsFrom', { from: Time.parseDate(updatedAt) })}
                                before={(
                                    <Avatar size={48} mode="app">
                                        <Icon28DocumentOutline/>
                                    </Avatar>
                                )}>
                        {sellerOrganizationDocumentTypes[type]}
                    </SimpleCell>
                </Link>
            ))}
        </If>
    );
};


export { SellerOrganizationDocsView };
