/**
 * IconNotion component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { SVGProps } from 'react';


const IconNotion = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 48 48" fill="currentColor" {...props}>
        <path d="M10.849 10.643c1.308 1.063 1.799.982 4.256.818l23.161-1.391c.492 0 .083-.49-.081-.571l-3.846-2.781c-.737-.572-1.719-1.227-3.601-1.064L8.312 7.288c-.818.081-.981.49-.655.818zM12 16.165V40.29c0 1.296.649 1.782 2.112 1.702l25.262-1.458c1.463-.08 1.626-.973 1.626-2.026V14.545c0-1.051-.406-1.619-1.3-1.538l-26.4 1.538c-.974.081-1.3.568-1.3 1.62m25.441.559c.166.746 0 1.491-.747 1.575l-1.242.247v18.213c-1.078.579-2.072.91-2.9.91-1.326 0-1.659-.414-2.652-1.655l-8.12-12.749V35.6l2.57.579s0 1.49-2.074 1.49L16.561 38c-.166-.331 0-1.159.579-1.324l1.492-.414V19.954l-2.071-.166c-.167-.746.247-1.821 1.408-1.905l6.131-.413 8.451 12.915V18.96l-2.155-.247c-.166-.912.497-1.574 1.325-1.655zM6.515 5.102l23.124-1.703c2.84-.243 3.571-.08 5.355 1.216l7.382 5.188C43.594 10.695 44 10.937 44 11.91v28.455c0 1.783-.649 2.838-2.921 2.999l-26.855 1.622c-1.705.081-2.517-.162-3.409-1.297l-5.436-7.053C4.405 35.338 4 34.367 4 33.231V7.937c0-1.458.649-2.674 2.515-2.835"></path>
    </svg>
);


export { IconNotion };
