/**
 * CeContentWrapper styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { responsive } from '@/styles/modules/responsive';


const CeContentWrapper = styled.div<{
    protectTextCopy?: boolean | null;
}>`
    user-select: ${({ protectTextCopy }) => protectTextCopy ? 'none' : 'initial'};

    .Group__separator {
        .Separator__in {
            display: none;
        }
    }

    code {
        user-select: text;
    }

    ${responsive([ 'gte-tablet' ])} {
        --fs-header: 24px;

        [data-component="BlockTitle"] {
            font-size: var(--fs-header);
            margin-bottom: var(--fs-header);
        }

        .Group__inner {
            padding: 0 !important;

            > section {
                padding: 0 !important;
            }

            &:after {
                display: none;
            }
        }
    }
`;


export { CeContentWrapper };
