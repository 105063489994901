/**
 * RecoverPasswordInput
 *
 * @author: exode <hello@exode.ru>
 */

import { IsIn, IsNotEmpty, ValidateIf } from 'class-validator';

import { isNotEmpty } from '../constants';

import { IsPhoneNumber } from '@/shared/validators';


export class RecoverPasswordInput {

    @IsIn([ 'phone', 'login' ])
    readonly mode?: 'phone' | 'login';

    @IsNotEmpty()
    readonly login: string;

    @ValidateIf((o) => o.mode === 'login')
    @IsNotEmpty({ message: isNotEmpty('Логин') })
    readonly emailOrLogin?: string;

    @ValidateIf((o) => o.mode === 'phone')
    @IsNotEmpty({ message: isNotEmpty('Телефон') })
    @IsPhoneNumber({ message: 'Некорректный телефон' })
    readonly phone?: string;

}
