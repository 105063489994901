/**
 * User component
 *
 * @author: exode <hello@exode.ru>
 */

import { DeepPartial } from 'typeorm';

import React, { ReactElement, useEffect } from 'react';

import { ProfileStore } from '@/store/user/profile';
import { ConfigStore, observer } from '@/store/core/config';
import { SubscriptionStore } from '@/store/subscription/subscription';

import { ProfileEntity } from '@/codegen/graphql';

import { Time } from '@/utils';
import { If, Link } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { useLocation } from '@/router/index';
import { Router } from '@/services/Utils/Router';

import { TextTooltip } from '@exode.ru/vkui/dist/unstable';
import { Icon20SmileAddOutline, Icon24SmileAddOutline } from '@vkontakte/icons';
import { Caption, RichCell, SimpleCell, SimpleCellProps, Text } from '@exode.ru/vkui';

import { LastOnlineText } from '@/components/Profile/Page';
import { UserAvatar, UserAvatarProps } from '@/components/Atoms/UserAvatar';


export type ProfilePartial = Omit<Partial<ProfileEntity>, 'user'>;

export interface UserCellProps extends SimpleCellProps {
    mode: 'simple' | 'rich' | 'div';
    userId: number;
    profile: ProfilePartial;
    lastOnlineAt: Date;
    caption?: string;
    actions?: ReactElement | ReactElement[];
    onClick?: () => void;
    dataTest?: string;
    className?: string;
    avatarSize?: number;
    onlineColor?: string | undefined;
    subtitleMode?: 'customText' | 'lastOnlineAt';
    avatarProps?: DeepPartial<UserAvatarProps>;
    nameProps?: DeepPartial<UserNameProps>;
    afterElements?: {
        userName?: ReactElement;
    };
}

interface UserNameProps {
    /** Required fields for profile */
    profile: Pick<ProfilePartial,
        'id' |
        'fullName' |
        'title' |
        'emojiTitle' |
        'titleState'>;
    /** After element */
    after?: boolean;
    /** Extra class names */
    className?: string;
    /** If user is owner - show emoji plus icon to set status */
    showStatusSet?: boolean;
    /** Plus icon to set status size */
    setStatusSize?: 'small' | 'medium';
    /** Change cursor style */
    disabled?: boolean;
    /** Autotest selector */
    dataTest?: string;
}

interface UserTitleProps {
    profile: Pick<ProfilePartial, 'title'>;
}

/**
 * User name
 * @returns {JSX.Element}
 * @constructor
 */
const UserName = observer((props: UserNameProps) => {

    const { route: { params: { photoViewer } } } = useLocation();

    const {
        profile,
        after,
        disabled,
        className,
        showStatusSet,
        setStatusSize = 'medium',
    } = props;

    const { t } = useI18n('components.Atoms.User');

    const canBeOpen = !disabled && ProfileStore.id === profile?.id && photoViewer !== 'true';

    useEffect(() => {
        SubscriptionStore.subscribeToProfileStatus(profile?.id);
    }, [ profile?.id ]);

    return (
        <div className={[
            className,
            'flex items-center gap-1',
            after && canBeOpen ? 'cursor-pointer' : '',
        ].join(' ')}>
            <span className="overflow-ellipsis">
                {profile?.fullName}
            </span>

            <If is={canBeOpen || !!profile?.emojiTitle}>
                <TextTooltip placement="right" hidden={!profile?.title?.trim()} text={(
                    <>
                        <If is={!!profile?.titleState?.manualExpiredAt}>
                            <b className="fs-9">
                                {t('expires', {
                                    time: Time.parseRelative(profile?.titleState?.manualExpiredAt).toLowerCase(),
                                })}
                            </b>
                            <br/>
                        </If>

                        {profile?.title}
                    </>
                )}>
                    <div className={[
                        'flex items-center',
                        canBeOpen ? 'cursor-pointer' : '',
                    ].join(' ')} onClick={(e) => {
                        if (canBeOpen) {
                            ConfigStore.isDesktop
                                ? Router.pushModal('profile-set-status')
                                : Router.pushPage('/settings', { scrollToSetStatus: 'true' });

                            e.stopPropagation();
                        }
                    }}>
                        {profile?.emojiTitle}

                        <If is={!profile?.emojiTitle && canBeOpen && !!showStatusSet}>
                            <If is={setStatusSize === 'small'}>
                                <Icon20SmileAddOutline className="inline" fill="var(--icon_secondary)"/>
                            </If>

                            <If is={setStatusSize === 'medium'}>
                                <Icon24SmileAddOutline className="inline" fill="var(--icon_secondary)"/>
                            </If>
                        </If>
                    </div>
                </TextTooltip>
            </If>
        </div>
    );
});

/**
 * User title
 * @returns {JSX.Element}
 * @constructor
 */
const UserTitle = observer((props: UserTitleProps) => {
    return (
        <>
            {props.profile?.title}
        </>
    );
});

/**
 * User cell
 * @returns {JSX.Element}
 * @constructor
 */

const UserCell = observer((props: UserCellProps) => {

    const {
        mode,
        userId,
        profile,
        actions,
        after,
        onClick,
        className,
        onlineColor,
        hasHover,
        dataTest,
        hasActive,
        lastOnlineAt,
        afterElements = {},
        subtitleMode = 'customText',
        disabled = false,
        avatarSize = 36,
        avatarProps = {},
        nameProps = {},
    } = props;

    const { title, fullName, avatar, sex } = profile || {};

    const subtitleText = props.subtitle || props.caption || title || '';

    const subtitle = (
        (subtitleMode === 'lastOnlineAt' || !`${subtitleText}`?.trim())
            ? <LastOnlineText userId={userId} sex={sex!} lastOnlineAt={lastOnlineAt}/>
            : subtitleText
    );

    switch (mode) {
        case 'div':
            return (
                <div className="flex items-center gap-2">
                    <UserAvatar userId={userId}
                                size={avatarSize}
                                name={profile?.fullName || ''}
                                src={avatar?.medium || avatar?.small || ''}/>

                    <div className="flex flex-col">
                        <Text weight="regular" className="!flex text-primary line-clamp-1 gap-2 items-center">
                            <User.Name profile={profile}/>

                            <>{afterElements?.userName}</>
                        </Text>

                        <Caption className="text-secondary line-clamp-1">
                            <LastOnlineText userId={userId} lastOnlineAt={lastOnlineAt}/>
                        </Caption>
                    </div>
                </div>
            );

        case 'simple':
            return (
                <SimpleCell after={after}
                            onClick={onClick}
                            hasHover={hasHover}
                            disabled={disabled}
                            data-test={dataTest}
                            data-value={userId}
                            hasActive={hasActive}
                            className={className}
                            subtitle={subtitle}
                            before={(<UserAvatar size={avatarSize}
                                                 {...avatarProps as UserAvatarProps}
                                                 userId={userId}
                                                 name={fullName || ''}
                                                 badgeColor={onlineColor || ''}
                                                 src={avatar?.medium || avatar?.small || ''}
                                                 className={[ 'mr-3', avatarProps.className ].join(' ')}/>)}>
                    <User.Name {...nameProps} profile={profile}
                               after={!!after}
                               disabled={disabled}
                               dataTest={[ dataTest, 'name' ].join(':')}/>
                </SimpleCell>
            );

        case 'rich':
            return (
                <RichCell onClick={onClick}
                          disabled={disabled}
                          data-test={dataTest}
                          data-value={userId}
                          className={className}
                          caption={subtitle}
                          actions={actions}
                          before={(
                              <UserAvatar size={avatarSize}
                                          {...avatarProps as UserAvatarProps}
                                          userId={userId}
                                          name={fullName || ''}
                                          src={avatar?.medium || ''}
                                          className={[ 'mt-2', avatarProps.className ].join(' ')}/>
                          )}>
                    <Link pushPage={{ id: '/@:userId([0-9_A-Za-z]+)', params: { userId: `${userId}` } }}>
                        <div className="w-fit">
                            <User.Name {...nameProps} profile={profile} disabled={disabled}/>
                        </div>
                    </Link>
                </RichCell>
            );
    }
});


const User = {
    Cell: UserCell,
    Name: UserName,
    Title: UserTitle,
};


export { User };


