/**
 * SplitButton
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactNode } from 'react';

import { Tappable, TappableProps } from '@exode.ru/vkui';


interface Props extends TappableProps {
    before?: ReactNode;
    text?: ReactNode;
}


const SplitButton = (props: Props) => {

    const {
        text,
        before,
        ...rest
    } = props;

    return (
        <Tappable {...rest} className={[
            'flex items-center justify-center',
            'bg-accent text-white vk-rounded select-none',
            rest.disabled ? 'opacity-80' : '',
        ].join(' ')}>
            <div className="rounded-full px-[6px]">
                {before}
            </div>

            <div className="h-[32px] w-[1.5px] bg-content"/>

            <div className="vkuiText--sizeY-compact pl-2.5 pr-3.5 font-vk-common fs-14">
                {text}
            </div>
        </Tappable>
    );
};


export { SplitButton };
