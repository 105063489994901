/**
 * PasswordFormView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Form, Formik } from 'formik';
import { createValidator } from 'class-validator-formik';

import { UserPasswordPageStore } from '../store';
import { observer, Page, useStore } from '@/pages/Core';

import { UpdateAuthedPasswordInput } from '@/libs/class-validator';

import { Field, If } from '@/cutils';
import { useI18n, useI18nStatic } from '@/hooks/core';

import { Button, FormItem, Header, SizeType } from '@exode.ru/vkui';

import { PasswordInput } from '@/components/Atoms/PasswordInput';

import { mStickyBottom2PbSafe } from '@/styles/modules/button';


interface Props {
    mode?: 'page' | 'modal';
}


const PasswordFormView = observer((props: Props) => {

    const { mode = 'page' } = props;

    const { t } = useI18n('pages.User.Password');
    const { t: tStatic } = useI18nStatic('static.pages.user.password.authed');

    const { action, store, input } = useStore(UserPasswordPageStore);

    return (
        <Page.Row fullHeight>
            <If is={mode === 'page'}>
                <Header mode="secondary">
                    {t('changePassword')}
                </Header>
            </If>

            <Formik enableReinitialize
                    validateOnBlur
                    validateOnChange
                    validateOnMount
                    initialValues={{ ...input }}
                    validate={createValidator(UpdateAuthedPasswordInput)}
                    onSubmit={(__, formik) => store.updateAuthed(formik, tStatic)}>
                {({
                      errors,
                      touched,
                      isValid,
                      setTouched,
                      isSubmitting,
                      values: { password, confirmPassword },
                  }) => (
                    <Form className="flex flex-col flex-1 mt-4">
                        <FormItem top={t('newPassword')}
                                  className="pt-0.5 pb-0"
                                  status={Field.status(errors, touched, 'password')}
                                  bottom={Field.message(errors, touched, 'password')}>
                            <PasswordInput initialShow
                                           name="password"
                                           value={password}
                                           sizeY={SizeType.REGULAR}
                                           onInput={action(store.onInput)}
                                           placeholder={t('enterNewPasswordPlaceholder')}
                                           onBlur={() => {
                                               setTouched({
                                                   password: true,
                                                   confirmPassword: true,
                                               });
                                           }}/>
                        </FormItem>

                        <FormItem className="pt-1"
                                  top={t('newPasswordConfirmation')}
                                  status={Field.status(errors, touched, 'confirmPassword')}
                                  bottom={Field.message(errors, touched, 'confirmPassword')}>
                            <PasswordInput initialShow
                                           name="confirmPassword"
                                           value={confirmPassword}
                                           sizeY={SizeType.REGULAR}
                                           onInput={action(store.onInput)}
                                           placeholder={t('enterAgainPlaceholder')}
                                           onBlur={() => {
                                               setTouched({
                                                   password: true,
                                                   confirmPassword: true,
                                               });
                                           }}/>
                        </FormItem>

                        <FormItem className={`${mStickyBottom2PbSafe}`}>
                            <Button size="l"
                                    stretched
                                    type="submit"
                                    className="content-shadow"
                                    loading={isSubmitting}
                                    disabled={isSubmitting || !isValid}>
                                {t('change')}
                            </Button>
                        </FormItem>
                    </Form>
                )}
            </Formik>
        </Page.Row>
    );
});


export { PasswordFormView };
