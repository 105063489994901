/**
 * TaskResultWidget
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { ReactElement, ReactNode } from 'react';

import { If } from '@/cutils';
import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';

import { TaskViewMode } from '@/types/task';
import { TaskAnswerStatus, TaskCommonFragmentFragment, TaskResultFragmentFragment } from '@/codegen/graphql';

import { IconButton } from '@mui/material';
import { SimpleCell, SimpleCellProps } from '@exode.ru/vkui';
import { Icon20Arrows2LeftRightInward, Icon20ReplyOutline, Icon20ViewOutline } from '@vkontakte/icons';

import { Accordion } from '@/components/Atoms/Accordion';
import { TaskBuilder } from '@/components/Task/Builder';
import { TagInlineText } from '@/components/Atoms/TagInlineText';


interface Props {
    mode: 'manage' | 'preview';
    icon: ReactElement;
    title: ReactNode;
    subtitle: string;
    tasks: TaskCommonFragmentFragment[];
    answers: TaskResultFragmentFragment[];
    isExpanded: boolean;
    onExpand: (expanded: boolean) => void;
    points?: number;
    maxPoints?: number;
    completionRate?: number;
    handleReply?: () => void;
    simpleCellProps?: Partial<SimpleCellProps>;
    managePointRenderer?: (answer: TaskResultFragmentFragment) => ReactNode;
}


const TaskResultWidget = (props: Props) => {

    const {
        mode,
        icon,
        title,
        tasks,
        answers,
        subtitle,
        onExpand,
        isExpanded,
        handleReply,
        points = 0,
        maxPoints = 0,
        completionRate,
        simpleCellProps,
        managePointRenderer = () => <></>,
    } = props;

    const { t } = useI18n('components.Widgets.Task.TaskResultWidget');

    return (
        <Page.Row separator="hide" className="w-full" innerNoVerticalPadding={0}>
            <div className="d-thin-border-bottom bg-hover">
                <SimpleCell before={icon}
                            hasHover={false}
                            hasActive={false}
                            className="cursor-pointer"
                            onClick={(e) => {
                                e.stopPropagation();

                                onExpand(!isExpanded);
                            }}
                            after={(
                                <div className="flex items-center gap-2">
                                    {simpleCellProps?.after}

                                    {handleReply && (
                                        <IconButton className="vk-shadow bg-content" onClick={(e) => {
                                            e.stopPropagation();

                                            handleReply();
                                        }}>
                                            <Icon20ReplyOutline fill="var(--icon_secondary)"/>
                                        </IconButton>
                                    )}
                                </div>
                            )}
                            subtitle={(
                                <div className="flex items-start gap-2 m:flex-col">
                                    <TagInlineText size="m"
                                                   gradient={points === maxPoints ? 'green' : 'blue'}
                                                   content={[
                                                       `${points} / ${maxPoints}`,
                                                       !_.isNil(completionRate) ? `→ ${completionRate}%` : '',
                                                   ].filter((e) => e).join(' ')}/>

                                    <If is={!!subtitle}>
                                        <div className="flex items-center gap-2">
                                            <span className="m:hidden">{'•'}</span>

                                            <span>{subtitle}</span>
                                        </div>
                                    </If>
                                </div>
                            )}>
                    {title}
                </SimpleCell>
            </div>

            <div className={[
                isExpanded ? '' : 'hidden',
                'flex flex-col justify-center w-full m:max-w-full',
            ].join(' ')}>
                {tasks.map((task, index) => {
                    const answer = _.find(answers, { task: { id: task.id } });

                    const { status, point = 0, maxPoint = 0 } = answer || {};

                    return (
                        <Accordion stopPropagation
                                   key={task.uuid}
                                   borderRadius="0px"
                                   horizontalPadding="0"
                                   iconOpen={<Icon20ViewOutline/>}
                                   iconClose={<Icon20Arrows2LeftRightInward style={{ transform: 'rotate(90deg)' }}/>}
                                   tappableClassName={[
                                       '!rounded-none thin-border-bottom bg-hover-50',
                                       'thin-border-top first:border-t-none last:border-b-none',
                                   ].join(' ')}
                                   header={(
                                       <div className="flex w-full items-center justify-around gap-2 mr-3">
                                           <span className="font-regular fs-16 flex flex-1 line-clamp-1">
                                               <span className="font-medium">
                                                   {index + 1}
                                               </span>

                                               {' • '}

                                               {task.title}
                                           </span>

                                           <If is={mode === 'manage'}>
                                               <div className="relative">
                                                   <If is={!!answer}>
                                                       <div className="-my-[8px]"
                                                            onClick={(e) => e.stopPropagation()}
                                                            style={{ '--vkui--size_field_height--compact': '30px' }}>
                                                           {answer && managePointRenderer(answer)}
                                                       </div>
                                                   </If>

                                                   <If is={!answer}>
                                                       <TagInlineText size="m"
                                                                      gradient="gray"
                                                                      content={t('withoutAnswer')}/>
                                                   </If>
                                               </div>
                                           </If>

                                           <If is={mode === 'preview'}>
                                               {([
                                                   TaskAnswerStatus.OnReview,
                                                   TaskAnswerStatus.WaitingCheck,
                                               ].includes(status as TaskAnswerStatus)
                                                   ? (
                                                       <TagInlineText size="m"
                                                                      gradient="gray"
                                                                      content={t('waitingCheck')}/>
                                                   )
                                                   : (
                                                       <TagInlineText size="m"
                                                                      onClick={(e) => e.stopPropagation()}
                                                                      content={[ point, maxPoint ].join(' / ')}
                                                                      gradient={(
                                                                          point === maxPoint
                                                                              ? 'green'
                                                                              : (point > 0 ? 'orange' : 'red')
                                                                      )}/>
                                                   ))}
                                           </If>
                                       </div>
                                   )}>
                            <div className="px-4">
                                <TaskBuilder mode={TaskViewMode.Show} task={task} show={{
                                    subMode: 'result',
                                    answer: { userAnswer: answer?.answer },
                                    pageRowProps: { className: 'card-clear-after' },
                                }}/>
                            </div>
                        </Accordion>
                    );
                })}
            </div>
        </Page.Row>
    );
};


export { TaskResultWidget };
