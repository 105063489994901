/**
 * ColorPicker styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { ColorInputPartProps } from './parts/ColorInputPart';


export const ColorInputPartContainer = styled.div<Pick<ColorInputPartProps, 'wrapperMode'>>`
    .MuiOutlinedInput-root {
        border: none;
        color: var(--text_primary);
        background: var(--input_background);
        font-family: var(--font-common), sans-serif;
        border-radius: var(--vkui--size_border_radius_paper--regular);

        box-shadow: ${({ wrapperMode }) => wrapperMode === 'shadow'
                ? 'var(--vkui--elevation3, 0 2px 24px 0 rgba(0, 0, 0, .08), 0 0 2px 0 rgba(0, 0, 0, .08))'
                : 'inset 0 0 0 var(--thin-border) var(--vkui--color_separator_primary)'
        };

        fieldset {
            border: none;
        }
    }
`;
