/**
 * App index
 *
 * @author: exode <hello@exode.ru>
 */

import 'moment/locale/ru';

import { Buffer } from 'buffer';

import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';

import { App } from './App';

import { BootstrapService } from '@/services/Core/Bootstrap';
import { AnalyticsService } from '@/services/Analytics/Analytics';

import { Meta } from '@/cutils';
import { IS_DEV } from '@/root/src/env';

import './api/console';

import '@exode.ru/vkui/dist/vkui.css';
import '@exode.ru/vkui/dist/fonts.css';
import '@exode.ru/vkui/dist/unstable.css';

import 'react-mobile-cropper/dist/style.css';
import 'react-photo-view/dist/react-photo-view.css';

import '../node_modules/swiper/swiper.min.css';
import '../node_modules/swiper/modules/pagination.min.css';
import '../node_modules/swiper/modules/effect-creative.min.css';

import '@exode-team/react-phone/style.css';

import './assets/styles/index.scss';
import './assets/styles/compiled/tailwind.css';


window.Buffer = window.Buffer || Buffer;

const BootstrapApp = async () => {

    const root = document.getElementById('root');
    const meta = document.getElementById('meta');

    /**
     * Точка входа в приложение
     */
    const start = () => {
        !!window.ReactNativeWebView ? startNative() : startWeb();
        BootstrapService.register();
    };

    /**
     * Инициализация web-сайта
     */
    const startWeb = () => {
        renderCsrDom();
    };

    /**
     * Инициализация внутри native приложения
     */
    const startNative = () => {
        renderCsrDom();

        AnalyticsService.onNativeOpen();
    };

    /**
     * Компонента приложения
     * @returns {JSX.Element}
     */
    const getAppDom = () => {
        return (<App/>);
    };

    /**
     * Рендеринг приложения на клиенте
     */
    const renderCsrDom = () => {
        ReactDOM.render(getAppDom(), root, BootstrapService.ready);
        ReactDOM.render(<Meta/>, meta);
    };

    start();
};


/**
 * Start the application
 */
(async () => BootstrapApp())();

/**
 * Catch error for sentry
 */
!IS_DEV && Sentry.init({
    tracesSampleRate: 1.0,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [ Sentry.browserTracingIntegration() ],
});
