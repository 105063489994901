/**
 * SocketProvider component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement, useRef } from 'react';

import { Socket } from 'socket.io-client';

import { socket } from '@/api/socket';

import { AppGlobalSubscriptions } from '@/components/Subscriptions';


export interface ISocketContext {
    socket: Socket | null;
}

interface SocketProviderProps {
    children: ReactElement | ReactElement[];
}

/**
 * Socket context
 * @returns {ISocketContext}
 */
const SocketContext = React.createContext<ISocketContext>({ socket: null });

/**
 * Socket provider
 * @param {SocketProviderProps} props
 * @returns {JSX.Element}
 * @constructor
 */
const SocketProvider = (props: SocketProviderProps) => {

    const { children } = props;

    const socketRef = useRef<Socket>(socket);

    return (
        <SocketContext.Provider value={{ socket: socketRef.current }}>
            {/** Render all global subscriptions */}
            <AppGlobalSubscriptions/>
            {/** Then render whole app */}
            {children}
        </SocketContext.Provider>
    );
};


export { SocketProvider, SocketContext };
