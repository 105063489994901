/**
 * PinnedView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';
import { ChatService } from '@/services/Chat/Chat';
import { useChatInfoContext } from '@/hooks/apollo';

import { Caption, Text } from '@exode.ru/vkui';
import { ChatMessageItem } from '@/types/chat';
import { Icon28CancelCircleOutline, Icon28MessagePinOutline } from '@vkontakte/icons';

import { chatIconClass } from '../items/styled';


interface Props {
    messages: ChatMessageItem[];
}


const PinnedView = (props: Props) => {

    const { messages } = props;

    const { handleUnpinMessage } = useChatInfoContext();

    return (
        <If is={!!messages.length}>
            <div className={[
                'absolute top-[52px] left-0 right-0 z-[1] flex',
                'items-center justify-between bg-content thin-border py-2 px-3.5',
            ].join(' ')}>
                <div className="flex items-center">
                    <Icon28MessagePinOutline width={24} height={24} fill="var(--accent)"/>

                    <div style={{ borderLeft: '2px solid var(--accent)' }} className="pl-4 ml-4">
                        <div className="flex items-center gap-3 ">
                            <Text className="text-accent fs-13" weight="semibold">
                                ---
                            </Text>

                            <Caption className="text-subhead first-letter:capitalize" weight="regular" level="2">
                                {ChatService.parseChatDate(messages?.at(-1)?.createdAt)}
                            </Caption>
                        </div>

                        <Text className="fs-13" weight="regular">
                            {messages?.at(-1)?.text}
                        </Text>
                    </div>
                </div>

                <div onClick={handleUnpinMessage}>
                    <Icon28CancelCircleOutline fill="var(--accent)" width={24} height={24} className={chatIconClass}/>
                </div>
            </div>
        </If>
    );
};


export { PinnedView };
