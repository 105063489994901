/**
 * Constants
 *
 * @author: exode <hello@exode.ru>
 */

import { useI18n } from '@/hooks/core';


export const twoFactorHeader = (
    hasOtp: boolean,
    isMobile = false,
) => {

    const { t } = useI18n('pages.User.SetTwoFactor');

    const title = hasOtp
        ? isMobile ? t('turnOff2FA') : t('turnOff2FactorAuth')
        : isMobile ? t('turnOn2FA') : t('turnOn2FactorAuth');

    const subheader = hasOtp
        ? t('enterCodeFromAppToTurnOff')
        : t('onLoginWeWillAskPasswordAndCodeFromApp');

    return { title, subheader };
};
