/**
 * ParagraphPart
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useEffect, useRef } from 'react';

import { If } from '@/cutils';
import { useCopy } from '@/hooks/core';

import { InnerHtml } from '@/components/Atoms/InnerHtml';


const ParagraphPart = ({ data, style }: any) => {

    const ref = useRef<HTMLDivElement>(null);

    const { copy } = useCopy(true);

    const content = _.isString(data)
        ? data
        : _.isString(data?.text) ? data.text : '';

    useEffect(() => {
        ref.current?.querySelectorAll('code').forEach((code) => {
            code.classList.add('cursor-pointer');

            code.onclick = () => copy(code.innerText);
        });
    }, [ ref.current ]);

    return (
        <If is={!!content}>
            <p ref={ref} style={style} className="editor-js-paragraph fs-content">
                <InnerHtml content={content} include={[ 'timeCodes' ]}/>
            </p>
        </If>
    );
};


export { ParagraphPart };
