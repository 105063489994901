/**
 * Config types
 *
 * @author: exode <hello@exode.ru>
 */

/**
 * Существующие ключи в storage, значения - имя в production
 */
export const localStorageKeys = {
    /** Storage config ttl */
    'storage:ttl': 'storage:ttl',

    /** Promotion banners */
    'user:cookie-agree': 'user:cookie-agree',
    'promo:banners': 'promo:banners',
    'fcm-notifications:asked': 'fcm-notifications:asked',

    /** User */
    'user:language': 'user:language',
    'user:event-stream': 'user:event-stream',
    'user:auth-token': 'user:auth-token',
    'user:referral-uuid': 'user:referral-uuid',
    'preference:scheme': 'preference:scheme',

    /** Mobx store persist keys */
    'store:user-auth': 'store:user-auth',
    'store:user-profile': 'store:user-profile',
    'store:seller': 'store:seller',
    'store:shop': 'store:shop',

    /** Payment */
    'payment:selected-acquiring': 'payment:selected-acquiring',

    /** Seller */
    'seller:id': 'seller:id',
    'seller:become-partner-status': 'seller:become-partner-status',

    /** Temporary (secondary) cart like a field of 'store:shop'.cart */
    'products:cart': 'products:cart',

    /** System */
    'main-tab:uuid': 'main-tab:uuid',
    'system:event-stream': 'system:event-stream',
    'native:on-device-ready-counter': 'native:on-device-ready-counter',
    'page-error-forced-refreshed': 'page-error-forced-refreshed',

    /** Chat */
    'chat:message-draft': 'chat-message-draft',

    /** Drafts */
    'draft:faq': 'draft:faq',
    'draft:task-builder': 'draft:task-builder',
    'draft:become-partner': 'draft:become-partner',
    'draft:course-create': 'draft:course-create',
    'draft:school-user-create': 'draft:school-user-create',
    'draft:practice-answer': 'draft:practice-answer',

    /** CMS */
    'cms:event-stream': 'cms:event-stream',
} as const;

export const cookieStorageKeys = {
    'auth:restore': 'auth:restore',
} as const;


export type LocalStorageKeyType = keyof typeof localStorageKeys;
export type CookieStorageKeyType = keyof typeof cookieStorageKeys;
