/**
 * UseSigned
 *
 * @author: exode <hello@exode.ru>
 */

import { ExchangeOtpSignedInput, useSignedExchangeOtpForSignatureMutation } from '@/codegen/graphql';


export const useSigned = () => {

    const [
        _exchangeOtpForSignature,
        { loading: exchangeOtpForSignatureLoading },
    ] = useSignedExchangeOtpForSignatureMutation();

    const exchangeOtpForSignature = (
        exchange: ExchangeOtpSignedInput,
        onCompleted?: (signature: string) => void,
        onError?: () => void,
    ) => {
        return _exchangeOtpForSignature({
            variables: { exchange },
            onCompleted: ({ signedExchangeOtpForSignature }) => {
                onCompleted?.(signedExchangeOtpForSignature);
            },
            onError: (e) => {
                onError?.();
                console.error(e);
            },
        });
    };

    return {
        exchangeOtpForSignature,
        exchangeOtpForSignatureLoading,
    };
};
