/**
 * MessageReaderWrapperItem
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { InView } from 'react-intersection-observer';

import { ConfigStore } from '@/store/core/config';

import { ChatMessageItem } from '@/types/chat';
import { DocumentEvent } from '@/types/window';

import { observer, useStore } from '@/pages/Core';
import { useChatInfoContext } from '@/hooks/apollo';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';


interface Props {
    message: ChatMessageItem;
    children: ReactElement | ReactElement[];
}


const MessageReaderWrapperItem = observer((props: Props) => {

    const {
        children,
        message: { id, isMine, isRead },
    } = props;

    const { chat } = useChatInfoContext();

    const { store } = useStore(ChatDialogsPageStore);

    const onInViewChange = (inView: boolean) => {
        if (inView) {
            ConfigStore.isInInteractive
                ? store.addUnreadMessageId(id)
                : document.addEventListener(
                    DocumentEvent.SystemWindowInteracted,
                    () => store.addUnreadMessageId(id),
                    { once: true },
                );
        }
    };

    return (
        <>
            {!isMine && !isRead && !!chat?.myMember
                ? <InView onChange={onInViewChange} children={children}/>
                : children
            }
        </>
    );
});


export { MessageReaderWrapperItem };
