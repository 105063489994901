/**
 * UseCourseStart
 *
 * @author: exode <hello@exode.ru>
 */

import { Router } from '@/services/Utils/Router';

import { useProductAccessProlongAccessToFreeMutation } from '@/codegen/graphql';


export const useCourseStart = () => {

    const [ _onAccessForFree, {
        loading: onAccessForFreeLoading,
        called: onAccessForFreeCalled,
    } ] = useProductAccessProlongAccessToFreeMutation();

    const onAccessForFree = (launchId: number | undefined) => {
        if (launchId) {
            return Router.authAction(
                () => _onAccessForFree({
                    variables: { launchId },
                    onCompleted: (data) => {
                        const {
                            product,
                            product: { course },
                        } = data.productAccessProlongAccessToFree;

                        if (course?.currentLesson) {
                            Router.pushPage(
                                '/courses/:page([0-9]+)/:courseId([0-9]+)/study/:lessonId([0-9]+)',
                                {
                                    courseId: `${product.course?.id}`,
                                    lessonId: `${course.currentLesson.id}`,
                                },
                            );
                        } else {
                            Router.pushPage(
                                '/courses/:page([0-9]+)/:courseId([0-9]+)/study/empty',
                                { courseId: `${product.course?.id}` },
                            );
                        }
                    },
                }),
                { enrollAfterAuth: 'true' },
            );
        }
    };

    return {
        onAccessForFree,
        onAccessForFreeCalled,
        onAccessForFreeLoading,
    };
};
