/**
 * DetailedEdit
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { Formik } from 'formik';

import { useI18n } from '@/hooks/core';
import { AutoSaveForm } from '@/cutils';

import { DetailedTaskQuestion } from '@/shared/types';

import { TaskBuilderEditProps } from '@/components/Task/Builder';

import { MaxPointSliderPart } from './parts/MaxPointSliderPart';


interface Props extends TaskBuilderEditProps<DetailedTaskQuestion> {

}


const DetailedEdit = (props: Props) => {

    const { task, update, onInstantChange } = props;

    const { t } = useI18n('components.Task.Builder.edit');

    return (
        <Formik initialValues={_.pick(task, 'maxPoint')} onSubmit={({ maxPoint }) => update({ maxPoint })}>
            {({ handleSubmit }) => (
                <AutoSaveForm onChange={handleSubmit}
                              className="flex flex-col gap-2 px-4"
                              onInstantChange={() => onInstantChange?.()}>
                    <MaxPointSliderPart onInstantChange={onInstantChange}/>
                </AutoSaveForm>
            )}
        </Formik>
    );
};


export { DetailedEdit };
