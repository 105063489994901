/**
 * Moment QA
 *
 * @author: exode <hello@exode.ru>
 */

import moment, { Moment } from 'moment';


const weekdaysMin = 'Ya_Du_Se_Şo_Pa_Ju_Sha'.split('_');
const weekdaysShort = 'Yak_Duý_Seş_Şar_Pay_Juma_Shan'.split('_');
const monthsShort = 'yan_fev_mar_apr_may_iyun_iyul_avg_sen_okt_noy_dek'.split('_');

moment.defineLocale('qa', {
    weekdaysMin: weekdaysMin,
    monthsShort: monthsShort,
    week: { dow: 1, doy: 7 },
    weekdaysShort: weekdaysShort,
    dayOfMonthOrdinalParse: /\d{1,2}-şi/,
    ordinal: (number: number) => number + '-şi',
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'D MMMM YYYY, dddd HH:mm',
    },
    calendar: {
        sameDay: '[Bügün saat] LT [da]',
        nextDay: '[Erte] LT [da]',
        nextWeek: 'dddd [künü saat] LT [da]',
        lastDay: '[Keşe saat] LT [da]',
        lastWeek: '[Ötken] dddd [künü saat] LT [da]',
        sameElse: 'L',
    },
    relativeTime: {
        future: '%s içinde',
        past: '%s burın',
        s: 'sekund',
        ss: '%d sekund',
        m: 'bir minut',
        mm: '%d minut',
        h: 'bir saat',
        hh: '%d saat',
        d: 'bir kün',
        dd: '%d kün',
        M: 'bir ay',
        MM: '%d ay',
        y: 'bir yıl',
        yy: '%d yıl',
    },
    months: (m: Moment, format?: string) => {
        const months = {
            'nominative': 'yanvar_fevral_mart_aprel_may_iyun_iyul_avgust_sentiyabr_oktyabr_noyabr_dekabr'.split('_'),
            'accusative': 'yanvarni_fevralni_martni_aprelni_mayni_iyunni_iyulni_avgustni_sentiyabrni_oktyabrni_noyabrni_dekabrni'.split('_'),
        };

        return format === 'format'
            ? months.accusative[m.month()]
            : months.nominative[m.month()];
    },
    weekdays: (d: Moment, format?: string) => {
        const weekdays = {
            'nominative': 'Yakşanba_Duyşanba_Seşenba_Şarşenba_Payşenba_Juma_Shanba'.split('_'),
            'accusative': 'Yakşanbani_Duyşanbani_Seşenbani_Şarşenbani_Payşenbani_Jumani_Shanbani'.split('_'),
        };

        return format === 'format'
            ? weekdays.accusative[d.day()]
            : weekdays.nominative[d.day()];
    },
});
