/**
 * Use i18n
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import type { $Tuple } from 'react-i18next/helpers';
import i18n, { FlatNamespace, KeyPrefix } from 'i18next';
import { FallbackNs, useTranslation, UseTranslationOptions, UseTranslationResponse } from 'react-i18next';

import { SERVE_DOMAIN } from '@/root/src/env';

import { Language } from '@/codegen/graphql';
import { ConfigStore } from '@/store/core/config';

import { Storage } from '@/api/storage';
import { Translate } from '@/utils/Translate';
import { translateResources } from '@/libs/i18n/resources';
import { AppServeDomain, TranslateJson } from '@/shared/types';


/**
 * Default fallback language
 */
export const getFallbackLang: () => 'ru' | 'uz' = () => {
    switch (SERVE_DOMAIN) {
        case AppServeDomain.Ru:
            return 'ru';

        case AppServeDomain.Uz:
        case AppServeDomain.Biz:
            return 'uz';

        default:
            return 'ru';
    }
};

export const useI18n = <
    Ns extends FlatNamespace | $Tuple<FlatNamespace> | undefined = undefined,
    KPrefix extends KeyPrefix<FallbackNs<Ns>> = undefined,
>(
    ns?: Ns,
    options?: UseTranslationOptions<KPrefix>,
): UseTranslationResponse<FallbackNs<Ns>, KPrefix> => {
    return useTranslation(
        ns,
        {
            keyPrefix: ns && [ `${ns}`?.split('.')?.[0], `${ns}`.split('.').slice(1).join('.') ].join(':'),
            ...options,
        },
    );
};

/**
 * Alias for useI18n without parsing of auto-generating
 */
export const useI18nStatic = useI18n;

/**
 * Get translated
 * @param {Record<, any> | undefined | null} data
 */
export const getTranslated = (
    data: TranslateJson | undefined | null,
) => {
    return Translate.getTranslated(data, ConfigStore.language);
};

/**
 * Get system language
 */
const getSystemLanguage = () => {
    return (navigator.language || (navigator as any).userLanguage).substring(0, 2);
};

/**
 * Get primary languages (active by default)
 * @returns {[]}
 */
export const getPrimaryLanguages = (): Language[] => {
    return [
        Language.Ru,
        Language.Uz,
    ];
};

/**
 * Get sorted languages (most popular first)
 * @returns {[]}
 */
export const getSortedLanguages = (): Language[] => {
    return _.uniq([
        ...getPrimaryLanguages(),
        ..._.values(Language),
    ]);
};

/**
 * Get saved or system language
 * @Note: Main function to define UX language
 */
export const getLanguage: () => Language = () => {
    const saved = Storage.get('user:language');

    const platformLanguages = window.exode.common?.organization.settings.languages || [];

    const availableLanguages = !_.isEmpty(platformLanguages)
        ? platformLanguages
        : getPrimaryLanguages();

    let language = _.capitalize(saved || getSystemLanguage()) as Language;

    if (!availableLanguages.includes(language)) {
        const fallbackLanguage = _.capitalize(getFallbackLang()) as Language;

        language = availableLanguages.includes(fallbackLanguage)
            ? fallbackLanguage
            : availableLanguages[0];
    }

    return (
        translateResources[language?.toLowerCase() as keyof typeof translateResources]
            ? language
            : _.capitalize(getFallbackLang())
    ) as Language;
};

/**
 * Set language
 * @param {keyof typeof translateResources} language
 */
export const setLanguage = (language: Language) => {
    i18n.changeLanguage(language.toLowerCase());

    Storage.set('user:language', language);

    ConfigStore.merge({ language });
};
