/**
 * ChatCreationView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { ChangeEvent, useRef, useState } from 'react';

import { IS_MARKETPLACE } from '@/root/src/env';

import { ConfigStore } from '@/store/core/config';
import { observer, RouterStore } from '@/store/core/router';

import { ScrollHelper } from '@/helpers/ui';
import { useI18n, usePrompt } from '@/hooks/core';
import { ChatListViewMode } from '@/types/chat';
import { useChatCreation } from '@/hooks/apollo';
import { Router } from '@/services/Utils/Router';
import { Field, Graphql, If, Portal } from '@/cutils';

import { SearchProfileListItem } from '@/types/profile';
import { ChatType, SearchProfileQuery } from '@/codegen/graphql';

import { TextTooltip } from '@exode.ru/vkui/unstable';
import { Button, FormItem, Search } from '@exode.ru/vkui';
import { Icon20MessageAddOutline, Icon20UsersOutline } from '@vkontakte/icons';

import { InnerHtml } from '@/components/Atoms/InnerHtml';
import { Placeholder } from '@/components/Atoms/Placeholder';
import { StickyButton } from '@/components/Atoms/StickyButton';
import { UserCheckboxCell } from '@/components/Atoms/UserCheckboxCell';
import { UserListSkeleton } from '@/components/Atoms/Skeleton/UserList';
import { UserSelectedChips } from '@/components/Atoms/UserSelectedChips';
import { WindowsCustomScrollbar } from '@/components/Atoms/Styled';

import { ChatListSeparatorItem } from '@/pages/Chat/Dialog';

import { friendsByStatusListConfig } from '@/pages/Profile/Friends/views/FriendsContentView';


interface Props {
    setMode: (mode: ChatListViewMode) => void;
    backToListState: () => void;
}


const ChatCreationView = observer((props: Props) => {

    const { t } = useI18n('pages.Chat.Dialog');

    const { setMode, backToListState } = props;

    const minGroupMembers = 1;
    const membersListRef = useRef<HTMLDivElement | null>(null);

    const [ searchName, setSearchName ] = useState('');
    const [ memberProfiles, setMemberProfiles ] = useState<SearchProfileListItem[]>([]);

    const { createChat, createChatLoading } = useChatCreation();

    const tooltipMessages = [
        memberProfiles.length < minGroupMembers
            ? t('selectAtLeast2People')
            : '',
    ].filter((e) => e);

    const { openPrompt: openChatPrompt } = usePrompt({
        title: t('createChatGroup'),
        isLoading: createChatLoading,
        subtitle: t('enterNameForGroup'),
        children: (
            <FormItem className="p-0" bottom={(
                t('countOfSelectedMembers', { membersCount: memberProfiles.length })
            )}>
                <UserSelectedChips placement="modal"
                                   ref={membersListRef}
                                   memberProfiles={memberProfiles}
                                   setMemberProfiles={setMemberProfiles}
                                   scrollWrapperClassName="max-h-[250px]"
                                   className="thin-border vk-rounded p-1.5 bg-hover"/>
            </FormItem>
        ),
        prompt: {
            mode: 'input',
            required: true,
            minLength: 1,
            maxLength: 100,
            placeholder: t('groupName'),
        },
        buttons: { confirm: { text: t('create') } },
        onConfirm: ({ prompt: name }) => {
            createChat({
                chat: {
                    name,
                    memberUserIds: _.map(memberProfiles, 'user.id'),
                    type: ChatType.PrivateGroup,
                },
                onCompleted: ({ id }) => {
                    setSearchName('');
                    setMemberProfiles([]);
                    setMode('list');

                    Router.replacePage('/chat', { chatId: `${id}` });
                },
            });
        },
    });

    return (
        <>
            <Portal id="page:chat:list-panel:search">
                <Search key="static-key"
                        value={searchName}
                        data-test="chat.create.search"
                        className="p-0 pl-[2px] input:pr-0"
                        placeholder={t('whoYouWantToInvite?')}
                        autoFocus={!RouterStore.hasOverlay && (ConfigStore.isDesktop || !!searchName)}
                        onKeyDown={(e) => {
                            if (e.key === 'Escape') {
                                backToListState();
                                (e.target as HTMLInputElement)?.blur();
                            }
                        }}
                        onChange={(Field.transform.ignoreSpaceOnChange(
                            (e: ChangeEvent<HTMLInputElement>) => setSearchName(e.target.value),
                        ))}
                        before={(
                            <div className="min-w-[20px]">
                                <Icon20MessageAddOutline className="-mr-1"/>
                            </div>
                        )}/>
            </Portal>

            <UserSelectedChips placement="page"
                               ref={membersListRef}
                               memberProfiles={memberProfiles}
                               setMemberProfiles={setMemberProfiles}/>

            <WindowsCustomScrollbar className="overflow-y-auto h-[calc(100%_-_44px)] relative">
                <SearchProfileQuery skip={IS_MARKETPLACE && !searchName?.trim()} children={(result) => (
                    <>
                        <Graphql.Loading result={result}>
                            <UserListSkeleton baseAvatarSize={50}
                                              listClassName="gap-[1px]"
                                              itemClassName="px-2.5 my-[6px]"/>
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ profileFindMany: { items } }) => (
                                <>
                                    {items?.map((profile) => {
                                        const isMember = !!_.find(memberProfiles, { id: profile.id });

                                        const onChange = () => {
                                            setMemberProfiles(
                                                (prevState) => isMember
                                                    ? prevState.filter(({ id }) => id !== profile.id)
                                                    : [ ...prevState, profile ],
                                            );

                                            setImmediate(() => ScrollHelper.scrollToBottom(membersListRef, true));
                                        };

                                        return (
                                            <UserCheckboxCell key={profile.id}
                                                              rounded={false}
                                                              profile={profile}
                                                              user={profile.user}
                                                              checked={isMember}
                                                              onChange={onChange}/>
                                        );
                                    })}

                                    <If is={_.isEmpty(items)}>
                                        <Placeholder iconSize={72}
                                                     innerPadding="12px"
                                                     header={t('emptyCreateGroupPlaceholder')}
                                                     className="my-auto m:pt-0 h-[calc(100%_-_52px)]"
                                                     icon={<Icon20UsersOutline className="text-accent opacity-[0.85]"/>}
                                                     content={(
                                                         <span className="m:w-full w-9/10 flex mx-auto">
                                                             {t('youCanCreateGroupPlaceholder')}
                                                         </span>
                                                     )}/>
                                    </If>
                                </>
                            )}
                        </Graphql.Success>

                        <Graphql.Error result={result}/>
                    </>
                )} variables={{
                    ...friendsByStatusListConfig,
                    list: {
                        skip: 0,
                        take: 20,
                    },
                    filter: {
                        search: searchName,
                        user: {
                            active: true,
                            search: searchName,
                        },
                    },
                }}/>

                <If is={!IS_MARKETPLACE && !searchName?.trim()}>
                    <ChatListSeparatorItem caption={t('useSearchAbove')} className="text-center fs-10 thin-border-top"/>
                </If>

                <If is={IS_MARKETPLACE && !searchName?.trim()}>
                    <Placeholder iconSize={72}
                                 innerPadding="12px"
                                 header={t('groupCreation')}
                                 className="my-auto m:pt-0 h-[calc(100%_-_52px)]"
                                 icon={<Icon20UsersOutline className="text-accent opacity-[0.85]"/>}
                                 content={(
                                     <span className="m:w-full w-9/10 flex mx-auto">
                                         {t('searchAndSelectUsers')}
                                     </span>
                                 )}/>
                </If>
            </WindowsCustomScrollbar>

            <StickyButton className={[
                '!mt-0 m:w-[calc(100vw_-_2rem)] m:fixed m:bottom-tabbar',
                '!z-0 m:!z-10 thin-border-top thin-border-bottom border-b-transparent d:px-2.5 py-2.5 d:pl-[12px]',
            ].join(' ')}>
                <TextTooltip placement="top" hidden={tooltipMessages?.length === 0} text={(
                    <InnerHtml content={tooltipMessages.join('<br/>')}/>
                )}>
                    <Button size="m"
                            stretched
                            loading={createChatLoading}
                            data-test="chat.create.button"
                            onClick={() => openChatPrompt()}
                            disabled={memberProfiles.length < minGroupMembers || createChatLoading}>
                        {(memberProfiles.length < minGroupMembers
                                ? t('selectMembers')
                                : t('createGroup', { membersCount: memberProfiles.length })
                        )}
                    </Button>
                </TextTooltip>
            </StickyButton>

            <div className="hidden m:flex h-[var(--tabbar\_height)]"/>
        </>
    );
});


export { ChatCreationView };
