/**
 * UseChatInfo
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { useRef, useState } from 'react';

import { useStore } from '@/pages/Core';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';

import { useConfirmDeleteMessages } from '@/hooks/apollo';

import { RoutePathType } from '@/router/paths';
import { useChatFindOneInfoQuery } from '@/codegen/graphql';

import { useChatContext } from '@/pages/Chat/Dialog/contexts/ChatContext';


interface Props {
    chatId: number | string | undefined;
    backOrTo?: RoutePathType;
    personalUserId?: number;
}


export const useChatInfoContext = () => {
    const chatContext = useChatContext();

    return chatContext || {} as ReturnType<typeof useChatInfo>;
};


export const useChatInfo = (props: Props) => {

    const { backOrTo, personalUserId } = props;

    const chatId = props.chatId ? +props.chatId : 0;

    const {
        data: chatInfo,
        error: chatInfoError,
        loading: chatInfoIsLoading,
    } = useChatFindOneInfoQuery({
        skip: !chatId,
        variables: { chatId },
    });

    const chat = chatInfo?.chatFindOne;

    const [ createChatIsLoading, setCreateChatIsLoading ] = useState(false);

    const myMemberIsBlocked = chat?.myMember?.blocked;
    const companionMemberIsBlocked = chat?.personalCompanionMember?.blocked;

    const { store, input } = useStore(ChatDialogsPageStore);

    const { openConfirmDeleteMessages } = useConfirmDeleteMessages(+chatId);

    const inputRef = useRef<HTMLTextAreaElement | null>(null);
    const chatMessagesRef = useRef<HTMLDivElement | null>(null);

    const resetSelectedMessage = () => (
        store.setSelectedMessages({})
    );

    const cancelEdit = () => {
        store.setInput('isEditing', 0);
        store.restoreDraftMessage(+chatId);

        resetSelectedMessage();
    };

    const handleDeleteMessage = () => {
        openConfirmDeleteMessages();
    };

    const handleClearReply = (chatId: number | string) => {
        store.setInput('replyMessage', _.omit(input.replyMessage, `${chatId}`));
    };

    return {
        chat,
        chatId,
        backOrTo,
        inputRef,
        chatInfoError,
        personalUserId,
        chatMessagesRef,
        myMemberIsBlocked,
        chatInfoIsLoading,
        companionMemberIsBlocked,
        cancelEdit,
        handleClearReply,
        handleDeleteMessage,
        resetSelectedMessage,
        createChatIsLoading,
        setCreateChatIsLoading,
        sendMessageIsBlocked: myMemberIsBlocked || companionMemberIsBlocked || chat?.archived,
        personalCompanionIsBlocked: chat?.personalCompanionMember?.blocked,
        scrollToBottom: (smooth = false) => {
            chatMessagesRef.current?.scrollTo({
                left: 0,
                top: chatMessagesRef.current.scrollHeight,
                behavior: smooth ? 'smooth' : undefined,
            });
        },
        handleEditMessage: () => {
            if (!chatId) {
                return;
            }

            store.mergeInput({
                replyMessage: _.omit(input.replyMessage, chatId),
            });

            store.mergeInput({
                message: store.state.selectedMessages[+chatId][0].text ?? '',
                isEditing: store.state.selectedMessages[+chatId][0].id,
            });

            resetSelectedMessage();

            inputRef.current?.focus();
        },
        handlePinMessage: () => {},
        handleUnpinMessage: () => {},
        handleImportantMessage: () => {},
        handleReplyMessage: () => {
            if (!chatId || !store.state.selectedMessages[+chatId]) {
                return;
            }

            inputRef.current?.focus();
            store.setInput('isEditing', 0);

            store.setInput('replyMessage', {
                ...input.replyMessage,
                [chatId]: store.state.selectedMessages[+chatId]?.[0],
            });

            resetSelectedMessage();
        },
    };
};
