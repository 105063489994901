/**
 * ColorInputPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { MuiColorInput, MuiColorInputProps } from 'mui-color-input';

import { ColorInputPartContainer } from '../ColorPicker.styled';


export interface ColorInputPartProps extends MuiColorInputProps {
    wrapperMode?: 'shadow' | 'border';
    dataTest?: string;
}


const ColorInputPart = (props: ColorInputPartProps) => {

    const {
        dataTest,
        format = 'hex',
        wrapperMode = 'border',
    } = props;

    return (
        <ColorInputPartContainer wrapperMode={wrapperMode}>
            <MuiColorInput format={format}
                           data-test={dataTest}
                           isAlphaHidden={true}
                           placeholder="#000000"
                           PopoverProps={{
                               PaperProps: {
                                   sx: {
                                       mt: '5px',
                                   },
                               },
                           }} {...props}/>
        </ColorInputPartContainer>
    );
};


export { ColorInputPart };
