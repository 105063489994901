/**
 * SubjectCategoriesTooltip
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { GqlResult } from '@/types/graphql';

import { QuestionTooltip } from '@/components/Atoms/QuestionTooltip';

import { TournamentFindOneQueryResult } from '@/codegen/graphql';


interface Props {
    children: ReactElement;
    categories: GqlResult<TournamentFindOneQueryResult>['tournamentFindOne']['categories'];
}


const SubjectCategoriesTooltip = (props: Props) => {

    const { children, categories } = props;

    return (
        <QuestionTooltip icon={children}
                         placement="bottom"
                         appearance="black"
                         shown={categories?.length === 0 ? false : undefined}
                         subheadClassName="max-w-[180px] p-2 flex flex-col gap-1"
                         subheadElement={(
                             <>
                                 {categories?.map(({ subjectCategory: { name, id } }) => (
                                     <li className="list-none" key={id}>
                                         {name}
                                     </li>
                                 ))}
                             </>
                         )}/>
    );
};


export { SubjectCategoriesTooltip };
