/**
 * UseUpdateUserManage
 *
 * @author: exode <hello@exode.ru>
 */

import { UpdateUserInput, useUserManageUpdateMutation } from '@/codegen/graphql';

import { Notify } from '@/cutils';
import { useI18n } from '@/hooks/core';


export const useUpdateUserManage = () => {

    const { t } = useI18n('hooks.apollo.user');

    const [ _update, { loading: updateUserLoading, error: updateUserError } ] = useUserManageUpdateMutation();

    const updateUserManage = async (
        { userId, ...rest }: UpdateUserInput & { userId: number },
    ) => {
        await _update({
            variables: { userId, userInput: { ...rest } },
            onError(error) {
                console.error(error);
            },
            onCompleted() {
                Notify.toast.success(t('changesSavedSuccessfully'));
            },
        });
    };

    return {
        updateUserManage,
        updateUserError,
        updateUserLoading,
    };
};
