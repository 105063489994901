/**
 * TooltipsView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { Icon16HelpOutline } from '@vkontakte/icons';

import { InnerHtml } from '@/components/Atoms/InnerHtml';
import { QuestionTooltip } from '@/components/Atoms/QuestionTooltip';


const PriceTagsTooltip = () => {

    const { t } = useI18n('pages.Manage.Course.Launches.Pricing.views');

    return (
        <QuestionTooltip subheadClassName="max-w-[280px] p-2"
                         subheadElement={<InnerHtml content={t('pricingTagsExamples')}/>}
                         icon={<Icon16HelpOutline className="cursor-pointer"/>}/>
    );
};

const PriceAccessPeriodTooltip = () => {

    const { t } = useI18n('pages.Manage.Course.Launches.Pricing.views');

    return (
        <QuestionTooltip subheadClassName="max-w-[180px] p-2"
                         subheadElement={t('productAccessPeriod')}
                         icon={<Icon16HelpOutline className="cursor-pointer"/>}/>
    );
};


export { PriceTagsTooltip, PriceAccessPeriodTooltip };
