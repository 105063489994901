/**
 * ChecklistContentElementEdit
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';
import { nanoid } from 'nanoid';

import React from 'react';

import { FastField, FastFieldProps, FieldArray, Formik } from 'formik';

import { useI18n, useOnceUpdate } from '@/hooks/core';

import { ChecklistContent } from '@/shared/types';

import { AutoSaveForm, Field, If } from '@/cutils';
import { ContentElementType } from '@/codegen/graphql';

import { Icon20AddCircle, Icon24DeleteOutline } from '@vkontakte/icons';
import { Button, Checkbox, FormItem, Input, List } from '@exode.ru/vkui';

import { ConfirmButton } from '@/components/Atoms/ConfirmButton';
import { getContentElementContextValue } from '@/components/ContentElement';

import { BlockHeaderPart, BlockWrapperPart } from '../parts/edit';

import { ContentElementEditProps } from '../interfaces';


interface Props extends ContentElementEditProps<ChecklistContent> {

}


const ChecklistContentElementEdit = (props: Props) => {

    const {
        list,
        loading,
        onEdit,
        update,
        onDelete,
        contentElement,
    } = props;

    const { t } = useI18n('components.ContentElement.edit');

    const {
        edit: {
            withTitle = true,
            blockHeaderProps = {},
            blockWrapperProps = {},
            BlockWrapper = BlockWrapperPart,
        } = {},
    } = getContentElementContextValue(ContentElementType.Checklist);

    const {
        initialValues,
        handleUpdateSubmit,
    } = useOnceUpdate(
        _.pick(contentElement, [ 'title', 'content' ]),
        async (values) => update?.(values),
    );

    return (
        <Formik initialValues={initialValues} onSubmit={handleUpdateSubmit}>
            {({
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  values: {
                      title,
                      content: { items = [] },
                  },
              }) => (
                <AutoSaveForm onChange={() => handleSubmit()} onInstantChange={() => onEdit?.()}>
                    <BlockWrapper index={list?.index} {...blockWrapperProps}>
                        <BlockHeaderPart list={list}
                                         loading={loading}
                                         onDelete={onDelete}
                                         type={ContentElementType.Checklist}
                                         {...blockHeaderProps}/>

                        <If is={withTitle}>
                            <FormItem className="px-0 pt-0.5" top={t('blockHeader')}>
                                <Input name="title"
                                       value={title || ''}
                                       data-test="ce.checklist.title"
                                       placeholder={t('enterBlockHeader')}
                                       onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                            </FormItem>
                        </If>

                        <FieldArray name="content.items">
                            {({ push, remove }) => (
                                <List className="flex flex-col gap-3.5 pb-4">
                                    {items?.map((item, index) => (
                                        <div key={item.uuid}
                                             className="flex items-center gap-3 first:mt-2 min-h-[40px] w-full">
                                            <FastField name={`content.items[${index}].defaultChecked`}>
                                                {({ field }: FastFieldProps) => (
                                                    <Checkbox {...field} disabled={item.disabled}
                                                              checked={item.defaultChecked}
                                                              onChange={(e) => {
                                                                  setFieldValue(
                                                                      `content.items[${index}].defaultChecked`,
                                                                      e.target.checked,
                                                                  );
                                                              }}/>
                                                )}
                                            </FastField>

                                            <FormItem className="p-0 flex-1">
                                                <div className="flex items-center gap-2">
                                                    <FastField name={`content.items[${index}].text`}>
                                                        {({ field }: FastFieldProps) => (
                                                            <Input {...field} className="w-full"
                                                                   value={item.text || ''}
                                                                   placeholder={t('enterText')}
                                                                   autoFocus={!item.text && index === items?.length - 1}/>
                                                        )}
                                                    </FastField>
                                                </div>
                                            </FormItem>

                                            <If is={items?.length > 1}>
                                                <div className="bg-input rounded-full thin-border">
                                                    <ConfirmButton className="opacity-75 hover:opacity-100"
                                                                   initialIcon={(
                                                                       <Icon24DeleteOutline fill="var(--icon_secondary)"/>
                                                                   )}
                                                                   callback={() => {
                                                                       remove(index);
                                                                       handleSubmit();
                                                                   }}/>
                                                </div>
                                            </If>
                                        </div>
                                    ))}

                                    <div className="flex justify-center">
                                        <Button size="l"
                                                mode="tertiary"
                                                before={<Icon20AddCircle/>}
                                                data-test="ce.checklist.add-option"
                                                onClick={() => {
                                                    const option: ChecklistContent['items'][number] = {
                                                        uuid: nanoid(),
                                                        text: '',
                                                        defaultChecked: false,
                                                        disabled: false,
                                                    };

                                                    push(option);
                                                    handleSubmit();
                                                }}>
                                            {t('addOption')}
                                        </Button>
                                    </div>
                                </List>
                            )}
                        </FieldArray>
                    </BlockWrapper>
                </AutoSaveForm>
            )}
        </Formik>
    );
};


export { ChecklistContentElementEdit };
