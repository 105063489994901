/**
 * SessionMetaProvider component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { createContext, FC, useEffect, useLayoutEffect, useState } from 'react';

import { APP_VERSION, IS_DEV, IS_NATIVE, IS_WEB, LAUNCHER } from '@/root/src/env';

import { ConfigStore } from '@/store/core/config';

import { i18n } from '@/libs/i18n';
import { useLocation } from '@/router/index';

import { Storage } from '@/api/storage';
import { DocumentEvent } from '@/types/window';
import { observer, UserAuthStore } from '@/store/user/auth';

import { UpdateMetaSessionInput, useUpdateSessionMetaMutation } from '@/codegen/graphql';


export interface SessionContext {
    permission: NotificationPermission;
    notificationRequested: boolean;
    getWebFcmToken: () => void;
}


export const SessionMetaContext = createContext<SessionContext>({
    permission: 'default',
    notificationRequested: false,
    getWebFcmToken: () => {},
});


export const SessionMetaProvider: FC = observer((props) => {

    const { route: { pageId } } = useLocation();

    const getPermission = () => {
        return 'Notification' in window
            ? Notification.permission
            : 'default';
    };

    const [ updateSessionMeta ] = useUpdateSessionMetaMutation();
    const [ permission, setPermission ] = useState(getPermission());

    const [ notificationRequested, setNotificationRequested ] = useState(
        !('Notification' in window)
        || [ 'granted' ].includes(getPermission())
        || IS_DEV
        || !IS_WEB
        || Storage.get('fcm-notifications:asked'),
    );

    const getWebFcmToken = () => {
        const event = new CustomEvent(DocumentEvent.FcmWebGetToken);

        document.dispatchEvent(event);

        /** Remove delay on click */
        setNotificationRequested(true);
        Storage.set('fcm-notifications:asked', true);
    };

    const partialSessionMeta: () => UpdateMetaSessionInput = () => ({
        launcher: LAUNCHER,
        appLocation: pageId,
        appVersion: APP_VERSION,
        userAgent: navigator.userAgent,
        language: ConfigStore.language,
        timezone: -new Date().getTimezoneOffset() / 60,
    });

    const onAuthStateChanged = () => {
        /** Here we can handle user from event */
    };

    const onReceiveToken = async (e: any) => {
        const { token } = e?.detail || {};

        if (!notificationRequested) {
            setNotificationRequested(true);
        }

        if (!UserAuthStore.isLoggedIn) {
            return;
        }

        await updateSessionMeta({
            variables: {
                sessionMeta: {
                    ...partialSessionMeta(),
                    fcmToken: token,
                },
            },
        });
    };

    const checkPermission = async () => {
        try {
            const status = await navigator.permissions.query({
                name: 'notifications',
            });

            const updatePermission = () => {
                setPermission(getPermission());
            };

            status.addEventListener('change', updatePermission);

            return () => {
                status.removeEventListener('change', updatePermission);
            };
        } catch (err) {}
    };

    const onDeclinedToken = () => {
        setNotificationRequested(true);
    };

    const handleUpdateSessionMeta = () => {
        if (!UserAuthStore.isLoggedIn || !ConfigStore.isMainTab) {
            return;
        }

        setImmediate(async () => {
            try {
                await updateSessionMeta({
                    variables: {
                        sessionMeta: partialSessionMeta(),
                    },
                });
            } catch (e) {}
        });
    };

    useEffect(() => {
        i18n.on('languageChanged', handleUpdateSessionMeta);
        document.addEventListener(DocumentEvent.AuthStateChanged, onAuthStateChanged);
        document.addEventListener(DocumentEvent.FcmWebTokenReceived, onReceiveToken);
        document.addEventListener(DocumentEvent.FcmWebTokenDeclined, onDeclinedToken);

        if (IS_NATIVE) {
            document.addEventListener(DocumentEvent.FcmNativeTokenReceived, onReceiveToken);
        }

        /** Получили разрешение на запрос */
        if (notificationRequested) {
            getWebFcmToken();
        }

        return () => {
            i18n.off('languageChanged', handleUpdateSessionMeta);
            document.removeEventListener(DocumentEvent.AuthStateChanged, onAuthStateChanged);
            document.removeEventListener(DocumentEvent.FcmWebTokenReceived, onReceiveToken);
            document.removeEventListener(DocumentEvent.FcmWebTokenDeclined, onDeclinedToken);

            if (IS_NATIVE) {
                document.removeEventListener(DocumentEvent.FcmNativeTokenReceived, onReceiveToken);
            }
        };
    }, [ notificationRequested ]);

    useEffect(() => {
        checkPermission();
    }, []);

    useLayoutEffect(() => {
        handleUpdateSessionMeta();
    }, [ pageId ]);

    return (
        <SessionMetaContext.Provider value={{
            permission,
            getWebFcmToken,
            notificationRequested,
        }}>
            {props.children}
        </SessionMetaContext.Provider>
    );
});


