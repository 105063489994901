/**
 * Task helpers
 *
 * @author: exode <hello@exode.ru>
 */

import * as _ from 'lodash';


export const getTaskCompletionStat = (
    tasks: {
        id: number;
        maxPoint: number;
    }[],
    answers: {
        point: number;
        maxPoint: number;
        task: { id: number };
    }[],
) => {
    const result = {
        pointsAmount: 0,
        maxPointsAmount: 0,
    };

    for (const task of tasks) {
        const answer = _.find(answers, { task: { id: task.id } });

        if (!answer) {
            result.maxPointsAmount += task.maxPoint;

            continue;
        }

        result.pointsAmount += answer.point;
        result.maxPointsAmount += answer.maxPoint;
    }

    const calculateRate = (
        pointsAmount: number,
        maxPointsAmount: number,
    ) => {
        return Math.round(pointsAmount / (maxPointsAmount || 1) * 100);
    };

    return {
        ...result,
        calculateRate,
        completionRate: calculateRate(result.pointsAmount, result.maxPointsAmount),
    };
};
