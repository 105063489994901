/**
 * Use language
 *
 * @Note: Wrap a usage case in observer
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { PlatformStore } from '@/store/platform';

import { getPrimaryLanguages } from '@/hooks/core';


export const useLanguage = () => {

    const { COMMON } = PlatformStore;

    const platformLanguages = COMMON?.organization?.settings?.languages || [];

    const languages = !_.isEmpty(platformLanguages)
        ? platformLanguages
        : getPrimaryLanguages();

    const showLanguageSwitch = languages.length !== 1;

    return {
        languages,
        showLanguageSwitch,
    };
};
