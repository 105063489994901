/**
 * UserSettingsPage store
 *
 * @author: exode <hello@exode.ru>
 */

import { authenticator } from 'otplib';
import { FormikHelpers } from 'formik';

import { makeObservable } from 'mobx';
import { observer, PageStore } from '@/pages/Core';
import { UserAuthStore } from '@/store/user/auth';

import { APP_DOMAIN_FULL } from '@/root/src/env';

import { UserAuthService } from '@/services/User/Auth';
import { Router } from '@/services/Utils/Router';


class UserSettingsPage extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    state = {
        input: {
            hasOtp: false,
            otp: '',
            secret: '',
            code: '',
        },
        loading: {
            setOtpAuth: false,
        },
    };

    generateSecret() {
        const { input } = this.state;
        const { domain, email } = UserAuthStore.user || {};

        const accountName = [ email, domain ].filter(e => e).join(' • ');

        input.secret = authenticator.generateSecret();
        input.code = authenticator.keyuri(accountName, APP_DOMAIN_FULL, input.secret);
    }

    async setOtpAuth(formik: FormikHelpers<UserSettingsPage['state']['input']>) {
        const { input } = this.state;
        const { otp, secret, hasOtp } = input;

        const { success, payload, exception } = await this.wrapService('setOtpAuth', {
            service: UserAuthService.setOtp({ secret, otp, active: !hasOtp }),
        });

        if (!success) {
            if (exception) {
                formik.setErrors({ otp: exception.message });
            }

            return false;
        }

        input.otp = '';
        input.hasOtp = payload.hasOtp;

        Router.pushPage('/settings');
    }
}

const UserSettingsPageStore = new UserSettingsPage();


export { observer, UserSettingsPageStore };
