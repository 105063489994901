/**
 * UserSelectedChips
 *
 * @author: exode <hello@exode.ru>
 */

import React, { Dispatch, ForwardedRef, forwardRef, SetStateAction } from 'react';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { SchoolUserFindManyItem } from '@/types/user';
import { SearchProfileListItem } from '@/types/profile';

import { Chip } from '@mui/material';
import { Icon16CancelCircle } from '@vkontakte/icons';

import { CustomScrollbar } from '@/components/Atoms/Styled';
import { UserAvatar } from '@/components/Atoms/UserAvatar';


export interface UserSelectedChipsProps {
    placement: 'page' | 'modal';
    memberProfiles: (SearchProfileListItem | SchoolUserFindManyItem['profile'])[];
    setMemberProfiles: Dispatch<SetStateAction<any[]>>;
    className?: string;
    scrollWrapperClassName?: string;
}


const UserSelectedChips = forwardRef((props: UserSelectedChipsProps, ref: ForwardedRef<HTMLDivElement>) => {

    const {
        placement,
        memberProfiles,
        setMemberProfiles,
        scrollWrapperClassName = 'h-[115px]',
        className = 'p-1.5 bg-hover thin-border-bottom',
    } = props;

    const { t } = useI18n('components.Atoms.UserSelectedChips');

    return (
        <div className={className}>
            <div className="w-full bg-content thin-border vk-rounded overflow-hidden">
                <CustomScrollbar ref={ref} className={[
                    scrollWrapperClassName,
                    'flex flex-wrap items-start content-start gap-1 overflow-y-auto p-1.5',
                ].join(' ')}>
                    <If is={!memberProfiles?.length}>
                        <div className="flex flex-1 h-full items-center justify-center text-secondary fs-13">
                            {t('usersNotSelected')}
                        </div>
                    </If>

                    {memberProfiles.map((profile) => (
                        <Chip key={profile.id} className="bg-hover thin-border" label={(
                            <span className="text-primary">
                                {profile.fullNameShort}
                            </span>
                        )} deleteIcon={(
                            placement === 'page'
                                ? <Icon16CancelCircle width={18}
                                                      height={18}
                                                      fill="var(--icon_secondary)"
                                                      className="!mr-[8px] opacity-75"/>
                                : undefined
                        )} onDelete={(
                            placement === 'page'
                                ? () => {
                                    setMemberProfiles((prevState) => (
                                        prevState.filter(({ id }) => id !== profile.id)
                                    ));
                                }
                                : undefined
                        )} avatar={(
                            <UserAvatar size={24}
                                        name={profile.fullName}
                                        userId={profile.user.id}
                                        showUserOnlineBadge={false}
                                        src={profile.avatar.medium || ''}/>
                        )}/>
                    ))}
                </CustomScrollbar>
            </div>
        </div>
    );
});


export { UserSelectedChips };
