/**
 * ProfileTab
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';
import moment from 'moment';

import React from 'react';

import { Form, Formik } from 'formik';

import { SchoolUserUpdateInputSchema } from '@/libs/class-validator';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';
import { ObjectUtil } from '@/utils';
import { useSchoolUser } from '@/hooks/apollo';
import { languageNames } from '@/shared/types';
import { Field, Graphql, If } from '@/cutils';

import { SchoolUserFindOneQuery } from '@/codegen/graphql';

import { Icon24MailOutline, Icon24PhoneOutline } from '@vkontakte/icons';
import { Button, ButtonGroup, FormItem, FormLayoutGroup, InfoRow, Input } from '@exode.ru/vkui';

import { InfoRowWrapper } from '@/components/Atoms/InfoRowWrapper';


interface Props {
    userId: number;
}


const ProfileTab = (props: Props) => {

    const { userId } = props;

    const { t } = useI18n('pages.Manage.School.Users.views.EditUserFormView');

    const {
        updateUserCause,
        userExceptionMap,
        schoolUserManageUpdate,
        schoolUserManageUpdateLoading,
    } = useSchoolUser();

    return (
        <SchoolUserFindOneQuery children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <Page.Spinner/>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({ schoolUserFindOne: user }) => (
                        <Formik validateOnBlur
                                validateOnMount
                                validateOnChange
                                enableReinitialize
                                validationSchema={SchoolUserUpdateInputSchema}
                                initialValues={{
                                    ..._.pick(user, [
                                        'email',
                                        'phone',
                                    ]),
                                    profile: _.pick(user.profile, [ 'firstName', 'lastName' ]),
                                }}
                                initialErrors={{
                                    email: userExceptionMap.email[updateUserCause],
                                    phone: userExceptionMap.phone[updateUserCause],
                                }}
                                onSubmit={(values, { resetForm }) => {
                                    schoolUserManageUpdate(user.id, {
                                        ...values,
                                        profile: {
                                            firstName: values.profile.firstName || '',
                                            lastName: values.profile.lastName || '',
                                        },
                                    }, () => resetForm());
                                }}>
                            {({
                                  values,
                                  errors,
                                  isValid,
                                  touched,
                                  resetForm,
                                  handleBlur,
                                  handleSubmit,
                                  initialValues,
                                  handleChange,
                              }) => (
                                <Form onSubmit={handleSubmit}>
                                    <FormLayoutGroup className="px-0 pt-0" mode="horizontal">
                                        <FormItem top={`${t('firstName')} *`}
                                                  status={Field.status(errors, touched, 'profile', 'firstName')}
                                                  bottom={Field.message(errors, touched, 'profile', '', 'firstName')}>
                                            <Input onBlur={handleBlur}
                                                   name="profile.firstName"
                                                   placeholder={t('firstName')}
                                                   value={values.profile.firstName || ''}
                                                   onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                                        </FormItem>

                                        <FormItem top={`${t('lastName')} *`}
                                                  status={Field.status(errors, touched, 'profile', 'lastName')}
                                                  bottom={Field.message(errors, touched, 'profile', '', 'lastName')}>
                                            <Input onBlur={handleBlur}
                                                   name="profile.lastName"
                                                   placeholder={t('lastName')}
                                                   value={values.profile.lastName || ''}
                                                   onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                                        </FormItem>
                                    </FormLayoutGroup>

                                    <FormLayoutGroup className="px-0 pt-0" mode="horizontal">
                                        <FormItem top={t('phoneNumber')}
                                                  status={Field.status(errors, touched, 'phone')}
                                                  bottom={Field.message(errors, touched, 'phone', '')}>
                                            <Input name="phone"
                                                   placeholder="+"
                                                   onBlur={handleBlur}
                                                   value={values.phone || ''}
                                                   before={<Icon24PhoneOutline/>}
                                                   onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                                        </FormItem>

                                        <FormItem top={t('email')}
                                                  status={Field.status(errors, touched, 'email')}
                                                  bottom={Field.message(errors, touched, 'email', '')}>
                                            <Input name="email"
                                                   placeholder="—"
                                                   onBlur={handleBlur}
                                                   value={values.email || ''}
                                                   before={<Icon24MailOutline/>}
                                                   onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                                        </FormItem>
                                    </FormLayoutGroup>

                                    <If is={!ObjectUtil.isEqual(initialValues, values)}>
                                        <ButtonGroup stretched className="justify-end mt-1 mb-6">
                                            <Button size="s"
                                                    mode="secondary"
                                                    className="w-fit"
                                                    onClick={() => resetForm()}
                                                    disabled={schoolUserManageUpdateLoading}>
                                                {t('cancel')}
                                            </Button>

                                            <Button size="s"
                                                    type="submit"
                                                    className="w-fit"
                                                    loading={schoolUserManageUpdateLoading}
                                                    disabled={!isValid || schoolUserManageUpdateLoading}>
                                                {t('save')}
                                            </Button>
                                        </ButtonGroup>
                                    </If>

                                    <InfoRowWrapper className="mt-4" cells={[
                                        <InfoRow header={t('birthDate')}>
                                            <>{user.profile?.bdate || '—'}</>
                                        </InfoRow>,

                                        <InfoRow header={t('userLanguage')}>
                                            <>{user.language ? languageNames[user.language] : '—'}</>
                                        </InfoRow>,
                                    ]}/>

                                    <InfoRowWrapper className="mt-4" cells={[
                                        <InfoRow header={t('timezone')}>
                                            <>
                                                {_.isNil(user.timezone)
                                                    ? '—'
                                                    : [
                                                        'GMT ',
                                                        user.timezone > 0 ? '+' : '',
                                                        user.timezone,
                                                    ].join('')
                                                }
                                            </>
                                        </InfoRow>,

                                        <InfoRow header={t('dateOfSignUp')}>
                                            <>{moment(user.createdAt).format('D MMM YYYY HH:MM')}</>
                                        </InfoRow>,
                                    ]}/>

                                    <InfoRowWrapper className="mt-4" cells={[
                                        <InfoRow header={t('personalDomain')}>
                                            <>{user.domain}</>
                                        </InfoRow>,

                                        <InfoRow header={t('tgId')}>
                                            <>{user.tgId || '—'}</>
                                        </InfoRow>,
                                    ]}/>

                                    <InfoRowWrapper className="mt-4" cells={[
                                        <InfoRow header={t('createdBy')}>
                                            <>{user.createdBy?.profile?.fullName || t('bySelf')}</>
                                        </InfoRow>,
                                    ]}/>
                                </Form>
                            )}
                        </Formik>
                    )}
                </Graphql.Success>
            </>
        )} variables={{ userId }}/>
    );
};


export { ProfileTab };
