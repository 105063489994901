/**
 * ThemeTogglePart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { SegmentedControl } from '@exode.ru/vkui';

import { ColorPickerProps } from '@/components/Atoms/ColorPicker';


interface Props {
    onThemeChange: ColorPickerProps['onThemeChange'];
}


const ThemeTogglePart = (props: Props) => {

    const { onThemeChange } = props;

    const { t } = useI18n('components.Atoms.ColorPicker.parts');

    return (
        <SegmentedControl size="m" className="w-full" onChange={(value) => onThemeChange?.(value as 'light')} options={[
            {
                value: 'light',
                label: t('light'),
            },
            {
                value: 'dark',
                label: t('dark'),
            },
        ]}/>
    );
};


export { ThemeTogglePart };
