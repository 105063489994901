/**
 * ChatCellCaptionPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';

import { If } from '@/cutils';
import { useStore } from '@/pages/Core';

import { useI18n } from '@/hooks/core';
import { ChatService } from '@/services/Chat/Chat';

import { Counter } from '@exode.ru/vkui';

import { TypingItem } from '../items/TypingItem';
import { ChatCellItemProps } from '../items/ChatCellItem';


interface Props extends ChatCellItemProps {

}


const ChatCellCaptionPart = (props: Props) => {

    const {
        chat,
        lastMessageText,
    } = props;

    const { t } = useI18n('pages.Chat.Dialog');

    const { lastMessage, countUnread, myMember } = chat;

    const { store } = useStore(ChatDialogsPageStore);

    const isTyping = store.chatIsTyping(chat.id);

    return (
        <div className="flex flex-1 items-end justify-between">
            <If is={isTyping}>
                <div className="flex-col m:h-[38px]">
                    <span className="mt-0.5 grid w-full max-w-[100%] min-h-[28px] !items-baseline pt-1">
                        <TypingItem chat={chat}
                                    captionClassName="fs-13"
                                    dotContainerClassName="self-start !mt-[5px]"
                                    typingState={store.state.chatsTypings?.[chat.id]}
                                    className="w-full text-ellipsis !items-baseline fs-13"/>
                    </span>
                </div>
            </If>

            <If is={!isTyping}>
                <div className="flex flex-1 flex-col">
                    <span className={[
                        'flex text-primary opacity-70 fs-14',
                        'text-ellipsis line-clamp-1 max-w-[calc(100%_-_10px)]',
                    ].join(' ')}>
                        {lastMessage?.isMine ? t('you') : lastMessage?.user.profile.fullName}
                    </span>

                    <div className="mt-0.5 grid w-full max-w-[100%]">
                        <If is={!lastMessageText}>
                            <span className="w-full text-ellipsis line-clamp-1 fs-13">
                                {ChatService.lastMessageText(lastMessage)}
                            </span>
                        </If>

                        <If is={!!lastMessageText}>
                            <>{lastMessageText}</>
                        </If>
                    </div>
                </div>
            </If>

            <If is={!!countUnread}>
                <Counter size="s"
                         className="p-0.5 rounded-full px-[3px] !min-w-[auto]"
                         mode={myMember?.muted ? 'secondary' : 'primary'}>
                    {countUnread}
                </Counter>
            </If>
        </div>
    );
};


export { ChatCellCaptionPart };
