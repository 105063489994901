/**
 * RightPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from '@/store/user/profile';
import { RouterStore } from '@/store/core/router';

import { useI18n, useLanguage } from '@/hooks/core';
import { Auth, If, Link, Platform, Unauth } from '@/cutils';

import { Search } from '@exode.ru/vkui';
import { TextTooltip } from '@exode.ru/vkui/unstable';
import { Icon20DoorEnterArrowRightOutline } from '@vkontakte/icons';

import { HeaderItem } from '@/components/Desktop/Panel';
import { SplitButton } from '@/components/Atoms/SplitButton';
import { LanguageDropdown } from '@/components/Atoms/LanguageDropdown';
import { DesktopNavigationContextMenuDrawer } from '@/components/Desktop/Navigation';

import { SettingsDropdownPart } from './SettingsDropdownPart';


const RightPart = observer(() => {

    const loginDisabled = RouterStore.pageId === '/login';

    const { t } = useI18n('components.Desktop.Panel.Header');

    const { showLanguageSwitch } = useLanguage();

    return (
        <div className="viewport__right-menu vkuiSplitCol--spaced h-full">
            <div className="h-full flex items-center justify-end">
                <Auth>
                    <Platform.Market>
                        <Link pushPage={{ id: '/friends' }}>
                            <Search after="" style={{ width: '100%' }}/>
                        </Link>
                    </Platform.Market>

                    <SettingsDropdownPart/>
                </Auth>

                <Unauth>
                    <If is={showLanguageSwitch}>
                        <LanguageDropdown component={HeaderItem} componentProps={{
                            className: '!rounded-none ml-1',
                        }}/>
                    </If>

                    <Link disabled={loginDisabled} pushPage={{ id: '/login' }}>
                        <div className="h-[var(--panelheader\_height)]">
                            <TextTooltip hidden={!loginDisabled} placement="bottom" text={t('youAlreadyOnLoginPage')}>
                                <div className="flex flex-1 px-4 h-full items-center justify-center">
                                    <SplitButton text={t('login')}
                                                 disabled={loginDisabled}
                                                 before={<Icon20DoorEnterArrowRightOutline fill="white"/>}/>
                                </div>
                            </TextTooltip>
                        </div>
                    </Link>
                </Unauth>

                <DesktopNavigationContextMenuDrawer/>
            </div>
        </div>
    );
});


export { RightPart };
