/**
 * EditMode
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { SaveStoreDeepKeys, SaveStoreKeys, Saving, SavingStore } from '@/store/core/saving';

import { Page } from '@/pages/Core';

import { If } from '@/cutils';
import { useTaskBlockManage, useTaskManage } from '@/hooks/apollo';

import { ContentElementType, StorageSpace, TaskAnswerType } from '@/codegen/graphql';

import { Div } from '@exode.ru/vkui';

import { useTaskBuilderContext } from '@/components/Task/Builder';
import { ContentElementProvider } from '@/components/ContentElement';
import { ContentLayoutBlockManage } from '@/components/Content/ContentLayoutBlockManage';

import { EditHeaderPart } from './parts/EditHeaderPart';

import { TaskBuilderProps } from '../TaskBuilder';


import { ArrangeEdit, DetailedEdit, MappingEdit, MultipleEdit } from './types';


interface Props extends TaskBuilderProps {
    header: ReactElement;
}


const EditMode = (props: Props) => {

    const {
        task,
        header,
        edit: {
            onUpdate,
            onInstantChange,
            getExistBlocks = () => ([]),
        } = {},
    } = props;

    const components = {
        [TaskAnswerType.Arrange]: ArrangeEdit,
        [TaskAnswerType.Boolean]: MultipleEdit,
        [TaskAnswerType.Detailed]: DetailedEdit,
        [TaskAnswerType.FillSpaces]: DetailedEdit,
        [TaskAnswerType.Mapping]: MappingEdit,
        [TaskAnswerType.Multiple]: MultipleEdit,
        [TaskAnswerType.MultipleWithAttachment]: MultipleEdit,
        [TaskAnswerType.Short]: MultipleEdit,
        [TaskAnswerType.Single]: MultipleEdit,
        [TaskAnswerType.SingleWithAttachment]: MultipleEdit,
    };

    const EditComponent = components[task.answerType];

    const { edit } = useTaskBuilderContext();

    const {
        updateTask,
        updaterTaskError,
        updateTaskLoading,
    } = useTaskManage();

    const {
        createBlock,
        createBlockError,
        createBlockLoading,
        updateBlock,
        updateBlockError,
        updateBlockLoading,
        deleteBlock,
        deleteBlockError,
        deleteBlockLoading,
        recoverBlock,
        recoverBlockError,
        recoverBlockLoading,
        updateBlockOrder,
        updateBlockOrderError,
        updateBlockOrderLoading,
        updateBlockOrderInCache,
    } = useTaskBlockManage({
        taskId: task?.id || 0,
        getExistBlocks: () => getExistBlocks(task.id),
    });

    const setEditing = (editing = true) => {
        SavingStore.setEditing(
            SaveStoreKeys.TaskEdit,
            SaveStoreDeepKeys.TaskEditEdit,
            editing,
        );
    };

    Saving.listenSetSaving(
        SaveStoreKeys.TaskEdit,
        SaveStoreDeepKeys.TaskEditEdit,
        [
            updateTaskLoading,
            createBlockLoading,
            updateBlockLoading,
            deleteBlockLoading,
            recoverBlockLoading,
            updateBlockOrderLoading,
        ],
        [
            updaterTaskError,
            createBlockError,
            updateBlockError,
            deleteBlockError,
            recoverBlockError,
            updateBlockOrderError,
        ],
    );

    return (
        <Page.Row innerNoVerticalPadding={0}>
            <div className="flex flex-col flex-1 pt-1.5 pb-0">
                {/** Редактирование только заголовка */}
                <EditHeaderPart task={task}
                                header={header}
                                updateTask={(values) => updateTask(task.id, values, onUpdate)}/>

                {/** Редактирование CE слоя */}
                <div className={[ !edit?.list?.expanded ? 'hidden' : '' ].join(' ')}>
                    <ContentElementProvider value={{
                        edit: {
                            common: {
                                BlockWrapper: Div,
                                blockWrapperProps: { className: 'p-0' },
                                blockHeaderProps: { className: 'no-margin !mb-3' },
                            },
                            EditorJsBlock: {
                                withTitle: false,
                            },
                        },
                    }}>
                        <ContentLayoutBlockManage placement="card"
                                                  ButtonWrapper={Div}
                                                  emptyPlaceholder={null}
                                                  blocks={task?.blocks || []}
                                                  setBlockEditing={setEditing}
                                                  options={{ space: StorageSpace.Task }}
                                                  addBlockButtonPartProps={{ size: 's' }}
                                                  className={[ 'px-4', task?.blocks?.length ? 'mt-3' : '' ].join(' ')}
                                                  includeTypes={[
                                                      ContentElementType.Audio,
                                                      ContentElementType.Video,
                                                      ContentElementType.EditorJsBlock,
                                                  ]}
                                                  actions={{
                                                      createBlock,
                                                      createBlockError,
                                                      createBlockLoading,
                                                      updateBlock,
                                                      updateBlockError,
                                                      updateBlockLoading,
                                                      deleteBlock,
                                                      deleteBlockError,
                                                      deleteBlockLoading,
                                                      recoverBlock,
                                                      recoverBlockError,
                                                      recoverBlockLoading,
                                                      updateBlockOrder,
                                                      updateBlockOrderError,
                                                      updateBlockOrderLoading,
                                                      updateBlockOrderInCache,
                                                  }}/>
                    </ContentElementProvider>

                    <If is={task.answerType !== TaskAnswerType.Detailed}>
                        <div className="mb-3"/>
                    </If>

                    {/** Редактирование EditComponent (в зависимости от типа) */}
                    <EditComponent task={task}
                                   onInstantChange={onInstantChange}
                                   update={(values) => updateTask(task.id, values, onUpdate)}/>
                </div>

                <div className="pb-1.5"/>
            </div>
        </Page.Row>
    );
};


export { EditMode };
