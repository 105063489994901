/**
 * Pagination component
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { memo, useLayoutEffect, useMemo } from 'react';

import { ScrollHelper } from '@/helpers/ui';
import { ConfigStore } from '@/store/core/config';
import { If, StickyObserver } from '@/cutils';

import { Pagination as VkUiPagination, PaginationProps } from '@exode.ru/vkui';

import styles from './Pagination.module.scss';


interface Props extends PaginationProps {
    totalPages: number;
    sticky?: boolean;
}


const Pagination = memo((props: Props) => {

    const { totalPages, sticky = false } = props;

    const currentPage = useMemo(() => props.currentPage, [ props.currentPage ]);

    const { stickyContainer, centeredPagination, centeredPaginationRounded } = styles;

    const containerClass = sticky ? stickyContainer : '';

    const paginationClass = sticky
        ? centeredPagination
        : centeredPaginationRounded;

    const siblingCount = ConfigStore.isDesktop ? 2 : 1;

    useLayoutEffect(() => {
        ScrollHelper.to();
    }, [ currentPage ]);

    return (
        <If is={totalPages > 1}>
            <StickyObserver stackedProps={{ 'data-stacked-bottom': 'true' }}>
                <div className={[ containerClass ].join(' ')}>
                    <VkUiPagination siblingCount={siblingCount} className={[
                        paginationClass,
                        'd-thin-border m-thin-border-top',
                        props.className,
                    ].join(' ')} {..._.omit(props, 'className')} />
                </div>
            </StickyObserver>
        </If>
    );
});


export { Pagination };
