/**
 * UsePayout
 *
 * @author: exode <hello@exode.ru>
 */

import { ApolloError, useApolloClient } from '@apollo/client';

import { GqlResult } from '@/types/graphql';

import {
    CreateOperationPayoutInput,
    CreateRequisitePayoutInput,
    PayoutFindOneDetailDocument,
    PayoutFindOneDetailQuery,
    PayoutOperationCreateMutationResult,
    PayoutOperationFindManyDocument,
    PayoutOperationSumAmountByFilterDocument,
    PayoutRequisiteFindOneActiveDocument,
    PayoutRequisiteFindOneActiveQuery,
    usePayoutOperationCalculateBeforePayoutLazyQuery,
    usePayoutOperationCreateMutation,
    usePayoutRequisiteCreateMutation,
} from '@/codegen/graphql';


const usePayout = () => {

    const apolloClient = useApolloClient();

    const [
        _createPayoutRequisite,
        { loading: createPayoutRequisiteLoading },
    ] = usePayoutRequisiteCreateMutation({
        onError: (error) => console.error(error),
        update: (cache) => {
            const { payoutRequisiteFindOneActive } = cache.readQuery<PayoutFindOneDetailQuery>({
                query: PayoutFindOneDetailDocument,
            }) || {};

            if (!payoutRequisiteFindOneActive) {
                apolloClient?.refetchQueries({
                    include: [ PayoutFindOneDetailDocument ],
                });
            }
        },
    });

    const createPayoutRequisite = (
        requisite: CreateRequisitePayoutInput,
        onCompleted?: () => void,
    ) => {
        return _createPayoutRequisite({
            variables: { requisite },
            onCompleted: () => {
                onCompleted?.();
            },
            update: (cache, { data }) => {
                const cached = cache.readQuery<PayoutRequisiteFindOneActiveQuery>({
                    query: PayoutRequisiteFindOneActiveDocument,
                });

                if (data && cached) {
                    cache.writeQuery<PayoutRequisiteFindOneActiveQuery>({
                        query: PayoutRequisiteFindOneActiveDocument,
                        data: {
                            ...cached,
                            payoutRequisiteFindOneActive: {
                                ...data.payoutRequisiteCreate,
                            },
                        },
                    });
                }
            },
        });
    };

    const [ _createPayoutOperation,
        { loading: createPayoutOperationLoading },
    ] = usePayoutOperationCreateMutation();

    const createPayoutOperation = (
        operation: CreateOperationPayoutInput,
        onCompleted?: (operation: GqlResult<PayoutOperationCreateMutationResult>['payoutOperationCreate']) => void,
        onError?: (e: ApolloError) => void,
    ) => {
        return _createPayoutOperation({
            variables: { operation },
            onCompleted: (d) => {
                onCompleted?.(d?.payoutOperationCreate);
            },
            onError: (e) => {
                onError?.(e);
            },
        });
    };

    /** Refetch after created new payout operation */
    const refetchOnPayoutCreated = async () => {
        return apolloClient.refetchQueries({
            include: [
                PayoutOperationFindManyDocument,
                PayoutOperationSumAmountByFilterDocument,
            ],
        });
    };

    const [ _getCalculateBeforePayoutLazy, {
        data: calculateBeforePayoutLazy,
        loading: getCalculateBeforePayoutLazyLoading,
    } ] = usePayoutOperationCalculateBeforePayoutLazyQuery();

    const getCalculateBeforePayoutLazy = (
        operation: CreateOperationPayoutInput,
    ) => {
        return _getCalculateBeforePayoutLazy({
            variables: { operation },
        });
    };

    return {
        createPayoutOperation,
        createPayoutOperationLoading,
        createPayoutRequisite,
        refetchOnPayoutCreated,
        createPayoutRequisiteLoading,
        getCalculateBeforePayoutLazy,
        getCalculateBeforePayoutLazyLoading,
        calculateBeforePayoutLazy: calculateBeforePayoutLazy?.payoutOperationCalculateBeforePayout || ({
            minAmount: 0,
            transferAmount: 0,
            isInternal: false,
            transactionAmount: 0,
        }),
    };
};


export { usePayout };
