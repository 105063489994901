/**
 * CourseSearchSelect component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { FilterCourseInput, useCourseFindManyLazyQuery } from '@/codegen/graphql';

import { useI18n } from '@/hooks/core';

import { SearchSelect } from '@/components/Atoms/SearchSelect';


interface Props {
    onSelect: (params: any) => void;
    dataTest?: string;
    filter?: FilterCourseInput;
    ignoreCourseIds?: number[];
    selectedCourseIds?: number[];
}


const CourseSearchSelect = (props: Props) => {

    const {
        filter,
        dataTest,
        onSelect,
        ignoreCourseIds,
        selectedCourseIds,
    } = props;

    const { t } = useI18n('components.Course.CourseSearchSelect');

    const [ search, { data, loading, variables } ] = useCourseFindManyLazyQuery({
        fetchPolicy: 'network-only',
        onError: (error) => console.error(error),
    });

    const options = data?.courseFindMany.items
        ?.map(({ name, image, product }) => ({
            value: product?.id ?? 0,
            label: name,
            avatar: image.main || '',
        }))
        .filter((e) => !ignoreCourseIds?.includes(e.value));

    const searchCallback = async (value: string) => search({
        variables: {
            list: { take: 100, skip: 0 },
            filter: {
                ...filter,
                manage: true,
                search: value,
            },
        },
    });

    return (
        <SearchSelect avatarType="icon"
                      loading={loading}
                      dataTest={dataTest}
                      onSelect={onSelect}
                      options={options || []}
                      selectedIds={selectedCourseIds}
                      searchCallback={searchCallback}
                      placeholder={t('enterCourseName')}
                      initialSearch={{
                          deps: [],
                          skip: false,
                      }}
                      emptyText={(
                          variables?.filter?.search
                              ? t('searchDidNotGiveResults')
                              : t('startTypingCourseName')
                      )}/>
    );
};


export { CourseSearchSelect };
