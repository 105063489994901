/**
 * ObjectUtil
 *
 * @author: exode <hello@exode.ru>
 */

import * as _ from 'lodash';


class ObjectUtil {

    /**
     * Create a map collection
     * @param {Record<any, any>[]} object
     * @param {string} keyField
     * @param valueField
     * @returns {Map<any, any>}
     */
    static makeDict(object: Record<any, any>[], keyField: string = 'id', valueField: string = '') {
        const map = new Map();

        for (const item of object) {
            map.set(_.get(item, keyField), valueField ? _.get(item, item) : item);
        }

        return map;
    }

    /**
     * Collecting property values by object key
     * @param {object} object
     * @param {string | number} key
     * @param {any[]} array
     * @returns {any[]}
     */
    static collectPropValues(object: Record<any, any>, key: string | number, array: any[] = []) {
        _.forOwn(object, (value: any) => value[key]
            ? array.push(value[key])
            : _.isObject(value) ? this.collectPropValues(value, key, array) : '',
        );

        return array;
    }

    /**
     * Сравнение JSON stringify двух объектов
     * @param first
     * @param second
     * @returns {boolean}
     */
    static isEqual(first: any, second: any) {
        return JSON.stringify(first || {}) === JSON.stringify(second || {});
    }

    /**
     * Значения свойств меняются на названия их ключей
     * @param {{}} object
     * @param prefix
     */
    static makeValueAsName(object = {}, prefix = '') {
        const result: any = {};

        _.map(object, (_value, key) => {
            result[key] = _.isObject(_value) && !Array.isArray(_value)
                ? this.makeValueAsName(_value, `${key}.`)
                : (prefix + key);
        });

        return result;
    }

    /**
     * Инструменты упаковки и распаковки значений с сохранением типизаций
     * Usage: local storage, crypto, etc.
     * @returns {{packValue: (value: any) => string, repackValue: (value: any) => any}}
     */
    static packTools<T = any>() {
        const packValue = (value: any) => {
            return JSON.stringify(!_.isNil(value) ? value : '');
        };

        const repackValue = (value: any): T => {
            try {
                value = JSON.parse(value);
            } catch (e) {}

            return value;
        };

        return { packValue, repackValue };
    }

    /**
     * Get differences
     * @param {B} before
     * @param {A} after
     * @param {(keyof B)[]} keys
     * @returns {{[p: string]: {from: any, to: any}}}
     */
    static getDiff<B = Record<string, any>, A = Record<string, any>>(
        before: B,
        after: A,
        keys: (keyof B)[],
    ) {
        const changes: {
            [key in keyof B]?: {
                from: any;
                to: any;
            };
        } = {};

        keys.forEach((key) => {
            const beforeValue = _.get(before, key);
            const afterValue = _.get(after, key);

            if (!_.isEqual(beforeValue, afterValue)) {
                if (_.isObject(beforeValue) && _.isObject(afterValue)) {
                    const nestedKeys = Object.keys(beforeValue) as (keyof typeof beforeValue)[];
                    const nestedDiff = this.getDiff(beforeValue, afterValue, nestedKeys);

                    if (Object.keys(nestedDiff).length > 0) {
                        changes[key] = {
                            from: Object.keys(nestedDiff).reduce((acc, nestedKey) => ({
                                ...acc,
                                [nestedKey]: beforeValue[nestedKey as keyof typeof beforeValue],
                            }), {}),
                            to: Object.keys(nestedDiff).reduce((acc, nestedKey) => ({
                                ...acc,
                                [nestedKey]: afterValue[nestedKey as keyof typeof beforeValue],
                            }), {}),
                        };
                    }
                } else {
                    changes[key] = {
                        from: beforeValue,
                        to: afterValue,
                    };
                }
            }
        });

        return changes;
    }

    /**
     * Find first missing item by enum
     * @param {string[]} dynamicArray
     * @param enumObject
     * @returns {string}
     */
    static findFirstMissingItemByEnum(
        dynamicArray: string[],
        enumObject: Record<string, string>,
    ) {
        const enumValues = Object.values(enumObject);

        return enumValues.find((role) => !dynamicArray.includes(role)) || enumValues[0];
    }

    /**
     * Deep merge obects (union all props)
     * @param {T} target
     * @param {Partial<T>} source
     * @param {[]} ignoreSourcePaths - ignore from target, but not from source
     * @returns {T}
     * @Input {"a": { "a": 1, "b": 2 } } & { "a": { "c": 3 } }
     * @Output: { "a": { "a": 1, "b": 2, "c": 3 } }
     */
    static deepMerge<T extends object>(
        target: T,
        source: any,
        ignoreSourcePaths: string[] = [],
    ): T {
        const isObject = (item: any): item is object => {
            return item && typeof item === 'object' && !Array.isArray(item);
        };

        const shouldIgnore = (path: string): boolean => {
            return ignoreSourcePaths.some(ignorePath => {
                const regex = new RegExp(`^${ignorePath.replace(/\./g, '\\.')}($|\.)`);

                return regex.test(path);
            });
        };

        const mergeRecursive = (
            target: T,
            source: T,
            currentPath: string,
        ): any => {
            if (shouldIgnore(currentPath)) {
                return source !== undefined ? source : target;
            }

            if (isObject(target) && isObject(source)) {
                const merged: Record<string, any> = { ...target };

                for (const key in source) {
                    if (source.hasOwnProperty(key)) {
                        merged[key] = mergeRecursive(
                            target[key as keyof typeof target] as T,
                            source[key as keyof typeof target] as T,
                            currentPath ? `${currentPath}.${key}` : key,
                        );
                    }
                }

                return merged;
            }

            return source !== undefined ? source : target;
        };

        return mergeRecursive(target, source, '');
    }

}


export { ObjectUtil };
