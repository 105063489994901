/**
 * GradientBannerImage
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';


interface Props {
    children: ReactElement;
    className?: string;
}


const GradientBannerImage = (props: Props) => {

    const { children, className = '' } = props;

    return (
        <div className={[ 'Banner__image banner__image', className ].join(' ')}>
            {children}
        </div>
    );
};


export { GradientBannerImage };
