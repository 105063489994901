/**
 * FooterBlock component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect } from 'react';

import { IS_VK, IS_WEB } from '@/root/src/env';

import { SchoolStore } from '@/store/platform';
import { RouterStore } from '@/store/core/router';
import { observer, PageStore } from '@/store/core/page';

import { useI18n, useLanguage } from '@/hooks/core';

import { useLocation } from '@/router/index';
import { RoutePathType } from '@/router/paths';
import { DomainSwitcher, If, Platform, Responsive, Router } from '@/cutils';

import { LanguageSwitcher } from '@/components/Atoms/LanguageSwitcher';
import { LanguageDropdown } from '@/components/Atoms/LanguageDropdown';

import { FooterPart } from './parts/FooterPart';
import { BrandingPart } from './parts/BrandingPart';
import { FooterLegalPart } from './parts/FooterLegalPart';

import { FooterWrapper, Wrapper } from './FooterBlock.styled';


const FooterBlock = observer(() => {

    const { t } = useI18n('components.Navigation.Block.Footer');

    const { route: { pageId } } = useLocation();
    const { showLanguageSwitch } = useLanguage();

    const ignore: RoutePathType[] = [
        '/chat',
        '/manage/practice/chat',
    ];

    useEffect(() => {
        PageStore.merge({ footer: !ignore.includes(pageId) });

        return () => {
            PageStore.merge({ footer: false });
        };
    }, [ pageId ]);

    return (
        <If is={IS_WEB && !IS_VK && RouterStore.type !== 'fullscreen'}>
            <Router ignore={ignore}>
                <div className={[
                    'bg-page-initial',
                    PageStore.mode === 'slim-menu' ? 'd:ml-[70.5px]' : '',
                ].join(' ')}>
                    <FooterWrapper className={[
                        'viewport bg-content-50 thin-border-top z-30',
                        'm:bg-gradient-to-t from-transparent to-gray-100 dark:to-gray-900',
                    ].join(' ')}>
                        <div data-page-mode={PageStore.dataPageMode}
                             className="viewport__wrapper flex-col !max-w-[1200px]">
                            <Wrapper className={[
                                'd:grid grid-cols-4 gap-2 flex d:justify-around',
                                'm:items-center m:flex-col m:gap-8 p-4 pt-10',
                            ].join(' ')}>
                                <FooterPart/>
                            </Wrapper>

                            <If is={showLanguageSwitch}>
                                <Responsive.Mobile>
                                    <div className="px-4 mt-2 w-fit">
                                        <LanguageDropdown switcher={(
                                            <LanguageSwitcher mode="panel-header" componentProps={{
                                                className: 'bg-selected text-accent vk-rounded',
                                            }}/>
                                        )} dropdownProps={{
                                            offsetSkidding: 0,
                                            offsetDistance: 10,
                                            placement: 'right-start',
                                        }} componentProps={{
                                            className: 'thin-border vk-rounded !py-0 bg-button-secondary',
                                        }}/>
                                    </div>
                                </Responsive.Mobile>
                            </If>

                            <div className="p-4 d:pb-10 m:pb-16 fs-12">
                                <div className="flex d:justify-between m:flex-col">
                                    <FooterLegalPart/>
                                </div>

                                <div className="text-secondary mt-4 pb-safe">
                                    <Platform.Market>
                                        <DomainSwitcher Ru={t('copyrightProtectedRu')}
                                                        Uz={t('copyrightProtectedUz')}
                                                        Kz={t('copyrightProtectedKz')}/>
                                    </Platform.Market>

                                    <Platform.School>
                                        {SchoolStore.blocks.meta?.footerCaption}
                                    </Platform.School>
                                </div>
                            </div>
                        </div>

                        <BrandingPart/>
                    </FooterWrapper>
                </div>
            </Router>
        </If>
    );
});


export { FooterBlock };
