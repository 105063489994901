/**
 * TaskBuilderContext
 *
 * @author: exode <hello@exode.ru>
 */

import React, { createContext, ReactElement, useContext } from 'react';

import { TaskViewMode } from '@/types/task';

import { TaskBuilderProps } from '@/components/Task/Builder';


interface TaskBuilderContext {
    mode: TaskViewMode;
    edit: NonNullable<TaskBuilderProps['edit']>;
    show: NonNullable<TaskBuilderProps['show']>;
}

interface TaskBuilderProviderProps {
    value: TaskBuilderContext;
    children: ReactElement | ReactElement[];
}


/**
 * TaskBuilder context
 */
const TaskBuilderContext = createContext<TaskBuilderContext>({
    mode: TaskViewMode.Edit,
    edit: {},
    show: { subMode: 'answer' },
});

/**
 * TaskBuilder provider
 * @constructor
 */
const TaskBuilderProvider = (props: TaskBuilderProviderProps) => {
    return (
        <TaskBuilderContext.Provider value={props.value}>
            {props.children}
        </TaskBuilderContext.Provider>
    );
};

/**
 * UseTaskBuilderContext hook
 * @returns {TaskBuilderContext}
 */
const useTaskBuilderContext = (): TaskBuilderContext => useContext(TaskBuilderContext);


export {
    TaskBuilderProvider,
    useTaskBuilderContext,
};
