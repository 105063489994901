/**
 * ShortFormSellerBecomeRequestInput
 *
 * @author: exode <hello@exode.ru>
 */

import { IsEnum, IsNotEmpty, IsString } from 'class-validator';

import { SellerOrganizationForm, SellerType } from '@/codegen/graphql';

import { isNotEmpty } from '../constants';


export class ShortFormSellerBecomeRequestInput {

    @IsEnum(SellerOrganizationForm)
    readonly organizationForm: SellerOrganizationForm;

    @IsEnum(SellerType)
    readonly type: SellerType;

    @IsString()
    @IsNotEmpty({ message: isNotEmpty('Имя') })
    readonly organizationName: string;

    @IsString()
    @IsNotEmpty({ message: isNotEmpty('Поле') })
    readonly contactInfo: string;

}
