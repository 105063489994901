/**
 * Editor util
 *
 * @author: exode <hello@exode.ru>
 */

import { createReactEditorJS } from 'react-editor-js';

import { basicRequestHeaders } from '@/api/headers';
import { restApiUrl, uploadApiUrl } from '@/api/constants';

import Math from 'editorjs-math';

import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
import List from '@editorjs/list';
import LinkTool from '@editorjs/link';
import Raw from '@editorjs/raw';
import Header from '@editorjs/header';
import Image from '@editorjs/image';
import Quote from '@editorjs/quote';
import Marker from '@editorjs/marker';
import CheckList from '@editorjs/checklist';
import Delimiter from '@editorjs/delimiter';
import InlineCode from '@editorjs/inline-code';

import { useI18n } from '@/hooks/core';
import { StorageSpace } from '@/codegen/graphql';

import { CustomQuoteTool } from '@/components/Atoms/EditorJsTools';


interface Options {
    space: StorageSpace;
}


const editorJsTools = (options: Options) => {

    const { t } = useI18n('components.Utils.Editor');

    return {
        embed: Embed,
        table: Table,
        marker: Marker,
        list: List,
        linkTool: {
            class: LinkTool,
            shortcut: 'CMD+SHIFT+L',
            config: {
                additionalRequestHeaders: basicRequestHeaders(),
                endpoint: restApiUrl + 'parse/site/metadata/editor-js',
            },
        },
        raw: Raw,
        header: Header,
        checklist: CheckList,
        delimiter: Delimiter,
        inlineCode: InlineCode,
        image: {
            class: Image,
            config: {
                buttonContent: t('chooseImage'),
                additionalRequestHeaders: basicRequestHeaders(),
                additionalRequestData: {
                    space: options.space,
                },
                endpoints: {
                    byUrl: uploadApiUrl + 'storage/upload/editor-js/by-url',
                    byFile: uploadApiUrl + 'storage/upload/editor-js/by-file',
                },
            },
        },
        math: {
            class: Math,
        },
        quote: {
            class: CustomQuoteTool as typeof Quote,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+O',
            config: {
                quotePlaceholder: t('quoteText'),
                captionPlaceholder: t('quoteAuthorOrSign'),
            },
        },
    };
};

/**
 * See docs here https://github.com/codex-team/editor.js/blob/next/example/example-i18n.html
 */
const i18nEditorJs = () => {

    const { t } = useI18n('components.Utils.Editor');

    return {
        messages: {
            ui: {
                blockTunes: {
                    toggler: {
                        'Click to tune': t('clickToEdit'),
                        'or drag to move': t('orDrag&Drop'),
                    },
                },
                inlineToolbar: {
                    converter: {
                        'Convert to': t('convertTo'),
                    },
                },
                toolbar: {
                    toolbox: {
                        'Add': t('add'),
                    },
                },
                popover: {
                    'Filter': t('search'),
                    'Nothing found': t('nothingFound'),
                },
            },
            toolNames: {
                'Text': t('paragraph'),
                'Heading': t('heading'),
                'List': t('list'),
                'Checklist': t('checklist'),
                'Quote': t('quote'),
                'Image': t('image'),
                'Delimiter': t('divider'),
                'Raw HTML': t('htmlFragment'),
                'Table': t('table'),
                'Link': t('link'),
                'Marker': t('marker'),
                'Bold': t('bold'),
                'Italic': t('italic'),
                'InlineCode': t('inlineCode'),
            },
            tools: {
                warning: {
                    'Title': t('title'),
                    'Message': t('message'),
                },
                link: {
                    'Add a link': t('enterLink'),
                },
                stub: {
                    'The block can not be displayed correctly.': t('theBlockCannotBeDisplayedCorrectly'),
                },
                code: {
                    'Enter a code': t('code'),
                },
                linkTool: {
                    'Link': t('link'),
                    'Couldn\'t fetch the link data': t('notAbleToFetchData'),
                    'Couldn\'t get this link data, try the other one': t('notAbleToFetchDataByLinkTryAnother'),
                    'Wrong response format from the server': t('serverIssues'),
                },
                header: {
                    'Header': t('heading'),
                    'Heading 1': t('heading1'),
                    'Heading 2': t('heading2'),
                    'Heading 3': t('heading3'),
                    'Heading 4': t('heading4'),
                    'Heading 5': t('heading5'),
                    'Heading 6': t('heading6'),
                },
                paragraph: {
                    'Enter something': t('enterText'),
                },
                list: {
                    'Ordered': t('ordered'),
                    'Unordered': t('unordered'),
                },
                table: {
                    'With headings': t('withHeadings'),
                    'Without headings': t('withoutHeadings'),
                    'Heading': t('heading'),
                    'Add row below': t('addRowBelow'),
                    'Add row above': t('addRowAbove'),
                    'Delete row': t('deleteRow'),
                    'Add column to left': t('addColumnToLeft'),
                    'Add column to right': t('addColumnToRight'),
                    'Delete column': t('deleteColumn'),
                },
                image: {
                    'Caption': t('caption'),
                    'With border': t('withBorder'),
                    'Stretch image': t('stretchImage'),
                    'With background': t('withBackground'),
                },
            },
            blockTunes: {
                delete: {
                    'Delete': t('delete'),
                    'Click to delete': t('confirm'),
                },
                moveUp: {
                    'Move up': t('moveUp'),
                },
                moveDown: {
                    'Move down': t('moveDown'),
                },
            },
        },
    };
};

const ReactEditorJs = createReactEditorJS();


export { editorJsTools, i18nEditorJs, ReactEditorJs };
