/**
 * WebhookFormView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { Form, Formik } from 'formik';
import { createValidator } from 'class-validator-formik';

import { useLocation } from '@/router/index';

import { CreateWebhookInput } from '@/libs/class-validator';

import { ObjectUtil } from '@/utils';
import { useI18n } from '@/hooks/core';
import { Router } from '@/services/Utils/Router';
import { Field, Notify } from '@/cutils';
import { useWebhookManage } from '@/hooks/apollo';

import { webhookSellerEvents } from '@/shared/types';
import { CreateEndpointWebhookInput, WebhookEvent } from '@/codegen/graphql';
import { SchoolWebhookEndpointItem, schoolWebhookEvent } from '@/types/school';

import { TextTooltip } from '@exode.ru/vkui/dist/unstable';
import { Button, Checkbox, Counter, FormItem, Header, Input, List, Textarea } from '@exode.ru/vkui';

import { StickyButton } from '@/components/Atoms/StickyButton';
import { CheckListTooltip } from '@/components/Atoms/CheckListTooltip';
import { FieldBottomLimiter } from '@/components/Atoms/FieldBottomLimiter';


interface Props {
    webhook?: SchoolWebhookEndpointItem;
}


const WebhookFormView = (props: Props) => {

    const { webhook } = props;

    const { t } = useI18n('pages.Manage.School.Webhook.views');

    const { route: { params } } = useLocation();

    const {
        createWebhookEndpoint,
        createWebhookEndpointLoading,
        updateWebhookEndpoint,
        updateWebhookEndpointLoading,
        getInitialValues,
    } = useWebhookManage();

    const webhookEvents = schoolWebhookEvent();

    const enabled = [
        WebhookEvent.UserSignedUp,
        WebhookEvent.ProductEnrolledToFree,
    ];

    const onSubmit = (values: CreateEndpointWebhookInput) => {
        return !!webhook?.id
            ? updateWebhookEndpoint(webhook.id, values, () => {
                Router.replaceModal();

                Notify.toast.success(t('successfullyUpdated'));
            })
            : createWebhookEndpoint(values, () => {
                Router.replaceModal();

                Notify.toast.success(t('successfullyCreated'));
            });
    };

    return (
        <Formik validateOnBlur
                validateOnMount
                validateOnChange
                onSubmit={onSubmit}
                initialValues={getInitialValues(webhook)}
                validate={createValidator(CreateWebhookInput)}>
            {({
                  values,
                  errors,
                  touched,
                  isValid,
                  handleBlur,
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  initialValues,
              }) => (
                <Form onSubmit={handleSubmit} className="p-3">
                    <FormItem top={t('url')}
                              className="p-0 mt-2"
                              status={Field.status(errors, touched, 'url')}
                              bottom={(
                                  <FieldBottomLimiter maxLength={255}
                                                      value={values.url}
                                                      message={Field.message(errors, touched, 'url')}/>
                              )}>
                        <Input autoFocus
                               name="url"
                               maxLength={255}
                               value={values.url}
                               onBlur={handleBlur}
                               placeholder={t('enterUrl')}
                               onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                    </FormItem>

                    <FormItem className="px-0 pb-0"
                              top={t('serviceNote')}
                              status={Field.status(errors, touched, 'note')}
                              bottom={(
                                  <FieldBottomLimiter maxLength={255}
                                                      value={values.note}
                                                      message={Field.message(errors, touched, 'note')}/>
                              )}>
                        <Textarea rows={2}
                                  name="note"
                                  maxLength={255}
                                  value={values.note || ''}
                                  data-test="webhook.note"
                                  placeholder={t('noteExample')}
                                  onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                    </FormItem>

                    <Header mode="secondary" className="my-0 p-0" aside={(
                        <Counter size="m" mode="secondary">
                            {values.events.length}
                        </Counter>
                    )}>
                        {t('selectedEvents')}
                    </Header>

                    <List>
                        {_.sortBy(webhookSellerEvents, (e) => !enabled.includes(e)).map((event, index) => (
                            <Checkbox key={index}
                                      value={event}
                                      name="events"
                                      className="checkbox-origin"
                                      disabled={!enabled.includes(event)}
                                      checked={values.events?.includes(event)}
                                      description={webhookEvents[event]?.description}
                                      onChange={(e) => {
                                          const { value } = e.target;

                                          setFieldValue(
                                              'events',
                                              values.events.includes(value as WebhookEvent)
                                                  ? values.events?.filter((e) => e !== value)
                                                  : [ ...values.events, value ],
                                          );
                                      }}>
                                {webhookEvents[event]?.title}
                            </Checkbox>
                        ))}
                    </List>

                    <StickyButton className="px-0 pb-3">
                        <TextTooltip placement="left" shown={!isValid && !!params.modal} hidden={isValid} text={(
                            <CheckListTooltip list={_.values(errors).map((text) => ({
                                mode: 'error',
                                text: `${text}`,
                            }))}/>
                        )}>
                            <Button stretched
                                    size="l"
                                    type="submit"
                                    className="mt-6"
                                    appearance="accent"
                                    loading={createWebhookEndpointLoading || updateWebhookEndpointLoading}
                                    disabled={(
                                        createWebhookEndpointLoading
                                        || updateWebhookEndpointLoading
                                        || !isValid
                                        || ObjectUtil.isEqual(initialValues, values)
                                    )}>
                                {webhook?.id ? t('save') : t('create')}
                            </Button>
                        </TextTooltip>
                    </StickyButton>
                </Form>
            )}
        </Formik>
    );
};


export { WebhookFormView };
