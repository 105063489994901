/**
 * ChatDialogsPage store
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { makeObservable } from 'mobx';

import { Storage } from '@/api/storage';
import { observer, PageStore } from '@/pages/Core';
import { ChatType, SortDirection, StorageFileType } from '@/codegen/graphql';

import { SendChatMessagePayload } from '@/hooks/apollo';
import { ChatFolderType, ChatMessageItem, ChatTypings } from '@/types/chat';


interface SelectMessagesInput {
    message: ChatMessageItem;
    chatId: number | undefined | null;
    withResetAnother?: boolean;
}

interface ExpandMessagesInput {
    chatId: number | undefined | null;
    messageId: number;
    expanded?: boolean;
    withResetAnother?: boolean;
}

interface UnsentMessagePayload extends SendChatMessagePayload {
    messageId: number;
}

interface DefaultChatListFilter {
    folder: ChatFolderType;
    chatSearch?: string;
    types?: ChatType[];
}

/** Перемещен в store для решения circular dependency */
export const defaultChatListFilter: DefaultChatListFilter = {
    chatSearch: '',
    types: [] as ChatType[],
    folder: 'All' as ChatFolderType,
};

export const dtoFilterChatListTransformer = (filter: DefaultChatListFilter) => ({
    ..._.omit(filter, [
        'folder',
        'chatSearch',
    ]),
    types: (filter?.folder === 'All' || !filter?.folder)
        ? []
        : [ filter?.folder ],
});

export const defaultChatAttachesFilter = {
    storageFileTypes: [
        StorageFileType.Image,
        StorageFileType.Video,
    ],
};

export const dtoFilterChatAttachesTransformer = (filter: typeof defaultChatAttachesFilter) => filter;


class ChatDialogsPage extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    /** Состояние scroll в разрезе ключей чатов */
    chatScrolls: { [key: number]: number } = {};

    state = {
        unreadMessageIds: new Set() as Set<number>,
        chatsTypings: {} as Record<number, ChatTypings>,
        expandedMessages: {} as Record<number, Record<number, boolean>>,
        selectedMessages: {} as Record<number, ChatMessageItem[]>,
        unsentMessages: {} as Record<number, UnsentMessagePayload[]>,
        input: {
            message: '',
            isEditing: 0,
            replyMessage: {} as Record<number, ChatMessageItem>,
        },
        list: {
            messages: { take: 100 },
            chats: { skip: 0, take: 30 },
            profile: { skip: 0, take: 10 },
            attachments: { skip: 0, take: 20 },
            members: { skip: 0, page: 1, take: 15 },
        },
        filter: {
            messages: {},
            members: {},
            chats: dtoFilterChatListTransformer(defaultChatListFilter),
            attachments: dtoFilterChatAttachesTransformer(defaultChatAttachesFilter),
        },
        sort: {
            messages: { id: SortDirection.Desc },
            chats: { lastAction: SortDirection.Desc },
            attachments: {},
            members: { createdAt: SortDirection.Asc },
        },
        cursor: {
            messages: {},
            chats: {},
            attachments: {},
            members: {},
        },
    };

    merge(partial: Partial<this>) {
        Object.assign(this, partial);
    }

    /**
     * Установка выбранных сообщений
     * @param {Record<number, ChatMessageItem[]>} value
     */
    setSelectedMessages(value: Record<number, ChatMessageItem[]>) {
        this.state.selectedMessages = value;
    }

    /**
     * Set unsent messages
     * @param {{chatId: number, message: string, messageId: number}} payload
     */
    addUnsentMessage(payload: UnsentMessagePayload) {
        const { chatId } = payload;

        this.state.unsentMessages = {
            ...this.state.unsentMessages,
            [chatId]: [
                ...(this.state.unsentMessages[chatId] || []),
                payload,
            ],
        };
    }

    /**
     * Remove unsent message
     * @param {number} chatId
     * @param {number} messageId
     */
    removeUnsentMessage(
        chatId: number,
        messageId: number,
    ) {
        this.state.unsentMessages = {
            ...this.state.unsentMessages,
            [chatId]: this.state.unsentMessages[chatId]
                .filter(({ messageId: mid }) => mid !== messageId),
        };
    }

    /**
     * Выбор сообщений
     * @param {SelectMessagesInput} options
     */
    handleSelectMessage(options: SelectMessagesInput) {
        const {
            chatId,
            message,
            withResetAnother = false,
        } = options;

        if (!chatId) {
            return;
        }

        if (withResetAnother) {
            return this.state.selectedMessages = { [+chatId]: [ message ] };
        }

        const selectedMessages = this.state.selectedMessages[chatId];

        this.state.selectedMessages = selectedMessages?.find(({ id }) => message.id === id)
            ? {
                ...this.state.selectedMessages,
                [chatId]: selectedMessages.filter(({ id }) => id !== message.id),
            }
            : {
                ...this.state.selectedMessages,
                [chatId]: [ ...(selectedMessages ?? []), message ],
            };
    }

    /**
     * Раскрытие сообщения (виджет)
     * @param {ExpandMessagesInput} options
     * @returns {{[p: number]: boolean}}
     */
    handleExpandMessage(options: ExpandMessagesInput) {
        const {
            chatId,
            messageId,
            expanded = true,
            withResetAnother = false,
        } = options;

        if (!chatId) {
            return;
        }

        if (!this.state.expandedMessages[chatId]) {
            this.state.expandedMessages[chatId] = {};
        }

        if (withResetAnother) {
            return this.state.expandedMessages[chatId] = { [messageId]: expanded };
        }

        this.state.expandedMessages[chatId] = {
            ...this.state.expandedMessages[chatId],
            [messageId]: expanded,
        };
    }

    /**
     * Восстановление черновика
     * @param {number | undefined} chatId
     */
    restoreDraftMessage(chatId: number | undefined) {
        if (!chatId) {
            return;
        }

        this.state.input.message = Storage.get('chat:message-draft')?.[chatId] ?? '';
    }

    /**
     * Сохранение черновика
     * @param {string} value
     * @param {number | undefined} chatId
     */
    saveDraftMessage(value: string, chatId: number | undefined) {
        if (!chatId) {
            return;
        }

        Storage.set(
            'chat:message-draft',
            { ...(Storage.get('chat:message-draft') ?? {}), [chatId]: value },
        );
    }

    /**
     * Получение статуса "Печатает"
     * @param {number} chatId
     * @return {boolean}
     */
    chatIsTyping(chatId: number) {
        return !!this.state.chatsTypings?.[chatId]?.profiles?.length;
    }

    /**
     * Установка статуса "Печатает"
     * @param {ChatTypings} chat
     */
    setChatsTypings(chat: ChatTypings) {
        this.state.chatsTypings = { ...this.state.chatsTypings, [chat.chatId]: chat };
    }

    /**
     * Сброс статуса "Печатает"
     */
    clearChatsTypings() {
        this.state.chatsTypings = {};
    }

    /**
     * Добавление непрочитанных сообщений
     * @param {number} messageId
     */
    addUnreadMessageId(messageId: number) {
        if (!messageId || this.state.unreadMessageIds.has(messageId)) {
            return;
        }

        this.state.unreadMessageIds.add(messageId);
    }

    /**
     * Установка непрочитанных сообщений
     * @param {number[]} messageIds
     */
    setUnreadMessageIds(messageIds: number[]) {
        this.state.unreadMessageIds = new Set(messageIds);
    }

}

const ChatDialogsPageStore = new ChatDialogsPage();


export { observer, ChatDialogsPageStore };
