/**
 * UseProductLaunch
 *
 * @author: exode <hello@exode.ru>
 */

import { useI18n } from '@/hooks/core';
import { GqlResult } from '@/types/graphql';

import { Notify } from '@/cutils';
import { Router } from '@/services/Utils/Router';

import {
    ProductLaunchFindManyDocument,
    ProductLaunchFindManyQuery,
    ProductLaunchFindOneQueryResult,
    ProductPublicationManualPublishLaunchMutationResult,
    PublishLaunchProductInput,
    UpdateLaunchProductInput,
    useProductLaunchCreateMutation,
    useProductLaunchUpdateMutation,
    useProductPublicationManualPublishLaunchMutation,
} from '@/codegen/graphql';

import { ManageLaunchesPageStore } from '@/pages/Manage/Courses/Launches/store';

import { defaultLaunchesFilter } from '@/pages/Manage/Courses/Launches/filter';


interface Props {
    courseId: number;
    launch?: GqlResult<ProductLaunchFindOneQueryResult>['productLaunchFindOne'];
    filter?: Partial<typeof defaultLaunchesFilter>;
    list?: typeof ManageLaunchesPageStore['state']['list'];
    sort?: typeof ManageLaunchesPageStore['state']['sort'];
}


export const useProductLaunch = (props: Props) => {

    const { courseId, launch, filter, sort, list } = props;

    const { t } = useI18n('hooks.apollo.product');

    const getVariables = () => ({
        list: { ...list },
        filter: { ...filter },
        sort: { ...sort },
    });

    const initialLaunchUpdateData = {
        name: launch?.name || '',
        saleStartAt: launch?.saleStartAt || null,
        saleFinishAt: launch?.saleFinishAt || null,
        infinityAccess: launch?.infinityAccess,
    } as UpdateLaunchProductInput;

    const [ createLaunch, { loading: createLaunchLoading } ] = useProductLaunchCreateMutation({
        onError: (error) => console.error(error),
        update: (cache, { data }) => {
            const variables = getVariables();

            const cachedLaunches = cache.readQuery<ProductLaunchFindManyQuery>({
                query: ProductLaunchFindManyDocument,
                variables,
            });

            if (!cachedLaunches) {
                return console.warn('[Cache]: cachedLaunches отсутствуют в кэше');
            }

            if (data?.productLaunchCreate) {
                const { productLaunchCreate } = data;

                cache.writeQuery<ProductLaunchFindManyQuery>({
                    query: ProductLaunchFindManyDocument,
                    variables,
                    data: {
                        productLaunchFindMany: {
                            __typename: 'ListLaunchProductOutput',
                            count: (cachedLaunches.productLaunchFindMany.count ?? 1) + 1,
                            page: cachedLaunches.productLaunchFindMany.page ?? 1,
                            pages: cachedLaunches.productLaunchFindMany.pages ?? 1,
                            items: [ productLaunchCreate, ...(cachedLaunches.productLaunchFindMany.items ?? []) ],
                        },
                    },
                });

                Router.pushPage(
                    '/manage/course/:courseId([0-9]+)/launches/:page([0-9]+)/:launchId([0-9]+)/settings',
                    {
                        courseId: `${courseId}`,
                        launchId: `${productLaunchCreate.id}`,
                    },
                );
            }

            /** Refetch lessons count in course card */
            cache.evict({
                id: `CourseEntity:${courseId}`,
                fieldName: 'product',
            });
        },
    });

    const [ _updateLaunch, { loading: updateLaunchLoading } ] = useProductLaunchUpdateMutation({
        onError(error) {
            console.log(error);

            Notify.vkui({
                appearance: 'error',
                message: t('errorSomethingWentWrong'),
            });
        },
    });

    const updateLaunch = (
        launchId: number,
        launch: UpdateLaunchProductInput,
        onCompleted?: () => void,
    ) => {
        return _updateLaunch({
            variables: { launchId, launch },
            onCompleted: () => onCompleted?.(),
        });
    };

    const [ _manualPublishLaunch, {
        loading: manualPublishLaunchLoading,
    } ] = useProductPublicationManualPublishLaunchMutation({
        onError: (error) => console.error(error),
    });

    const manualPublishLaunch = (
        launchId: number,
        publication: PublishLaunchProductInput,
        onCompleted?: (
            data: GqlResult<ProductPublicationManualPublishLaunchMutationResult>['productPublicationManualPublishLaunch'],
        ) => void,
    ) => {
        return _manualPublishLaunch({
            variables: { launchId, publication },
            onCompleted: (data) => onCompleted?.(data.productPublicationManualPublishLaunch),
        });
    };

    return {
        createLaunch,
        createLaunchLoading,
        updateLaunch,
        updateLaunchLoading,
        manualPublishLaunch,
        manualPublishLaunchLoading,
        initialLaunchUpdateData,
    };
};
