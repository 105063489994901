/**
 * UseCoursePracticeAttempt
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { useMemo, useRef, useState } from 'react';

import { ScrollHelper } from '@/helpers/ui';
import { LessonPractice } from '@/types/practice';


interface Props {
    practice: LessonPractice;
}


export const useCoursePracticeAttempt = (props: Props) => {

    const { practice } = props;

    const attemptReviewRef = useRef<HTMLDivElement>(null);
    const [ previewAttemptId, setPreviewAttemptId ] = useState<number | undefined>();

    const previewAttempt = useMemo(() => _.find(
            practice?.attempts,
            { id: previewAttemptId },
        ),
        [ previewAttemptId, practice.attempts ],
    );

    const scrollToAttemptReview = () => setImmediate(() => {
        if (attemptReviewRef.current) {
            ScrollHelper.to(
                ScrollHelper.calcTopToElement(
                    attemptReviewRef.current,
                    -60,
                ),
                true,
            );
        }
    });

    return {
        attemptReviewRef,
        previewAttemptId,
        setPreviewAttemptId,
        previewAttempt,
        scrollToAttemptReview,
    };
};
