/**
 * AppErrorFallback component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { HTMLAttributes, ReactNode } from 'react';

import { EXODE_ECOSYSTEM_SUPPORT_TG_URL } from '@/root/src/env';

import { If, Link } from '@/cutils';
import { useCopy, useI18n } from '@/hooks/core';

import { Button, Caption, Text, Title } from '@exode.ru/vkui';
import { Icon16Replay, Icon20CopyOutline, Icon56WifiOutline } from '@vkontakte/icons';

import { Satellite } from '@/images/kits';


interface Props extends HTMLAttributes<HTMLDivElement> {
    onClick: React.MouseEventHandler<HTMLElement>;
    buttonText?: string;
    isBoundary?: boolean;
    message?: ReactNode;
    caption?: ReactNode;
    errorInfo?: string;
}


const ErrorFallback = (props: Props) => {

    const { t } = useI18n('components.App.ErrorFallback');

    const {
        onClick,
        caption,
        errorInfo,
        isBoundary = false,
        buttonText = t('repeatRequest'),
        ...rest
    } = props;

    const { copy } = useCopy(t('errorInfoIsCopied'), () => alert(t('errorInfoIsCopied')));

    return (
        <div className={[
            isBoundary
                ? 'justify-start mt-20 font-vk-sans-display gap-8 !text-lg'
                : 'justify-center gap-4',
            'flex flex-col items-center text-center p-5',
        ].join(' ')} {...rest}>
            <>
                {isBoundary
                    ? <img alt="Satellite" src={Satellite} className="mt-10"/>
                    : <Icon56WifiOutline width={72} height={72}/>
                }
            </>

            <Title level="1">
                {t('occurredError')}
            </Title>

            <Text weight="regular">
                {isBoundary ? t('tryAgainLaterOrContactToSupport') : t('oopsNoConnectionToServer')}
            </Text>

            <Button className="mt-2" size="m" onClick={onClick} before={<Icon16Replay/>}>
                {buttonText}
            </Button>

            <Link toOuter={EXODE_ECOSYSTEM_SUPPORT_TG_URL}>
                <Button size="m" mode="tertiary" className="mt-4">
                    {t('contactSupport')}
                </Button>
            </Link>

            <If is={!!caption}>
                <Caption className="mt-2 text-secondary">
                    {caption}
                </Caption>
            </If>

            <If is={!!errorInfo}>
                <Button size="s"
                        mode="outline"
                        className="mt-4"
                        before={<Icon20CopyOutline/>}
                        onClick={() => copy(errorInfo)}>
                    {t('copyErrorToSupport')}
                </Button>
            </If>
        </div>
    );
};


export { ErrorFallback };
