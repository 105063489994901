/**
 * UpdateCourseInput
 *
 * @author: exode <hello@exode.ru>
 */

import { IsString, MaxLength } from 'class-validator';


export class UpdateCourseInput {

    @IsString()
    @MaxLength(130)
    readonly name: string;

    @IsString()
    @MaxLength(350)
    readonly description: string;

}
