/**
 * Match validator
 *
 * @author: exode <hello@exode.ru>
 */

import {
    registerDecorator,
    ValidationArguments,
    ValidationOptions,
    ValidatorConstraint,
    ValidatorConstraintInterface,
} from 'class-validator';


export function Match(property: string, validationOptions?: ValidationOptions) {
    return (object: any, propertyName: string) => {
        registerDecorator({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            validator: MatchConstraint,
            constraints: [ property, validationOptions ],
        });
    };
}

@ValidatorConstraint({ name: 'Match' })
export class MatchConstraint implements ValidatorConstraintInterface {

    validate(value: any, args: ValidationArguments) {
        const [ relatedPropertyName ] = args.constraints;
        const relatedValue = (args.object as any)[relatedPropertyName];

        return value === relatedValue;
    }

    defaultMessage(args: ValidationArguments) {
        const [ relatedPropertyName, validationOptions ] = args.constraints;

        if (validationOptions?.message) {
            return validationOptions.message;
        }

        return `${relatedPropertyName} and ${args.property} don't match`;
    }

}
