/**
 * UserInfoItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { FieldArray, useFormikContext } from 'formik';

import { Field, If } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { useSchoolUser } from '@/hooks/apollo';

import { Button, FormItem, Input } from '@exode.ru/vkui';
import { Icon24AddSquareOutline, Icon24DeleteOutline, Icon24MailOutline } from '@vkontakte/icons';

import { PhoneInput } from '@/components/Atoms/PhoneInput';
import { ConfirmButton } from '@/components/Atoms/ConfirmButton';


const UserInfoItem = () => {

    const { t } = useI18n('pages.Manage.School.Users.views');

    const {
        errors,
        touched,
        setFieldValue,
        handleBlur, handleChange,
        values: { users: { accounts } },
    } = useFormikContext<ReturnType<typeof useSchoolUser>['initialValuesForCreate']>();

    return (
        <FieldArray name="users.accounts">
            {({ push, remove }) => (
                <div className="flex flex-col gap-4">
                    {accounts.map((account, index) => {
                        const { type, email, phone } = account;

                        const isEmailMode = type === 'email';

                        return (
                            <div key={index} className="flex items-start gap-2 w-full">
                                <FormItem className="p-0" top=" ">
                                    <div className={[
                                        'fs-15 font-medium thin-border',
                                        'flex items-center justify-center bg-hover vk-rounded',
                                        'whitespace-nowrap min-h-[42px] min-w-[42px] leading-none',
                                    ].join(' ')}>
                                        {index + 1}
                                    </div>
                                </FormItem>

                                <FormItem className="p-0 flex flex-col flex-1" status={Field.status<any>(
                                    errors,
                                    touched,
                                    isEmailMode
                                        ? `users.accounts.${index}.email`
                                        : `users.accounts.${index}.phone`,
                                )} top={(
                                    <div className="flex justify-between">
                                        {isEmailMode ? t('userEmail') : t('userPhone')}

                                        <span className="text-accent cursor-pointer"
                                              data-test="user-create.phone-email-toggler"
                                              onClick={() => {
                                                  setFieldValue(
                                                      `users.accounts.${index}.type`,
                                                      isEmailMode ? 'phone' : 'email',
                                                  );
                                              }}>
                                            {isEmailMode ? t('switchToPhone') : t('switchToEmail')}
                                        </span>
                                    </div>
                                )} bottom={Field.message<any>(
                                    errors,
                                    touched,
                                    isEmailMode
                                        ? `users.accounts.${index}.email`
                                        : `users.accounts.${index}.phone`,
                                )}>
                                    <If is={isEmailMode}>
                                        <Input value={email}
                                               autoFocus={!email}
                                               onBlur={handleBlur}
                                               before={<Icon24MailOutline/>}
                                               data-test="user-create.email"
                                               placeholder={t('enterUserEmail')}
                                               name={`users.accounts.${index}.email`}
                                               onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                                    </If>

                                    <If is={!isEmailMode}>
                                        <PhoneInput required
                                                    value={phone}
                                                    autoFocus={!phone}
                                                    onBlur={handleBlur}
                                                    inputClassName="w-full p-4"
                                                    data-test="user-create.phone"
                                                    placeholder={t('enterUserPhone')}
                                                    name={`users.accounts.${index}.phone`}
                                                    onChange={(phone) => {
                                                        setFieldValue(`users.accounts.${index}.phone`, phone);
                                                    }}/>
                                    </If>
                                </FormItem>

                                <If is={accounts?.length > 1}>
                                    <FormItem className="p-0" top=" ">
                                        <ConfirmButton stopPropagation
                                                       text={t('deleteUser')}
                                                       data-test="user.delete"
                                                       callback={() => remove(index)}
                                                       className="rounded-full bg-hover thin-border"
                                                       confirmProps={{ className: 'min-h-[42px] min-w-[42px]' }}
                                                       initialIcon={(
                                                           <Icon24DeleteOutline fill="var(--accent)"/>
                                                       )}
                                                       confirmIcon={(
                                                           <Icon24DeleteOutline fill="var(--destructive)"/>
                                                       )}/>
                                    </FormItem>
                                </If>
                            </div>
                        );
                    })}

                    <div>
                        <Button stretched
                                size="l"
                                type="button"
                                mode="secondary"
                                className="mt-2"
                                data-test="user.add"
                                disabled={accounts.length >= 30}
                                before={<Icon24AddSquareOutline/>}
                                onClick={() => {
                                    push({ type: accounts?.at(-1)?.['type'] || 'email' });
                                }}>
                            {t('anotherStudent')}
                        </Button>
                    </div>
                </div>
            )}
        </FieldArray>
    );
};


export { UserInfoItem };
