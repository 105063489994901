/**
 * CourseLessonPracticeInput
 *
 * @author: exode <hello@exode.ru>
 */

import { IsInt, IsOptional, Max } from 'class-validator';


export class CourseLessonPracticeInput {

    @IsInt()
    @IsOptional()
    @Max(100, { message: 'Значение не должно быть более 100%' })
    readonly passThreshold: string;

}
