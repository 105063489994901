/**
 * Message styled item
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const MessageContainer = styled.div`
    position: relative;

    .message-text {
        a, span {
            display: contents;
        }
    }
`;

export const ActionButtonsContainer = styled.div`
    display: flex;
    gap: 0.75rem;
    margin-bottom: 2px;
    justify-content: center;
`;
