/**
 * CreateGroupView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { Formik } from 'formik';

import { Field } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { useLocation } from '@/router/index';
import { useGroupManage } from '@/hooks/apollo';
import { Router } from '@/services/Utils/Router';

import { Icon24AddSquareOutline } from '@vkontakte/icons';
import { Button, FormItem, Input } from '@exode.ru/vkui';

import { FieldBottomLimiter } from '@/components/Atoms/FieldBottomLimiter';

import { UsersSelectView } from '@/pages/Manage/School/Users';


const CreateGroupView = () => {

    const { t } = useI18n('pages.Manage.Courses.Groups.GroupsMain.views');

    const { route: { params } } = useLocation();

    const {
        createGroup,
        createGroupCause,
        groupExceptionMap,
        createGroupLoading,
    } = useGroupManage({ withSavingStore: true });

    return (
        <Formik enableReinitialize initialErrors={{
            name: groupExceptionMap.name[createGroupCause],
        }} onSubmit={(values) => {
            createGroup(values, (group) => {
                Router.replacePage(
                    '/manage/course/:courseId([0-9]+)/groups/:page([0-9]+)/:groupId([0-9]+)/settings',
                    { ..._.pick(params, [ 'courseId' ]), groupId: `${group.id}` },
                );
            });
        }} initialValues={{
            name: '',
            memberUserIds: [],
            productId: +params.productId,
        }}>
            {({ handleSubmit, values, handleChange, setFieldValue, errors, touched }) => (
                <UsersSelectView direction="row"
                                 wrapperClassName="px-3"
                                 userSelectedChipsProps={{ scrollWrapperClassName: 'h-[calc(100dvh_-_55px_-_322px)]' }}
                                 onChange={(profiles) => {
                                     setFieldValue('memberUserIds', _.map(profiles, 'user.id'));
                                 }}
                                 after={(
                                     <Button stretched
                                             size="l"
                                             type="submit"
                                             className="mt-3"
                                             loading={createGroupLoading}
                                             onClick={() => handleSubmit()}
                                             before={<Icon24AddSquareOutline/>}
                                             disabled={createGroupLoading || !values.name}>
                                         {t('createGroup')}
                                     </Button>
                                 )}
                                 before={(
                                     <FormItem top={t('groupName')}
                                               className="p-0 pb-2"
                                               status={Field.status(errors, touched, 'name')}
                                               bottom={(
                                                   <FieldBottomLimiter maxLength={100} value={values.name} message={(
                                                       Field.message(errors, touched, 'name', '')
                                                   )}/>
                                               )}>
                                         <Input name="name"
                                                maxLength={100}
                                                value={values.name}
                                                placeholder={t('enterGroupName')}
                                                onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                                     </FormItem>
                                 )}/>
            )}
        </Formik>
    );
};


export { CreateGroupView };
