/**
 * Use upload files
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { nanoid } from 'nanoid';
import { Canceler } from 'axios';

import { ChangeEvent, Dispatch, MutableRefObject, SetStateAction, useState } from 'react';

import { StorageFileType } from '@/shared/types';
import { StorageFileEntity, StorageImageSize, StorageSpace } from '@/codegen/graphql';

import { Notify } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { UploadService } from '@/services/Upload/Upload';
import { FileImageDimensions, FileUtil, Http } from '@/utils';


interface Props {
    maxFiles: number;
}

export interface FileInfo {
    name: string;
    size: string;
    fileExtension: string;
    duration?: number;
    dimensions?: FileImageDimensions;
}

export interface UploadedFilePreviews {
    id: string;
    type: StorageFileType;
    file: File;
    src: string;
    info: FileInfo | null,
}


const useUploadFiles = (props: Props) => {

    const { t } = useI18n('hooks.core');

    const [ previews, setPreviews ] = useState<UploadedFilePreviews[]>([]);

    const loadFilesWithPreviews = (e: ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;

        e.preventDefault();
        e.stopPropagation();

        if (!files) {
            return;
        }

        if (files && files.length > props.maxFiles
            || (previews.length + files.length) > props.maxFiles
        ) {
            return Notify.toast.error(
                t('maxFileUploadError', { max: props.maxFiles }),
                { icon: '⚠️' },
            );
        }

        _.forEach(files, async (file) => {
            const info = await getFilesInfo(file);

            const type = FileUtil.getFileTypeByMimeType(file.type);

            if (type === StorageFileType.Image) {
                const fileReader = new FileReader();

                fileReader.readAsDataURL(file);

                fileReader.onloadend = () => (
                    setPreviews((prev) => ([
                        ...prev,
                        {
                            file,
                            info,
                            type,
                            id: nanoid(),
                            src: fileReader.result as string,
                        },
                    ]))
                );
            } else {
                setPreviews((prev) => ([
                    ...prev,
                    {
                        file,
                        info,
                        type,
                        src: '',
                        id: nanoid(),
                    },
                ]));
            }
        });
    };

    return { previews, loadFilesWithPreviews, setPreviews, getFilesInfo };
};

const setupFilesInfo = async (files: FileList | File[]) => {
    const fileInfos: FileInfo[] = [];

    for (const file of files || []) {
        const result = await getFilesInfo(file);

        fileInfos.push(result);
    }

    return fileInfos;
};

const getFilesInfo = async (file: File): Promise<FileInfo> => {
    const { name, size, type } = file;

    const [ __, extension ] = type.split('/');

    const fileExtension = extension?.includes('vnd')
        ? 'docx'
        : extension?.includes('plain') ? 'txt' : extension;

    const dimensions = type?.includes('image')
        ? await FileUtil.getImageDimensions(file)
        : undefined;

    const duration = /video|audio/.test(type)
        ? await FileUtil.getMediaDurationByFile(file)
        : undefined;

    return _.omitBy({
        name,
        fileExtension,
        duration,
        dimensions,
        size: FileUtil.getFileSize(size),
    }, _.isUndefined) as unknown as FileInfo;
};

const uploadFile = async (
    options: {
        id: string;
        space: StorageSpace;
        files: FileList | File[] | null;
        cancelFileUploadRef: MutableRefObject<Canceler | null>;
        filesInfo?: FileInfo[];
        setUploadProgress?: Dispatch<SetStateAction<any>>;
    },
): Promise<{
    success: boolean;
    exception?: string;
    payload?: Partial<StorageFileEntity>[];
}> => {
    const files = options.files as FileList;

    if (!files) {
        return {
            success: false,
            exception: '',
            payload: undefined,
        };
    }

    const {
        id,
        space,
        setUploadProgress,
        cancelFileUploadRef,
        filesInfo = await setupFilesInfo(files),
    } = options;

    const formData = new FormData();

    formData.append('space', space);

    Http.appendFormData(formData, 'meta', filesInfo);

    _.forEach(files, (file) => file && formData.append('files', file));

    const { success, exception, payload } = await UploadService.uploadFiles(
        id,
        formData,
        {
            setUploadProgress,
            cancelRef: cancelFileUploadRef,
            imageSizes: [ StorageImageSize.Medium, StorageImageSize.Small ],
        },
    );

    return { success, exception, payload };
};

export {
    uploadFile,
    useUploadFiles,
    setupFilesInfo,
    getFilesInfo,
};
