/**
 * AudioOptionAtom
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If, SvgComponent } from '@/cutils';

import { Caption, Checkbox } from '@exode.ru/vkui';

import { useTaskBuilderContext } from '@/components/Task/Builder';

import { CheckboxOptionAtomProps } from './CheckboxOptionAtom';


interface Props extends CheckboxOptionAtomProps {

}


const AudioOptionAtom = (props: Props) => {

    const { variant, checkbox } = props;

    const { show: { subMode } } = useTaskBuilderContext();

    const { location } = variant.attachments?.main || {};

    const [ comment, fill ] = subMode === 'result'
        ? checkbox.getCheckboxCommentAndFill(variant)
        : [ null, null ];

    return (
        <div>
            <div className="flex flex-1 w-full gap-2">
                <Checkbox checked={checkbox.getIsChecked(variant)}
                          onChange={(e) => checkbox.handleChange(variant, e.target.checked)}
                          onIcon={<SvgComponent element={checkbox.onIcon} svgProps={{ fill }}/>}
                          offIcon={<SvgComponent element={checkbox.offIcon} svgProps={{ fill }}/>}
                          className={[
                              'flex px-1.5 aspect-[1/1]',
                              subMode === 'result' ? 'pointer-events-none' : '',
                          ].join(' ')}/>

                <audio controls className="flex flex-1 vk-rounded max-w-full">
                    <source src={location}/>
                </audio>
            </div>

            <If is={!!comment}>
                <Caption className="text-subhead text-center mt-1">
                    {comment}
                </Caption>
            </If>
        </div>
    );
};


export { AudioOptionAtom };
