/**
 * LoginAuthInput
 *
 * @author: exode <hello@exode.ru>
 */

import { IsIn, IsNotEmpty, IsOptional, MinLength, ValidateIf } from 'class-validator';

import { IsPhoneNumber } from '@/shared/validators';

import { isMinMax, isNotEmpty } from '../constants';


export class LoginAuthInput {

    @IsIn([ 'phone', 'login' ])
    readonly mode?: 'phone' | 'login';

    @IsNotEmpty()
    readonly login: string;

    @ValidateIf((o) => o.mode === 'login')
    @IsNotEmpty({ message: isNotEmpty('Логин') })
    readonly emailOrLogin?: string;

    @ValidateIf((o) => o.mode === 'phone')
    @IsNotEmpty({ message: isNotEmpty('Телефон') })
    @IsPhoneNumber({ message: 'Некорректный телефон' })
    readonly phone?: string;

    @IsNotEmpty({ message: isNotEmpty('Пароль') })
    @MinLength(6, { message: isMinMax(6, 'Пароль', 'min') })
    readonly password: string;

    @IsOptional()
    @IsNotEmpty({ message: isNotEmpty('Код') })
    @MinLength(6, { message: isMinMax(6, 'Код', 'equal') })
    readonly otp?: string;

}
