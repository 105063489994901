/**
 * CreateByUnauthPayerInvoiceInput
 *
 * @author: exode <hello@exode.ru>
 */

import { IsEmail, IsIn, ValidateIf } from 'class-validator';

import { IsPhoneNumber } from '@/shared/validators';


export class CreateByUnauthPayerInvoiceInput {

    @IsIn([ 'phone', 'login' ])
    readonly mode: 'phone' | 'login';

    @IsEmail({}, { message: 'Введите корректный email' })
    @ValidateIf((o) => o.mode === 'login')
    readonly email?: string;

    @IsPhoneNumber({ message: 'Введите корректный телефон' })
    @ValidateIf((o) => o.mode === 'phone')
    readonly phone?: string;

}
