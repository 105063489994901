/**
 * EditorJsTextArea styled container
 *
 * @author: exode <hello@exode.ru>
 */

import { CSSProperties } from 'react';

import styled from 'styled-components';

import { spreadStyle } from '@/components/Atoms/Styled';


export const Container = styled.div<{
    bgColor?: string;
    isReadOnly?: boolean;
    redactorStyle?: CSSProperties;
    paragraphStyle?: CSSProperties;
}>`
    .codex-editor {
        background: ${({ bgColor }) => bgColor};
    }

    .codex-editor__redactor {
        ${({ redactorStyle }) => spreadStyle(redactorStyle)};
        min-height: ${({ redactorStyle }) => redactorStyle?.minHeight || '320px'};
        padding-bottom: ${({ redactorStyle }) => redactorStyle?.paddingBottom || '40px'} !important;
    }

    .ce-paragraph {
        ${({ paragraphStyle }) => spreadStyle(paragraphStyle)};
    }
`;
