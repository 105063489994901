/**
 * NotionPageContentElementShow
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect } from 'react';

import { NotionRenderer } from 'react-notion-x';
import { ExtendedRecordMap } from 'notion-types';

import { observer, PreferenceStore } from '@/store/preference/preference';

import { If } from '@/cutils';
import { Url } from '@/utils';
import { useI18n } from '@/hooks/core';
import { NotionPage } from '@/shared/types';
import { lazyWithRetry } from '@/helpers/react';

import { Skeleton } from '@mui/material';
import { Icon24HideOutline } from '@vkontakte/icons';

import { Placeholder } from '@/components/Atoms/Placeholder';
import { ContentElementShowProps } from '@/components/ContentElement';

import { fetchNotionPage } from './helper';
import { Container } from './NotionPageContentElementShow.styled';


interface Props extends ContentElementShowProps<NotionPage> {

}


const Pdf = lazyWithRetry(() => import('react-notion-x/build/third-party/pdf')
    .then(module => ({ default: module.Pdf })),
);

const Code = lazyWithRetry(() => import('react-notion-x/build/third-party/code')
    .then(module => ({ default: module.Code })),
);

const Modal = lazyWithRetry(() => import('react-notion-x/build/third-party/modal')
    .then(module => ({ default: module.Modal })),
);

const Equation = lazyWithRetry(() => import('react-notion-x/build/third-party/equation')
    .then(module => ({ default: module.Equation })),
);

const Collection = lazyWithRetry(() => import('react-notion-x/build/third-party/collection')
    .then(module => ({ default: module.Collection })),
);


const NotionPageContentElementShow = observer((props: Props) => {

    const { contentElement: { content: { notionUrl } } } = props;

    const { t } = useI18n('components.ContentElement.show');

    const [ error, setError ] = React.useState<string>('');
    const [ loading, setLoading ] = React.useState(true);
    const [ stylesLoaded, setStylesLoaded ] = React.useState(false);

    const [ recordMap, setRecordMap ] = React.useState<ExtendedRecordMap | undefined>(undefined);

    useEffect(() => {
        const notionId = Url.safetyParseUrl(notionUrl)?.pathname;

        if (notionId) {
            fetchNotionPage(notionId, {
                setError,
                setLoading,
                setRecordMap,
                setStylesLoaded,
            });
        }
    }, [ notionUrl ]);

    return (
        <If is={!!notionUrl}>
            <If is={!loading && stylesLoaded}>
                <If is={!!error}>
                    <Placeholder iconSize={72}
                                 icon={<Icon24HideOutline/>}
                                 header={t('notionPageNotAvailable')}
                                 content={t('contentNotFoundOrPrivate')}/>
                </If>

                <If is={!!recordMap}>
                    <Container>
                        <NotionRenderer disableHeader
                                        previewImages
                                        className="!bg-transparent"
                                        darkMode={PreferenceStore.isDark}
                                        recordMap={recordMap as ExtendedRecordMap}
                                        components={{
                                            Pdf,
                                            Code,
                                            Modal,
                                            Equation,
                                            Collection,
                                        }}/>
                    </Container>
                </If>
            </If>

            <If is={loading || !stylesLoaded}>
                <Skeleton width="100%"
                          height="350px"
                          variant="rectangular"
                          className="mt-2 mb-4 flex vk-rounded bg-hover"/>
            </If>
        </If>
    );
});


export { NotionPageContentElementShow };
