/**
 * Constants
 *
 * @author: exode <hello@exode.ru>
 */

export const isNotEmpty = (name: string, form = 'пустым') => `${name} не может быть ${form}`;

export const isMinMax = (
    length: number,
    name: string,
    restrict: 'min' | 'max' | 'equal',
    form = 'должен',
) => {
    const restriction = restrict === 'min'
        ? 'не менее'
        : restrict === 'max'
            ? 'не более'
            : '';

    return `${name} ${form} содержать ${restriction} ${length} символов`;
};
