/**
 * Console override
 *
 * @author: exode <hello@exode.ru>
 */

import { IS_PROD } from '@/root/src/env';

import { Notify } from '@/cutils';

import { Icon20BracketsSlashOutline } from '@vkontakte/icons';


const warn = console.warn;

console.warn = function (...args) {
    if (args[0].includes('Cache')) {
        if (!IS_PROD) {
            Notify.toast.error('GQL cache error', {
                position: 'bottom-right',
                icon: <Icon20BracketsSlashOutline width={20} height={20}/>,
            });
        }
    }

    warn.apply(console, args);
};
