/**
 * CourseLinkInput
 *
 * @author: exode <hello@exode.ru>
 */

import * as Yup from 'yup';


export const CourseLinkInputSchema = Yup.object().shape({
    preferenceSettings: Yup.object().shape({
        cardCustomLink: Yup.string()
            .nullable()
            .url('Введите корректный URL адрес')
            .max(255, 'URL должна быть не длиннее 255 символов'),
    }),
});
