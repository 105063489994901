/**
 * ManageSellerRequestModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Modal } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { Button, Text, Title } from '@exode.ru/vkui';
import { Icon20MessageHeart, Icon28HeartCircleOutline, Icon28LiveClockBadgeOutline } from '@vkontakte/icons';

import { Router } from '@/services/Utils/Router';
import { InlineInfoCaptionText } from '@/components/Atoms/InlineInfoCaptionText';


interface Props {
    id: string;
}


const ManageSellerRequestModal = (props: Props) => {

    const { t } = useI18n('modals.Manage.Seller.SellerRequest');

    return (
        <Modal.Card id={props.id} icon={(
            <Modal.CardIcon icon={<Icon28LiveClockBadgeOutline className="text-white"/>}/>
        )} header={(
            <Title className="modal-title" level="2" weight="3">
                {t('requestToVerificationIsSent')}
            </Title>
        )} actions={[
            <div className="flex w-full p-2">
                <Button stretched
                        size="l"
                        key="next"
                        before={<Icon28HeartCircleOutline/>}
                        onClick={() => Router.replaceModal()}
                        data-test="partner-request-modal.applied.button.ok">
                    {t('wellWillWait')}
                </Button>
            </div>,
        ]}>
            <Modal.Body className="flex flex-col">
                <Text className="mt-4 text-center">
                    {t('weWillCheckItSoon')}
                </Text>

                <InlineInfoCaptionText className="m:px-3 my-5"
                                       text={t('weWillNotifyByEmailOrMessages')}
                                       icon={<Icon20MessageHeart fill="var(--accent)" className="m:hidden"/>}/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { ManageSellerRequestModal };
