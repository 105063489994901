/**
 * DialogContext
 *
 * @author: exode <hello@exode.ru>
 */

import React, { createContext, ReactElement, useContext } from 'react';


interface DialogContext {

}

interface DialogProviderProps {
    value: DialogContext;
    children: ReactElement | ReactElement[];
}


/**
 * Dialog context
 */
const DialogContext = createContext<DialogContext>({});

/**
 * Dialog provider
 * @constructor
 */
const DialogProvider = (props: DialogProviderProps) => {
    return (
        <DialogContext.Provider value={props.value}>
            {props.children}
        </DialogContext.Provider>
    );
};

/**
 * UseDialogContext hook
 * @returns {DialogContext}
 */
const useDialogContext = (): DialogContext => useContext(DialogContext);


export {
    DialogProvider,
    useDialogContext,
};
