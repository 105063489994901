/**
 * CheckpointContentElementEdit
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useCallback } from 'react';

import { Formik } from 'formik';

import { AutoSaveForm, Field } from '@/cutils';

import { CheckpointContent } from '@/shared/types';
import { ContentElementType } from '@/codegen/graphql';
import { useI18n, useOnceUpdate } from '@/hooks/core';

import { Cell, Checkbox, FormItem, Input } from '@exode.ru/vkui';

import { ColorPicker } from '@/components/Atoms/ColorPicker';
import { TextAccordion } from '@/components/Atoms/TextAccordion';
import { ContentElementEditProps, getContentElementContextValue } from '@/components/ContentElement';

import { CheckpointContentElementShow } from '../show/CheckpointContentElementShow';
import { BlockHeaderPart, BlockWrapperPart } from '../parts/edit';


interface Props extends ContentElementEditProps<CheckpointContent> {

}


const CheckpointContentElementEdit = (props: Props) => {

    const {
        list,
        loading,
        onEdit,
        update,
        onDelete,
        contentElement,
    } = props;

    const { t } = useI18n('components.ContentElement.edit');

    const {
        edit: {
            blockHeaderProps = {},
            blockWrapperProps = {},
            BlockWrapper = BlockWrapperPart,
        } = {},
    } = getContentElementContextValue(ContentElementType.Checkpoint);

    const {
        initialValues,
        handleUpdateSubmit,
    } = useOnceUpdate(
        _.pick(contentElement, [ 'content' ]),
        async (values) => update?.(values),
    );

    const debouncedCallback = useCallback(
        _.debounce((callback) => callback(), 500),
        [ initialValues ],
    );

    return (
        <Formik initialValues={initialValues} onSubmit={handleUpdateSubmit}>
            {({
                  values,
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  values: { content },
              }) => (
                <AutoSaveForm onChange={() => handleSubmit()} onInstantChange={() => onEdit?.()}>
                    <BlockWrapper index={list?.index} {...blockWrapperProps}>
                        <BlockHeaderPart list={list}
                                         loading={loading}
                                         onDelete={onDelete}
                                         type={ContentElementType.Checkpoint}
                                         {...blockHeaderProps}/>

                        <CheckpointContentElementShow contentElement={{
                            ...contentElement,
                            myInteraction: null,
                            content: values.content,
                        }}/>

                        <TextAccordion className="mt-2" text={t('settings')}>
                            <div className="flex flex-col items-center gap-3 mt-4 mb-2.5 w-full">
                                <FormItem top={t('buttonText')} className="p-0 w-full">
                                    <Input name="content.text"
                                           className="w-full"
                                           value={content.text}
                                           placeholder={t('enterText')}
                                           onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                                </FormItem>

                                <FormItem className="p-0 w-full">
                                    <Checkbox hasHover={false}
                                              hasActive={false}
                                              checked={!!content.message?.show}
                                              className="w-full checkbox-origin"
                                              description={t('showMessageAfterClickDescription')}
                                              onChange={(e) => {
                                                  onEdit?.();

                                                  setFieldValue(
                                                      'content.message.show',
                                                      e.target.checked,
                                                  );

                                                  debouncedCallback(() => handleSubmit());
                                              }}>
                                        {t('showMessageAfterClick')}
                                    </Checkbox>
                                </FormItem>

                                <FormItem className="p-0 w-full">
                                    <Cell hasHover={false}
                                          hasActive={false}
                                          subtitle={t('hereYouCanChangeBgColor')}
                                          after={(
                                              <ColorPicker color={content.bgColor || ''} onChange={(color) => {
                                                  onEdit?.();
                                                  setFieldValue('content.bgColor', color);
                                                  debouncedCallback(() => handleSubmit());
                                              }}/>
                                          )}>
                                        {t('buttonColor')}
                                    </Cell>
                                </FormItem>

                                <FormItem className="p-0 w-full">
                                    <Cell hasHover={false}
                                          hasActive={false}
                                          subtitle={t('hereYouCanChangeTextColor')}
                                          after={(
                                              <ColorPicker color={content.textColor || ''} onChange={(color) => {
                                                  onEdit?.();
                                                  setFieldValue('content.textColor', color);
                                                  debouncedCallback(() => handleSubmit());
                                              }}/>
                                          )}>
                                        {t('textColor')}
                                    </Cell>
                                </FormItem>
                            </div>
                        </TextAccordion>
                    </BlockWrapper>
                </AutoSaveForm>
            )}
        </Formik>
    );
};


export { CheckpointContentElementEdit };
