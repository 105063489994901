/**
 * Helper
 *
 * @author: exode <hello@exode.ru>
 */

import { ExtendedRecordMap } from 'notion-types';


export const loadNotionStyles = async () => {
    /** @ts-ignore @aslan */
    await import('react-notion-x/src/styles.css');
};

export const fetchNotionPage = async (
    notionId: string,
    options: {
        setError: (error: string) => void;
        setLoading: (loading: boolean) => void;
        setRecordMap: (data: ExtendedRecordMap | undefined) => void;
        setStylesLoaded: (loading: boolean) => void;
    },
) => {
    const {
        setError,
        setLoading,
        setRecordMap,
        setStylesLoaded,
    } = options;

    try {
        setLoading(true);
        setError('');

        const [ response ] = await Promise.all([
            fetch(`https://notion-api.splitbee.io/v1/page/${notionId}`),
            loadNotionStyles().then(() => setStylesLoaded(true)),
        ]);

        if (!response.ok) {
            throw new Error('Failed to fetch page');
        }

        const data = await response.json();
        const processedData = processRecordMap(data);

        if (!processedData) {
            throw new Error('INVALID_PAGE_DATA_RECEIVED');
        }

        setRecordMap(processedData);
    } catch (err) {
        setRecordMap(undefined);
        setError('FAILED_TO_LOAD_NOTION_PAGE');
    } finally {
        setLoading(false);
    }
};

export const processRecordMap = (data: any): ExtendedRecordMap | null => {
    if (!data || typeof data !== 'object') {
        return null;
    }

    const recordMap: ExtendedRecordMap = {
        block: {},
        collection: {},
        collection_view: {},
        collection_query: {},
        notion_user: {},
        signed_urls: {},
    };

    Object.entries(data).forEach(([ id, block ]: [ string, any ]) => {
        if (block && typeof block === 'object' && 'value' in block) {
            recordMap.block[id] = {
                role: block.role || 'reader',
                value: {
                    id: block.value.id || id,
                    type: block.value.type || 'text',
                    properties: block.value.properties || {},
                    format: block.value.format || {},
                    content: block.value.content || [],
                    ...block.value,
                },
            };
        }
    });

    return recordMap;
};
