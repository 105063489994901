/**
 * SellerProvider component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { createContext, ReactElement, useContext, useEffect, useState } from 'react';

import { UserAuthStore } from '@/store/user/auth';
import { observer, SellerStore } from '@/store/user/seller';

import { useSeller } from '@/hooks/apollo';
import { UserService } from '@/services/User/User';
import { UserRbacService } from '@/services/User/Rbac';

import { Storage } from '@/api/storage';
import { GqlResult } from '@/types/graphql';
import { DocumentEvent } from '@/types/window';
import { GetUserSellersQueryResult, Permission } from '@/codegen/graphql';
import { SellerOfCurrentUser, SellerRequestOfCurrentUser, SellersOfCurrentUser } from '@/types/seller';


interface Props {
    children: ReactElement;
}

interface SellerContext {
    isAdmin: boolean;
    isSeller: boolean;
    seller: SellerOfCurrentUser;
    sellers: GqlResult<GetUserSellersQueryResult>['sellerFindManyByUser']['items'];
    hasSelectedSeller: boolean;
    refetchSelectSellerQueries: () => void;
    setStateOnCurrentSellerChange: (
        seller: SellerOfCurrentUser | SellersOfCurrentUser[number],
    ) => void;
}


const SellerContext = createContext<SellerContext>({
    isAdmin: false,
    isSeller: false,
    seller: undefined,
    sellers: [],
    hasSelectedSeller: false,
    refetchSelectSellerQueries: () => ({}),
    setStateOnCurrentSellerChange: (
        seller: SellerOfCurrentUser | SellersOfCurrentUser[number],
    ) => ({}),
});


const SellerProvider = observer((props: Props) => {

    const { id: userId, permissions } = UserAuthStore.user || {};

    const {
        getCurrentSellerLazy,
        getCurrentSellerLazyRefetch,
        currentSellerLazy,
        getUserSellersLazy,
        userSellersLazy,
        getCurrentSellerBecomeRequestLazy,
        getCurrentSellerBecomeRequestLazyRefetch,
        refetchSelectSellerQueries,
    } = useSeller();

    const { handleSelectSeller } = useSeller();

    const [ isAdmin, setIsAdmin ] = useState(UserService.isAdmin);
    const [ isSeller, setIsSeller ] = useState(UserService.isSeller);

    const [ hasSelectedSeller, setHasSelectedSeller ] = useState(!!Storage.get('seller:id'));

    /** Centralized place manage states */
    const setStateOnCurrentSellerChange = (
        seller: SellerOfCurrentUser | SellersOfCurrentUser[number],
    ) => {
        if (!seller) {
            return;
        }

        SellerStore.merge({
            managerPermissions: seller?.currentManager.permissions || [],
        });

        /** If before wasn't seller - set isSeller = true */
        if (!isSeller) {
            setIsSeller(true);
        }

        /** If before wasn't hasSelectedSeller - set hasSelectedSeller = true */
        if (!hasSelectedSeller) {
            setHasSelectedSeller(true);
        }
    };

    /** Listen to document event */
    const handleSellerIdChanged = async () => {
        /** Refetch current seller */
        const { data: { sellerCurrentUserSeller: seller } } = await getCurrentSellerLazyRefetch();

        if (seller) {
            setStateOnCurrentSellerChange(seller);

            await handleSelectSeller(
                seller.id,
                seller.currentManager.permissions || [],
                undefined,
                true,
            );
        }

        /** Refetch become request for new seller */
        const {
            data: {
                sellerBecomeRequestFindOneByCurrentUser: request,
            },
        } = await getCurrentSellerBecomeRequestLazyRefetch();

        onSellerBecomeRequestCompleted(request);
    };

    /** Get current seller by LS and write to currentSellerLazy */
    const handleGetCurrentSeller = () => {
        return getCurrentSellerLazy({
            onCompleted: async (data) => {
                const { sellerCurrentUserSeller } = data;

                setStateOnCurrentSellerChange(sellerCurrentUserSeller);
            },
        });
    };

    /** Get current seller become request */
    const handleGetCurrentSellerRequest = () => {
        return getCurrentSellerBecomeRequestLazy({
            onCompleted: ({ sellerBecomeRequestFindOneByCurrentUser: request }) => {
                onSellerBecomeRequestCompleted(request);
            },
        });
    };

    /** Handle seller become request completed */
    const onSellerBecomeRequestCompleted = (request: SellerRequestOfCurrentUser) => {
        SellerStore.merge({
            requestStatus: request?.status || undefined,
        });

        request?.id
            ? Storage.set('seller:become-partner-status', request.status)
            : Storage.destroy('seller:become-partner-status');
    };

    useEffect(() => {
        setIsAdmin(UserService.isAdmin);

        /** Become new seller - update states */
        if (!isSeller && UserService.isSeller) {
            setIsSeller(UserService.isSeller);
        }
    }, [ permissions ]);

    useEffect(() => {
        if (!userId) {
            setIsAdmin(false);
            setIsSeller(false);

            return;
        }

        getUserSellersLazy({
            onCompleted: async ({ sellerFindManyByUser: { items } }) => {
                const [ first ] = items || [];

                if (!first) {
                    return SellerStore.merge({ managerPermissions: [] });
                }

                /** If not selected current seller - make first as a default */
                if (!hasSelectedSeller) {
                    Storage.set('seller:id', first.id);

                    await handleGetCurrentSeller();
                    await handleGetCurrentSellerRequest();
                }
            },
        });

        if (hasSelectedSeller) {
            handleGetCurrentSeller();

            handleGetCurrentSellerRequest();
        }
    }, [ userId ]);

    useEffect(() => {
        document.addEventListener(
            DocumentEvent.SellerIdIsChanged,
            handleSellerIdChanged,
        );

        return () => {
            document.removeEventListener(
                DocumentEvent.SellerIdIsChanged,
                handleSellerIdChanged,
            );
        };
    }, []);

    return (
        <SellerContext.Provider value={{
            isAdmin,
            isSeller,
            hasSelectedSeller,
            refetchSelectSellerQueries,
            setStateOnCurrentSellerChange,
            seller: currentSellerLazy?.sellerCurrentUserSeller,
            sellers: userSellersLazy?.sellerFindManyByUser.items || [],
        }}>
            {props.children}
        </SellerContext.Provider>
    );
});


/**
 * UseSellerContext hook
 * @returns {SellerContext}
 */
const useSellerContext = (): SellerContext => useContext(SellerContext);

/**
 * UseSellerInfo hook
 * @param {{editManager?: {id: number, isSellerOwner: boolean}}} options
 */
const useSellerInfo = (
    options: {
        editManager?: {
            id: number;
            isSellerOwner: boolean;
        }
    } = {}) => {

    const { seller } = useSellerContext();

    const { currentManager } = seller || {};

    const allowedToEdit = currentManager?.isSellerOwner
        || (
            currentManager?.id !== options.editManager?.id
            && !options.editManager?.isSellerOwner
            && UserRbacService.canAny(
                SellerStore.permissions,
                [ Permission.SellerManageManagers ],
            )
        );

    return {
        currentManager,
        forbiddenToEdit: !allowedToEdit,
    };
};


export {
    SellerProvider,
    useSellerInfo,
    useSellerContext,
};

