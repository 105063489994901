/**
 * Мета информация (мета теги страницы и т.д.)
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { PageParams } from 'router.tsx';

import { FetchPolicy } from '@apollo/client';

import { Language } from '@/codegen/graphql';
import { MetaPropsType } from '@/types/page';
import { RoutePathType } from '@/router/paths';

/** Page meta requests */
import { lessonMetaRequest } from '@/pages/Course/Study/meta';
import { profileMetaRequest } from '@/pages/Profile/Profile/meta';
import { courseMetaRequest } from '@/pages/Course/Information/meta';
import { tournamentMetaRequest, tournamentMetaSoftRequest } from '@/pages/Tournament/Lobby/meta';


/** /Page meta requests */


export interface MetaRequestOptions {
    fetchPolicy?: FetchPolicy;
    language?: Language;
}

type PageItemType = Partial<MetaPropsType & {
    extends: RoutePathType;
    middleware: Function;
    canonicalParams: readonly string[];
    request: (
        id: RoutePathType,
        params: PageParams,
        options?: MetaRequestOptions,
    ) => Promise<Partial<MetaPropsType>>;
}>

type MetaObjectType = {
    pages: { [key in RoutePathType]: PageItemType };
    common: {
        keywords: string;
    }
};


const MetaContent: MetaObjectType = {
    pages: {

        /** APP LOGIC ROUTES: */

        /** Default (main) route */
        '/': {
            title: 'Твоя платформа для развития',
            icon: '/static/images/brand/biz/icon.png',
            description: [
                'Онлайн курсы и турниры по различным темам и предметам обучения.',
                'Инструменты для онлайн преподавания, сосредоточенные в одном месте.',
                'Подготовка к ЕГЭ по русскому языку, математике профильной и базовой, обществознанию и другим предметам.',
                'Саморазвиваться — легко!',
            ].join(' '),
            content: [
                'Интересная подготовка к ЕГЭ и Саморазвитие в любых направлениях.',
                'Маркетплейс курсов для учеников и преподавателей.',
                'Подготовка к ЕГЭ по русскому языку, математике профильной и базовой, обществознанию и другим предметам.',
                'Подготовка к ОГЭ, дошкольная подготовка.',
            ].join(' '),
            keywords: [
                'подготовка к ЕГЭ, вопросы и ответы exode, турниры exode, видеокурсы exode',
                'обучающий курс егэ, репетиторы exode, бизнес курсы, SMM курсы, онлайн курсы по психологии.',
            ].join(', '),
        },

        /** Core */
        '/404': {
            title: 'Страница не найдена',
            description: [
                'Страница, на которую вы пытаетесь перейти, кажется, не существует.',
                'Обычно это случается из-за неправильной или устаревшей ссылки.',
            ].join(' '),
            keywords: 'страница не найдена, 404, нет такой страницы, нет страницы',
        },
        '/app': {
            extends: '/',
            title: 'Мобильное приложение exode.ru — AppStore, GooglePlay',
            description: 'Всегда под рукой - официальные удобные приложение exode для android и iOS. Не трать время попусту находясь вне дома',
        },

        /** TabRoutes (except main as default) */
        '/actual': {
            title: 'Актуальное',
            description: 'Лента новейших материалов со всего exode.',
            keywords: 'актуальное exode, лента exode',
        },
        '/education': {
            extends: '/',
            title: 'Моё обучение — курсы и занятия в образовательной социальной сети exode',
        },
        '/catalog': {
            extends: '/',
            title: 'Каталог курсов',
        },
        '/catalog/:page([0-9]+)': {
            extends: '/catalog',
            title: 'Каталог курсов',
        },
        '/tournaments': {
            title: 'Турниры — соревнуйся и оттачивай знания вместе с тысячами соперников',
            description: [
                'Теперь можно играть и учиться одновременно!',
                'Скорость и ум - турниры в exode. Нескучная подготовка к ЕГЭ по всем предметам',
            ].join(' '),
            keywords: [
                'турниры exode, соревнования exode, блиц-турнир exode, блиц exode, подготовка',
                'играючи exode, интересная подготовка к егэ exode, подготовка exode',
            ].join(' '),
        },
        '/menu': {
            title: 'Меню',
            description: 'Главное меню exode.',
            keywords: 'меню exode, управление exode, разделы exode, пункты меню exode',
        },


        /** FEATURE-BASE ROUTES: (A-Z) */

        /** AchievementRoutes */
        '/achievements': {
            title: 'Достижения',
            description: 'Достижения и титулы в exode. Награды за прогресс и победы в турнирах.',
            keywords: 'достижения exode, награды exode, титулы exode, прогресс exode, ачивки exode',
        },

        /** BookmarkRoutes */
        '/bookmarks': {
            title: 'Мои заметки',
            description: 'Сохранённые записи и галереи из конспектов - заметки в exode легко создавать, а также удобно делиться.',
            keywords: [
                'заметки exode, закладки exode, записи exode, сохранённое exode, конспекты exode',
                'галереи заметок exode, сохранённые записи exode',
            ].join(', '),
        },

        /** ChatRoutes */
        '/chat': {
            title: 'Мессенджер',
            description: 'Общение в exode, чаты и группы для совместного обучения с друзьями или лёгких бесед.',
            keywords: 'сообщения exode, чаты exode, каналы exode, группы exode, общение exode',
        },
        '/chat/attachments': {
            extends: '/chat',
        },
        '/chat/members/:page([0-9]+)': {
            extends: '/chat',
        },

        /** Collaborations */
        '/become/partner': {
            extends: '/become/partner/:requestId([0-9]+)',
        },
        '/become/partner/:requestId([0-9]+)': {
            title: 'Партнерская программа',
            description: 'Начните зарабатывать на продажах курсов вместе с exode. 1 - Заполните форму. 2 - Опубликуйте курс. 3 - Готово! 🤑',
            keywords: 'партнерство exode, зарабатывать на продаже курсов, инфобизнес экзод, стать партнером',
        },

        /** CompanyRoutes */
        '/about': {
            title: 'О проекте exode — Подробнее об образовательной платформе.',
            description: [
                'Мы стремимся сделать образование доступным для всех, независимо от местоположения или социального статуса.',
                'Объединяя учащихся, преподавателей и ученых со всего мира - мы создаем',
                'инклюзивное и вдохновляющее образовательное сообщество, способствующее личностному росту и прогрессу общества.',
            ].join(' '),
            keywords: [
                'о exode, что такое exode, для чего нужен сайт exode.ru, ',
                'кто такой и зачем нужен exode, для чего приложение exode, зачем приложение exode, зачем exode',
            ].join(' '),
        },
        '/support': {
            title: 'Центр знаний и заботы',
            description: 'Помощь в использовании проекта exode.',
            keywords: [
                'помощь использования exode',
                'поддержка, помощь, условия использования exode',
            ].join(', '),
        },
        '/support/account-deletion-request': {
            extends: '/support',
        },

        /** CourseRoutes */
        '/education/:page([0-9]+)': {
            extends: '/education',
        },
        '/courses/:page([0-9]+)/:courseId([0-9]+)': {
            request: courseMetaRequest,
        },
        '/courses/:page([0-9]+)/:courseId([0-9]+)/study/empty': {
            request: courseMetaRequest,
        },
        '/course/:courseId([0-9_A-Za-z]+)': {
            extends: '/courses/:page([0-9]+)/:courseId([0-9]+)',
        },
        '/courses/:page([0-9]+)/:courseId([0-9]+)/study/:lessonId([0-9]+)': {
            request: lessonMetaRequest,
        },
        '/courses/enrolled': {
            extends: '/',
            title: 'Всё обучение',
            description: 'Курсы за экзы и платные курсы, открытые в вашем аккаунте.',
        },

        /** ManageRoutes */
        '/manage/seller/product-managers/add': {
            title: 'Создание коллаборанта',
            extends: '/',
        },
        '/manage/seller/product-managers/:page([0-9]+)': {
            title: 'Менеджера продуктов',
            extends: '/',
        },
        '/manage/seller/managers/add': {
            title: 'Добавление сотрудника',
            extends: '/',
        },
        '/manage/seller/managers/:page([0-9]+)': {},
        '/manage/seller/managers/:page([0-9]+)/:managerId([0-9]+)/permissions': {},
        '/manage/seller/product-managers/:page([0-9]+)/:productManagerId([0-9]+)/permissions': {},
        '/manage/seller/managers/:page([0-9]+)/:managerId([0-9]+)/info': {},
        '/manage/seller/managers/:page([0-9]+)/:managerId([0-9]+)/statistics': {},
        '/admin/users/:page([0-9]+)': {
            title: 'Пользователи платформы',
            extends: '/',
        },
        '/admin/partner-requests/:page([0-9]+)': {
            title: 'Список заявок партнерства',
            extends: '/',
        },
        '/admin/partner-requests/:page([0-9]+)/:requestId([0-9]+)': {
            title: 'Заявка партнерства',
            extends: '/',
        },
        '/admin/articles/:page([0-9]+)': {
            title: 'Статьи',
            extends: '/',
        },
        '/admin/article/:articleId([0-9]+)': {
            title: 'Редактирование статьи',
            extends: '/admin/articles/:page([0-9]+)',
        },
        '/admin/users/:page([0-9]+)/:userId([0-9]+)/permissions': {
            title: 'Права доступа пользователя',
            extends: '/',
        },
        '/admin/users/:page([0-9]+)/:userId([0-9]+)/info': {
            title: 'Информация о пользователе',
            extends: '/',
        },
        '/admin/users/:page([0-9]+)/:userId([0-9]+)/statistics': {
            title: 'Статистика пользователя',
            extends: '/',
        },
        '/manage': {
            title: 'Панель управления',
            extends: '/',
        },
        '/manage/seller': {
            title: 'Аккаунт продавца',
            extends: '/',
        },
        '/manage/course/:courseId([0-9]+)/launches/:page([0-9]+)': {
            title: 'Запуски',
            extends: '/manage/courses/:page([0-9]+)',
        },
        '/manage/course/:courseId([0-9]+)/groups/:page([0-9]+)': {
            title: 'Группы',
            extends: '/manage/courses/:page([0-9]+)',
        },
        '/manage/course/:courseId([0-9]+)/groups/:page([0-9]+)/:groupId([0-9]+)/students': {
            title: 'Ученики группы',
            extends: '/manage/courses/:page([0-9]+)',
        },
        '/manage/course/:courseId([0-9]+)/groups/:page([0-9]+)/:groupId([0-9]+)/settings': {
            title: 'Настройки группы',
            extends: '/manage/courses/:page([0-9]+)',
        },
        '/manage/course/:courseId([0-9]+)/launches/:page([0-9]+)/:launchId([0-9]+)/settings': {
            title: 'Настройки запуска',
            extends: '/manage/courses/:page([0-9]+)',
        },
        '/manage/course/:courseId([0-9]+)/launches/:page([0-9]+)/:launchId([0-9]+)/publication': {
            title: 'Публикация запуска',
            extends: '/manage/courses/:page([0-9]+)',
        },
        '/manage/course/:courseId([0-9]+)/discounts/:page([0-9]+)': {
            title: 'Промокоды',
            extends: '/',
        },
        '/manage/invoices/:page([0-9]+)': {
            title: 'Список инвойсов',
            extends: '/',
        },
        '/manage/invoices/:page([0-9]+)/:invoiceUuid': {
            title: 'Информация об инвойсе',
            extends: '/',
        },
        '/manage/payments/:page([0-9]+)': {
            title: 'Список платежей',
            extends: '/',
        },
        '/manage/refunds/:page([0-9]+)': {
            title: 'Список возвратов',
            extends: '/',
        },
        '/manage/seller/documents': {
            title: 'Документы продавца',
            extends: '/',
        },
        '/manage/seller/become-request/:requestId([0-9]+)': {
            title: 'Заявка партнерства',
            extends: '/',
        },
        '/manage/practice/chat': {
            title: 'Проверка практики учеников',
            extends: '/',
        },
        '/manage/homeworks/:page([0-9]+)': {
            extends: '/',
        },
        '/manage/students/:page([0-9]+)': {
            title: 'Мои ученики',
            extends: '/',
        },
        '/manage/students/:page([0-9]+)/:courseId([0-9]+)': {
            title: 'Мои ученики',
            extends: '/',
        },
        '/manage/students/:page([0-9]+)/:courseId([0-9]+)/:launchId([0-9]+)': {
            title: 'Мои ученики',
            extends: '/',
        },
        '/manage/payouts/:page([0-9]+)': {
            title: 'Вывод средств',
            extends: '/',
        },
        '/manage/seller/balance/:page([0-9]+)': {
            title: 'Операции по балансу',
            extends: '/',
        },
        '/manage/reports/sales': {
            title: 'Отчеты по доходу',
            extends: '/',
        },
        '/manage/tournament/create': {
            title: 'Создание официального турнира',
            extends: '/',
        },
        '/manage/articles': {
            title: 'Мои статьи',
            extends: '/',
        },
        '/manage/articles/:articleId([0-9]+)': {
            title: 'Статья',
            extends: '/',
        },
        '/manage/stories': {
            title: 'Сторис видео',
            extends: '/',
        },
        '/manage/stories/create': {
            title: 'Создание истории',
            extends: '/',
        },
        '/manage/stories/:storiesId([0-9]+)': {
            title: 'Просмотр сторис',
            extends: '/',
        },
        '/manage/tasks/:page([0-9]+)': {
            title: 'База заданий',
            extends: '/',
        },
        '/manage/tasks/:page([0-9]+)/:taskId([0-9_A-Za-z-_]+)': {
            title: 'Задание из базы заданий',
            extends: '/',
        },
        '/manage/tasks/create': {
            title: 'Создание задания',
            extends: '/',
        },
        '/manage/courses/:page([0-9]+)': {
            title: 'Мои продукты',
            extends: '/',
        },
        '/manage/course/:courseId([0-9]+)/content/:page([0-9]+)/:lessonId([0-9]+)': {
            title: 'Редактирование урока',
            extends: '/manage/courses/:page([0-9]+)',
        },
        '/manage/course/:courseId([0-9]+)/content/:page([0-9]+)/:lessonId([0-9]+)/settings': {
            title: 'Настройки урока',
            extends: '/manage/courses/:page([0-9]+)',
        },
        '/manage/course/:courseId([0-9]+)/content/:page([0-9]+)/:lessonId([0-9]+)/practice': {
            title: 'Редактирование практики к уроку',
            extends: '/manage/courses/:page([0-9]+)',
        },
        '/manage/course/:courseId([0-9]+)/navigator': {
            title: 'Информация',
            extends: '/manage/courses/:page([0-9]+)',
        },
        '/manage/course/:courseId([0-9]+)/information/branding': {
            title: 'Брендирование - фото и видео',
            extends: '/manage/courses/:page([0-9]+)',
        },
        '/manage/course/:courseId([0-9]+)/information/details': {
            title: 'Детали',
            extends: '/manage/courses/:page([0-9]+)',
        },
        '/manage/course/:courseId([0-9]+)/information/actions': {
            title: 'Действия',
            extends: '/manage/courses/:page([0-9]+)',
        },
        '/manage/course/:courseId([0-9]+)/information/author': {
            title: 'Авторы контента',
            extends: '/manage/courses/:page([0-9]+)',
        },
        '/manage/course/:courseId([0-9]+)/information/seo': {
            title: 'SEO оптимизация',
            extends: '/manage/courses/:page([0-9]+)',
        },
        '/manage/course/:courseId([0-9]+)/content/:page([0-9]+)': {
            title: 'Содержание',
            extends: '/manage/courses/:page([0-9]+)',
        },
        '/manage/course/:courseId([0-9]+)/faq': {
            title: 'FAQ - ответы на сомнения',
            extends: '/manage/courses/:page([0-9]+)',
        },
        '/manage/course/:courseId([0-9]+)/faq/:faqId([0-9]+)': {
            title: 'Редактирование FAQ',
            extends: '/manage/courses/:page([0-9]+)',
        },
        '/manage/course/:courseId([0-9]+)/certificates': {
            extends: '/manage/courses/:page([0-9]+)',
        },
        '/manage/course/:courseId([0-9]+)/comments': {
            extends: '/manage/courses/:page([0-9]+)',
        },
        '/manage/product/:productId([0-9]+)/discounts/:page([0-9]+)': {
            title: 'Промокоды',
            extends: '/',
        },
        '/school/create': {},
        '/manage/school': {},
        '/manage/school/webhooks': {},
        '/manage/school/settings/basic': {},
        '/manage/school/settings/preferences': {},
        '/manage/school/settings/blocks': {},
        '/manage/school/settings/left-menu': {},
        '/manage/school/settings/custom-code': {},
        '/manage/school/acquiring': {},
        '/manage/school/subscription': {},
        '/manage/school/disciplines': {},
        '/manage/school/analytics': {},
        '/manage/school/notifications': {},
        '/manage/school/legal': {},
        '/manage/school/privacy': {},
        '/manage/school/users/:page([0-9]+)': {},
        '/manage/school/managers/:page([0-9]+)': {},
        '/manage/school/managers/:page([0-9]+)/:managerId([0-9]+)/permissions': {},
        '/manage/school/managers/add': {},
        '/manage/school/product-managers/:page([0-9]+)': {},
        '/manage/school/product-managers/:page([0-9]+)/:productManagerId([0-9]+)/permissions': {},
        '/manage/school/product-managers/add': {},
        '/manage/my-schools': {},

        /** ExamRoutes */
        '/exams-timeout': {
            title: 'Сколько осталось до экзамена? - Время до ЕГЭ, реальный таймер.',
            description: 'Следим за датами проведения основной волны ЕГЭ. Пора уже начать готовиться — cкачай приложение exode',
            keywords: 'время до егэ, времени до егэ, сколько времени до егэ, сколько осталось до егэ, сколько времени осталось до егэ',
        },
        '/exams-subject/:subject([a-z-]+)': {
            extends: '/exams-timeout',
        },

        /** ProfileRoutes */
        '/friends': {
            title: 'Друзья',
            description: 'Приглашайте друзей и готовьтесь к ЕГЭ. Вместе - веселее!',
            keywords: 'друзья exode, знакомые exode, одноклассники exode, приятели exode, люди exode, приглашение exode',
        },
        '/likes': {
            title: 'Понравилось',
            description: 'Список публикаций и турниров, которые вам понравились.',
            keywords: 'лайки exode, понравилось exode, мои лайки exode, избранное exode',
        },
        '/notifications': {
            title: 'Уведомления',
            description: 'Список всех уведомлений о событиях в вашем аккаунте exode',
            keywords: 'оповещения exode, уведомления exode',
        },
        '/@:userId([0-9_A-Za-z]+)': {
            request: profileMetaRequest,
            canonicalParams: [ 'test_id' ],
            title: 'Страница профиля',
            description: 'Профиль пользователя exode.ru',
            keywords: 'профиль пользователя exode, страница профиля exode, аккаунт пользователя exode, моя страница exode',
        },
        '/@:userId([0-9_A-Za-z]+)/friends': {
            extends: '/@:userId([0-9_A-Za-z]+)',
        },
        '/my-subjects': {
            title: 'Мои предметы',
            description: 'Выбор предмета для подготовки. Рекомендации турниров, вопросов и чатов основаны на вашем выборе.',
            keywords: [
                'математика exode, русский exode, общество exode, обществознание exode, история exode',
                'физика exode, химия exode, информатика exode, биология exode, литература exode, география exode',
            ].join(', '),
        },
        '/share': {
            extends: '/@:userId([0-9_A-Za-z]+)',
            title: 'Поделиться ссылкой',
        },
        '/wallet': {
            title: 'Мой баланс',
            description: [
                'Баланс твоего счёта в exode. Пополняй и переводы экзы любому пользователю exode.',
                'Получай экзы за победы, достижения, приглашение друзей и многое другое!',
            ].join(' '),
            keywords: 'баланс exode, кошелёк exode, счёт exode, экзы exode, пополнение баланса exode, перевод экзов exode',
        },
        '/wallet/:page([0-9]+)': {
            extends: '/wallet',
        },
        '/wallet/send': {
            title: 'Отправить экзы',
            extends: '/wallet',
        },
        '/wallet/send/:userId([0-9]+)': {
            title: 'Отправить экзы',
            extends: '/wallet',
        },
        '/wallet/send/successful': {
            title: 'Успешно отправлено',
            extends: '/wallet',
        },

        /** PromotionRoutes */
        '/promo/bonus': {
            title: 'Получить бонус exode — Всё просто, как 1×1',
            description: 'Бесплатное пополнение баланса exode. Бонус получает как отправитель, так и получатель.',
            keywords: 'как пополнить баланс exode, бесплатное пополнение баланса exode, приглашение друзей exode, бонусная ссылка exode',
        },
        '/referral/:referralId([0-9_A-Za-z-_]+)': {
            extends: '/promo/bonus',
        },

        /** QuestionRoutes */
        '/my-questions': {
            title: 'Мои вопросы в exode — Q&A',
            description: [
                'Ответы на любые вопросы по ЕГЭ, домашней работе или поступлению в вуз.',
                'Спрашивай и отвечай в exode. Учиться — легко!',
            ].join(', '),
            keywords: [
                'решить задание, задать вопрос, q&a, вопросы и ответы, задание егэ, домашняя работа',
                'знания, школьные знания',
            ].join(', '),
        },

        /** RangeRoutes */
        '/range': {
            title: 'Рейтинг',
            description: [
                'Рейтинг по достижениям, победам и количеству экзов за неделю, месяц и всё время.',
                'Для самых ловких, самых смелых, умных, быстрых и умелых на ЕГЭ.',
            ].join(', '),
            keywords: [
                'рейтинг exode, турнирная таблица exode, рейтинг достижений exode, рейтинг побед exode',
                'рейтинг в моём городе exode, рейтинг егэ exode',
            ].join(', '),
        },

        /** TournamentRoutes */
        '/tournaments/:page([0-9]+)': {
            extends: '/tournaments',
        },
        '/tournaments/history': {
            title: 'История моих турниров',
            extends: '/tournaments',
        },
        '/tournaments/history/ongoing/:page([0-9]+)': {
            extends: '/tournaments/history',
        },
        '/tournaments/history/waiting/:page([0-9]+)': {
            extends: '/tournaments/history',
        },
        '/tournaments/history/finished/:page([0-9]+)': {
            extends: '/tournaments/history',
        },
        '/tournaments/create': {
            extends: '/tournaments/history',
            title: 'Создание турнира',
            description: [
                'Создавай сражения по тестовым задачам из ЕГЭ: существуют открытые турниры, закрытые и официальные — ',
                'проводимые командой exode. Участвуй в турнирах и готовься к ЕГЭ играючи!',
            ].join(' '),
        },
        '/tournaments/subjects-select': {
            title: 'Выбор предмета',
            description: [
                'Выбирай предметы для подготовки к задачам из ЕГЭ: существуют открытые турниры, закрытые и официальные — '
                + 'проводимые командой exode. Участвуй в турнирах и готовься к ЕГЭ играючи!',
            ].join(' '),
        },
        '/tournaments/blitz-select': {
            extends: '/tournaments',
        },
        '/tournament/blitz/waiting/:subject([a-z-]+)': {
            extends: '/tournaments/history',
            title: 'Ожидание Блиц-соперника',
        },
        '/tournament/invite/:tournamentId([0-9]+)': {
            extends: '/tournaments/history',
            request: tournamentMetaRequest,
        },
        '/tournament/lobby/:tournamentId([0-9]+)': {
            extends: '/tournaments/history',
            title: 'Лобби турнира',
            request: tournamentMetaSoftRequest,
        },
        '/tournament/members/:tournamentId([0-9]+)': {
            extends: '/tournament/lobby/:tournamentId([0-9]+)',
            title: 'Участники турнира',
            request: tournamentMetaSoftRequest,
        },
        '/tournament/friends/:tournamentId([0-9]+)': {
            extends: '/tournament/lobby/:tournamentId([0-9]+)',
            title: 'Пригласить друзей',
            request: tournamentMetaSoftRequest,
        },
        '/tournament/process/:tournamentId([0-9]+)': {
            extends: '/tournaments/history',
            title: 'Турнир',
            request: tournamentMetaSoftRequest,
        },
        '/tournament/process/:tournamentId([0-9]+)/progress': {
            extends: '/tournaments/history',
            title: 'Турнир',
            request: tournamentMetaSoftRequest,
        },
        '/tournament/result/:tournamentId([0-9]+)/:page([0-9]+)': {
            extends: '/tournaments/history',
            title: 'Результаты турнира',
            request: tournamentMetaSoftRequest,
        },

        /** PayRouter */
        '/pay/:productId([0-9]+)/:priceId([0-9]+)': {
            title: 'Оплата',
        },
        '/pay/:customName([0-9_A-Za-z_-]+)/:productId([0-9]+)/:priceId([0-9]+)': {
            title: 'Оплата',
        },
        '/cart': {
            title: 'Моя корзина',
        },
        '/cart/:cartUuid([0-9_A-Za-z_-]+)': {
            title: 'Оплата заказа',
        },
        '/payment/:invoiceUuid([0-9_A-Za-z_-]+)': {
            title: 'Инвойс оплаты',
        },

        /** UserRouter */
        '/login': {
            title: 'Добро пожаловать в exode • Вход',
            description: [
                'Большая часть функций приложения — прямо в браузере! Доступ к созданию и участию в турнирах,',
                'видеокурсам, заметкам и ленте вопросов.',
            ].join(' '),
            keywords: 'вход в exode, вход в exode web, exode web login, exode web',
        },
        '/login/app': {
            extends: '/login',
        },
        '/login/recover': {
            extends: '/login',
        },
        '/login/signin': {
            extends: '/login',
        },
        '/login/email/signup': {
            extends: '/login',
        },
        '/settings': {
            title: 'Настройки',
            description: 'Подбор параметров и настроек по вашему курсу в exode. Настроены на результат.',
            keywords: 'настройки exode, параметры exode, персонализация exode',
        },
        '/settings/set-two-factor': {
            extends: '/settings',
        },
        '/settings/password': {
            extends: '/settings',
        },

        /** UtilsRouter */
        '/utils/page': {},

        /** WikiRoutes */
        '/wiki': {
            title: 'Что такое exode и как им пользоваться? • База знаний',
            description: [
                'Всё, что нужно знать, чтобы в совершенстве применять платформу exode для успешной сдачи ЕГЭ ',
                'и поступления в престижные вузы. Ответим на ваш вопрос, поможем решить проблему. База знаний exode',
            ].join(' '),
            keywords: [
                'что такое exode, как зарегистрироваться в exode, как пользоваться exode',
                'турниры exode, видеокурсы exode, подготовка к ЕГЭ exode',
            ].join(' '),
        },

    },
    common: {
        keywords: [
            ', сайт exode, экзод, приложение экзод, exode, приложение exode, exode.ru, екзод, приложение екзод, ',
            'exode_ru, exoderu, эксод, эксоде',
        ].join(', '),
    },
} as const;


_.each(MetaContent.pages, (value, key) => {
    const id = key as RoutePathType;
    const item = value as PageItemType;

    MetaContent.pages[id] = item.extends
        ? { ...MetaContent.pages[item.extends], ...item }
        : item;
});


export { MetaContent };
