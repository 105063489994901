/**
 * UpdateAuthedPasswordInput
 *
 * @author: exode <hello@exode.ru>
 */

import { IsNotEmpty, MinLength } from 'class-validator';

import { Match } from '@/shared/validators';

import { isMinMax, isNotEmpty } from '../constants';


export class UpdateAuthedPasswordInput {

    @MinLength(6, { message: isMinMax(6, 'Пароль', 'min') })
    @IsNotEmpty({ message: isNotEmpty('Пароль') })
    readonly password: string;

    @Match('password', { message: 'Пароли должны совпадать' })
    @MinLength(6, { message: isMinMax(6, 'Пароль', 'min') })
    @IsNotEmpty({ message: isNotEmpty('Пароль') })
    readonly confirmPassword: string;

}
