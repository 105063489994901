/**
 * RepliedMessageItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import moment from 'moment';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { ChatMessageItem } from '@/types/chat';
import { StorageFileType } from '@/codegen/graphql';

import { Avatar, Caption } from '@exode.ru/vkui';
import { Icon20WidgetsOutline, Icon28DocumentTextOutline } from '@vkontakte/icons';


interface Props {
    replyMessage: ChatMessageItem['replyMessage'];
    placement?: 'message' | 'above-input';
}


const RepliedMessageItem = (props: Props) => {

    const { replyMessage, placement = 'message' } = props;

    const { t } = useI18n('pages.Chat.Dialog');

    const {
        text,
        user,
        medias,
        createdAt,
    } = replyMessage || {};

    return (
        <div style={{
            borderLeft: placement === 'message'
                ? '2px solid var(--accent)'
                : '',
        }} className={[
            placement === 'above-input' ? 'ml-2 !p-0 !m-0' : '',
            'flex gap-2 flx-row flex-1 px-2 py-1 my-2',
        ].join(' ')}>
            <If is={medias?.[0]?.storage?.fileType === StorageFileType.Image}>
                <Avatar mode="app" size={32} src={medias?.[0]?.storage?.location || ''}/>
            </If>

            <If is={medias?.[0]?.storage?.fileType === StorageFileType.File}>
                <Avatar mode="app" size={32}>
                    <Icon28DocumentTextOutline width={24} height={24} fill="var(--accent)"/>
                </Avatar>
            </If>

            <If is={!!medias?.some((m) => m.widget)}>
                <Avatar size={32}>
                    <Icon20WidgetsOutline width={24} height={24} fill="var(--accent)"/>
                </Avatar>
            </If>

            <div className="flex flex-col flex-1">
                <Caption className="line-clamp-1 mb-[2px]" level="1">
                    <span className="font-medium text-accent">
                        {user?.profile?.fullName}
                    </span>

                    <span className="text-subhead">
                        {' • '}
                        {moment(createdAt).calendar()}
                    </span>
                </Caption>

                <Caption level="2" className={[
                    'word-break fs-13',
                    text && !!medias?.length ? 'line-clamp-1' : '',
                ].join(' ')}>
                    <If is={!!text}>
                        <>{text}</>
                    </If>

                    <If is={!text && !!medias?.length}>
                        <span className="text-subhead">
                            {t('photo', { count: medias?.length })}
                        </span>
                    </If>
                </Caption>
            </div>
        </div>
    );
};


export { RepliedMessageItem };
