/**
 * UpdateGroupSettingsInput
 *
 * @author: exode <hello@exode.ru>
 */

import { IsString, MinLength } from 'class-validator';

import { isMinMax } from '../constants';


export class UpdateGroupSettingsInput {

    @IsString()
    @MinLength(1, { message: isMinMax(1, 'Название', 'min', 'должно') })
    readonly name: string;

}
