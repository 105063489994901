/**
 * PulsePoint component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';


interface Props {
    className?: string;
    size?: number;
}


const PulsePoint = (props: Props) => {

    const { className, size = 8 } = props;

    return (
        <span className={[ 'relative flex', className ].join(' ')} style={{ width: size, height: size }}>
            <span className={[
                'animate-ping absolute inline-flex h-full w-full',
                'rounded-full bg-accent opacity-75',
            ].join(' ')}/>

            <span style={{ width: size, height: size }}
                  className="relative inline-flex rounded-full h-2 w-2 bg-accent"/>
        </span>
    );
};


export { PulsePoint };
