/**
 * ChatMessageContentElementShow
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { motion } from 'framer-motion';

import { observer, SchoolStore } from '@/store/platform';

import { useI18n } from '@/hooks/core';
import { ChatMessageContent } from '@/shared/types';

import { Icon28Smile } from '@vkontakte/icons';

import { ChatMessage } from '@/components/Chat/ChatMessage';
import { ContentElementShowProps } from '@/components/ContentElement';


interface Props extends ContentElementShowProps<ChatMessageContent> {

}


const ChatMessageContentElementShow = observer((props: Props) => {

    const {
        contentElement: {
            uuid,
            content: {
                animate,
                messages,
                avatarUrl,
                senderName,
            },
        },
    } = props;

    const { t } = useI18n('components.ContentElement.show');

    const avatarSrc = avatarUrl || SchoolStore?.school?.iconUrl;

    return (
        <motion.div key={uuid}
                    className="w-full mb-1 d:mt-2 d:mb-3"
                    initial={animate ? { opacity: 0, y: 20 } : undefined}
                    whileInView={animate ? { opacity: 1, y: 0 } : undefined}
                    viewport={animate ? { once: true, amount: 0.2 } : undefined}
                    transition={animate ? { duration: 0.5, type: 'spring' } : undefined}>
            <ChatMessage side="left"
                         header={senderName}
                         textClassName="fs-content"
                         texts={_.map(messages, 'text')}
                         placeholder={t('enterMessageText')}
                         dataTest={`ce.chat-message.${uuid}`}
                         avatar={{
                             entityId: 0,
                             src: avatarSrc || '',
                             name: senderName || '',
                             children: (senderName || avatarSrc) ? undefined : <Icon28Smile/>,
                         }}/>
        </motion.div>
    );
});


export { ChatMessageContentElementShow };
