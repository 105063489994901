/**
 * PriceFormView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { Form, Formik } from 'formik';
import { createValidator } from 'class-validator-formik';

import { APP_URL } from '@/root/src/env';

import { CreatePriceProductInput, ProductPriceMode, UpdatePriceProductInput } from '@/codegen/graphql';

import { PlatformStore } from '@/store/platform';
import { observer, useStore } from '@/pages/Core';
import { ManageCoursePricingPageStore } from '@/pages/Manage/Courses/Launches/Pricing/store';

import { ProductPriceInput } from '@/libs/class-validator';

import { ObjectUtil } from '@/utils';
import { useI18n } from '@/hooks/core';
import { useProductPrice } from '@/hooks/apollo';
import { Router } from '@/services/Utils/Router';
import { Field, If, Notify, numberOrNull } from '@/cutils';

import { productCurrency, ProductLaunchItem, ProductPriceItem } from '@/types/product';

import { TextTooltip } from '@exode.ru/vkui/unstable';
import { Icon16Clear, Icon24PaymentCardOutline } from '@vkontakte/icons';
import { Button, Card, ChipsInput, FormItem, FormLayoutGroup, Input } from '@exode.ru/vkui';

import { IconPicker } from '@/components/Atoms/IconPicker';
import { ContentCard } from '@/components/Atoms/ContentCard';
import { FilterInput } from '@/components/Atoms/FilterInput';
import { StickyButton } from '@/components/Atoms/StickyButton';
import { InputSideInfo } from '@/components/Atoms/InputSideInfo';
import { ToggleCheckbox } from '@/components/Atoms/ToggleCheckbox';
import { CopyToClipboard } from '@/components/Atoms/CopyToClipboard';
import { CheckListTooltip } from '@/components/Atoms/CheckListTooltip';
import { DateInputInterval } from '@/components/Atoms/DateInputInterval';
import { GroupSearchSelect } from '@/components/Group/GroupSearchSelect';
import { FieldBottomLimiter } from '@/components/Atoms/FieldBottomLimiter';

import { PriceAccessPeriodTooltip, PriceTagsTooltip } from './TooltipsView';


interface Props {
    productId: number;
    launchId: number;
    courseId?: number;
    price?: ProductPriceItem;
    launch?: ProductLaunchItem | ProductPriceItem['launch'];
}


const PriceFormView = observer((props: Props) => {

    const {
        price,
        launch,
        courseId,
        launchId,
        productId,
    } = props;

    const { COMMON } = PlatformStore;

    const { t } = useI18n('pages.Manage.Course.Launches.Pricing');

    const { list, sort } = useStore(ManageCoursePricingPageStore);

    const {
        createPrice,
        createPriceCause,
        createPriceLoading,
        updatePrice,
        updatePriceCause,
        updatePriceLoading,
        priceExceptionMap,
        getInitialValues,
    } = useProductPrice({
        list,
        sort,
        productId,
        launchId,
        onCompleted: () => {
            Router.replaceModal();

            Notify.toast.success(
                price
                    ? t('tariffSuccessfullyUpdated')
                    : t('tariffSuccessfullyCreated'),
            );
        },
    });

    const handleSubmit = async (values: ReturnType<typeof getInitialValues>) => {
        const priceInput: UpdatePriceProductInput & CreatePriceProductInput = {
            amount: +values.amount,
            groupId: values.groupId,
            previousAmount: +values.previousAmount || null,
            title: values.title ? values.title : t('newTariff'),
            ..._.pick(values, [
                'tags',
                'icon',
                'active',
                'description',
                'accessDays',
                'activeFrom',
                'activeTo',
                'infinityAccess',
            ]),
        };

        price?.id
            ? await updatePrice(price.id, priceInput)
            : await createPrice(productId, { ...priceInput, launchId });
    };

    return (
        <Formik validateOnBlur
                validateOnMount
                validateOnChange
                enableReinitialize
                onSubmit={handleSubmit}
                validate={createValidator(ProductPriceInput)}
                initialValues={getInitialValues(price, launch)}
                initialErrors={{
                    title: priceExceptionMap.title[createPriceCause || updatePriceCause],
                    activeFrom: priceExceptionMap.activeFrom[createPriceCause || updatePriceCause],
                }}>
            {({
                  values,
                  errors,
                  isValid,
                  touched,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  initialValues,
              }) => (
                <Form>
                    <FormItem bottom={t('tariffIcon')} className="flex flex-col items-center">
                        <IconPicker defaultIcon={values.icon} onSelect={(icon) => setFieldValue('icon', icon)}/>
                    </FormItem>

                    <div className="px-4 my-2">
                        <Card mode="shadow" className="pb-0.5 dark:thin-border">
                            <If is={!!price}>
                                <FormItem top={t('linkToPay')} className="pb-0">
                                    <CopyToClipboard className="!mx-0"
                                                     before={<Icon24PaymentCardOutline/>}
                                                     link={`${APP_URL}/pay/${productId}/${price?.id}`}/>
                                </FormItem>
                            </If>

                            <FormItem top={t('groupAfterPayment')} className="pb-3.5">
                                <GroupSearchSelect filter={{ courseIds: courseId ? [ courseId ] : null }}
                                                   selectedGroupIds={values.groupId ? [ values.groupId ] : [ 0 ]}
                                                   onSelect={({ value }) => setFieldValue('groupId', value || null)}
                                                   extraOptions={(
                                                       !price
                                                           ? [ { value: 0, label: t('newGroupForTariff') } ]
                                                           : undefined
                                                   )}/>
                            </FormItem>
                        </Card>
                    </div>

                    <FormItem className="pb-0"
                              top={t('tariffName')}
                              status={Field.status(errors, touched, 'title')}
                              bottom={(
                                  <FieldBottomLimiter maxLength={65}
                                                      value={values.title}
                                                      message={Field.message(errors, touched, 'title', '')}/>
                              )}>
                        <Input name="title"
                               maxLength={65}
                               onBlur={handleBlur}
                               value={values.title}
                               data-test="price.title"
                               placeholder={t('tariffNamePlaceholder')}
                               onInput={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                    </FormItem>

                    <FormItem className="pb-0"
                              onBlur={handleBlur}
                              top={t('tariffDescription')}
                              status={Field.status(errors, touched, 'description')}
                              bottom={(
                                  <FieldBottomLimiter maxLength={65}
                                                      value={values.description}
                                                      message={Field.message(errors, touched, 'description', '')}/>
                              )}>
                        <Input name="description"
                               maxLength={65}
                               value={values.description}
                               data-test="price.description"
                               placeholder={t('tariffDescriptionPlaceholder')}
                               onInput={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                    </FormItem>

                    <FormLayoutGroup className="pb-0" mode="horizontal">
                        <FormItem className="pb-0"
                                  top={t('currentPrice')}
                                  status={Field.status(errors, touched, 'amount')}
                                  bottom={Field.message(errors, touched, 'amount')}>
                            <FilterInput name="amount"
                                         regExp={/^[0-9]*$/}
                                         value={values.amount}
                                         onBlur={handleBlur}
                                         placeholder={t('currentPricePlaceholder')}
                                         callback={(e) => setFieldValue('amount', numberOrNull(e.target.value))}
                                         after={(
                                             <InputSideInfo side="after">
                                                 {COMMON && productCurrency[COMMON.organization.settings.currency]}
                                             </InputSideInfo>
                                         )}/>
                        </FormItem>

                        <FormItem className="px-0 pb-0"
                                  top={t('priceWithoutDiscount')}
                                  status={Field.status(errors, touched, 'previousAmount')}
                                  bottom={Field.message(errors, touched, 'previousAmount')}>
                            <FilterInput regExp={/^[0-9]*$/}
                                         name="previousAmount"
                                         onBlur={handleBlur}
                                         value={values.previousAmount}
                                         placeholder={t('priceWithoutDiscount')}
                                         callback={(e) => {
                                             setFieldValue('previousAmount', numberOrNull(e.target.value));
                                         }}
                                         after={(
                                             <InputSideInfo side="after">
                                                 {COMMON && productCurrency[COMMON.organization.settings.currency]}
                                             </InputSideInfo>
                                         )}/>
                        </FormItem>
                    </FormLayoutGroup>

                    <FormItem className="py-0 mt-2">
                        <ContentCard compact
                                     mode="outline"
                                     hasHover={false}
                                     hasActive={false}
                                     activeAfter={false}
                                     className="!rounded-lg"
                                     innerCardClassName="bg-hover px-4 relative z-[1]"
                                     header={(
                                         <ToggleCheckbox name="active"
                                                         checked={!!values.active}
                                                         onChange={() => setFieldValue('active', !values.active)}
                                                         description={(
                                                             !values.active
                                                                 ? t('nowTariffIsTurnedOff')
                                                                 : t('nowTariffIsTurnedOn')
                                                         )}>
                                             {t('tariffActivity')}
                                         </ToggleCheckbox>
                                     )}/>
                    </FormItem>

                    <FormItem className="py-0 mt-2 px-0">
                        <DateInputInterval className="pb-0"
                                           key={[ values.activeFrom, values.activeTo ].join('.')}
                                           handleChange={(field, value) => setFieldValue(field, value || null)}
                                           from={{
                                               name: 'activeFrom',
                                               label: t('startDate'),
                                               value: values.activeFrom,
                                               error: errors.activeFrom,
                                               dataTest: 'price.activeFrom',
                                               compare: {
                                                   to: launch?.saleFinishAt,
                                               },
                                               dateInputProps: {
                                                   enableTime: true,
                                                   minMaxInclusivity: '[]',
                                                   minDate: launch?.saleStartAt,
                                                   maxDate: launch?.saleFinishAt,
                                               },
                                           }}
                                           to={{
                                               name: 'activeTo',
                                               label: t('expirationDate'),
                                               value: values.activeTo,
                                               error: errors.activeTo,
                                               dataTest: 'price.activeTo',
                                               compare: {
                                                   from: launch?.saleStartAt,
                                               },
                                               dateInputProps: {
                                                   enableTime: true,
                                                   minMaxInclusivity: '[]',
                                                   minDate: values.activeFrom,
                                                   maxDate: launch?.saleFinishAt,
                                               },
                                           }}/>
                    </FormItem>

                    <FormItem status={Field.status(errors, touched, 'tags')}
                              bottom={Field.message(errors, touched, 'tags', '')}
                              top={(
                                  <div className="flex gap-1">
                                      <span>
                                          {t('tariffOptionsOrBenefits')}
                                          {' '}
                                          <>{(launch?.countPrices || 0) > 1 ? t('minOptionsOrBenefits') : ''}</>
                                      </span>
                                      <PriceTagsTooltip/>
                                  </div>
                              )}>
                        <ChipsInput setOnBlur
                                    name="tags"
                                    onBlur={handleBlur}
                                    data-test="price.tags"
                                    addOptionEventKeys={[ 'Enter', ',' ]}
                                    placeholder={t('enterAndClickEnter')}
                                    value={values.tags.map((tag) => ({ value: tag?.trim(), label: tag }))}
                                    onChange={chips => setFieldValue('tags', chips.map(({ value }) => value))}
                                    after={(
                                        <Icon16Clear data-test="price.clear-tags"
                                                     onClick={() => setFieldValue('tags', [])}
                                                     className="cursor-pointer hover:opacity-90 active:opacity-70"/>
                                    )}/>
                    </FormItem>

                    <If is={values.mode === ProductPriceMode.SelfDefinition}>
                        <FormItem status={Field.status(errors, touched, 'accessDays')}
                                  bottom={Field.message(errors, touched, 'accessDays')}
                                  top={(
                                      <div className="flex gap-1">
                                          {t('accessDaysToProduct')}
                                          <PriceAccessPeriodTooltip/>
                                      </div>
                                  )}>
                            <FilterInput name="accessDays"
                                         regExp={/^[0-9]*$/}
                                         onBlur={handleBlur}
                                         value={values.accessDays?.toString()}
                                         callback={(e) => setFieldValue('accessDays', numberOrNull(e.target.value))}
                                         after={(
                                             <span className="px-2.5">
                                                 {t('days', { count: values.accessDays || 0 })}
                                             </span>
                                         )}/>
                        </FormItem>
                    </If>

                    <StickyButton>
                        <TextTooltip placement="left"
                                     key={JSON.stringify(errors)}
                                     hidden={_.isEmpty(_.values(errors).filter((e) => e))}
                                     text={(
                                         <CheckListTooltip list={_.values(errors).map((text) => ({
                                             text: text && `${text}`,
                                             mode: 'error',
                                         }))}/>
                                     )}>
                            <Button stretched
                                    size="l"
                                    type="submit"
                                    className="mt-2"
                                    data-test="price.create"
                                    loading={createPriceLoading || updatePriceLoading}
                                    disabled={(
                                        createPriceLoading
                                        || updatePriceLoading
                                        || !isValid
                                        || ObjectUtil.isEqual(initialValues, values)
                                    )}>
                                {!price
                                    ? t('createTariff')
                                    : (ObjectUtil.isEqual(initialValues, values) ? t('allSaved') : t('save'))
                                }
                            </Button>
                        </TextTooltip>
                    </StickyButton>
                </Form>
            )}
        </Formik>
    );
});


export { PriceFormView };
