/**
 * FilterView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { useI18n } from '@/hooks/core';
import { observer } from '@/pages/Core';
import { SvgComponent } from '@/cutils';

import { ProductLaunchStatus } from '@/codegen/graphql';
import { practiceAttemptStatus } from '@/types/practice';

import { Icon20ListAddOutline } from '@vkontakte/icons';

import { CourseSearchSelect } from '@/components/Course/CourseSearchSelect';
import { SubNavigationSelect } from '@/components/Atoms/SubNavigationSelect';

import { useManageHomeworkFilter } from '../filter';


const FilterView = observer(() => {

    const { t } = useI18n('pages.Manage.Homework.views');

    const practiceAttemptStatuses = practiceAttemptStatus();

    const { urlFilter, setUrlFilter } = useManageHomeworkFilter();

    const svgProps = {
        with: 18,
        height: 18,
        fill: 'var(--white)',
    };

    return (
        <div className="flex items-center flex-wrap px-8 gap-3 mt-5">
            <SubNavigationSelect name="statuses"
                                 initialTitle={t('all')}
                                 value={urlFilter.statuses?.[0]}
                                 onChange={(__, value) => setUrlFilter({ statuses: value === 'all' ? [] : [ value ] })}
                                 options={(
                                     _.map(_.entries({
                                         'all': {
                                             name: t('all'),
                                             bgColor: 'bg-button-secondary',
                                             icon: <Icon20ListAddOutline {...svgProps}/>,
                                         },
                                         ...practiceAttemptStatuses,
                                     }), ([ key, { name, bgColor, icon } ]) => ({
                                         value: key,
                                         label: name || '',
                                         icon: (
                                             <SvgComponent element={icon} svgProps={{
                                                 ...svgProps,
                                                 className: [
                                                     bgColor,
                                                     'p-0.5 rounded-full',
                                                 ].join(' '),
                                             }}/>
                                         ),
                                     }))
                                 )}/>

            <div className="flex">
                <CourseSearchSelect onSelect={({ value }) => setUrlFilter({ courseIds: [ value ] })} filter={{
                    product: {
                        launch: {
                            statuses: [ ProductLaunchStatus.Published ],
                        },
                    },
                }}/>
            </div>
        </div>
    );
});


export { FilterView };
