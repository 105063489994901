/**
 * Rest api
 *
 * @author: exode <hello@exode.ru>
 */

import axios, { AxiosRequestConfig } from 'axios';

import { Url } from '@/utils';

import { restApiUrl } from '@/api/constants';
import { basicRequestHeaders } from '@/api/headers';

import { i18n } from '@/libs/i18n';
import { Notify } from '@/cutils/Notify';


class RestApi {

    /**
     * Заголовки авторизации в запросе
     * @returns Authorization: string, 'Content-Type': string
     * @constructor
     */
    private static get headers() {
        return basicRequestHeaders();
    }

    /**
     * Уведомление об ошибки запроса
     * @constructor
     * @param error
     */
    private static notifyFailedError(error: any) {
        if (error?.response?.status) {
            console.warn(`💩 Rest api call is not success:`, error?.response);

            if (error?.response?.status === 429) {
                const tStatic = i18n.t;

                Notify.toast.error(
                    tStatic('static:network.common.restapi.rateLimitingError'),
                );
            }
        } else {
            console.warn(`📶 No internet connection:`, error);
        }
    }

    /**
     * Обработка ошибки запроса
     * @param error
     * @returns {{exception: any, payload: any, success: boolean, unsent: boolean}}
     * @private
     */
    private static parseError(error: any) {
        this.notifyFailedError(error);

        const status = error?.response?.status;

        return {
            success: false,
            payload: {} as any,
            exception: status && error?.response?.data,
            unsent: !status,
        };
    }

    /**
     * Post запрос к Api
     * @param url
     * @param data
     * @param headers
     * @param config
     * @returns mixed
     */
    static async post(
        url: string,
        data = {},
        headers = {},
        config?: AxiosRequestConfig,
    ) {
        headers = { ...this.headers, ...headers };

        try {
            const result = await axios({
                data,
                headers,
                method: 'POST',
                url: !url.startsWith('https://')
                    ? [ restApiUrl, url ].join('')
                    : url,
                ...config,
            });

            return result.data;

        } catch (error: any) {
            return this.parseError(error);
        }
    }

    /**
     * Get запрос к Api
     * @param {string} url
     * @param {{}} headers
     * @param {{}} data
     * @param {AxiosRequestConfig} config
     * @returns {Promise<any>}
     */
    static async get(
        url: string,
        headers = {},
        data = {},
        config?: AxiosRequestConfig,
    ) {
        try {
            const result = await axios.get(
                [ restApiUrl, url, '?', Url.objectToQuery(data) ].join(''),
                {
                    headers: {
                        ...this.headers,
                        ...headers,
                    },
                    ...config,
                },
            );

            return result.data;

        } catch (error: any) {
            return this.parseError(error);
        }
    }

}


export { RestApi };
