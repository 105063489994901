/**
 * SubNavigationSelect styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div<{ hasValue: boolean }>`
    .Tappable__hoverShadow {
        background-color: transparent !important;
    }
`;

export const OptionsWrapper = styled.div`
    .SimpleCell {
        min-height: 32px !important;

        &__main {
            padding: 0 !important;
        }

        &__children {
            font-size: var(--vkui--font_subhead--font_size--compact) !important;
        }
    }
`;
