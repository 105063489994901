/**
 * CreateCourseInput
 *
 * @author: exode <hello@exode.ru>
 */

import { IsString, MaxLength } from 'class-validator';


export class CreateCourseInput {

    @IsString()
    @MaxLength(130)
    readonly name: string;

}
