/**
 * ButtonContentElementEdit
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';
import { nanoid } from 'nanoid';

import React, { Fragment, useCallback } from 'react';

import { FastField, FastFieldProps, FieldArray, Formik } from 'formik';

import { ButtonContent } from '@/shared/types';
import { AutoSaveForm, If } from '@/cutils';
import { useI18n, useOnceUpdate } from '@/hooks/core';

import { ContentElementType } from '@/codegen/graphql';

import { Icon20AddCircle, Icon24DeleteOutline } from '@vkontakte/icons';
import { Button, Cell, Checkbox, FormItem, Input, List, Separator } from '@exode.ru/vkui';

import { ColorPicker } from '@/components/Atoms/ColorPicker';
import { ConfirmButton } from '@/components/Atoms/ConfirmButton';
import { TextAccordion } from '@/components/Atoms/TextAccordion';
import { ContentElementEditProps, getContentElementContextValue } from '@/components/ContentElement';

import { ButtonContentElementShow } from '../show/ButtonContentElementShow';
import { BlockHeaderPart, BlockWrapperPart } from '../parts/edit';


interface Props extends ContentElementEditProps<ButtonContent> {

}


const ButtonContentElementEdit = (props: Props) => {

    const {
        list,
        loading,
        onEdit,
        update,
        onDelete,
        contentElement,
    } = props;

    const { t, i18n } = useI18n('components.ContentElement.edit');

    const {
        edit: {
            blockHeaderProps = {},
            blockWrapperProps = {},
            BlockWrapper = BlockWrapperPart,
        } = {},
    } = getContentElementContextValue(ContentElementType.Button);

    const {
        initialValues,
        handleUpdateSubmit,
    } = useOnceUpdate(
        _.pick(contentElement, [ 'content' ]),
        async (values) => update?.(values),
    );

    const debouncedCallback = useCallback(
        _.debounce((callback) => callback(), 500),
        [ initialValues ],
    );

    return (
        <Formik initialValues={initialValues} onSubmit={handleUpdateSubmit}>
            {({
                  values,
                  handleSubmit,
                  setFieldValue,
                  values: { content: { buttons = [] } },
              }) => (
                <AutoSaveForm onChange={() => handleSubmit()} onInstantChange={() => onEdit?.()}>
                    <BlockWrapper index={list?.index} {...blockWrapperProps}>
                        <BlockHeaderPart list={list}
                                         loading={loading}
                                         onDelete={onDelete}
                                         type={ContentElementType.Button}
                                         {...blockHeaderProps}/>

                        <ButtonContentElementShow contentElement={{
                            ...contentElement,
                            myInteraction: null,
                            content: values.content,
                        }}/>

                        <TextAccordion className="mt-2"
                                       text={t('settings')}
                                       initialExpanded={buttons.some(({ link }) => !link)}>
                            <FieldArray name="content.buttons">
                                {({ push, remove }) => (
                                    <List className="flex flex-col gap-3.5">
                                        {buttons.map((button, index) => (
                                            <Fragment key={button.uuid}>
                                                <If is={index > 0}>
                                                    <Separator wide className="mt-2 mb-1"/>
                                                </If>

                                                <div className="flex flex-col items-center gap-3 first:mt-2 mb-2.5 w-full">
                                                    <div className="flex w-full gap-2 items-start justify-start">
                                                        <FormItem top={t('buttonText')} className="p-0 w-full">
                                                            <FastField name={`content.buttons[${index}].text`}>
                                                                {({ field }: FastFieldProps) => (
                                                                    <Input {...field} className="w-full"
                                                                           placeholder={t('enterText')}/>
                                                                )}
                                                            </FastField>
                                                        </FormItem>

                                                        <If is={buttons?.length > 1}>
                                                            <div className="bg-input rounded-full thin-border mt-[25px]">
                                                                <ConfirmButton className="opacity-75 hover:opacity-100"
                                                                               initialIcon={(
                                                                                   <Icon24DeleteOutline fill="var(--icon_secondary)"/>
                                                                               )}
                                                                               callback={() => {
                                                                                   remove(index);
                                                                                   handleSubmit();
                                                                               }}/>
                                                            </div>
                                                        </If>
                                                    </div>

                                                    <FormItem top={t('buttonLink')} className="p-0 w-full">
                                                        <FastField name={`content.buttons[${index}].link`}>
                                                            {({ field }: FastFieldProps) => (
                                                                <Input {...field} className="w-full"
                                                                       placeholder={t('enterLink')}/>
                                                            )}
                                                        </FastField>
                                                    </FormItem>

                                                    <FormItem className="p-0 w-full">
                                                        <FastField name={`content.buttons[${index}].target`}>
                                                            {({ field }: FastFieldProps) => (
                                                                <Checkbox {...field} hasHover={false}
                                                                          hasActive={false}
                                                                          checked={field.value === '_blank'}
                                                                          className="w-full checkbox-origin"
                                                                          description={t('openInNewTabDescription')}
                                                                          onChange={(e) => {
                                                                              onEdit?.();

                                                                              setFieldValue(
                                                                                  `content.buttons[${index}].target`,
                                                                                  e.target.checked ? '_blank' : '_self',
                                                                              );

                                                                              debouncedCallback(() => handleSubmit());
                                                                          }}>
                                                                    {t('openInNewTab')}
                                                                </Checkbox>
                                                            )}
                                                        </FastField>
                                                    </FormItem>

                                                    <FormItem className="p-0 w-full">
                                                        <FastField key={i18n.language}
                                                                   name={`content.buttons[${index}].bgColor`}>
                                                            {({ field }: FastFieldProps) => (
                                                                <Cell hasHover={false}
                                                                      hasActive={false}
                                                                      subtitle={t('hereYouCanChangeBgColor')}
                                                                      after={(
                                                                          <ColorPicker color={field.value}
                                                                                       onChange={(color) => {
                                                                                           onEdit?.();
                                                                                           setFieldValue(field.name, color);
                                                                                           debouncedCallback(() => handleSubmit());
                                                                                       }}/>
                                                                      )}>
                                                                    {t('buttonColor')}
                                                                </Cell>
                                                            )}
                                                        </FastField>
                                                    </FormItem>

                                                    <FormItem className="p-0 w-full">
                                                        <FastField key={i18n.language}
                                                                   name={`content.buttons[${index}].textColor`}>
                                                            {({ field }: FastFieldProps) => (
                                                                <Cell hasHover={false}
                                                                      hasActive={false}
                                                                      subtitle={t('hereYouCanChangeTextColor')}
                                                                      after={(
                                                                          <ColorPicker color={field.value}
                                                                                       onChange={(color) => {
                                                                                           onEdit?.();
                                                                                           setFieldValue(field.name, color);
                                                                                           debouncedCallback(() => handleSubmit());
                                                                                       }}/>
                                                                      )}>
                                                                    {t('textColor')}
                                                                </Cell>
                                                            )}
                                                        </FastField>
                                                    </FormItem>
                                                </div>
                                            </Fragment>
                                        ))}

                                        <If is={buttons.length < 2}>
                                            <div className="flex justify-center">
                                                <Button size="l"
                                                        mode="tertiary"
                                                        before={<Icon20AddCircle/>}
                                                        onClick={() => {
                                                            const button: ButtonContent['buttons'][number] = {
                                                                uuid: nanoid(),
                                                                link: '',
                                                                bgColor: '',
                                                                textColor: '',
                                                                text: t('goTo'),
                                                                target: '_blank',
                                                            };

                                                            push(button);
                                                            handleSubmit();
                                                        }}>
                                                    {t('addButton')}
                                                </Button>
                                            </div>
                                        </If>
                                    </List>
                                )}
                            </FieldArray>
                        </TextAccordion>
                    </BlockWrapper>
                </AutoSaveForm>
            )}
        </Formik>
    );
};


export { ButtonContentElementEdit };
