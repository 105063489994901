/**
 * VideoContentElementShow
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useMemo } from 'react';

import { VideoContent } from '@/shared/types';
import { useParseToPlyrSources, usePlyrApi } from '@/hooks/core';

import { If } from '@/cutils';
import { Url } from '@/utils';

import { Plyr } from '@/components/Atoms/Plyr';
import { Kinescope } from '@/components/Atoms/Kinescope';
import { BlockTitle } from '@/components/Content/BlockTitle';

import { ContentElementShowProps } from '../interfaces';


interface Props extends ContentElementShowProps<VideoContent> {

}


const VideoContentElementShow = (props: Props) => {

    const { contentElement: { title, content } } = props;

    const { provider, id: videoId } = Url.parseVideoUrl(content?.location || '');

    const { plyrVideoRef } = usePlyrApi({ videoLink: content?.location });

    const player = useMemo(() => (() => {
        switch (provider) {
            case 'kinescope':
                return (
                    <Kinescope videoId={videoId} poster={content.wrapperUrl}/>
                );

            default:
                return (
                    <Plyr.Wrapper styles={{ roundedOnMobile: true }}>
                        <Plyr.Video ref={plyrVideoRef}
                                    deps={[ content?.location ]}
                                    source={useParseToPlyrSources(content?.location)}/>
                    </Plyr.Wrapper>
                );
        }
    })(), [ provider, videoId ]);

    return (
        <div>
            <>{player}</>

            <If is={!!title}>
                <BlockTitle title={title} className="d:mb-3 mt-4"/>
            </If>
        </div>
    );
};


export { VideoContentElementShow };
