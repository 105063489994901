/**
 * AboveInputItem
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { useStore } from '@/pages/Core';
import { ChatDialogsPageStore, observer } from '@/pages/Chat/Dialog/store';

import { Icon28CancelCircleOutline, Icon28ReplyOutline } from '@vkontakte/icons';

import { RepliedMessageItem } from '../RepliedMessageItem';


interface Props {
    chatId: number;
}


const AboveInputItem = observer((props: Props) => {

    const { chatId } = props;

    const { input, store } = useStore(ChatDialogsPageStore);

    const message = input.replyMessage[chatId];

    return (
        <div className="flex p-3 gap-2 thin-border-top relative">
            <div className="ml-[1px] pr-[18px] h-full flex" style={{ borderRight: '2px solid var(--accent)' }}>
                <Icon28ReplyOutline fill="var(--accent)"/>
            </div>

            <RepliedMessageItem placement="above-input" replyMessage={message}/>

            <div className="items-center cursor-pointer h-full flex"
                 onClick={() => store.setInput('replyMessage', _.omit(input.replyMessage, `${chatId}`))}>
                <Icon28CancelCircleOutline fill="var(--accent)"/>
            </div>
        </div>
    );
});


export { AboveInputItem };
