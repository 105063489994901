/**
 * Api constants
 *
 * @author: exode <hello@exode.ru>
 */

/**
 * Абсолютная ссылка rest api
 *
 * @type {string}
 */
export const restApiUrl = `${process.env.REACT_APP_REST_API_URL}`;

/**
 * Абсолютная ссылка api загрузки
 *
 * @type {string}
 */
export const uploadApiUrl = `${process.env.REACT_APP_UPLOAD_API_URL}`;
/**
 * Абсолютная ссылка GraphQL
 *
 * @type {string}
 */
export const graphQlApiUrl = `${process.env.REACT_APP_GRAPH_QL_URL}`;

/**
 * Абсолютная ссылка socket.io
 *
 * @type {string}
 */
export const wsUrl = `${process.env.REACT_APP_WS_API_URL}`;

/**
 * Абсолютная ссылка WS GraphQL
 *
 * @type {string}
 */
export const wsGraphqlUrl = `wss://${graphQlApiUrl.split('://')[1]}`;

