/**
 * Use date range input
 *
 * @author: exode <hello@exode.ru>
 */

import { useEffect, useState } from 'react';

import { useI18n } from '@/hooks/core';

import { StartFinishFieldName, validateDateBorder, validateDateRange } from '@/shared/validators';


export interface CompareDateOptions {
    from?: Date | undefined;
    to?: Date | undefined;
}


const useDateRangeInput = (
    fromDate: Date | undefined,
    toDate: Date | undefined,
    compareDates?: CompareDateOptions | null,
    onValidate?: (
        isValid: boolean,
        messages: string[],
    ) => void,
) => {

    const { t } = useI18n('hooks.core');

    const [ errorFrom, setErrorFrom ] = useState('');
    const [ errorTo, setErrorTo ] = useState('');

    const [ isValid, setIsValid ] = useState<boolean>(true);

    const [ from, setFrom ] = useState<Date | undefined>(fromDate);
    const [ to, setTo ] = useState<Date | undefined>(toDate);

    const clearErrors = () => {
        setErrorFrom('');
        setErrorTo('');
    };

    useEffect(() => {
        setFrom(fromDate);
        setTo(toDate);
    }, [ fromDate, toDate ]);

    const checkDateBorder = (
        name: StartFinishFieldName,
        value: Date | undefined,
    ) => {
        setErrorFrom('');
        setErrorTo('');

        const valid = validateDateBorder(value, name, from, to);

        setIsValid(valid);

        const message = name === 'from'
            ? t('dateCannotBeGreaterThanFinishing')
            : t('dateCannotBeLessThanStarting');

        if (!valid) {
            name === 'from'
                ? setErrorFrom(message)
                : setErrorTo(message);
        }

        return { valid, message: !valid && message };
    };

    const checkRange = (
        name: StartFinishFieldName,
        value: Date | undefined,
    ) => {
        const { valid, message } = validateDateRange(value, compareDates?.from, compareDates?.to);

        setIsValid(valid);

        if (!valid && message) {
            name === 'from'
                ? setErrorFrom(message)
                : setErrorTo(message);
        }

        return { valid, message: !valid && message };
    };

    const setFromOrTo = (
        name: StartFinishFieldName,
        value: Date | undefined,
    ) => {
        clearErrors();

        if (name === 'from') {
            const {
                valid: fromIsValidBorder,
                message: fromBorderMessage,
            } = checkDateBorder('from', value);

            const {
                valid: fromIsValidRange,
                message: fromRangeMessage,
            } = compareDates?.from
                ? checkRange('from', value)
                : { valid: true, message: '' };

            const totallyIsValid = fromIsValidBorder && fromIsValidRange;

            if (totallyIsValid) {
                setFrom(value);
            }

            /** Use setImmediate for not to conflict with base form validator */
            setImmediate(() => {
                onValidate?.(
                    totallyIsValid,
                    [ fromBorderMessage, fromRangeMessage ].filter(e => e) as string[],
                );
            });
        }

        if (name === 'to') {
            const {
                valid: toIsValidBorder,
                message: toBorderMessage,
            } = checkDateBorder('to', value);

            const {
                valid: toIsValidRange,
                message: toRangeMessage,
            } = compareDates?.to
                ? checkRange('to', value)
                : { valid: true, message: '' };

            const totallyIsValid = toIsValidBorder && toIsValidRange;

            if (totallyIsValid) {
                setTo(value);
            }

            /** Use setImmediate for not to conflict with base form validator */
            setImmediate(() => {
                onValidate?.(
                    totallyIsValid,
                    [ toBorderMessage, toRangeMessage ].filter(e => e) as string[],
                );
            });
        }
    };

    return {
        isValid,
        setFromOrTo,
        errorFrom,
        errorTo,
        from: from ? new Date(from) : from,
        to: to ? new Date(to) : to,
    };
};


export { useDateRangeInput };
