/**
 * Sticky util
 *
 * @author: exode <hello@exode.ru>
 */

import { v4 } from 'uuid';

import React, { ReactElement, useEffect } from 'react';

import styled from 'styled-components';


interface Props {
    children: ReactElement;
    container: string;
    top: number;
    bottom: number;
    width: number;
    className?: string;
}


const StickyContainer = styled.div`
    .sticky__inner {
        left: initial !important;
        transform: translate3d(0, 0, 0);
    }
`;


const Sticky = (props: Props) => {

    const { container, top, bottom, width, className = '' } = props;

    const id = 'id' + v4().replace(/-/g, '');

    useEffect(() => {
        let sticky: any = null;

        const initSticky = async () => {
            try {
                /** @ts-ignore @aslan */
                await import('sticky-sidebar');

                const sticker = document.getElementById(id);

                if (sticker && window.StickySidebar) {
                    sticky = new window.StickySidebar(sticker, {
                        containerSelector: container,
                        innerWrapperSelector: '.sticky__inner',
                        topSpacing: top,
                        bottomSpacing: bottom,
                        minWidth: width,
                    });
                }
            } catch (error) {
                console.error('Error loading sticky-sidebar:', error);
            }
        };

        initSticky();

        return () => {
            if (sticky) {
                sticky.destroy();
            }
        };
    }, [ id, container, top, bottom, width ]);

    return (
        <StickyContainer id={id} className={className} style={{ width, minWidth: width }}>
            <div className="sticky__inner">
                {props.children}
            </div>
        </StickyContainer>
    );
};


export { Sticky };
