/**
 * Core page constant file
 *
 * @author: exode <hello@exode.ru>
 */

export const pageRow = {
    classNames: {
        centredIsland: 'd:flex d:items-center d:justify-center d:py-[30px] d:w-[400px] d:mx-auto',
        gradientBanner: [
            'bg-black bg-cover bg-center m:bg-left-top bg-no-repeat',
            'm:m-3 m:mb-0 m:!rounded-2xl overflow-hidden',
        ].join(' '),
        hoverCardWrapper: 'mt-2 mb-4 m:px-3',
        hoverCard: [
            'flex flex-col w-full gap-5 py-5 p-4 box-border',
            'items-center justify-center vk-rounded bg-hover',
        ].join(' '),
    },
};
