/**
 * UseConfetti
 *
 * @author: exode <hello@exode.ru>
 */

import { useEffect, useLayoutEffect, useState } from 'react';

import { Router } from '@/services/Utils/Router';
import { ScrollHelper } from '@/helpers/ui';

import { useLocation } from '@/router/index';


interface Props {
    initialShow?: boolean;
}


const useConfetti = (props: Props) => {

    const { initialShow = false } = props;

    const { route: { params } } = useLocation();

    const getShowConfetti = () => ([
        initialShow,
        !params.modal,
        params.confetti !== 'false',
    ].every(e => e));

    const [ showConfetti, setShowConfetti ] = useState(getShowConfetti());

    useLayoutEffect(() => {
        if (showConfetti) {
            ScrollHelper.to(0, true);

            const timeout = setTimeout(() => {
                setShowConfetti(false);
                Router.updateParams({ confetti: 'false' });
            }, 3000);

            return () => clearTimeout(timeout);
        }
    }, [ showConfetti ]);

    useEffect(() => {
        setShowConfetti(getShowConfetti());
    }, [ initialShow, params.modal ]);

    return { showConfetti };
};


export { useConfetti };
