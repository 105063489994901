/**
 * ArrangeEdit
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect, useRef } from 'react';

import { If } from '@/cutils';
import { ArrangeTaskQuestion } from '@/shared/types';
import { useDebounceUpdate, useI18n } from '@/hooks/core';

import { FormItem } from '@exode.ru/vkui';

import { TaskBuilderEditProps } from '@/components/Task/Builder';

import { elementSelectors } from './types';
import { useEditor, useSkips, useSkipWord, useTextSelection } from './hooks';

import { SelectionTooltipAtom, SkipsListAtom, TextEditorAtom } from './atoms';

import { Container } from './ArrangeEdit.styled';


interface Props extends TaskBuilderEditProps<ArrangeTaskQuestion> {

}


const ArrangeEdit = (props: Props) => {

    const { task, update, onInstantChange } = props;

    const isInitialized = useRef(false);
    const editorRef = useRef<HTMLDivElement>(null);

    const { t } = useI18n('components.Task.Builder.edit.arrange');

    const { transformSkipsToState } = useSkips();
    const { transformSkipPlaceholdersToDOM } = useSkipWord(editorRef);

    const {
        skips,
        addSkip,
        updateSkip,
        removeSkip,
        clearSkips,
        getSkipsOrder,
        updateSkipsOrder,
        transformSkipsToDto,
        transformSkipTextToDto,
    } = useSkips({
        initialSkips: transformSkipsToState(task.question),
    });

    const {
        showTooltip,
        selectedWord,
        selectedSkip,
        selectedRange,
        tooltipMode,
        tooltipPosition,
        clearSelection,
        handleSkipClick,
        handleTextSelection,
    } = useTextSelection();

    const handleSkipWordChange = (
        uuid: string,
        newText: string,
    ) => {
        updateSkip(uuid, newText);
        updateSkipSpan(uuid, newText);
    };

    const {
        addSkipSpan,
        updateSkipSpan,
        removeSkipSpan,
        clearSkipSpans,
    } = useEditor(editorRef);

    const handleCreateSkip = () => {
        if (!selectedWord || !selectedRange || !editorRef.current) {
            return;
        }

        const skips = editorRef.current.querySelectorAll(elementSelectors.skipWord);

        const newSkip = addSkip({
            text: selectedWord,
            position: Array.from(skips).length,
        });

        const span = addSkipSpan(
            selectedWord,
            newSkip.uuid,
            {
                onClick: handleSkipClick,
                onChange: handleSkipWordChange,
            },
        );

        if (span) {
            selectedRange.deleteContents();
            selectedRange.insertNode(span);
        }

        clearSelection();
    };

    const handleRemoveSkip = (uuid: string) => {
        removeSkip(uuid);
        removeSkipSpan(uuid);
        clearSelection();
    };

    const handleClearSkips = () => {
        clearSkips();
        clearSkipSpans();
        clearSelection();
    };

    useEffect(() => {
        const handleSkipBlur = () => {
            clearSelection();
        };

        if (editorRef.current) {
            editorRef.current.addEventListener('skipBlur', handleSkipBlur);
        }

        return () => {
            if (editorRef.current) {
                editorRef.current.removeEventListener('skipBlur', handleSkipBlur);
            }
        };
    }, [ clearSelection ]);

    useEffect(() => {
        const observer = new MutationObserver(() => {
            if (editorRef.current) {
                updateSkipsOrder(getSkipsOrder(editorRef.current));
            }
        });

        if (editorRef.current) {
            observer.observe(editorRef.current, {
                childList: true,
                subtree: true,
                characterData: true,
            });
        }

        return () => observer.disconnect();
    }, [ updateSkipsOrder ]);

    /** Initialize editor */
    useEffect(() => {
        if (!isInitialized.current && editorRef.current) {
            const skipsDom = transformSkipPlaceholdersToDOM(
                task.question.textWithSkips,
                task.question.skips,
                {
                    onClick: handleSkipClick,
                    onChange: handleSkipWordChange,
                },
            );

            if (task.question.textWithSkips?.trim()) {
                editorRef.current?.classList.remove('empty');
            }

            while (skipsDom.firstChild) {
                editorRef.current.appendChild(skipsDom.firstChild);
            }

            isInitialized.current = true;
        }
    }, [ editorRef ]);

    /** Update task on edit */
    useDebounceUpdate({
        skips,
        textWithSkips: editorRef.current
            ? transformSkipTextToDto(editorRef.current?.innerHTML)
            : task.question.textWithSkips,
    }, ({ textWithSkips, skips }) => {
        onInstantChange?.();

        update({
            question: {
                textWithSkips,
                skips: transformSkipsToDto(skips, ({ isFake }) => !isFake),
                fakeSkips: transformSkipsToDto(skips, ({ isFake }) => !!isFake),
            },
        });
    });

    return (
        <Container>
            <FormItem top={t('yourTextForSkips')} className="pt-0">
                <div className="relative">
                    <TextEditorAtom ref={editorRef}
                                    onClearSkips={handleClearSkips}
                                    onClearSelection={clearSelection}
                                    onTextSelection={handleTextSelection}/>

                    <If is={showTooltip}>
                        <SelectionTooltipAtom mode={tooltipMode}
                                              position={tooltipPosition}
                                              onCreateSkip={handleCreateSkip}
                                              onRemoveSkip={() => {
                                                  if (selectedSkip) {
                                                      handleRemoveSkip(selectedSkip.uuid);
                                                  }
                                              }}/>
                    </If>
                </div>
            </FormItem>

            <SkipsListAtom skips={skips}
                           onRemoveSkip={handleRemoveSkip}
                           onAddFakeWord={(text) => addSkip({ text, isFake: true })}/>
        </Container>
    );
};


export { ArrangeEdit };
