/**
 * MessageMediaWidgetItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ChatMessageItem } from '@/types/chat';
import { MessageMediaWidgetType } from '@/codegen/graphql';

import { ChatWidget } from '../Widgets';


export interface MessageMediaWidgetItemProps {
    chatId: number;
    message: ChatMessageItem;
    widgetId: number;
    widget: ChatMessageItem['medias'][number]['widget'];
}


const MessageMediaWidgetItem = (props: MessageMediaWidgetItemProps) => {

    const { widget } = props;

    switch (widget?.type) {
        case MessageMediaWidgetType.CourseLessonPracticeAttempt:
            return <ChatWidget.PracticeAttempt {...props}/>;

        default:
            return <></>;
    }
};


export { MessageMediaWidgetItem };
