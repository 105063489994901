/**
 * ManageCourseCreateModal
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useLayoutEffect, useRef } from 'react';

import { Formik, FormikProps } from 'formik';

import { createValidator } from 'class-validator-formik';

import { observer } from 'mobx-react';

import { PlatformStore } from '@/store/platform';

import { Storage } from '@/api/storage';
import { useStableModalParams } from '@/router/index';
import { useFormikDraft, useI18n, useI18nStatic } from '@/hooks/core';
import { AutoSaveForm, Field, If, Link, Modal, Platform } from '@/cutils';

import { ContentCategorySpace, CourseType, EducationSubject, ProductCurrency } from '@/codegen/graphql';

import { Icon24AddSquareOutline } from '@vkontakte/icons';
import { Button, FormItem, Input, SegmentedControl, Title } from '@exode.ru/vkui';

import { useCourseManage } from '@/hooks/apollo';
import { CreateCourseInput } from '@/libs/class-validator';

import { InnerHtml } from '@/components/Atoms/InnerHtml';
import { FieldBottomLimiter } from '@/components/Atoms/FieldBottomLimiter';
import { SubjectSearchSelect } from '@/components/Subject/SubjectSearchSelect';
import { ContentCategorySearchSelect } from '@/components/Content/CategorySearchSelect';


interface Props {
    id: string;
}


const ManageCourseCreateModal = observer((props: Props) => {

    const { COMMON } = PlatformStore;

    const { t } = useI18n('modals.Course.Create');
    const { t: tStatic } = useI18nStatic('static.dictionary.declension.course');

    const [ { type } ] = useStableModalParams<{ type: CourseType }>();

    const formikRef = useRef<FormikProps<typeof initialValuesForCreate>>(null);

    const {
        createCourse,
        createCourseLoading,
        initialValuesForCreate,
    } = useCourseManage();

    const {
        handleRestore,
        handleSaveDraft,
    } = useFormikDraft({ storageKey: 'draft:course-create' });

    /** Restore draft on mount */
    useLayoutEffect(() => {
        handleRestore(formikRef.current?.setValues);

        setImmediate(() => formikRef.current?.validateForm());
    }, []);

    return (
        <Modal.Card id={props.id} header={(
            <Title level="2" weight="3" className="modal-title first-letter:capitalize">
                {t('createCourseModalTitle', {
                    courseType: tStatic(`single.parental.${type}`).toLowerCase(),
                })}
            </Title>
        )} subheader={t('nextYouWillGoToAdvancedSettings')}>
            <Modal.Body isScrollable className="p-3">
                <Formik validateOnBlur
                        validateOnChange
                        validateOnMount
                        innerRef={formikRef}
                        initialValues={initialValuesForCreate}
                        validate={createValidator(CreateCourseInput)}
                        onSubmit={(values) => {
                            createCourse({ type, ...values });

                            Storage.destroy('draft:course-create');
                        }}>
                    {({
                          values,
                          isValid,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          setFieldValue,
                      }) => (
                        <AutoSaveForm onSubmit={handleSubmit} handleSaveDraft={handleSaveDraft}>
                            <FormItem className="px-0 pb-0"
                                      status={Field.status(errors, touched, 'name')}
                                      top={t('courseNameModal', {
                                          courseType: tStatic(`single.parental.${type}`).toLowerCase(),
                                      })}
                                      bottom={(
                                          <FieldBottomLimiter maxLength={130}
                                                              value={values.name}
                                                              message={Field.message(errors, touched, 'name')}/>
                                      )}>
                                <Input autoFocus
                                       name="name"
                                       maxLength={130}
                                       onBlur={handleBlur}
                                       value={values.name}
                                       data-test="course.name"
                                       placeholder={t('enterCourseNamePlaceholder')}
                                       onChange={Field.transform.ignoreSpaceOnChange(handleChange)}/>
                            </FormItem>

                            <Platform.Market>
                                <FormItem className="px-0" top={(
                                    <div className="flex justify-between">
                                        <>{t('categoryModal')}</>

                                        <Platform.School>
                                            <Link pushModal={{ id: 'manage-school-disciplines' }}>
                                                <span className="text-accent">
                                                    {t('+newCategory')}
                                                </span>
                                            </Link>
                                        </Platform.School>
                                    </div>
                                )}>
                                    <ContentCategorySearchSelect selectedContentCategoryIds={(
                                        [ values?.contentCategoryId ].filter((e) => e) as number[]
                                    )} onSelect={({ value }) => {
                                        setFieldValue('contentCategoryId', value);
                                        setFieldValue('subject', []);
                                    }} filter={{
                                        visible: true,
                                        spaces: [ ContentCategorySpace.Course ],
                                    }}/>
                                </FormItem>

                                <FormItem className="px-0" top={(
                                    <div className="flex justify-between">
                                        <>{t('subjectModal')}</>

                                        <Platform.School>
                                            <Link pushModal={{ id: 'manage-school-disciplines' }}>
                                                <span className="text-accent">
                                                    {t('+newSubject')}
                                                </span>
                                            </Link>
                                        </Platform.School>
                                    </div>
                                )} bottom={(
                                    <If is={!!COMMON?.organization.info.messengerSupportUrl}>
                                        <div>
                                            Нет вашей категории или предмета? Напишите в{' '}
                                            <Link blank toOuter={COMMON?.organization.info.messengerSupportUrl}>
                                                <span className="text-accent">поддержку.</span>
                                            </Link>
                                        </div>
                                    </If>
                                )}>
                                    <SubjectSearchSelect select={{ disabled: !values.contentCategoryId }}
                                                         onSelect={({ value }) => setFieldValue('subject', value)}
                                                         selectedSubjects={[ values.subject || '' as EducationSubject ]}
                                                         filter={{
                                                             contentCategoryIds: [
                                                                 values.contentCategoryId,
                                                             ].filter((e) => e) as number[],
                                                         }}/>
                                </FormItem>
                            </Platform.Market>

                            <FormItem top={t('accessTypeModal')} className="px-0 mb-5" bottom={(
                                <span>
                                    <InnerHtml content={t('reminderAboutAccessType')}/>
                                </span>
                            )}>
                                <SegmentedControl className="thin-border"
                                                  value={values.product.currency}
                                                  onChange={(value) => setFieldValue('product.currency', value)}
                                                  options={[
                                                      {
                                                          value: initialValuesForCreate.product.currency,
                                                          label: t('courseTypePaid'),
                                                      },
                                                      {
                                                          value: ProductCurrency.Free,
                                                          label: t('courseTypeFree'),
                                                      },
                                                  ]}/>
                            </FormItem>

                            <Button stretched
                                    size="l"
                                    type="submit"
                                    mode="commerce"
                                    data-test="course.create-new"
                                    loading={createCourseLoading}
                                    before={<Icon24AddSquareOutline/>}
                                    disabled={!isValid || createCourseLoading}>
                                {t('createCourseBtnModal')}
                            </Button>
                        </AutoSaveForm>
                    )}
                </Formik>
            </Modal.Body>
        </Modal.Card>
    );
});


export { ManageCourseCreateModal };
