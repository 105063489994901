/**
 * DialogsListView
 *
 * @author: exode <hello@exode.ru>
 */

import React, { MutableRefObject } from 'react';

import { ObservableQuery, useApolloClient } from '@apollo/client';

import { observer, useStore } from '@/pages/Core';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';

import { ChatListItem } from '@/types/chat';

import {
    ChatAndProfileSearchFindManyQuery,
    ChatAndProfileSearchFindManyQueryVariables,
    ChatFindManyDocument,
    ChatFindManyQuery,
    ChatFindManyQueryResult,
    ChatFindManyQueryVariables,
} from '@/codegen/graphql';

import { Spinner } from '@exode.ru/vkui';
import { GqlResult } from '@/types/graphql';
import { InfiniteScroll } from '@/helpers/ui/Scroller';

import { ChatCellItem } from '../items/ChatCellItem';

import { useChatListFilter } from '../filter';


export interface DialogsListViewProps {
    /** ID текущего чата */
    activeChatId: number | undefined;
    /** Функция load more (определена по умолчанию) */
    loadMore?: () => void,
    /** Список чатов в кэше */
    chats: ChatListItem[];
    /** Значение текущего курсора */
    cursor: GqlResult<ChatFindManyQueryResult>['chatFindMany']['cursor'];
    /** Ref родителя */
    parentRef: MutableRefObject<HTMLDivElement | null>;
    /** Обработчик клика на чат */
    onChatCellClick: Function
        | ((mode: 'chat' | 'user', ids: { chatId: number } | { userId: number }) => void);
    /** UX флаг */
    isChatCellRounded?: boolean;
    /** Могут быть переопределены внутри loadMode: (fetchMore/variables/previousVariables) */
    fetchMore?: ObservableQuery<ChatFindManyQuery>['fetchMore']
        | ObservableQuery<ChatAndProfileSearchFindManyQuery>['fetchMore'];
    variables?: ChatFindManyQueryVariables;
    previousVariables?: ChatFindManyQueryVariables
        | ChatAndProfileSearchFindManyQueryVariables;
}


const DialogsListView = observer((props: DialogsListViewProps) => {

    const { cache } = useApolloClient();

    const { dtoFilter } = useChatListFilter();
    const { list, sort } = useStore(ChatDialogsPageStore);

    const {
        chats,
        cursor,
        parentRef,
        fetchMore,
        variables,
        activeChatId,
        onChatCellClick,
        previousVariables,
        isChatCellRounded = false,
        loadMore = async () => {
            const fetchMoreVariables = variables
                ? {
                    ...variables,
                    cursor: { before: cursor.before },
                }
                : {
                    filter: dtoFilter,
                    list: { ...list.chats },
                    sort: { ...sort.chats },
                    cursor: { before: cursor.before },
                };

            if (!fetchMore) {
                return;
            }

            const { data: { chatFindMany } } = await fetchMore({
                variables: fetchMoreVariables,
            });

            const cached = cache.readQuery<ChatFindManyQuery>({
                variables: previousVariables,
                query: ChatFindManyDocument,
            });

            if (chatFindMany) {
                cache.writeQuery<ChatFindManyQuery>({
                    variables: previousVariables,
                    query: ChatFindManyDocument,
                    data: {
                        chatFindMany: {
                            __typename: 'ListChatOutput',
                            cursor: {
                                __typename: 'CursorOutput',
                                ...chatFindMany.cursor,
                            },
                            count: chatFindMany.count || 0,
                            items: [
                                ...(cached?.chatFindMany.items ?? []),
                                ...(chatFindMany?.items ?? []),
                            ],
                        },
                    },
                });
            }
        },
    } = props;

    return (
        <InfiniteScroll threshold={1500}
                        useWindow={false}
                        initialLoad={true}
                        loadMore={loadMore}
                        hasMore={cursor.hasPrev}
                        getScrollParent={() => parentRef?.current}
                        loader={<Spinner key={0} className="mb-2.5 h-[60px] loader"/>}>
            {chats?.map((chat) => (
                <ChatCellItem key={chat.id}
                              chat={chat}
                              isRounded={isChatCellRounded}
                              onChatCellClick={onChatCellClick}
                              isActive={activeChatId === chat.id}/>
            ))}
        </InfiniteScroll>
    );
});


export { DialogsListView };
