/**
 * UserListSkeleton component
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { Skeleton } from '@mui/material';
import { Header, List, SimpleCell } from '@exode.ru/vkui';

import { If } from '@/cutils';
import { Page } from '@/pages/Core';


interface Props {
    count?: number;
    wrapRow?: boolean;
    withHeader?: boolean;
    baseAvatarSize?: number;
    baseLineHeight?: number;
    baseSkeletonWidth?: number | string;
    itemClassName?: string;
    listClassName?: string;
    headerClassName?: string;
}


const UserListSkeleton = (props: Props) => {

    const {
        wrapRow,
        withHeader,
        listClassName,
        count = 25,
        headerClassName,
        baseLineHeight = 15,
        baseAvatarSize = 48,
        itemClassName = 'my-2',
        baseSkeletonWidth = 100,
    } = props;

    const content = (
        <>
            <If is={!!withHeader}>
                <Header>
                    <Skeleton height={20}
                              variant="rectangular"
                              width={baseSkeletonWidth}
                              sx={{ width: baseSkeletonWidth }}
                              className={[ 'rounded-sm', headerClassName ].join(' ')}/>
                </Header>
            </If>

            <List className={[ 'flex flex-col', listClassName ].join(' ')}>
                {Array(count).fill(0).map((__, index) => (
                        <SimpleCell disabled key={index} className={itemClassName} before={(
                            <Skeleton className="mr-3"
                                      variant="circular"
                                      width={baseAvatarSize}
                                      height={baseAvatarSize}
                                      data-test="user-avatar-skeleton"/>
                        )} subtitle={(
                            <Skeleton height={13}
                                      variant="rectangular"
                                      width={baseSkeletonWidth}
                                      className="rounded-sm mb-1"/>
                        )}>
                            <Skeleton variant="rectangular"
                                      height={baseLineHeight}
                                      className="rounded-sm mb-1"
                                      sx={{ width: !_.isFinite(+baseSkeletonWidth) ? baseSkeletonWidth : undefined }}
                                      width={(
                                          _.isFinite(+baseSkeletonWidth)
                                              ? +baseSkeletonWidth + 20
                                              : baseSkeletonWidth
                                      )}/>
                        </SimpleCell>
                    ),
                )}
            </List>
        </>
    );

    return wrapRow ? <Page.Row>{content}</Page.Row> : content;
};


export { UserListSkeleton };
