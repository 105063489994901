/**
 * UseLesson
 *
 * @author: exode <hello@exode.ru>
 */

import { useEffect, useState } from 'react';

import { GqlResult } from '@/types/graphql';
import { CourseTabType } from '@/types/course';

import { Router } from '@/services/Utils/Router';
import { useLocation } from '@/router/index';
import { ScrollHelper } from '@/helpers/ui';

import {
    CourseLessonMetaUpsertMutationResult,
    CreateLessonMetaCourseInput,
    useCourseLessonMetaUpsertMutation,
} from '@/codegen/graphql';


export const useLesson = () => {

    const { route: { pageId, params } } = useLocation<{
        tab: CourseTabType
    }>();

    const [ lessonTab, setLessonTab ] = useState<CourseTabType>(params.tab);

    const changeLessonTab = (nextTab: CourseTabType) => {
        if (lessonTab === nextTab) {
            ScrollHelper.to(0, true);

            return;
        }

        setLessonTab(nextTab);

        Router.pushOnDReplaceOnM(pageId, { ...params, tab: nextTab });
    };

    const [
        _upsertMeta,
        {
            loading: upsertLessonMetaLoading,
            error: upsertLessonMetaError,
        },
    ] = useCourseLessonMetaUpsertMutation({
        onError: (error) => console.error(error),
    });

    const upsertLessonMeta = (
        lessonId: number,
        meta: CreateLessonMetaCourseInput,
        onCompleted?: (meta: GqlResult<CourseLessonMetaUpsertMutationResult>['courseLessonMetaUpsert']) => void,
    ) => {
        return _upsertMeta({
            variables: { lessonId, meta },
            onCompleted: (data) => {
                onCompleted?.(data.courseLessonMetaUpsert);
            },
        });
    };

    useEffect(() => {
        setLessonTab(params.tab || 'lesson');
    }, [ params.tab ]);

    return {
        lessonTab,
        changeLessonTab,
        upsertLessonMeta,
        upsertLessonMetaLoading,
        upsertLessonMetaError,
    };
};
