/**
 * Scrollbar custom function
 *
 * @author: exode <hello@exode.ru>
 */

export const customScrollbar = (bg = '--background_content') => {
    return `
    ::-webkit-scrollbar {
        width: 8px !important;
        height: 8px !important;
        background-color: var(${bg}) !important;
    }
    
    ::-webkit-scrollbar-track {
        margin-top: 5px !important;
        border-radius: 10px !important;
        background-color: var(${bg}) !important;
    }
    
    ::-webkit-scrollbar-track-piece {
        border-radius: 16px !important;
    }
    
    ::-webkit-scrollbar-thumb {
        border-radius: 16px !important;
        border: 1px solid var(${bg}) !important;
        background-color: var(--vkui--color_icon_tertiary_alpha, #999) !important;
    }
    
    ::-webkit-scrollbar-button {
        display: none !important;
    }
    
    * {
        scrollbar-width: thin !important;
        scrollbar-color: var(--vkui--color_icon_tertiary_alpha, #999) var(${bg}, transparent) !important;
    }
    
    .ModalRoot {
        ::-webkit-scrollbar-track {
             background-color:  ${bg === '--background_content' ? 'var(--modal_card_background)' : `var(${bg})`} !important;
        }
    
        ::-webkit-scrollbar {
             background-color:  ${bg === '--background_content' ? 'var(--modal_card_background)' : `var(${bg})`} !important;
        }
    }
`;
};
