/**
 * BrandingPart component
 *
 * @author: exode <hello@exode.ru>
 */

import { observer } from 'mobx-react';

import { RouterStore } from '@/store/core/router';

import { useRedirect } from '@/cutils';


const BrandingPart = observer(() => {

    const { redirect } = useRedirect();

    return (
        <div onClick={() => redirect('https://exode.biz/')} className={[
            'flex items-center justify-center opacity-70',
            'font-vk-sans-display hover:opacity-100 cursor-pointer',
            'm:mb-[var(--tabbar\\_height)] m:fs-15 h-[56px] m:h-[48px]',
            RouterStore.showTabbar ? 'pb-safe-tabbar' : '',
        ].join(' ')}>
            <>Powered by exode</>
        </div>
    );
});


export { BrandingPart };
