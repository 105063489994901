/**
 * EditorJsOutput styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { customScrollbar } from '@/styles/modules/scrollbar';


export const EditorJsOutputContainer = styled.div`
    line-height: 150%;
    font-size: calc(var(--vkui--font_text--font_size--regular) + 2px);

    /** Text styles */

    h1, h2, h3, h4, h5, h6 {
        font-weight: 400;
        line-height: 130%;
        font-family: var(--font-display), sans-serif;

        &:first-child {
            margin-top: 0 !important;
            margin-bottom: 8px !important;
        }
    }

    h1 {
        font-size: calc(var(--vkui--font_text--font_size--regular) + 5px);
    }

    h2 {
        font-size: calc(var(--vkui--font_text--font_size--regular) + 4px);
    }

    h3 {
        font-size: calc(var(--vkui--font_text--font_size--regular) + 3px);
    }

    h4 {
        font-size: calc(var(--vkui--font_text--font_size--regular) + 2px);
    }

    h5 {
        font-size: calc(var(--vkui--font_text--font_size--regular) + 1px);
    }

    h6 {
        font-size: calc(var(--vkui--font_text--font_size--regular));
    }

    a {
        color: var(--accent);
        text-decoration: none;
    }

    b {
        font-weight: 500;
    }

    .editor-js-paragraph {
        &:first-child {
            margin-top: 0;
        }
    }

    /** Table styles */

    .editor-js-table {
        width: 100%;
        display: block;
        margin: 5px 0 0;
        padding-bottom: 5px;
        table-layout: fixed;
        overflow-x: auto;
        border-spacing: 15px 0 !important;

        ${customScrollbar()};

        th {
            box-shadow: inset 0 0 0 1px var(--input_border);
        }

        tr {
            margin-top: -1px;

            td {
                border-top: none !important;
                vertical-align: top;
            }
        }

        tr:last-child {
            td {
                border-radius: 0 0 14px 14px !important;
            }
        }
    }

    /** List styles */

    [type="checkbox"] {
        height: 18px !important;
        min-height: 18px !important;
        width: 18px !important;
        min-width: 18px !important;
    }

    ol,
    ul {
        max-width: initial !important;
        padding-left: 1.5rem !important;
        margin-top: 8px !important;
        margin-bottom: 8px !important;

        li:not(:first-child) {
            margin-top: 8px;
        }
    }

    li {
        padding-left: 6px;

        &::marker {
            color: var(--text_primary);
            font-weight: bold;
            padding: 8px;
            background: var(--blue_primary);
        }
    }

    /** Video (iframe) */

    figure {
        height: auto !important;

        iframe {
            border-radius: var(--vkui--size_border_radius_paper--regular) !important;
        }
    }
`;
