/**
 * ComponentView
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { If } from '@/cutils';


interface Props {
    component: ReactElement | null;
}


const ComponentView = (props: Props) => {

    const { component } = props;

    return (
        <If is={!!component}>
            {component}
        </If>
    );
};


export { ComponentView };
