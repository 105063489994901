/**
 * Helper
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useEffect } from 'react';

import { FormikProps } from 'formik';

import { useEditorJs } from '@/hooks/core';

import { DocumentEvent } from '@/types/window';
import { ContentElementMyInteractionFragmentFragment, ContentElementType } from '@/codegen/graphql';

import { ContentElementItem } from './interfaces';
import { useContentElementContext } from './contexts/ContentElementContext';


/**
 * Get CE context value (merged with common)
 * @param {} type
 */
export const getContentElementContextValue = (type: ContentElementType) => {
    const {
        edit,
        show,
        edit: { common: editCommon = {} } = {},
        show: { common: showCommon = {} } = {},
    } = useContentElementContext();

    return {
        edit: _.assign(editCommon, edit?.[type]),
        show: _.assign(showCommon, show?.[type]),
    };
};

/**
 * Check content element is filled
 * @param {ContentElementItem} contentElement
 * @param {ReturnType<typeof useEditorJs>} editorJsHook
 * @returns {boolean}
 */
export const checkContentElementIsFilled = (
    contentElement: ContentElementItem,
    editorJsHook: ReturnType<typeof useEditorJs>,
): boolean => {
    const { isNotEmpty, isValidOutputData } = editorJsHook;

    switch (contentElement.type) {
        case ContentElementType.Audio:
            return !!contentElement.content.location;

        case ContentElementType.Button:
            return !!contentElement.content.buttons?.some((e: any) => e?.text);

        case ContentElementType.ChatMessage:
            return !!contentElement.content.messages?.some((e: any) => e?.text);

        case ContentElementType.Checklist:
            return !!contentElement.content.items.length;

        case ContentElementType.Text:
        case ContentElementType.EditorJsBlock:
            return (
                !!contentElement.title
                || (
                    !!isValidOutputData(contentElement.content)
                    && isNotEmpty(contentElement.content)
                )
            );

        case ContentElementType.Video:
            return !!contentElement.content.location;

        case ContentElementType.NotionPage:
            return !!contentElement.content.notionUrl;

        default:
            return true;
    }
};

/**
 * Reinitialize interaction formik
 */
export const useMyInteractionReinitialize = (
    contentElementUuid: string,
    formikRef: React.RefObject<FormikProps<any>>,
) => {

    const handleReinitialize = (e: any) => {
        try {
            const { uuid, myInteraction } = e.detail as {
                uuid: string;
                myInteraction: ContentElementMyInteractionFragmentFragment,
            };

            if (uuid === contentElementUuid) {
                formikRef.current?.resetForm({
                    values: myInteraction.data,
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        document.addEventListener(
            DocumentEvent.ContentElementMyInteractionUpdated,
            handleReinitialize,
        );

        return () => {
            document.removeEventListener(
                DocumentEvent.ContentElementMyInteractionUpdated,
                handleReinitialize,
            );
        };
    }, []);
};
